import React, { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { BiMenuAltLeft } from "react-icons/bi";
import { useDispatch } from "react-redux";

const Filter = ({
  getAllPredictions,
  predictionStatus,
  setPredictionStatus,
  search,
  totalResults,
  searchLoading,
  onlyFollowedUsers,
  user,
}) => {
  // handle dropdown
  const [dropdown, setDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const handleClick = () => {
    setDropdown(!dropdown);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef]);

  // handle dropdown
  const dispatch = useDispatch();

  const getAllOfPredictions = () => {
    setPredictionStatus("all");
    dispatch(
      getAllPredictions({
        totalResults,
        predictionStatus: "all",
        searchPrediction: search,
        onlyFollowedUsers,
        userId: user ? user?._id : "",
      })
    );
  };

  const getGrayPredictions = () => {
    setPredictionStatus(0);
    dispatch(
      getAllPredictions({
        totalResults,
        predictionStatus: 0,
        searchPrediction: search,
        onlyFollowedUsers,
        userId: user ? user?._id : "",
      })
    );
  };
  const getGreenPredictions = () => {
    setPredictionStatus(2);
    dispatch(
      getAllPredictions({
        totalResults,
        predictionStatus: 2,
        searchPrediction: search,
        onlyFollowedUsers,
        userId: user ? user?._id : "",
      })
    );
  };
  const getRedPredictions = () => {
    setPredictionStatus(1);
    dispatch(
      getAllPredictions({
        totalResults,
        predictionStatus: 1,
        searchPrediction: search,
        onlyFollowedUsers,
        userId: user ? user?._id : "",
      })
    );
  };
  const getYellowPredictions = () => {
    setPredictionStatus(3);
    dispatch(
      getAllPredictions({
        totalResults,
        predictionStatus: 3,
        searchPrediction: search,
        onlyFollowedUsers,
        userId: user ? user?._id : "",
      })
    );
  };
  return (
    <div className="flex items-center justify-end">
      <div className="tips-sort-options ">
        <div
          ref={dropdownRef}
          onClick={handleClick}
          className={`dropdown tips-sort-options__dropdown ${
            dropdown ? "active" : ""
          }`}
        >
          <button
            className={`button_small dropdown__toggle h-10 text-gray-500 ${
              dropdown ? "bg-white shadow-md" : "bg-gray-100"
            }`}
            disabled={searchLoading}
          >
            <span
              title={
                predictionStatus === "all"
                  ? "Toate"
                  : predictionStatus === 0
                  ? "Desfasurare"
                  : predictionStatus === 1
                  ? "Pierdute"
                  : predictionStatus === 2
                  ? "Castigate"
                  : "Anulate"
              }
              className="t-ellipsis text-xs"
            >
              {predictionStatus === "all"
                ? "Toate"
                : predictionStatus === 0
                ? "Desfasurare"
                : predictionStatus === 1
                ? "Pierdute"
                : predictionStatus === 2
                ? "Castigate"
                : "Anulate"}
            </span>
            <span className="dropdown__toggle__icon">
              <BiMenuAltLeft className="text-2xl text-blue-400" />
            </span>
          </button>
          <div className="dropdown__content dropdown__content--full w-full bg-white">
            <div
              onClick={getAllOfPredictions}
              title="Toate"
              className={`dropdown__list-item hover:text-blue-400 t-ellipsis  ${
                predictionStatus === "all" ? "active" : ""
              }`}
            >
              <span
                className={`t-ellipsis ${
                  predictionStatus === "all" ? "text-blue-400" : ""
                }`}
              >
                Toate
              </span>
            </div>
            <div
              onClick={getGrayPredictions}
              title="Desfasurare"
              className={`dropdown__list-item hover:text-sky-400 t-ellipsis ${
                predictionStatus === 0 ? "active" : ""
              }`}
            >
              <span
                className={`t-ellipsis ${
                  predictionStatus === 0 ? "text-sky-400" : ""
                }`}
              >
                Desfasurare
              </span>
            </div>
            <div
              onClick={getRedPredictions}
              title="Pierdute"
              className={`dropdown__list-item hover:text-sky-400 t-ellipsis ${
                predictionStatus === 1 ? "active" : ""
              }`}
            >
              <span
                className={`t-ellipsis ${
                  predictionStatus === 1 ? "text-sky-400" : ""
                }`}
              >
                Pierdute
              </span>
            </div>
            <div
              onClick={getGreenPredictions}
              title="Castigate"
              className={`dropdown__list-item hover:text-sky-400 t-ellipsis ${
                predictionStatus === 2 ? "active" : ""
              }`}
            >
              <span
                className={`t-ellipsis ${
                  predictionStatus === 2 ? "text-sky-400" : ""
                }`}
              >
                Castigate
              </span>
            </div>
            <div
              onClick={getYellowPredictions}
              title="Anulate"
              className={`dropdown__list-item hover:text-sky-400 t-ellipsis ${
                predictionStatus === 3 ? "active" : ""
              }`}
            >
              <span
                className={`t-ellipsis ${
                  predictionStatus === 3 ? "text-sky-400" : ""
                }`}
              >
                Anulate
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filter;

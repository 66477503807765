import React, { useEffect, useRef, useState } from "react";
import {
  FaArrowLeft,
  FaArrowRight,
  FaArrowUp,
  FaCalendarAlt,
  FaTimes,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllMatchesV2,
  getMatchOdds,
  RESETODDS,
} from "../../redux/features/rapidapi/rapidApiSlice";
import { addPrediction } from "../../redux/features/predictions/predictionsSlice";
import { addCotadedoi } from "../../redux/features/cotadedoi/cotadedoiSlice";
import { addBiletulzilei } from "../../redux/features/biletulzilei/biletulzileiSlice";
import { useNavigate } from "react-router-dom";
import LoaderWithText from "../../utils/Loaders/LoaderWithText";
import { convertDate } from "../../utils/ConvertDate/convertDate";
import TipHeader from "./TipHeader";
import { FiSearch } from "react-icons/fi";
import DatePicker from "react-datepicker";
import { ro } from "date-fns/locale";
import Leagues from "./Leagues";
import InfoPopup from "./InfoPopup";
import DuckLoader from "../../utils/Loaders/DuckLoader";
import OddsHeader from "./OddsHeader";
import MatchOdds from "./MatchOdds";
import MatchNoOdds from "./MatchNoOdds";
import TipAcumulator from "./TipAcumulator";
import SuccessPost from "./SuccessPost";
import { toast } from "react-toastify";

const CreatePrediction = ({
  isOpen,
  setIsOpen,
  evenimentePeBilet,
  setEvenimentePeBilet,
  toggleAccumulator,
  setToggleAccumulator,
  handleShowMatches,
  showMatches,
  startDate,
  setStartDate,
  setShowMatches,
}) => {
  useEffect(() => {
    const matchDate = {
      date: convertDate(new Date()),
    };
    dispatch(getAllMatchesV2(matchDate));
  }, []);
  const { matches, odds, isError, message, isErrorOdds, bookmaker, isLoading } =
    useSelector((state) => state.apiMatches);
  const { user } = useSelector((state) => state.auth);
  const { RESET } = useSelector((state) => state.predictions);

  const [todayDate, setTodayDate] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTodayDate(new Date());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const tomorrow = new Date(todayDate.getTime() + 24 * 60 * 60 * 1000);
  const dayAfterTomorrow = new Date(tomorrow.getTime() + 24 * 60 * 60 * 1000);
  const [matchOdds, setMatchOdds] = useState([]);
  const [filteredMatch, setFilteredMatch] = useState();
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // handle description //
  const [description, setDescription] = useState("");
  // handle description //
  // function to remove odds from selected match (cors error)
  const removeOdds = (obj) => {
    let { fixture } = obj;
    fixture = { ...fixture, odds: [] };
    return { ...obj, fixture };
  };
  // end
  const handleSelectedMatch = async (fixture) => {
    setLoadingOdds(true);
    dispatch(RESETODDS());
    const fixtureWOodds = removeOdds(fixture);
    setSelectedMatch(fixtureWOodds);
    await dispatch(
      getMatchOdds({
        date: convertDate(startDate),
        matchId: fixture.fixture.id,
      })
    );
  };

  // set odds after match selected //

  useEffect(() => {
    setMatchOdds(odds);
  }, [odds]);
  // end

  const setSearchEvent = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoadingOdds(false);
    }, 1500);
  }, [odds]);

  // sort by leagues //
  const [leagues, setLeagues] = useState([]);
  const [loadingLeagues, setLoadingLeagues] = useState(false);
  const [loadingOdds, setLoadingOdds] = useState(false);

  useEffect(() => {
    setLeagues([]);
    setLoadingLeagues(true);
    setLeagues((leagues) => {
      if (!filteredMatch) {
        return leagues;
      }
      const mappedLeagues = new Map();
      filteredMatch.forEach((fixture) => {
        if (!mappedLeagues.has(fixture.league.id)) {
          mappedLeagues.set(fixture.league.id, {
            leagueId: fixture.league.id,
            leagueCountry: fixture.league.country,
            leagueName: fixture.league.name,
            fixtures: [],
          });
        }

        mappedLeagues.get(fixture.league.id).fixtures.push(fixture);
      });
      return [...mappedLeagues.values()];
    });
  }, [filteredMatch, showMatches]);

  useEffect(() => {
    setTimeout(() => {
      setLoadingLeagues(false);
    }, 1500);
  }, [leagues]);

  // sort by leagues

  // Preia datele din API

  const getMatchesSelect = async (date) => {
    setLoadingLeagues(true);
    setStartDate(date);
    setFilteredMatch([]);
    setSearch("");
    const matchDate = {
      date: convertDate(date),
    };
    await dispatch(getAllMatchesV2(matchDate));
  };
  // Preia datele din API

  // Filtrare
  const filteredMatches = matches?.filter((eveniment) => {
    const evenimentNume =
      eveniment.teams.home.name + " vs " + eveniment.teams.away.name;

    return evenimentNume.toLowerCase().includes(search.toLowerCase());
  });

  useEffect(() => {
    setFilteredMatch(matches);
  }, [isLoading]);

  useEffect(() => {
    setFilteredMatch(filteredMatches);
  }, [search]);
  // Filtrare
  useEffect(() => {
    const divEl = document.querySelector(".create-tip-menu-wrapper");
    const body = document.querySelector("body");

    if (divEl && isOpen) {
      divEl.classList.add("create-tip-menu-wrapper--open");
      body.classList.add("modal-opened");
      return () => {
        divEl.classList.remove("create-tip-menu-wrapper--open");
        body.classList.remove("modal-opened");
      };
    } else {
    }
  }, [isOpen]);

  // scroll element to top
  const [showElement, setShowElement] = useState(false);
  const divRef = useRef(null);

  const handleScroll = () => {
    if (divRef.current.scrollTop > 250) {
      setShowElement(true);
    } else {
      setShowElement(false);
    }
  };
  const handleClickToTop = () => {
    divRef.current.scrollTo({ top: 0, behavior: "smooth" });
  };
  // scroll element to top

  const handleGoBack = () => {
    setSelectedMatch(null);
    setSearch("");
    dispatch(RESETODDS());
    setToggleAccumulator(false);
  };

  // accumulator actions //

  const handleDeleteTicket = () => {
    setEvenimentePeBilet([]);
    localStorage.removeItem("evenimentePeBilet");
    setToggleAccumulator(false);
    setDescription("");
  };
  // accumulator actions //

  // on select prediction, show odds

  const [selectedBet, setSelectedBet] = useState("");

  const [selectedValue, setSelectedValue] = useState("");
  const [selectedOdd, setSelectedOdd] = useState("");

  useEffect(() => {
    dispatch(RESETODDS());
    setSelectedBet("");
    setSelectedValue("");
    setSelectedOdd("");
  }, [selectedMatch]);

  const handleValueChange = (odd, value) => {
    const matchAdded = {
      eveniment: selectedMatch,
      prediction: {
        id: odd.id,
        name: odd.name,
        value: value.value,
        odd: value.odd,
        status: 0,
      },
    };
    setEvenimentePeBilet((prevBilet) => {
      return prevBilet
        .filter(
          (match) => match.eveniment.fixture.id !== selectedMatch.fixture.id
        )
        .concat(matchAdded);
    });
    setToggleAccumulator(true);
  };

  // chatgpt
  // on select prediction, show odds

  const handleDeleteEvenimentDePeBilet = (id) => {
    setDescription("");
    setEvenimentePeBilet((prevBilet) => {
      return evenimentePeBilet.filter((obj) => obj.eveniment.fixture.id !== id);
    });
    localStorage.setItem(
      "evenimentePeBilet",
      JSON.stringify(
        evenimentePeBilet.filter((obj) => obj.eveniment.fixture.id !== id)
      )
    );
  };

  // multiply odds //
  const multiplyOdds = (obj) => {
    let product = 1;
    obj.forEach((item) => {
      product *= parseFloat(item.prediction.odd);
    });
    return product;
  };
  // multiply ods //
  const [totalOdd, setTotalOdd] = useState(null);
  const [totalReturn, setTotalReturn] = useState(null);
  const [stake, setStake] = useState(10);
  const [maxStakeValue, setMaxStakeValue] = useState(0);

  useEffect(() => {
    setTotalOdd(multiplyOdds(evenimentePeBilet).toFixed(2));
  }, [evenimentePeBilet]);

  useEffect(() => {
    setTotalReturn((totalOdd * stake).toFixed(2));
  }, [stake, totalOdd]);

  const [loadingAdd, setLoadingAdd] = useState(false);
  const [tipComplete, setTipComplete] = useState(false);

  // handle stake input //

  const calculateStakeValue = (odd) => {
    let stakeValue = 0;
    if (odd >= 1.01 && odd <= 2.49) {
      stakeValue = 10;
    } else if (odd >= 2.5 && odd <= 2.74) {
      stakeValue = 9;
    } else if (odd >= 2.75 && odd <= 2.99) {
      stakeValue = 8;
    } else if (odd >= 3.0 && odd <= 3.99) {
      stakeValue = 7;
    } else if (odd >= 4.0 && odd <= 4.99) {
      stakeValue = 6;
    } else if (odd >= 5.0 && odd <= 5.99) {
      stakeValue = 5;
    } else if (odd >= 6.0 && odd <= 7.99) {
      stakeValue = 4;
    } else if (odd >= 8.0 && odd <= 9.99) {
      stakeValue = 3;
    } else if (odd >= 10.0 && odd <= 19.99) {
      stakeValue = 2;
    } else {
      stakeValue = 1;
    }
    return stakeValue;
  };

  const handleStakeChange = (e) => {
    if (e.target.value >= 1) {
      const stakeValue = e.target.value;
      setMaxStakeValue(calculateStakeValue(totalOdd));
      if (isCotaDeDoi) {
        setStake(10);
      } else {
        setStake(Math.min(stakeValue, maxStakeValue));
      }
    }
  };

  useEffect(() => {
    setMaxStakeValue(calculateStakeValue(totalOdd));
    setStake(calculateStakeValue(totalOdd));
  }, [totalOdd]);
  // handle stake input //

  // handle close ticket x //
  const handleCloseTicket = () => {
    const newValue = !JSON.parse(localStorage.getItem("showMatches"));
    localStorage.setItem("showMatches", JSON.stringify(newValue));

    // Trigger the storage event manually to notify other components
    const storageEvent = new StorageEvent("storage", {
      key: "showMatches",
      newValue: JSON.stringify(newValue),
    });
    window.dispatchEvent(storageEvent);
    setShowMatches(!showMatches);
    setTipComplete(false);
  };
  // handle close ticket x //

  // handle create new tip button //
  const handleCreateNewTicket = () => {
    setTipComplete(false);
    setDescription("");
    setStartDate(todayDate);
  };

  // handle create new tip button //

  // handle view my tip button //
  const handleViewMyTip = () => {
    navigate("/profile");
    setDescription("");
  };
  // handle view my tip button //

  // set to localstorage evenimente pe bilet //
  useEffect(() => {
    if (selectedMatch !== null) {
      localStorage.setItem(
        "evenimentePeBilet",
        JSON.stringify(evenimentePeBilet)
      );
    }
  }, [
    selectedMatch,
    evenimentePeBilet,
    handleValueChange,
    handleDeleteEvenimentDePeBilet,
  ]);

  // check if match started //
  const [disabledEvents, setDisabledEvents] = useState([]);

  const handleErrorClose = () => {
    dispatch(RESET());
    handleGoBack();
  };

  useEffect(() => {
    const divEl = document.querySelector(".notification__body");

    if ((divEl && isError) || isError) {
      divEl.classList.add("create-tip-menu__body--overlay");
      divEl.classList.add("body-scroll-hidden");
      return () => {
        divEl.classList.remove("create-tip-menu__body--overlay");
        divEl.classList.remove("body-scroll-hidden");
      };
    } else {
    }
  }, [isError]);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTimestamp = Date.now();
      const updatedDisabledEvents = evenimentePeBilet.filter(
        (event) => event.eveniment.fixture.timestamp * 1000 < currentTimestamp
      );
      setDisabledEvents(updatedDisabledEvents);
      // setAnError(true);
    }, 60000);
    return () => clearInterval(interval);
  }, [evenimentePeBilet, setDisabledEvents]);

  useEffect(() => {
    const currentTimestamp = Date.now();
    const updatedDisabledEvents = evenimentePeBilet.filter(
      (event) => event.eveniment.fixture.timestamp * 1000 < currentTimestamp
    );
    setDisabledEvents(updatedDisabledEvents);
  }, [evenimentePeBilet]);
  // end

  const [isCotaDeDoi, setIsCotaDeDoi] = useState(false);
  const [cotaDeDoiDate, setCotaDeDoiDate] = useState(null);
  const [isBiletulZilei, setIsBiletulZilei] = useState(false);
  const [biletulZileiDate, setBiletulZileiDate] = useState(null);

  const handlePublicBilet = async () => {
    setSelectedMatch(null);
    setLoadingAdd(true);
    handleGoBack();
    setTipComplete(true);
    setDescription("");
    setEvenimentePeBilet([]);
    localStorage.removeItem("evenimentePeBilet");
    setStartDate(todayDate);

    if (isCotaDeDoi) {
      const biletcotadedoi = {
        evenimente: evenimentePeBilet,
        totalOdd: totalOdd,
        stake: stake,
        status: 0,
        totalReturn: totalReturn,
        totalEvenimente: evenimentePeBilet.length,
        type: evenimentePeBilet.length === 1 ? "simplu" : "multiplu",
        description: description,
        date: convertDate(cotaDeDoiDate),
      };
      await dispatch(addCotadedoi(biletcotadedoi))
        .then((res) => {
          console.log(res);
          if (res.payload !== "Error saving data.") {
            toast.success(
              `${
                evenimentePeBilet.length > 1
                  ? `Bilet ${evenimentePeBilet.length} evenimente - cota totala ${totalOdd} adaugat cu succes!`
                  : `Predictie ${
                      evenimentePeBilet[0].eveniment.teams.home.name +
                      " - " +
                      evenimentePeBilet[0].eveniment.teams.away.name
                    } - cota ${totalOdd} adaugata cu succes!`
              }`,
              {
                position: "top-right",
                className: "custom-notification-container-announcement",
                autoClose: true,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              }
            );
          }
          getMatchesSelect(todayDate);
        })
        .catch((error) => {
          toast.error("A aparut o eroare la adaugarea biletului");
        });
    } else if (isBiletulZilei) {
      const biletbiletulzilei = {
        evenimente: evenimentePeBilet,
        totalOdd: totalOdd,
        stake: stake,
        status: 0,
        totalReturn: totalReturn,
        totalEvenimente: evenimentePeBilet.length,
        type: evenimentePeBilet.length === 1 ? "simplu" : "multiplu",
        description: description,
        date: convertDate(biletulZileiDate),
      };
      await dispatch(addBiletulzilei(biletbiletulzilei))
        .then((res) => {
          console.log(res);
          if (res.payload !== "Error saving data.") {
            toast.success(
              `${
                evenimentePeBilet.length > 1
                  ? `Bilet ${evenimentePeBilet.length} evenimente - cota totala ${totalOdd} adaugat cu succes!`
                  : `Predictie ${
                      evenimentePeBilet[0].eveniment.teams.home.name +
                      " - " +
                      evenimentePeBilet[0].eveniment.teams.away.name
                    } - cota ${totalOdd} adaugata cu succes!`
              }`,
              {
                position: "top-right",
                className: "custom-notification-container-announcement",
                autoClose: true,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              }
            );
          }
          getMatchesSelect(todayDate);
        })
        .catch((error) => {
          toast.error("A aparut o eroare la adaugarea biletului");
        });
    } else {
      const bilet = {
        evenimente: evenimentePeBilet,
        totalOdd: totalOdd,
        stake: stake,
        status: 0,
        totalReturn: totalReturn,
        totalEvenimente: evenimentePeBilet.length,
        type: evenimentePeBilet.length === 1 ? "simplu" : "multiplu",
        description: description,
        createdAt: new Date(),
      };
      await dispatch(addPrediction(bilet))
        .then((res) => {
          console.log(res);
          if (res.payload !== "Error saving data.") {
            toast.success(
              `${
                evenimentePeBilet.length > 1
                  ? `Bilet ${evenimentePeBilet.length} evenimente - cota totala ${totalOdd} adaugat cu succes!`
                  : `Predictie ${
                      evenimentePeBilet[0].eveniment.teams.home.name +
                      " - " +
                      evenimentePeBilet[0].eveniment.teams.away.name
                    } - cota ${totalOdd} adaugata cu succes!`
              }`,
              {
                position: "top-right",
                className: "custom-notification-container-announcement",
                autoClose: true,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              }
            );
          }
          getMatchesSelect(todayDate);
        })
        .catch((error) => {
          toast.error("A aparut o eroare la adaugarea biletului");
        });
    }

    setTimeout(() => {
      setLoadingAdd(false);
    }, 1500);
  };

  // focus textarea
  const textareaRef = useRef(null);
  useEffect(() => {
    if (showMatches) {
      textareaRef.current.focus();
    }
  }, [showMatches]);
  return (
    <>
      <div className="notification__header notification__header_opened notification__header-add-prediction">
        <div className="flex items-center gap-2">
          {selectedMatch !== null ? (
            <div
              className="button_square button_square-xs"
              onClick={handleGoBack}
            >
              <span className="icon-keyboard_arrow_left">
                <FaArrowLeft />
              </span>
            </div>
          ) : null}

          <span className="sniper-title">Adauga predictie</span>
        </div>
        <div className="flex-row align-center">
          {/* <button className="button_small" type="button">
            Join for Free
          </button> */}
          <div className="header-hold">
            <div
              className="button_square button_square-xs"
              onClick={handleCloseTicket}
            >
              <span className="icon-keyboard_arrow_left">
                <FaTimes />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="notification__body" onScroll={handleScroll} ref={divRef}>
        <div>
          {loadingAdd ? (
            <DuckLoader rowCount={12} />
          ) : (
            <SuccessPost
              tipComplete={tipComplete}
              setTipComplete={setTipComplete}
              setDescription={setDescription}
              setStartDate={setStartDate}
              todayDate={todayDate}
            />
          )}
          <div
            className={`sniper-rolls-board-block ${
              selectedMatch !== null || tipComplete ? "hidden" : ""
            }`}
          >
            <form className="items-center flex w-full">
              <div
                className="relative w-full"
                style={{
                  margin: "12px",
                  height: "42px",
                  minHeight: "42px",
                  border: "none",
                }}
              >
                <div
                  className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
                  style={{ height: 36 }}
                >
                  <FiSearch className="w-5 h-5 text-gray-500 dark:text-gray-400" />
                </div>
                <input
                  ref={textareaRef}
                  value={search}
                  onChange={(e) => setSearchEvent(e)}
                  type="text"
                  className="bg-slate-100 border-none focus:border-none focus:ring-0 border-none text-gray-900 text-sm w-full pl-10 placeholder:text-xs dark:bg-bgMain2 dark:placeholder-gray-400 dark:text-white mb-0 hover:ring-white"
                  placeholder="Cauta dupa echipe"
                  required
                  style={{
                    height: 36,
                    border: "none",
                    borderRadius: "3px",
                    background: "linear-gradient(90deg,#465059 100%,#3e4851 0)",
                  }}
                />
              </div>
            </form>
            <div
              className="flex"
              style={{
                marginRight: "12px",
                marginBottom: "12px",
              }}
            >
              <button
                onClick={() => getMatchesSelect(todayDate)}
                disabled={convertDate(todayDate) === convertDate(startDate)}
                className="button_secondary button_medium mr-2"
              >
                <span className="button-text">Astazi</span>
              </button>
              <DatePicker
                className="button_icon button_icon-custom focus:ring-0 focus:ring-offset-0 focus:border-transparent"
                selected={startDate}
                onChange={(date: Date) => getMatchesSelect(date)}
                dateFormat="yyyy-MM-dd"
                minDate={todayDate}
                maxDate={dayAfterTomorrow}
                locale={ro}
                style={{ borderColor: "transparent" }}
              />
            </div>
          </div>
          <div
            className={`${
              selectedMatch !== null || tipComplete ? "hidden" : ""
            } create-tip-matches flex flex-col`}
          >
            {isLoading || loadingLeagues ? (
              <DuckLoader rowCount={12} />
            ) : (
              <Leagues
                evenimentePeBilet={evenimentePeBilet}
                leagues={leagues}
                handleSelectedMatch={handleSelectedMatch}
              />
            )}
          </div>
          <InfoPopup
            isError={isError}
            message={message}
            handleErrorClose={handleErrorClose}
          />
          <div
            className={`create-tip-match-info ${
              selectedMatch !== null ? "" : "hidden"
            } ${evenimentePeBilet?.length > 0 ? "mb-14" : ""}`}
          >
            {isLoading || loadingOdds ? (
              <div className="flex flex-col">
                <div className="preloader-section">
                  <div className="preloader-section__row">
                    <div className="preloader" style={{ height: 150 }}></div>
                  </div>
                </div>
                <DuckLoader rowCount={10} />
              </div>
            ) : (
              <>
                <OddsHeader
                  selectedMatch={selectedMatch}
                  matchOdds={matchOdds}
                  bookmaker={bookmaker}
                />
                {matchOdds?.map((odd, i) => (
                  <MatchOdds
                    key={i}
                    odd={odd}
                    selectedBet={selectedBet}
                    selectedMatch={selectedMatch}
                    evenimentePeBilet={evenimentePeBilet}
                    setSelectedBet={setSelectedBet}
                    setSelectedValue={setSelectedValue}
                    setSelectedOdd={setSelectedOdd}
                    handleValueChange={handleValueChange}
                  />
                ))}
                {matchOdds?.length === 0 && isErrorOdds ? (
                  <MatchNoOdds handleGoBack={handleGoBack} />
                ) : null}
              </>
            )}
          </div>
          <TipAcumulator
            startDate={startDate}
            setCotaDeDoiDate={setCotaDeDoiDate}
            setIsCotaDeDoi={setIsCotaDeDoi}
            isCotaDeDoi={isCotaDeDoi}
            setBiletulZileiDate={setBiletulZileiDate}
            setIsBiletulZilei={setIsBiletulZilei}
            isBiletulZilei={isBiletulZilei}
            evenimentePeBilet={evenimentePeBilet}
            toggleAccumulator={toggleAccumulator}
            setToggleAccumulator={setToggleAccumulator}
            handleDeleteTicket={handleDeleteTicket}
            disabledEvents={disabledEvents}
            stake={stake}
            description={description}
            setDescription={setDescription}
            handleStakeChange={handleStakeChange}
            handlePublicBilet={handlePublicBilet}
            handleGoBack={handleGoBack}
            totalReturn={totalReturn}
            totalOdd={totalOdd}
            handleDeleteEvenimentDePeBilet={handleDeleteEvenimentDePeBilet}
            user={user}
          />
          {showElement && (
            <div
              className={`${
                evenimentePeBilet?.length > 0 ? "scroll-to-top-b" : ""
              } scroll-to-top-block`}
              onClick={handleClickToTop}
            >
              <FaArrowUp className="w-6 h-6 text-white" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CreatePrediction;

const light = {
  title: "light",
  colors: {
    orange: "#fec33c",
    main: "#3699ff",
    mainDark: "#1b4965",
    mainLight: "#62b6cb",
    mainLighter: "#cae9ff",
    blackAndWhite: "#fff",
    blackAndWhiteR: "#000",
    textButton: "#999999",
    textSecondaryButton: "#464e5f",
    textColor: "#333",
    whiteColor: "#fff",
    shadow: "rgba(0,0,0,.2)",
    cardHeader: "#fff",
    bg1: "#fff",
    bg2: "#f0f0f0",
  },
  backgrounds: {
    bgMain: "#f2f2f2",
    bgHeader: "#ffffff",
    bgCardHome:
      'url("https://www.analizesportive.ro/assets/img/homepage/swish-home-angles2.jpg")',
  },
  buttons: {
    buttonMain: "#3699ff",
  },

  mediaQueries: {
    smallest: `only screen and (max-width: 25em)`,
    small: `only screen and (max-width: 37.5em)`,
    medium: `only screen and (max-width: 56em)`,
    large: `only screen and (max-width: 80em)`,
    largest: `only screen and (max-width: 90em)`,
  },
};

export default light;

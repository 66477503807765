import api from "../../../utils/api";

// Add biletul zilei
const addBiletulzilei = async (biletulzileiData) => {
  const response = await api.post(
    "biletulzilei/biletulzilei",
    biletulzileiData
  );
  return response.data;
};

// Get biletul zilei by date
const getBiletulzileiBydate = async (biletulzileiData) => {
  const response = await api.post(
    "biletulzilei/getBiletulzileiBydate",
    biletulzileiData
  );
  return response.data;
};

// Get all biletulzilei
const getAllBiletulzilei = async ({ totalResults, biletulzileiStatus }) => {
  const response = await api.get(
    `biletulzilei/getAllBiletulzilei?totalResults=${totalResults}&biletulzileiStatus=${biletulzileiStatus}`
  );
  return response.data;
};

// Approve eveniment biletul zilei
const approveBiletulzilei = async (matchData) => {
  const response = await api.post(
    "biletulzilei/approveBiletulzilei",
    matchData
  );
  return response.data;
};

// Approve biletul zilei ticket
const approveBiletulzileiTicket = async (matchData) => {
  const response = await api.post(
    "biletulzilei/approveBiletulzileiTicket",
    matchData
  );
  return response.data;
};

const biletulzileiService = {
  addBiletulzilei,
  getBiletulzileiBydate,
  getAllBiletulzilei,
  approveBiletulzilei,
  approveBiletulzileiTicket,
};

export default biletulzileiService;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Chat from "../../comp/Chat/Chat";
import Notifications from "../../comp/Notifications/Notifications";
import BottomToolbar from "../../comp/BottomToolbar/BottomToolbar";
import Footer from "../../comp/Footer/Footer";
import Header from "../../comp/Header/Header";
import VerifyAccount from "../../comp/VerifyAccount/VerifyAccount";
import Modal from "../../modals/Modal";
import ModalReset from "../../modals/ModalReset";
import ModalVerify from "../../modals/ModalVerify";
import ModalRegister from "../../modals/ModalRegister";
import ModalLoginWithCode from "../../modals/ModalLoginWithCode";
import ModalForgot from "../../modals/ModalForgot";
import ModalLogin from "../../modals/ModalLogin";
import { useLocation, useNavigate } from "react-router-dom";
import {
  RESETPREDICTION,
  getPredictionById,
} from "../../redux/features/predictions/predictionsSlice";
import {
  getCotadedoiBydate,
  getAllCotadedoi,
} from "../../redux/features/cotadedoi/cotadedoiSlice";
import usePredictionDispatch from "../../utils/LikeAndDislike/LikeAndDislike";
import {
  FaArrowAltCircleLeft,
  FaArrowAltCircleRight,
  FaSadTear,
} from "react-icons/fa";
import { convertDate } from "../../utils/ConvertDate/convertDate";
import PredictionBody from "../Prediction/PredictionBody";
import DuckLoader from "../../utils/Loaders/DuckLoader";
import PredictionResult from "../Prediction/PredictionResult";
import CreatePrediction from "../../comp/CreatePrediction/CreatePrediction";
import bzileiLogo from "../../assets/images/bzilei-01.svg";
import { Helmet } from "react-helmet-async";

const formatDate = (date) => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

const Page = ({
  handleShowNotification,
  handleShowChat,
  showChat,
  showNotification,
  handleClick,
  dropdown,
  onlineUsers,
  notificationsCount,
  unreadChatCount,
  setDropdown,
  desktopRef,
  mobileRef,
  setMessages,
  messages,
  isSharedPrediction,
  setNewMessage,
  newMessage,
  showMatches,
  setShowMatches,
  getUser,
  setIsSharedPrediction,
  toggleAccumulator,
  setToggleAccumulator,
  handleShowMatches,
  toggleMobileMenu,
  setMobileMenu,
  mobileMenu,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { isLoading, isLoadingActions, prediction, predictions } = useSelector(
    (state) => state.predictions
  );
  const { user, users, isLoggedIn, isLoadingUsers } = useSelector(
    (state) => state.auth
  );
  const {
    cotadedoi,
    isLoading: isLoadingCotaDeDoi,
    averageTotalOdd,
    last5StatusValues,
    averageYield,
  } = useSelector((state) => state.cotadedoi);
  // change date
  const pathname = location.pathname;
  const dateRegex = /(\d{4}-\d{2}-\d{2})$/;
  const match = pathname.match(dateRegex);
  const dateF = match ? match[1] : null;
  const parsedDate = new Date(dateF);

  const [currentDate, setCurrentDate] = useState(parsedDate);

  const handleYesterdayClick = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() - 1);
    setCurrentDate(newDate);
  };

  const handleTomorrowClick = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + 1);
    setCurrentDate(newDate);
  };

  const handleTodayClick = () => {
    const today = new Date();
    setCurrentDate(today);
  };

  const tomorrowDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
  // change date
  //const isMounted = useRef(true); // Using a ref to track the initial mount state

  useEffect(() => {
    const formattedDate = currentDate.toISOString().split("T")[0];
    navigate(`/cotadedoi/cota-de-doi-fotbal-${formattedDate}`);
  }, [currentDate, navigate]);

  useEffect(() => {
    // This block runs when the component is initially mounted
    //if (isMounted.current) {
    //isMounted.current = false; // Update the ref to indicate that the initial mount is done
    //return; // Exit the effect to prevent dispatch on initial mount
    //}

    const cotadedoiData = {
      date: convertDate(currentDate),
    };
    dispatch(getCotadedoiBydate(cotadedoiData));

    // Dispatch getAllCotadedoi with your specified parameters
    dispatch(
      getAllCotadedoi({
        cotadedoiStatus: "all",
        totalResults: 5,
      })
    );
  }, [currentDate, dispatch]);

  // modal
  const searchParams = new URLSearchParams(location.search);
  const [showModal, setShowModal] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showForgotModal, setShowForgotModal] = useState(false);
  const [showLoginWithCodeModal, setShowLoginWithCodeModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);

  const handleClickOutside = (e) => {
    if (e.target.id === "modal-target") {
      setShowModal(false);
      setShowAuthModal(false);
      setShowForgotModal(false);
      setShowLoginWithCodeModal(false);
      setShowRegisterModal(false);
      setShowVerifyModal(false);
      setShowResetModal(false);
      dispatch(RESETPREDICTION());

      // Delete modal id parameter from URL
      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.delete("modal");
      newSearchParams.delete("id");
      newSearchParams.delete("tk");
      navigate(`${location.pathname}?${newSearchParams.toString()}`);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const [isLoadingPrediction, setIsLoadingThePrediction] = useState(false);
  const handleModal = (eveniment) => {
    if (!showModal) {
      if (!isLoadingPrediction && location.search === "") {
        // Display a loading indicator for 5 seconds
        setIsLoadingThePrediction(true);
        const predictionData = {
          _id: eveniment._id,
        };
        dispatch(getPredictionById(predictionData));
        setTimeout(() => {
          setIsLoadingThePrediction(false); // Remove loading indicator after dispatch
        }, 2000); // 5000 milliseconds (5 seconds)
      }

      // Add modal id parameter to URL
      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.set("modal", "prediction");
      newSearchParams.set("id", eveniment._id);
      navigate(`${location.pathname}?${newSearchParams.toString()}`);
      setShowModal(true);
    } else {
      if (prediction._id === eveniment._id) {
        // If the modal is already open for the clicked event, do nothing
        return;
      }
      dispatch(RESETPREDICTION());
      setShowModal(false);

      // Delete modal id parameter from URL
      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.delete("modal");
      newSearchParams.delete("id");
      navigate(`${location.pathname}?${newSearchParams.toString()}`);
    }
  };

  useEffect(() => {
    const modalPredictionId = new URLSearchParams(location.search).get("id");

    if (modalPredictionId && prediction === null) {
      setIsLoadingThePrediction(true);
      const predictionData = {
        _id: modalPredictionId,
      };
      setShowModal(true);
      dispatch(getPredictionById(predictionData));
      setTimeout(() => {
        setIsLoadingThePrediction(false); // Remove loading indicator after dispatch
      }, 2000); // 5000 milliseconds (5 seconds)
    }
  }, [location.search, predictions, showModal]);

  useEffect(() => {
    const modalPredictionId = new URLSearchParams(location.search).get("modal");

    if (modalPredictionId === "autentificare") {
      setShowAuthModal(true);
      setShowForgotModal(false);
      setShowRegisterModal(false);
    } else if (modalPredictionId === "recuperare-parola") {
      setShowForgotModal(true);
      setShowAuthModal(false);
      setShowRegisterModal(false);
    } else if (modalPredictionId === "autentificare-cod") {
      setShowLoginWithCodeModal(true);
      setShowAuthModal(false);
      setShowForgotModal(false);
      setShowRegisterModal(false);
    } else if (modalPredictionId === "inregistrare") {
      setShowRegisterModal(true);
      setShowAuthModal(false);
      setShowForgotModal(false);
    } else if (modalPredictionId === "verificare-cont") {
      setShowVerifyModal(true);
    } else if (modalPredictionId === "resetare-parola") {
      setShowResetModal(true);
    }
  }, [
    location.search,
    showAuthModal,
    showForgotModal,
    showLoginWithCodeModal,
    showRegisterModal,
    showVerifyModal,
    showResetModal,
  ]);

  // Update search params and navigate to the updated URL
  useEffect(() => {
    if (prediction) {
      if (showModal) {
        searchParams.set("modal", "prediction");
        searchParams.set("id", prediction._id);
      } else {
        searchParams.delete("modal");
        searchParams.delete("id");
      }
      navigate(`${location.pathname}?${searchParams.toString()}`);
    }
  }, [showModal, isLoadingPrediction, prediction]);

  useEffect(() => {
    if (showModal && prediction) {
      const intervalId = setInterval(() => {
        const predictionData = {
          _id: prediction._id,
        };
        dispatch(getPredictionById(predictionData));
      }, 40000);
      return () => clearInterval(intervalId);
    }
  }, [showModal, prediction]);

  // auth modal
  const handleAuthenticationModal = (modalId, show, additionalParams = {}) => {
    if (show) {
      // Add modal id parameter to URL
      const newSearchParams = searchParams;
      newSearchParams.set("modal", modalId);
      for (const key in additionalParams) {
        newSearchParams.set(key, additionalParams[key]);
      }
      navigate(`${location.pathname}?${newSearchParams.toString()}`);
    } else {
      // Delete modal id parameter from URL
      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.delete("modal");
      for (const key in additionalParams) {
        newSearchParams.delete(key);
      }
      navigate(`${location.pathname}?${newSearchParams.toString()}`);
    }
  };

  // Example usage
  const handleAuthModal = () => {
    if (!showAuthModal) {
      handleAuthenticationModal("autentificare", true);
      setShowAuthModal(true);
    } else {
      setShowAuthModal(false);
      handleAuthenticationModal("autentificare", false);
    }
  };

  const handleForgotModal = () => {
    handleAuthModal();
    if (!showForgotModal) {
      handleAuthenticationModal("recuperare-parola", true);
      setShowForgotModal(true);
    } else {
      setShowForgotModal(false);
      handleAuthenticationModal("recuperare-parola", false);
    }
  };

  const handleLoginWithCodeModal = (email) => {
    if (!showLoginWithCodeModal) {
      handleAuthenticationModal("autentificare-cod", true, { email });
      setShowLoginWithCodeModal(true);
    } else {
      setShowLoginWithCodeModal(false);
      handleAuthenticationModal("autentificare-cod", false, { email });
    }
  };

  const handleRegisterModal = () => {
    if (!showRegisterModal) {
      handleAuthenticationModal("inregistrare", true);
      setShowRegisterModal(true);
    } else {
      setShowRegisterModal(false);
      handleAuthenticationModal("inregistrare", false);
    }
  };

  const handleVerifyModal = (tk) => {
    if (!showVerifyModal) {
      handleAuthenticationModal("verificare-cont", true, { tk });
      setShowVerifyModal(true);
    } else {
      setShowVerifyModal(false);
      handleAuthenticationModal("verificare-cont", false, { tk });
    }
  };

  const handleResetModal = (tk) => {
    if (!showResetModal) {
      handleAuthenticationModal("resetare-parola", true, { tk });
      setShowResetModal(true);
    } else {
      setShowResetModal(false);
      handleAuthenticationModal("resetare-parola", false, { tk });
    }
  };

  //

  useEffect(() => {
    const body = document.querySelector("body");

    if (
      showModal ||
      showAuthModal ||
      showForgotModal ||
      showLoginWithCodeModal ||
      showRegisterModal ||
      showVerifyModal ||
      showResetModal
    ) {
      body.classList.add("body-scroll-hidden");
      return () => {
        body.classList.remove("body-scroll-hidden");
      };
    } else {
    }
  }, [
    showModal,
    showAuthModal,
    showForgotModal,
    showLoginWithCodeModal,
    showRegisterModal,
    showVerifyModal,
    showResetModal,
  ]);
  // modal

  // like dislike prediction

  const { handleLikePrediction, handleDislikePrediction } =
    usePredictionDispatch();

  // like dislike prediction

  // adauga predictie

  // get currentselected matches de pe ticket  din localstorage //

  const [evenimentePeBilet, setEvenimentePeBilet] = useState([]);
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("evenimentePeBilet"));
    if (items) {
      setEvenimentePeBilet(items);
    }
  }, []);
  const [isOpen, setIsOpen] = useState(false);

  // end
  // adauga predictie

  const dateParam = currentDate.toISOString().split("T")[0];

  return (
    <>
      <Helmet>
        <title>
          Cota de doi fotbal
          {cotadedoi?.totalOdd ? ` - cota ${cotadedoi.totalOdd}` : ""} -
          {` ${dateParam} `}
          AnalizeSportive
        </title>
        <meta
          name="description"
          content="Biletul cota de doi GRATUIT de la cei mai buni tipsteri din Romania."
        />
        <meta name="og:image" content="https://i.ibb.co/FnPJ7dF/cover.jpg" />
      </Helmet>
      <div className="page page-cotadedoi">
        <div className="page__main">
          <div id="react-modal-screen">
            <div id="modals">
              {showModal && (
                <Modal
                  handleModal={handleModal}
                  showModal={showModal}
                  prediction={prediction}
                  user={user}
                  isLoggedIn={isLoggedIn}
                  isLoading={isLoading}
                  isLoadingActions={isLoadingActions}
                  handleDislikePrediction={handleDislikePrediction}
                  handleLikePrediction={handleLikePrediction}
                  isLoadingPrediction={isLoadingPrediction}
                />
              )}
              {showAuthModal && (
                <ModalLogin
                  showAuthModal={showAuthModal}
                  setShowAuthModal={setShowAuthModal}
                  handleAuthModal={handleAuthModal}
                  handleForgotModal={handleForgotModal}
                  handleRegisterModal={handleRegisterModal}
                />
              )}
              {showForgotModal && (
                <ModalForgot
                  showForgotModal={showForgotModal}
                  setShowForgotModal={setShowForgotModal}
                  handleAuthModal={handleAuthModal}
                  handleForgotModal={handleForgotModal}
                  handleRegisterModal={handleRegisterModal}
                />
              )}
              {showLoginWithCodeModal && (
                <ModalLoginWithCode
                  handleLoginWithCodeModal={handleLoginWithCodeModal}
                  setShowLoginWithCodeModal={setShowLoginWithCodeModal}
                  showLoginWithCodeModal={showLoginWithCodeModal}
                />
              )}
              {showRegisterModal && (
                <ModalRegister
                  handleRegisterModal={handleRegisterModal}
                  showRegisterModal={showRegisterModal}
                  setShowRegisterModal={setShowRegisterModal}
                  setShowAuthModal={setShowAuthModal}
                  handleAuthModal={handleAuthModal}
                />
              )}
              {showVerifyModal && (
                <ModalVerify
                  setShowVerifyModal={setShowVerifyModal}
                  handleVerifyModal={handleVerifyModal}
                />
              )}
              {showResetModal && (
                <ModalReset handleResetModal={handleResetModal} />
              )}
            </div>
          </div>
          <VerifyAccount
            user={user}
            isLoading={isLoading}
            isLoggedIn={isLoggedIn}
          />
          <Header
            handleShowNotification={handleShowNotification}
            handleShowChat={handleShowChat}
            showChat={showChat}
            showNotification={showNotification}
            handleClick={handleClick}
            dropdown={dropdown}
            desktopRef={desktopRef}
            onlineUsers={onlineUsers}
            notificationsCount={notificationsCount}
            unreadChatCount={unreadChatCount}
            handleAuthModal={handleAuthModal}
            handleRegisterModal={handleRegisterModal}
            setMobileMenu={setMobileMenu}
            mobileMenu={mobileMenu}
          />
          <div className="main container">
            <div className="content">
              <div className="page-block page-block_cms">
                <div className="about-token-landing-page bonus-landing-pages">
                  <div className="bonus-container-about-token">
                    <div className="content-block welcome-section">
                      <div className="date-picker-div">
                        <button
                          onClick={handleYesterdayClick}
                          className="disabled:cursor-not-allowed button_medium"
                          type="button"
                        >
                          <span className="flex space-between align-center gap-2">
                            <FaArrowAltCircleLeft />
                            {formatDate(
                              new Date(
                                currentDate.getTime() - 24 * 60 * 60 * 1000
                              )
                            )}
                          </span>
                        </button>
                        <button
                          onClick={handleTodayClick}
                          className="button_secondary button_medium white-important-text"
                          type="button"
                        >
                          Biletul de astazi
                        </button>
                        <button
                          onClick={handleTomorrowClick}
                          disabled={tomorrowDate > new Date()}
                          className={`${
                            tomorrowDate > new Date()
                              ? "disabled:cursor-not-allowed "
                              : ""
                          }button_medium`}
                          type="button"
                        >
                          <span className="flex space-between align-center gap-2">
                            {formatDate(
                              new Date(
                                currentDate.getTime() + 24 * 60 * 60 * 1000
                              )
                            )}
                            <FaArrowAltCircleRight />
                          </span>
                        </button>
                      </div>
                      <div className="bets-info reversed">
                        <div className="bets-info__text _animate _fade-in _delay-02 _active">
                          <h1>
                            <img
                              className="decoy-logo"
                              src={bzileiLogo}
                              alt="Decoy logo"
                            />
                            Cota de doi {dateParam}
                          </h1>
                          <p className="white">
                            "Cota de Doi" reprezinta mai mult decat un simplu
                            serviciu de pronosticuri sportive. Este o modalitate
                            de a transforma pasiunea ta pentru sport in
                            castiguri palpabile, cu o abordare inovatoare si
                            captivanta. Alege "Cota de Doi" pentru a experimenta
                            provocarea, excitatia si satisfactia de a castiga cu
                            fiecare bilet compus cu selecții cu o cota minima de
                            2. Pariem pe succesul tau!"
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="content-block token-stats-block">
                      <h2 className="text-center">Statistici</h2>
                      <div className="bonus-stats">
                        <div className="bonus-stats-block _animate _fade-in _active">
                          <div className="bonus-stats-block__info">
                            <span className="label">Cota medie</span>
                            <strong className="amount">
                              <span className="icon-decoy_s"></span>
                              <span>{averageTotalOdd}</span>
                            </strong>
                          </div>
                        </div>
                        <div className="bonus-stats-block _animate _fade-in _delay-02 _active">
                          <div className="bonus-stats-block__info">
                            <span className="label">Randament</span>
                            <strong className="amount">
                              <span className="icon-usd_s"></span>
                              <span>{averageYield}%</span>
                            </strong>
                          </div>
                        </div>
                        <div className="bonus-stats-block _animate _fade-in _delay-04 _active">
                          <div className="bonus-stats-block__info">
                            <span className="label">Forma</span>
                            <div className="team-form team-form--left team-form--sm">
                              {last5StatusValues?.map((status, index) => (
                                <div
                                  key={index}
                                  className={`team-form__item ${
                                    status === 1
                                      ? "team-form__item--l"
                                      : status === 2
                                      ? "team-form__item--w"
                                      : ""
                                  }`}
                                ></div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="bonus-stats-block _animate _fade-in _delay-06 _active">
                          <div className="bonus-stats-block__info">
                            <span className="label">Pariaza acum</span>
                            <strong className="amount">
                              <span className="icon-usd_s"></span>
                              <span>SuperBet</span>
                            </strong>
                          </div>
                        </div>
                      </div>
                      {/* <div className="decoy-mining-price__banner">
                      <div className="decoy-mining-price__banner-wrapper">
                        <img
                          className="decoy-mining-price__banner-image _animate _scale-in _active"
                          src="https://superbet.ro/static/img/sb-logo.svg"
                          alt="Coin"
                          width="120"
                        />
                        <span className="mt-3">50 RON FREEBET</span>
                      </div>
                      <div className="decoy-mining-price__banner-text font-bold">
                        Încasează 50RON FREEBET fără depunere + 500RON la
                        depozit!
                      </div>
                      <button
                        style={{ background: "#ea060a", fontWeight: "600" }}
                        className="disabled:cursor-not-allowed button_medium"
                        type="button"
                      >
                        <span className="flex space-between align-center gap-2">
                          Deschide cont
                        </span>
                      </button>
                    </div> */}
                    </div>

                    <div className="content-section">
                      <div className="content-block">
                        {isLoadingCotaDeDoi ? (
                          <DuckLoader rowCount={3} />
                        ) : (
                          <>
                            {cotadedoi !== null ? (
                              <>
                                <PredictionBody predictionStored={cotadedoi} />
                                <PredictionResult
                                  predictionStored={cotadedoi}
                                />
                              </>
                            ) : (
                              <div className="disabled-section">
                                <div className="disabled-section__media">
                                  <FaSadTear className="text-6xl" />
                                </div>
                                <span className="disabled-section__text">
                                  Nu este postat biletul cota de doi pentru data{" "}
                                  {formatDate(currentDate)}
                                </span>
                              </div>
                            )}
                          </>
                        )}

                        <div className="bilet__content mt-8">
                          <h2 className="bilet__content__title">
                            Ce este cota de doi?
                          </h2>
                          <p className="bilet__description">
                            Zilnic, va prezentam un singur bilet cu cele mai
                            bune sugestii pentru pariuri sportive, care va pot
                            dubla investitia. Nu exista nicio garantie ca
                            fiecare zi va aduce profit in pariurile sportive,
                            deoarece nu exista o cota 2 sigura! Cu toate
                            acestea, va asiguram ca vom depune eforturi pentru a
                            obtine rezultate bune pe termen lung si pentru a
                            inregistra un profit cu cota 2 la pariuri, atunci
                            cand analizam rezultatele.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
            <div
              className={`page__panel ${
                showNotification || showMatches || showChat
                  ? ""
                  : "page__panel_hidden"
              }`}
            >
              <div id="react-toolbar-right">
                <div
                  className={`side-panel ${
                    showNotification || showMatches || showChat
                      ? ""
                      : "side-panel_hidden"
                  }`}
                >
                  {showChat && (
                    <div className="chat" id="chat">
                      <Chat
                        user={user}
                        isLoggedIn={isLoggedIn}
                        onlineUsers={onlineUsers}
                        users={users}
                        setMessages={setMessages}
                        messages={messages}
                        newMessage={newMessage}
                        setNewMessage={setNewMessage}
                        predictions={predictions}
                        isSharedPrediction={isSharedPrediction}
                        setIsSharedPrediction={setIsSharedPrediction}
                        showChat={showChat}
                        getUser={getUser}
                        handleAuthModal={handleAuthModal}
                      />
                    </div>
                  )}

                  {showNotification && (
                    <div className="notification">
                      <Notifications user={user} handleModal={handleModal} />
                    </div>
                  )}

                  {showMatches && (
                    <div className="notification notification_game">
                      <CreatePrediction
                        evenimentePeBilet={evenimentePeBilet}
                        setEvenimentePeBilet={setEvenimentePeBilet}
                        toggleAccumulator={toggleAccumulator}
                        setToggleAccumulator={setToggleAccumulator}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        showMatches={showMatches}
                        handleShowMatches={handleShowMatches}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        setShowMatches={setShowMatches}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <BottomToolbar
            handleRegisterModal={handleRegisterModal}
            handleShowChat={handleShowChat}
            notificationsCount={notificationsCount}
            mobileRef={mobileRef}
            showChat={showChat}
            setDropdown={setDropdown}
            dropdown={dropdown}
            isLoggedIn={isLoggedIn}
            toggleMobileMenu={toggleMobileMenu}
            mobileMenu={mobileMenu}
            setMobileMenu={setMobileMenu}
          />
        </div>
      </div>
    </>
  );
};

export default Page;

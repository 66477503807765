import React, { useEffect, useState } from "react";
import FooterPrediction from "./FooterPrediction";
import HeaderPrediction from "./HeaderPrediction";
import Prediction from "./Prediction";
import Ticket from "./Ticket";
import ShowMoreText from "react-show-more-text";
import DuckLoader from "../../utils/Loaders/DuckLoader";

const PredictionBox = ({
  eveniment,
  user,
  isLoggedIn,
  handleModal,
  handleLikePrediction,
  isLoadingActions,
  onlineUsers,
  setMessages,
  messages,
  setShowChat,
  showChat,
  newMessage,
  setNewMessage,
  isSharedPrediction,
  setIsSharedPrediction,
  isLoadingAllPredictions,
}) => {
  return (
    <div
      className="flex flex-col w-full rounded-lg p-4 mb-6 last:mb-0 _fade-in-bottom _animate _active pred-box"
      style={{ background: "#24262b" }}
    >
      {isLoadingAllPredictions ? (
        <DuckLoader rowCount={1} />
      ) : (
        <>
          <HeaderPrediction eveniment={eveniment} onlineUsers={onlineUsers} />
          {eveniment?.description !== "" ? (
            <ShowMoreText
              lines={2}
              more="mai mult"
              less="mai putin"
              className="w-full mt-4 content-css text-sm text-gray support-textarea"
              anchorclassName="read-more-button text-blue-500 font-semibold"
              expanded={false}
              width={400}
              truncatedEndingComponent={"... "}
            >
              {eveniment?.description}
            </ShowMoreText>
          ) : null}
          {eveniment?.evenimente?.length === 1 ? (
            <Prediction
              eveniment={eveniment}
              user={user}
              isLoggedIn={isLoggedIn}
              handleModal={handleModal}
              handleLikePrediction={handleLikePrediction}
            />
          ) : (
            <Ticket
              eveniment={eveniment}
              user={user}
              isLoggedIn={isLoggedIn}
              handleModal={handleModal}
              handleLikePrediction={handleLikePrediction}
            />
          )}
          <FooterPrediction
            eveniment={eveniment}
            user={user}
            isLoggedIn={isLoggedIn}
            handleLikePrediction={handleLikePrediction}
            isLoadingActions={isLoadingActions}
            setMessages={setMessages}
            messages={messages}
            showChat={showChat}
            setShowChat={setShowChat}
            newMessage={newMessage}
            setNewMessage={setNewMessage}
            isSharedPrediction={isSharedPrediction}
            setIsSharedPrediction={setIsSharedPrediction}
          />
        </>
      )}
    </div>
  );
};

export default PredictionBox;

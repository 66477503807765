import api from "../../../utils/api";

// Send automated email
const sendAutomatedEmail = async (emailData) => {
  const response = await api.post("sendAutomatedEmail", emailData);
  return response.data.message;
};

const emailService = {
  sendAutomatedEmail,
};

export default emailService;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filteredMatches: [],
};

const filteredMatchesSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    FILTER_MATCHES(state, action) {
      const { matches, search } = action.payload;
      const tempMatches = matches.filter((match) =>
        match.homeTeam.toLowerCase().includes(search.toLowerCase())
      );
      state.filteredMatches = tempMatches;
    },
  },
});

export const { FILTER_MATCHES } = filteredMatchesSlice.actions;

export const selectMatches = (state) => state.filteredMatches.filteredMatches;

export default filteredMatchesSlice.reducer;

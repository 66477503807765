import api from "../../../utils/api";

// Add cota de doi
const addCotadedoi = async (cotadedoiData) => {
  const response = await api.post("cotadedoi/cotadedoi", cotadedoiData);
  return response.data;
};

// Get cota de doi by date
const getCotadedoiBydate = async (cotadedoiData) => {
  const response = await api.post(
    "cotadedoi/getCotadedoiBydate",
    cotadedoiData
  );
  return response.data;
};

// Get all cota de doi
const getAllCotadedoi = async ({ totalResults, cotadedoiStatus }) => {
  const response = await api.get(
    `cotadedoi/getAllCotadedoi?totalResults=${totalResults}&cotadedoiStatus=${cotadedoiStatus}`
  );
  return response.data;
};

// Approve eveniment cota de doi
const approveCotadedoi = async (matchData) => {
  const response = await api.post("cotadedoi/approveCotadedoi", matchData);
  return response.data;
};

// Approve cota de doi ticket
const approveCotadedoiTicket = async (matchData) => {
  const response = await api.post(
    "cotadedoi/approveCotadedoiTicket",
    matchData
  );
  return response.data;
};

const cotadedoiService = {
  addCotadedoi,
  getCotadedoiBydate,
  getAllCotadedoi,
  approveCotadedoi,
  approveCotadedoiTicket,
};

export default cotadedoiService;

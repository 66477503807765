import React from "react";
import Moment from "react-moment";

const OddsHeader = ({ selectedMatch, matchOdds, bookmaker }) => {
  return (
    <div className="create-tip-match-info-header">
      <div className="create-tip-match-info-header__main relative">
        <div className="create-tip-match-info-header__main__team">
          <div className="team-link">
            <img
              width="50"
              height="50"
              src={selectedMatch?.teams?.home?.logo}
            />
            <span className="text-sm mt-2 text-white text-center font-semibold">
              {selectedMatch?.teams?.home?.name}
            </span>
          </div>
        </div>
        <div className="create-tip-match-info-header__main__status">
          <div className="create-tip-match-info-header__main__status__timer">
            <Moment format="HH:mm" className="text-4xl orange-color font-bold">
              {selectedMatch?.fixture?.timestamp * 1000}
            </Moment>
          </div>
          <div className="create-tip-match-info-header__main__status__date">
            <Moment format="DD.MM.YYYY" className="text-slate-400 text-sm">
              {selectedMatch?.fixture?.timestamp * 1000}
            </Moment>
          </div>
        </div>
        <div className="create-tip-match-info-header__main__team create-tip-match-info-header__main__team--away">
          <div className="team-link">
            <img
              width="50"
              height="50"
              src={selectedMatch?.teams?.away?.logo}
            />
            <span className="text-sm mt-2 text-white text-center font-semibold">
              {selectedMatch?.teams?.away?.name}
            </span>
          </div>
        </div>
      </div>
      {matchOdds?.length !== 0 ? (
        <div className="text-center flex items-center justify-center mt-4 z-10">
          <span className="font-bold text-xs text-gray-200 flex">
            Cote preluate de pe :
            <b className="text-red-200 ml-1">{bookmaker}</b>
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default OddsHeader;

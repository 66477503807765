import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  loginWithCode,
  RESET,
  sendLoginCode,
} from "../redux/features/auth/authSlice";
import { toast } from "react-toastify";
import { FaTimes } from "react-icons/fa";

const initialState = {
  n1: "",
  n2: "",
  n3: "",
  n4: "",
  n5: "",
  n6: "",
};

const ModalLoginWithCode = ({ handleLoginWithCodeModal, onCodeEntered }) => {
  const [formData, setFormData] = useState(initialState);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");

  const [loginCodeArray, setLoginCodeArray] = useState(Array(6).fill(""));
  const loginCode = loginCodeArray.join("");
  const handleInputChange = (event, index) => {
    const { value } = event.target;
    const newCode = [...loginCode];
    newCode[index] = value;
    setLoginCodeArray(newCode);

    // move to next input
    if (value && index < 5) {
      document.getElementById(`n${index + 2}`).focus();
    }

    // go back to previous input
    if (!value && index > 0) {
      document.getElementById(`n${index}`).focus();
    }
  };

  const handlePaste = (event) => {
    const pasteData = event.clipboardData.getData("Text");
    const pasteArray = pasteData.split("");

    if (pasteArray.length === 6) {
      pasteArray.forEach((value, index) => {
        const input = document.getElementById(`n${index + 1}`);
        if (input) {
          input.value = value;
          handleInputChange({ target: { value } }, index);
        }
      });
    }
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, isLoggedIn, isSuccess } = useSelector(
    (state) => state.auth
  );
  const loginUserWithCode = async (e) => {
    e.preventDefault();

    if (loginCode === "") {
      return toast.error("Please fill in the login code");
    }
    if (loginCode.length !== 6) {
      return toast.error("Access Code must be 6 characters");
    }

    const code = {
      loginCode,
    };
    console.log(code, email);
    await dispatch(loginWithCode({ code, email }));
  };

  useEffect(() => {
    if (isSuccess && isLoggedIn) {
      toast.success("Cod de acces corect, autentificare");
      navigate("/");
      handleLoginWithCodeModal();
    }

    dispatch(RESET());
  }, [isLoggedIn, isSuccess, dispatch, navigate]);

  const sendUserLoginCode = async () => {
    await dispatch(sendLoginCode(email));
    await dispatch(RESET());
  };

  return (
    <div className="modal-screen modal-screen_login showModalBg">
      <div className="modal-screen-wrap" id="modal-target">
        <div className="modal-window showModalWindow">
          <div className="modal-header flex-row flex-row_center flex-row_space-between">
            <div className="modal-title">
              <div className="flex-row flex-row_space-between align-center full-width">
                <span>Dispozitiv nou</span>
              </div>
            </div>
            <div className="flex-row flex-row_center">
              <div className="btn-close p-4" onClick={handleLoginWithCodeModal}>
                <FaTimes className="text-lg text-gray-500" />
              </div>
            </div>
          </div>
          <div className="modal-content">
            <div className="modal-window__content">
              <div className="welcome-logo">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/socialape-bea5b.appspot.com/o/logo.png?alt=media"
                  alt="AnalizeSportive"
                  title="AnalizeSportive"
                />
              </div>
              <div>
                <div className="welcome-text flex-row flex-row_center">
                  <span>Dispozitiv nou</span>
                </div>

                <form
                  className="space-y-4 md:space-y-6"
                  onSubmit={loginUserWithCode}
                  name="verify"
                >
                  <div className="block-row">
                    <div className="info-block-component default-color">
                      <div className="info-block-component__icon">
                        <span className="icon-info_outline"></span>
                      </div>
                      <div className="info-block-component__text">
                        Introdu codul de verificare din e-mail pentru a continua
                        autentificarea.
                      </div>
                    </div>
                  </div>
                  <div className="element-input__group">
                    <div className="grid">
                      {[1, 2, 3, 4, 5, 6].map((index) => (
                        <div className="grid__item" key={index}>
                          <input
                            type="tel"
                            name={`n${index}`}
                            id={`n${index}`}
                            autoComplete="off"
                            className=""
                            maxLength="1"
                            value={loginCode[index - 1]}
                            onChange={(event) =>
                              handleInputChange(event, index - 1)
                            }
                            onPaste={handlePaste}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="block-row_custom-middle button-flex-holder">
                    <button disabled={isLoading} type="submit">
                      Continua cu autentificarea
                    </button>
                  </div>
                  <div className="help-text text-center">
                    <span onClick={sendUserLoginCode} className="link">
                      Retrimite codul
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalLoginWithCode;

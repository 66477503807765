import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import matchesService from "./matchesService";
import { toast } from "react-toastify";

const initialState = {
  match: null,
  matches: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Get all matches
export const getMatches = createAsyncThunk(
  "matches/allmatches",
  async (_, thunkAPI) => {
    try {
      return await matchesService.getMatches();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get match by date

export const getMatchesByDate = createAsyncThunk(
  "matches/matchbydate",
  async (matchDate, thunkAPI) => {
    try {
      return await matchesService.getMatchesByDate(matchDate);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Approve match
export const approveMatch = createAsyncThunk(
  "matches/approvematch",
  async (matchData, thunkAPI) => {
    try {
      return await matchesService.approveMatch(matchData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Approve eveniment
export const approveEveniment = createAsyncThunk(
  "matches/approveeveniment",
  async (matchData, thunkAPI) => {
    try {
      return await matchesService.approveEveniment(matchData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Add match
export const addMatch = createAsyncThunk(
  "matches/addmatch",
  async (matchRequest, thunkAPI) => {
    try {
      return await matchesService.addMatch(matchRequest);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete match
export const deleteMatch = createAsyncThunk(
  "matches/deleteMatch",
  async (id, thunkAPI) => {
    try {
      return await matchesService.deleteMatch(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get all predictions by event
export const getAllPredictionsSortByEveniment = createAsyncThunk(
  "matches/allpredictionssortbyeveniment",
  async (params, thunkAPI) => {
    const { date } = params;
    try {
      return await matchesService.getAllPredictionsSortByEveniment({
        date,
      });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

const matchSlice = createSlice({
  name: "matches",
  initialState,
  reducers: {
    RESET(state) {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      // Get all matches
      .addCase(getMatches.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getMatches.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.matches = action.payload;
      })
      .addCase(getMatches.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      // Get matches by date
      .addCase(getMatchesByDate.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getMatchesByDate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.matches = action.payload;
      })
      .addCase(getMatchesByDate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      // Approve match
      .addCase(approveMatch.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(approveMatch.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        toast.success(action.payload.message);
      })
      .addCase(approveMatch.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      // Approve eveniment
      .addCase(approveEveniment.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(approveEveniment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        toast.success(action.payload.message);
      })
      .addCase(approveEveniment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      // Delete match
      .addCase(deleteMatch.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteMatch.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
        toast.success(action.payload);
      })
      .addCase(deleteMatch.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      // Add match
      .addCase(addMatch.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addMatch.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
        toast.success(action.payload);
      })
      .addCase(addMatch.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      // Get all predictions by event
      .addCase(getAllPredictionsSortByEveniment.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllPredictionsSortByEveniment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.matches = action.payload.predictionsByEvent;
      })
      .addCase(getAllPredictionsSortByEveniment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      });
  },
});

export const { RESET } = matchSlice.actions;

export const selecMatch = (state) => state.match.match;

export default matchSlice.reducer;

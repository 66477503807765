import api from "../../../utils/api";

// Get all matches from RapidApi
const getAllMatches = async (matchDate) => {
  const response = await api.post("rapidapi/getmatches", matchDate);
  return response.data;
};

// Get all matches from RapidApi v2
const getAllMatchesV2 = async (matchDate) => {
  const response = await api.post("rapidapi/getmatchesandstore", matchDate);
  return response.data;
};

// Get match from RapidApi
const getMatch = async (matchData) => {
  const response = await api.post("rapidapi/getmatch", matchData);
  return response.data;
};
// Get match odd from RapidApi
const getMatchOdds = async (matchData) => {
  const response = await api.post("rapidapi/getmatchodds", matchData);
  return response.data;
};

// Get all matches from RapidApi
const getMatchesEvenimente = async (matchDate) => {
  const response = await api.post("rapidapi/getmatchesevenimente", matchDate);
  return response.data;
};
const rapidApiService = {
  getAllMatches,
  getMatchOdds,
  getMatch,
  getAllMatchesV2,
  getMatchesEvenimente,
};

export default rapidApiService;

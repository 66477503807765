import React, { useEffect, useRef, useState } from "react";
import {
  getBiletulzileiBydate,
  getAllBiletulzilei,
} from "../../redux/features/biletulzilei/biletulzileiSlice";
import { useDispatch, useSelector } from "react-redux";
import { format, isAfter } from "date-fns"; // Import date-fns library for formatting dates
import { ro } from "date-fns/locale";
import { convertDate } from "../../utils/ConvertDate/convertDate";
import { Link } from "react-router-dom";
import DuckLoader from "../../utils/Loaders/DuckLoader";
import { FaDotCircle } from "react-icons/fa";

const BiletulZilei = ({ handleModal }) => {
  const dispatch = useDispatch();
  const { isLoading, biletulzilei, firstMatchStart, last5StatusValues } =
    useSelector((state) => state.biletulzilei);

  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentDateGMT3, setCurrentDateGMT3] = useState(
    new Date(new Date().getTime() + 3 * 60 * 60 * 1000)
  );
  // change date
  //const isMountedBiletulZilei = useRef(true); // Using a ref to track the initial mount state

  useEffect(() => {
    // This block runs when the component is initially mounted
    //if (isMountedBiletulZilei.current) {
    //isMountedBiletulZilei.current = false; // Update the ref to indicate that the initial mount is done
    //return; // Exit the effect to prevent dispatch on initial mount
    //}

    // Dispatch getAllCotadedoi with your specified parameters
    dispatch(
      getBiletulzileiBydate({
        date: convertDate(currentDate),
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getAllBiletulzilei({
        biletulzileiStatus: "all",
        totalResults: 5,
      })
    );
  }, []);
  // first match start/
  // Convert timestamp to Date instance
  const date = new Date(firstMatchStart * 1000);

  // Format the Date instance to display hours and minutes
  const formattedTime = format(date, "HH:mm");

  // Convert the provided timestamp to milliseconds
  const providedDate = new Date(firstMatchStart * 1000);

  // Get the current timestamp in milliseconds
  const currentTimestamp = Date.now();

  // Check if the current timestamp is after the provided timestamp
  const isCurrentTimestampAfter = isAfter(currentTimestamp, providedDate);

  // Format the provided timestamp
  const formattedProvidedTime = format(providedDate, "HH:mm");

  //
  const formattedDate = currentDate.toISOString().split("T")[0];
  const formattedDateGMT3 = currentDateGMT3.toISOString().split("T")[0];
  return (
    <div className="token-info__tile flex-col">
      <section className="accordion-section__block section-info flex justify-between items-center">
        <h2>Biletul Zilei</h2>
      </section>

      {isLoading ? (
        <DuckLoader rowCount={1} />
      ) : (
        <>
          <div className="token-info__tile-wrapper w-full flex-col cota-de-doi-box">
            <div className="flex w-full items-center justify-between">
              <span className="text-sm text-gray-500">
                Ultimele {last5StatusValues?.length} rezultate
              </span>

              <div className="team-form team-form--left team-form--sm">
                {last5StatusValues?.map((status, index) => (
                  <div
                    key={index}
                    className={`team-form__item ${
                      status === 1
                        ? "team-form__item--l"
                        : status === 2
                        ? "team-form__item--w"
                        : ""
                    }`}
                  ></div>
                ))}
              </div>
            </div>

            <Link
              to={`/biletul-zilei/biletul-zilei-fotbal-${formattedDateGMT3}`}
              className="flex flex-col pt-4"
            >
              <div
                className={` ${
                  biletulzilei?.status === 1
                    ? "prediction-details-lost"
                    : biletulzilei?.status === 2
                    ? "prediction-details-won"
                    : biletulzilei?.status === 3
                    ? "prediction-details-void"
                    : "prediction-details-ns"
                }  prediction-details flex items-center justify-between px-3 py-2 rounded-lg cursor-pointer mb-2`}
                style={{ background: "#24262b", minHeight: 48 }}
              >
                <div className="flex flex-col">
                  {biletulzilei === null ? (
                    <span className="text-sm uppercase text-white">
                      Nu exista bilet momentan
                    </span>
                  ) : (
                    <span
                      className={`${
                        biletulzilei !== null && biletulzilei?.status === 0
                          ? "text-xs"
                          : "text-sm"
                      }  uppercase text-white`}
                    >
                      Bilet compus {biletulzilei?.totalEvenimente}{" "}
                      {biletulzilei?.totalEvenimente === 1
                        ? "eveniment"
                        : "evenimente"}
                    </span>
                  )}

                  {biletulzilei !== null && biletulzilei?.status === 0 ? (
                    <span className="text-xs text-gray-400">
                      {isCurrentTimestampAfter ? (
                        <div className="flex items-center">
                          <span className="test-dot-green"></span>
                          Cel putin un eveniment de pe bilet a inceput
                        </div>
                      ) : (
                        `Primul eveniment incepe la ora ${formattedTime}`
                      )}
                    </span>
                  ) : null}
                </div>
                {biletulzilei !== null ? (
                  <div
                    className={`flex relative items-center shadow-sm flex-col justify-center h-11 m-0 px-0.5 rounded-lg text-center ${
                      biletulzilei?.status === 0
                        ? "gray-bg-color"
                        : biletulzilei?.status === 2
                        ? "green-bg-color"
                        : biletulzilei?.status === 1
                        ? "red-bg-color"
                        : biletulzilei?.status === 3
                        ? "bg-yellow-300"
                        : null
                    } text-white text-roboto`}
                    style={{ fontSize: "13px", minWidth: "46px" }}
                  >
                    {biletulzilei?.totalOdd.toFixed(2)}
                    {biletulzilei?.status ===
                    0 ? null : biletulzilei?.status === 2 ? (
                      <span
                        style={{
                          fontSize: "8px",
                          opacity: ".8",
                          fontWeight: "400",
                          textTransform: "uppercase",
                          marginTop: 2,
                        }}
                      >
                        CASTIGAT
                      </span>
                    ) : biletulzilei?.status === 1 ? (
                      <span
                        style={{
                          fontSize: "8px",
                          opacity: ".8",
                          fontWeight: "400",
                          textTransform: "uppercase",
                          marginTop: 2,
                        }}
                      >
                        PIERDUT
                      </span>
                    ) : biletulzilei?.status === 3 ? (
                      <span
                        style={{
                          fontSize: "8px",
                          opacity: ".8",
                          fontWeight: "400",
                          textTransform: "uppercase",
                          marginTop: 2,
                        }}
                      >
                        ANULAT
                      </span>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </Link>
          </div>

          <div className="mt-3">
            <p style={{ fontSize: "12px", lineHeight: "18px", margin: 0 }}>
              <strong>Biletul zilei</strong> - Sugestii zilnice pentru bilete la
              pariuri cu cote intre 2 si 10, cu sanse mari de succes. Gratuit si
              accesibil tuturor pariorilor.
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default BiletulZilei;

import React from "react";
import "../../assets/css/error.css";
import { BiMessageAltError } from "react-icons/bi";

const NotFoundPage = () => {
  return (
    <div className="error-page">
      <div className="container">
        <div className="container__header">
          <a className="logo" href="https://analizesportive.ro">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/socialape-bea5b.appspot.com/o/logo.png?alt=media"
              alt="AnalizeSportive"
            />
            <span>AnalizeSportive</span>
          </a>
        </div>
        <div className="container__content">
          <div className="icon">
            <BiMessageAltError className="text-8xl" />
          </div>
          <div className="title">Pagina nu a fost gasita!</div>
          <div className="text">
            Va rugam sa verificati ca ati introdus corect adresa paginii. <br />{" "}
            Daca totusi nu puteti gasi pagina de care aveti nevoie, vizitati
            pagina noastra <a href="https://analizesportive.ro">principala</a>{" "}
            sau contactati echipa noastra de suport pentru a obtine ajutor.
          </div>
        </div>
        <div className="container__footer">&nbsp;</div>
      </div>
    </div>
  );
};

export default NotFoundPage;

import React from "react";
import Moment from "react-moment";
import "moment/locale/ro";
import { Avatar, Tooltip } from "flowbite-react";
import ShowMoreText from "react-show-more-text";
import { FaRegComments, FaShare } from "react-icons/fa";

const Ticket = ({
  eveniment,
  user,
  handleLikePrediction,
  isLoggedIn,
  handleModal,
}) => {
  return (
    <div
      onClick={() => handleModal(eveniment)}
      className={`flex w-full rounded-lg my-4 cursor-pointer ${
        eveniment.status === 1
          ? "prediction-block-lost"
          : eveniment.status === 2
          ? "prediction-block-won"
          : eveniment.status === 3
          ? "prediction-block-void"
          : "prediction-block-ns"
      }`}
    >
      <div
        className={`flex w-full p-2 items-center justify-between ${
          eveniment.status === 1
            ? "prediction-details-lost"
            : eveniment.status === 2
            ? "prediction-details-won"
            : eveniment.status === 3
            ? "prediction-details-void"
            : "prediction-details-ns"
        } prediction-details`}
      >
        <div className="flex grow flex-1 w-full">
          <div className="flex items-center justify-center mr-2 w-14">
            <div className="flex flex-col first-bg w-full text-white text-center items-center justify-center h-9 rounded-l-lg">
              <span className="font-bold">{eveniment?.totalEvenimente}</span>
            </div>
          </div>
          <div className="flex flex-col justify-center">
            <div className="flex text-sm text-white uppercase">
              Bilet compus {eveniment?.totalEvenimente} evenimente
            </div>
          </div>
        </div>
        <div
          className={`flex relative items-center flex-col justify-center h-11 m-0 px-0.5 rounded-lg shadow-sm text-center ${
            eveniment.status === 0
              ? "gray-bg-color"
              : eveniment.status === 2
              ? "green-bg-color"
              : eveniment.status === 1
              ? "red-bg-color"
              : eveniment.status === 3
              ? "bg-yellow-400"
              : null
          } text-white text-roboto`}
          style={{ fontSize: "13px", minWidth: "46px" }}
        >
          {eveniment?.totalOdd?.toFixed(2)}
          {eveniment?.status === 0 ? null : eveniment?.status === 2 ? (
            <span
              style={{
                fontSize: "8px",
                opacity: ".8",
                fontWeight: "400",
                textTransform: "uppercase",
                marginTop: 2,
              }}
            >
              CASTIGAT
            </span>
          ) : eveniment?.status === 1 ? (
            <span
              style={{
                fontSize: "8px",
                opacity: ".8",
                fontWeight: "400",
                textTransform: "uppercase",
                marginTop: 2,
              }}
            >
              PIERDUT
            </span>
          ) : eveniment?.status === 3 ? (
            <span
              style={{
                fontSize: "8px",
                opacity: ".8",
                fontWeight: "400",
                textTransform: "uppercase",
                marginTop: 2,
              }}
            >
              ANULAT
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Ticket;

import React from "react";
import UserAvatar from "../../utils/UserAvatar/UserAvatar";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { MdVerified } from "react-icons/md";

const PredictionTop = ({ predictionStored }) => {
  return (
    <div
      className="flex justify-between items-center w-full"
      style={{ padding: "10px 30px 10px 30px" }}
    >
      <Link
        to={`/p/${predictionStored?.user?.handle}`}
        className="flex user-prediction"
      >
        <div className="profile-block-avatar flex-column">
          <UserAvatar
            name={predictionStored?.user?.name}
            level={predictionStored?.user?.level}
            levelXp={predictionStored?.user?.levelXp}
            photo={
              predictionStored !== undefined
                ? predictionStored?.user?.photo
                : null
            }
          />
        </div>
        <div className="flex flex-col justify-center" style={{ gap: 4 }}>
          <div className="flex gap-2">
            <span>{predictionStored?.user?.name}</span>
            <span className="pointer">
              {predictionStored?.user?.role === "tipster" ||
              predictionStored?.user?.role === "admin" ? (
                <MdVerified
                  data-tooltip-id="tooltip-verified-tipster-e"
                  data-tooltip-content="Tipster verificat"
                  className="text-green-400"
                />
              ) : null}
            </span>
            <Tooltip
              id="tooltip-verified-tipster-e"
              data-tooltip-delay-show={200}
              place="right"
              style={{
                fontSize: 12,
                paddingTop: 0,
                paddingBottom: 0,
                textTransform: "none",
                borderRadius: "12px",
                color: "#fff",
                lineHeight: "32px",
                backgroundColor: "#3a434b",
              }}
            />
          </div>
          <div className="flex items-center justify-center">
            <span
              style={{ fontWeight: "600" }}
              className={`text-sm uppercase ${
                predictionStored?.user.profit === 0
                  ? "gray-color"
                  : predictionStored?.user.profit > 0
                  ? "green-color"
                  : "red-color"
              } mr-1`}
            >
              {predictionStored?.user.profit > 0
                ? "+" + predictionStored?.user.profit.toFixed(2)
                : predictionStored?.user.profit.toFixed(2)}
            </span>
            <span className="text-sm text-gray-400 tipster-info__tips-count flex items-center">
              {predictionStored?.user.predictions} Predictii
            </span>
          </div>
        </div>
      </Link>
      <div className="flex items-center justify-center select-none flex-column">
        <span className="mb-2">Adaugat</span>
        <Moment locale="ro" className="text-sm text-white" fromNow>
          {predictionStored?.createdAt}
        </Moment>
      </div>
    </div>
  );
};

export default PredictionTop;

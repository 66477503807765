import React from "react";
import { FaArrowLeft } from "react-icons/fa";

const MatchNoOdds = ({ handleGoBack }) => {
  return (
    <div className="list-empty-message flex mt-8">
      <div className="list-empty-message__img-wrap">
        <img
          alt="list-empty-message"
          className="list-empty-message__img lazyLoad isLoaded"
          src="https://cdn.oddspedia.com/images/static/user-profile/empty-followers.svg"
        />
      </div>
      <div className="list-empty-message__text text-sm text-gray-500">
        Nu sunt cote momentan pentru acest eveniment
      </div>
      <div className="create-tip-accumulator__actions mt-4">
        <button
          className="button_icon button_icon-custom"
          onClick={handleGoBack}
        >
          <span className="ico-button">
            <FaArrowLeft />
          </span>
          <span className="ml-1 button-text">Inapoi</span>
        </button>
      </div>
    </div>
  );
};

export default MatchNoOdds;

import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "flowbite-react";
import {
  BsFillHandThumbsUpFill,
  BsFillHandThumbsDownFill,
} from "react-icons/bs";
import usePredictionDispatch from "../../utils/LikeAndDislike/LikeAndDislike";
import { useDispatch, useSelector } from "react-redux";

const calculatePercentages = (likes, dislikes) => {
  if (likes === 0 && dislikes === 0) {
    const likePercentage = 0;
    const dislikePercentage = 0;
    return { likePercentage, dislikePercentage };
  } else {
    const total = likes + dislikes;
    const likePercentage = (likes / total) * 100;
    const dislikePercentage = (dislikes / total) * 100;
    return { likePercentage, dislikePercentage };
  }
};
const PredictionVote = ({ predictionStored }) => {
  const { user } = useSelector((state) => state.auth);
  const { likePercentage, dislikePercentage } = calculatePercentages(
    predictionStored?.likes,
    predictionStored?.dislikes
  );

  // like dislike prediction

  const { handleLikePrediction, handleDislikePrediction } =
    usePredictionDispatch();

  // like dislike prediction
  return (
    <div
      className="flex flex-col mb-4 w-full items-center justify-center"
      style={{ padding: "10px 30px 10px 30px" }}
    >
      <span className="py-4 text-sm text-slate-900 font-bold uppercase italic select-none">
        Esti de acord cu aceasta predictie?
      </span>
      <div
        className={`vote-reactions__actions w-full ${
          predictionStored?.likes !== 0 || predictionStored?.dislikes !== 0
            ? " is-voted"
            : ""
        }`}
      >
        <button
          onClick={() => handleLikePrediction(user?._id, predictionStored._id)}
          className={`vote-reactions__btn vote-reactions__btn--left ${
            predictionStored && predictionStored.likedBy
              ? predictionStored.likedBy.findIndex(
                  (x) => x._id.toString() === user?._id.toString()
                ) !== -1
                ? "active"
                : ""
              : ""
          }`}
          style={{
            width: `${likePercentage}%`,
          }}
        >
          <i className=" u-circle">
            <BsFillHandThumbsUpFill className="text-xl u-circle" />
          </i>
          <span>
            {predictionStored?.likes === 0 && predictionStored?.dislikes !== 0
              ? ""
              : "Da"}
          </span>
        </button>
        <button
          onClick={() =>
            handleDislikePrediction(user?._id, predictionStored._id)
          }
          className={`vote-reactions__btn vote-reactions__btn--right ${
            predictionStored && predictionStored.dislikedBy
              ? predictionStored?.dislikedBy.findIndex(
                  (x) => x._id.toString() === user?._id.toString()
                ) !== -1
                ? "active"
                : ""
              : ""
          }`}
          style={{
            width: `${dislikePercentage}%`,
          }}
        >
          <span>
            {predictionStored?.dislikes === 0 && predictionStored?.likes !== 0
              ? ""
              : "Nu"}
          </span>
          <i className=" u-circle">
            <BsFillHandThumbsDownFill className="text-xl" />
          </i>
        </button>
      </div>
      {predictionStored?.likes !== 0 || predictionStored?.dislikes !== 0 ? (
        <div className="vote-reactions__stats w-full px-2">
          <div className="vote-reactions__stats__col">
            <div className="user-avatars-stack">
              {predictionStored && predictionStored.likedBy
                ? predictionStored?.likedBy.slice(0, 3).map((user, i) => (
                    <Link
                      key={i}
                      to={`/p/${user?.handle}`}
                      title={user?.name}
                      className="user-avatar hover:z-10 cursor-pointer bg-gray-200   dark:bg-gray-900 dark:border-gray-900"
                    >
                      <Tooltip
                        className={`bg-bgMain w-max `}
                        content={user.name}
                      >
                        <img
                          key={user._id}
                          src={user.photo}
                          alt={`${user.name}'s photo`}
                          className="w-6 h-6 rounded-full avatar-abbreviation"
                        />
                      </Tooltip>
                    </Link>
                  ))
                : ""}
            </div>
            <span className="text-xs">
              <b className="mr-1">{predictionStored?.likes}</b>
              {predictionStored && predictionStored.likes
                ? predictionStored?.likes === 1
                  ? "apreciere"
                  : "aprecieri"
                : "aprecieri"}

              {predictionStored && predictionStored.likedBy ? (
                predictionStored?.likedBy.findIndex(
                  (x) => x._id.toString() === user?._id.toString()
                ) !== -1 ? (
                  <span className="ml-2 orange-color uppercase">
                    (Alegerea mea)
                  </span>
                ) : null
              ) : (
                ""
              )}
            </span>
          </div>
          <div className="vote-reactions__stats__col">
            <span className="text-xs">
              {predictionStored && predictionStored.dislikedBy ? (
                predictionStored?.dislikedBy.findIndex(
                  (x) => x._id.toString() === user?._id.toString()
                ) !== -1 ? (
                  <span className="mr-2 orange-color uppercase">
                    (Alegerea mea)
                  </span>
                ) : null
              ) : (
                ""
              )}
              {predictionStored?.dislikes === 0 ? null : (
                <>
                  <b className="mr-1">{predictionStored?.dislikes}</b>
                </>
              )}
            </span>
            <div className="user-avatars-stack">
              {predictionStored && predictionStored.dislikedBy
                ? predictionStored?.dislikedBy.slice(0, 3).map((user, i) => (
                    <Link
                      key={i}
                      to={`/p/${user?.handle}`}
                      title={user?.name}
                      className="user-avatar hover:z-10 cursor-pointer bg-gray-200   dark:bg-gray-900 dark:border-gray-900"
                    >
                      <Tooltip
                        className={`bg-bgMain w-max `}
                        content={user.name}
                      >
                        <img
                          key={user._id}
                          src={user.photo}
                          alt={`${user.name}'s photo`}
                          className="w-6 h-6 rounded-full avatar-abbreviation"
                        />
                      </Tooltip>
                    </Link>
                  ))
                : ""}
            </div>
          </div>
        </div>
      ) : (
        <div
          className="flex items-center text-slate-500 mt-2 justify-center w-full h-6 px-4 select-none"
          style={{ fontSize: "12px" }}
        >
          Fi primul care voteaza!
        </div>
      )}
    </div>
  );
};

export default PredictionVote;

import api from "../../../utils/api";

// Validate email
export const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

// Register user
const register = async (userData) => {
  const response = await api.post("users/register", userData);
  localStorage.setItem("authToken", response.data.token);
  return response.data;
};

// Login user
const login = async (userData) => {
  const response = await api.post("users/login", userData);
  localStorage.setItem("authToken", response.data.token);
  return response.data;
};

// Logout user
const logout = async () => {
  const response = await api.get("users/logout");
  localStorage.removeItem("authToken");
  return response.data.message;
};

// Get status (loggedin or not)
const getLoginStatus = async () => {
  const response = await api.get("users/loginStatus");
  return response.data;
};

// Get user profile
const getUser = async () => {
  const response = await api.get("users/getUser");
  localStorage.setItem("userData", JSON.stringify(response.data));
  return response.data;
};

// Update user profile
const updateUser = async (userData) => {
  const response = await api.patch("users/updateUser", userData);
  localStorage.setItem("userData", JSON.stringify(response.data));
  return response.data;
};

// Get user notifications
const getUserNotifications = async (userData) => {
  const response = await api.post("users/getnotifications", userData);
  localStorage.setItem("userData", JSON.stringify(response.data));
  return response.data;
};

// Set user notifications read
const setNotificationRead = async (userData) => {
  const response = await api.post("users/notification/read", userData);
  localStorage.setItem("userData", JSON.stringify(response.data));
  return response.data;
};

// Send Verification email
const sendVerificationEmail = async () => {
  const response = await api.post("users/sendVerificationEmail");
  return response.data.message;
};

// Send Verification email
const verifyUser = async (verificationToken) => {
  const response = await api.patch(`users/verifyUser/${verificationToken}`);
  return response.data;
};

// Change Password
const changePassword = async (userData) => {
  const response = await api.patch("users/changePassword", userData);
  return response.data.message;
};

// Forgot Password
const forgotPassword = async (userData) => {
  const response = await api.post("users/forgotPassword", userData);
  return response.data.message;
};

// Reset Password
const resetPassword = async (userData, resetToken) => {
  const response = await api.patch(
    `users/resetPassword/${resetToken}`,
    userData
  );
  localStorage.setItem("authToken", response.data.token);
  return response.data;
};

// Get users
const getUsers = async (usersData) => {
  const response = await api.post("users/getUsers", usersData);
  return response.data;
};

// Delete user
const deleteUser = async (id) => {
  const response = await api.delete(`users/${id}`);
  return response.data.message;
};

// Upgrade user
const upgradeUser = async (userData) => {
  const response = await api.post("users/upgradeUser", userData);
  return response.data.message;
};

// Send Login Code
const sendLoginCode = async (email) => {
  const response = await api.post(`users/sendLoginCode/${email}`);
  return response.data.message;
};

// Login With Code
const loginWithCode = async (code, email) => {
  const response = await api.post(`users/loginWithCode/${email}`, code);
  localStorage.setItem("authToken", response.data.token);
  return response.data;
};

// Login With Google
const loginWithGoogle = async (userToken) => {
  const response = await api.post("users/google/callback", userToken);
  return response.data;
};

// Get user by username
const getUserByUsername = async (userData) => {
  const response = await api.post("users/getuserbyusername", userData);
  return response.data;
};

// Follow user
const followUser = async (userData) => {
  const response = await api.post("users/follow", userData);
  return response.data;
};

const authService = {
  register,
  login,
  logout,
  getLoginStatus,
  getUser,
  updateUser,
  sendVerificationEmail,
  verifyUser,
  changePassword,
  forgotPassword,
  resetPassword,
  getUsers,
  deleteUser,
  upgradeUser,
  sendLoginCode,
  loginWithCode,
  loginWithGoogle,
  getUserByUsername,
  followUser,
  getUserNotifications,
  setNotificationRead,
};

export default authService;

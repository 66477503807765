import React, { useState, useEffect } from "react";
import { MdDelete } from "react-icons/md";
import { BsCheck2All } from "react-icons/bs";
import Notification from "../../comp/Notifications/Notification";
import { useDispatch } from "react-redux";
import {
  getUserNotifications,
  setNotificationRead,
} from "../../redux/features/auth/authSlice";

const Notifications = ({ user, handleModal }) => {
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState(null);
  const [totalNotifications, setTotalNotifications] = useState(null);
  const [limit, setLimit] = useState(15);
  useEffect(() => {
    setNotifications(user?.notifications);
    setUnreadNotifications(user?.unreadNotifications);
    setTotalNotifications(user?.totalNotifications);
  }, [user]);

  // trigger load more notif
  const handleScroll = (e) => {
    console.log(
      e.target.scrollHeight,
      e.target.scrollTop,
      e.target.clientHeight
    );
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && totalNotifications > limit) {
      setLimit(limit + 5);
      const userData = {
        _id: user?._id,
        limit: limit + 5,
      };
      dispatch(getUserNotifications(userData));
    }
  };
  //end
  const handleNotification = (id) => {
    const userData = {
      userId: user?._id,
      notificationId: id,
    };
    dispatch(setNotificationRead(userData));
  };
  return (
    <>
      <div className="notification__header notification__header_opened">
        <div>
          <span className="notification-counter">
            Notificari <span>({unreadNotifications})</span>
          </span>
        </div>
        {/* <div className="flex-row flex-row_center">
          <BsCheck2All />
          <div className="delete" data-tip="Delete all" currentitem="false">
            <MdDelete />
          </div>
        </div> */}
      </div>
      <div className="notification__body" onScroll={handleScroll}>
        {notifications?.map((n) => (
          <Notification
            key={n._id}
            handleModal={handleModal}
            n={n}
            setNotificationRead={setNotificationRead}
            userId={user?._id}
          />
        ))}
      </div>
    </>
  );
};

export default Notifications;

import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { FaAnchor } from "react-icons/fa";
import Chat from "../../comp/Chat/Chat";
import CreatePrediction from "../../comp/CreatePrediction/CreatePrediction";
import TicketInBottom from "../../comp/CreatePrediction/TicketInBottom";
import Footer from "../../comp/Footer/Footer";
import Header from "../../comp/Header/Header";
import Notifications from "../../comp/Notifications/Notifications";
import VerifyAccount from "../../comp/VerifyAccount/VerifyAccount";
import Modal from "../../modals/Modal";
import ModalForgot from "../../modals/ModalForgot";
import ModalLogin from "../../modals/ModalLogin";
import ModalLoginWithCode from "../../modals/ModalLoginWithCode";
import ModalRegister from "../../modals/ModalRegister";
import ModalReset from "../../modals/ModalReset";
import ModalVerify from "../../modals/ModalVerify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  getPredictionById,
  RESETPREDICTION,
  RESETPREDICTIONSBYUSER,
  likePrediction,
  dislikePrediction,
  getAllPredictionsForUser,
} from "../../redux/features/predictions/predictionsSlice";
import { convertDate } from "../../utils/ConvertDate/convertDate";
import { getAllMatchesV2 } from "../../redux/features/rapidapi/rapidApiSlice";
import {
  updateUser,
  getUserByUsername,
} from "../../redux/features/auth/authSlice";
import AvatarBox from "./AvatarBox";
import Moment from "react-moment";
import GraphProfile from "./GraphProfile";
import UserPredictions from "./UserPredictions";
import BottomToolbar from "../../comp/BottomToolbar/BottomToolbar";
import ModalChangeAvatar from "../../modals/ModalChangeAvatar";
import ModalChangePassword from "../../modals/ModalChangePassword";
import { toast, ToastContainer } from "react-toastify";
import DuckLoader from "../../utils/Loaders/DuckLoader";
import { Helmet } from "react-helmet-async";

const cloud_name = process.env.REACT_APP_CLOUD_NAME;
const upload_preset = process.env.REACT_APP_UPLOAD_PRESET;

const Profile = ({
  onlineUsers,
  setMessages,
  messages,
  showChat,
  setShowChat,
  setNewMessage,
  newMessage,
  isSharedPrediction,
  setIsSharedPrediction,
  getUser,
  toggleMobileMenu,
  setMobileMenu,
  mobileMenu,
}) => {
  const { handle } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [loadingProfile, setLoadingProfile] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoadingProfile(false);
    }, 500);
  }, []);

  const { isLoading, isLoggedIn, isSuccess, userProfile, user, users } =
    useSelector((state) => state.auth);

  const { prediction, predictionsByUser, isLoadingActions } = useSelector(
    (state) => state.predictions
  );

  useEffect(() => {
    const body = document.querySelector("body");

    body.classList.add("about-token");
    return () => {
      body.classList.remove("about-token");
    };
  }, []);

  const initialState = {
    name: user?.name || "",
    email: user?.email || "",
    phone: user?.phone || "",
    bio: user?.bio || "",
    photo: user?.photo || "",
    role: user?.role || "",
    isVerified: user?.isVerified || false,
    userAgent: user?.userAgent || null,
  };

  const initialStatePassword = {
    oldPassword: "",
    password: "",
    password2: "",
  };

  const [profile, setProfile] = useState(initialState);
  const [formData, setFormData] = useState(initialStatePassword);
  const { oldPassword, password, password2 } = formData;
  const [profileImage, setProfileImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const [showAvatarModal, setShowAvatarModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

  const handleChangePasswordModal = () => {
    setShowChangePasswordModal(!showChangePasswordModal);
  };

  const handleAvatarModal = () => {
    setShowAvatarModal(!showAvatarModal);
  };
  const saveProfile = async (e) => {
    e.preventDefault();
    let imageURL;
    try {
      if (
        profileImage !== null &&
        profileImage !== undefined &&
        (profileImage.type === "image/jpeg" ||
          profileImage.type === "image/jpg" ||
          profileImage.type === "image/png")
      ) {
        const image = new FormData();
        image.append("file", profileImage);
        image.append("cloud_name", cloud_name);
        image.append("upload_preset", upload_preset);

        // Save image to cloudinary
        const response = await fetch(
          "https://api.cloudinary.com/v1_1/analizesportive/image/upload",
          {
            method: "post",
            body: image,
          }
        );
        const imgData = await response.json();
        imageURL = imgData.secure_url.toString();
      }

      // Save profile to MongoDB
      const userData = {
        name: profile.name,
        phone: profile.phone,
        bio: profile.bio,
        photo: profileImage ? imageURL : profile.photo,
      };
      // Dispatch the updateUser action and wait for it to complete
      const updateResponse = await dispatch(updateUser(userData));

      // If the update response is successful, call the handleAvatarModal function
      if (updateResponse.meta.requestStatus === "fulfilled") {
        handleAvatarModal(); // Call your handleAvatarModal function
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useLayoutEffect(() => {
    if (user) {
      setProfile({
        ...profile,
        name: user.name,
        email: user.email,
        phone: user.phone,
        photo: user.photo,
        bio: user.bio,
        role: user.role,
        isVerified: user.isVerified,
        userAgent: user.userAgent,
      });
    }
  }, [user]);

  const [panel, setPanel] = useState(false);
  // modal
  const [showModal, setShowModal] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showForgotModal, setShowForgotModal] = useState(false);
  const [showLoginWithCodeModal, setShowLoginWithCodeModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);

  const handleClickOutside = (e) => {
    if (e.target.id === "modal-target") {
      setShowModal(false);
      setShowAuthModal(false);
      setShowForgotModal(false);
      setShowLoginWithCodeModal(false);
      setShowRegisterModal(false);
      setShowVerifyModal(false);
      setShowResetModal(false);
      setShowAvatarModal(false);
      setShowChangePasswordModal(false);
      dispatch(RESETPREDICTION());

      // Delete modal id parameter from URL
      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.delete("modal");
      newSearchParams.delete("id");
      newSearchParams.delete("tk");
      navigate(`${location.pathname}?${newSearchParams.toString()}`);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const [isLoadingPrediction, setIsLoadingThePrediction] = useState(false);
  const handleModal = (eveniment) => {
    if (!showModal) {
      if (!isLoadingPrediction && location.search === "") {
        // Display a loading indicator for 5 seconds
        setIsLoadingThePrediction(true);
        const predictionData = {
          _id: eveniment._id,
        };
        dispatch(getPredictionById(predictionData));
        setTimeout(() => {
          setIsLoadingThePrediction(false); // Remove loading indicator after dispatch
        }, 2000); // 5000 milliseconds (5 seconds)
      }

      // Add modal id parameter to URL
      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.set("modal", "prediction");
      newSearchParams.set("id", eveniment._id);
      navigate(`${location.pathname}?${newSearchParams.toString()}`);
      setShowModal(true);
    } else {
      if (prediction._id === eveniment._id) {
        // If the modal is already open for the clicked event, do nothing
        return;
      }
      dispatch(RESETPREDICTION());
      setShowModal(false);

      // Delete modal id parameter from URL
      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.delete("modal");
      newSearchParams.delete("id");
      navigate(`${location.pathname}?${newSearchParams.toString()}`);
    }
  };

  useEffect(() => {
    const modalPredictionId = new URLSearchParams(location.search).get("id");

    if (modalPredictionId && prediction === null) {
      setIsLoadingThePrediction(true);
      const predictionData = {
        _id: modalPredictionId,
      };
      setShowModal(true);
      dispatch(getPredictionById(predictionData));
      setTimeout(() => {
        setIsLoadingThePrediction(false); // Remove loading indicator after dispatch
      }, 2000); // 5000 milliseconds (5 seconds)
    }
  }, [location.search, predictionsByUser, showModal]);

  useEffect(() => {
    const modalPredictionId = new URLSearchParams(location.search).get("modal");

    if (modalPredictionId === "autentificare") {
      setShowAuthModal(true);
      setShowForgotModal(false);
      setShowRegisterModal(false);
    } else if (modalPredictionId === "recuperare-parola") {
      setShowForgotModal(true);
      setShowAuthModal(false);
      setShowRegisterModal(false);
    } else if (modalPredictionId === "autentificare-cod") {
      setShowLoginWithCodeModal(true);
      setShowAuthModal(false);
      setShowForgotModal(false);
      setShowRegisterModal(false);
    } else if (modalPredictionId === "inregistrare") {
      setShowRegisterModal(true);
      setShowAuthModal(false);
      setShowForgotModal(false);
    } else if (modalPredictionId === "verificare-cont") {
      setShowVerifyModal(true);
    } else if (modalPredictionId === "resetare-parola") {
      setShowResetModal(true);
    }
  }, [
    location.search,
    showAuthModal,
    showForgotModal,
    showLoginWithCodeModal,
    showRegisterModal,
    showVerifyModal,
    showResetModal,
  ]);

  // Update search params and navigate to the updated URL
  useEffect(() => {
    if (prediction) {
      if (showModal) {
        searchParams.set("modal", "prediction");
        searchParams.set("id", prediction._id);
      } else {
        searchParams.delete("modal");
        searchParams.delete("id");
      }
      navigate(`${location.pathname}?${searchParams.toString()}`);
    }
  }, [showModal, isLoadingPrediction, prediction]);

  useEffect(() => {
    if (showModal && prediction) {
      const intervalId = setInterval(() => {
        const predictionData = {
          _id: prediction._id,
        };
        dispatch(getPredictionById(predictionData));
      }, 40000);
      return () => clearInterval(intervalId);
    }
  }, [showModal, prediction]);

  // auth modal
  const handleAuthenticationModal = (modalId, show, additionalParams = {}) => {
    if (show) {
      // Add modal id parameter to URL
      const newSearchParams = searchParams;
      newSearchParams.set("modal", modalId);
      for (const key in additionalParams) {
        newSearchParams.set(key, additionalParams[key]);
      }
      navigate(`${location.pathname}?${newSearchParams.toString()}`);
    } else {
      // Delete modal id parameter from URL
      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.delete("modal");
      for (const key in additionalParams) {
        newSearchParams.delete(key);
      }
      navigate(`${location.pathname}?${newSearchParams.toString()}`);
    }
  };

  // Example usage
  const handleAuthModal = () => {
    if (!showAuthModal) {
      handleAuthenticationModal("autentificare", true);
      setShowAuthModal(true);
    } else {
      setShowAuthModal(false);
      handleAuthenticationModal("autentificare", false);
    }
  };

  const handleForgotModal = () => {
    handleAuthModal();
    if (!showForgotModal) {
      handleAuthenticationModal("recuperare-parola", true);
      setShowForgotModal(true);
    } else {
      setShowForgotModal(false);
      handleAuthenticationModal("recuperare-parola", false);
    }
  };

  const handleLoginWithCodeModal = (email) => {
    if (!showLoginWithCodeModal) {
      handleAuthenticationModal("autentificare-cod", true, { email });
      setShowLoginWithCodeModal(true);
    } else {
      setShowLoginWithCodeModal(false);
      handleAuthenticationModal("autentificare-cod", false, { email });
    }
  };

  const handleRegisterModal = () => {
    if (!showRegisterModal) {
      handleAuthenticationModal("inregistrare", true);
      setShowRegisterModal(true);
    } else {
      setShowRegisterModal(false);
      handleAuthenticationModal("inregistrare", false);
    }
  };

  const handleVerifyModal = (tk) => {
    if (!showVerifyModal) {
      handleAuthenticationModal("verificare-cont", true, { tk });
      setShowVerifyModal(true);
    } else {
      setShowVerifyModal(false);
      handleAuthenticationModal("verificare-cont", false, { tk });
    }
  };

  const handleResetModal = (tk) => {
    if (!showResetModal) {
      handleAuthenticationModal("resetare-parola", true, { tk });
      setShowResetModal(true);
    } else {
      setShowResetModal(false);
      handleAuthenticationModal("resetare-parola", false, { tk });
    }
  };

  //

  useEffect(() => {
    const body = document.querySelector("body");

    if (
      showModal ||
      showAuthModal ||
      showForgotModal ||
      showLoginWithCodeModal ||
      showRegisterModal ||
      showVerifyModal ||
      showResetModal
    ) {
      body.classList.add("body-scroll-hidden");
      return () => {
        body.classList.remove("body-scroll-hidden");
      };
    } else {
    }
  }, [
    showModal,
    showAuthModal,
    showForgotModal,
    showLoginWithCodeModal,
    showRegisterModal,
    showVerifyModal,
    showResetModal,
  ]);
  // modal

  // notif
  const [showNotification, setShowNotification] = useState(false);
  const [showMatches, setShowMatches] = useState(false);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [unreadChatCount, setUnreadChatCount] = useState(0);

  useEffect(() => {
    if (showMatches || showNotification || showChat) {
      const mainPage = document.querySelector(".page__main");
      mainPage.classList.add("page__main_collapsed");
      return () => {
        mainPage.classList.remove("page__main_collapsed");
      };
    }
  }, [showMatches, showNotification, showChat]);

  const handlePanel = () => {
    setPanel(!panel);
    setShowMatches(false);
    setShowNotification(false);
    setShowChat(false);
    setDropdown(false);
  };

  const handleShowNotification = () => {
    setShowNotification(!showNotification);
    setShowMatches(false);
    setShowChat(false);
  };
  const handleShowChat = () => {
    setShowChat(!showChat);
    setShowNotification(false);
    setShowMatches(false);
    handleViewChatNotifications(0);
  };

  const handleViewChatNotifications = () => {
    setNotificationsCount(0);
    setUnreadChatCount(0);
  };
  const [startDate, setStartDate] = useState(new Date());
  const [toggleAccumulator, setToggleAccumulator] = useState(false);

  const handleShowMatches = () => {
    const matchDate = {
      date: convertDate(new Date()),
    };
    dispatch(getAllMatchesV2(matchDate)).then(() => {
      setShowMatches(!showMatches);
      setShowNotification(false);
      setShowChat(false);
      setToggleAccumulator(true);
    });
  };
  // handle dropdown
  const [dropdown, setDropdown] = useState(false);
  const desktopRef = useRef(null);
  const mobileRef = useRef(null);

  useEffect(() => {
    if (dropdown || showNotification || showMatches) {
      setPanel(false);
    }
  }, [dropdown, showNotification, showMatches]);

  const handleClick = () => {
    setDropdown(!dropdown);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        desktopRef.current &&
        !desktopRef.current.contains(event.target) &&
        mobileRef.current &&
        !mobileRef.current.contains(event.target)
      ) {
        setDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [desktopRef, mobileRef]);

  // handle dropdown

  // actions like dislikes comments
  const handleLikePrediction = (userId, predictionId) => {
    const likeData = {
      userId,
      predictionId,
    };
    dispatch(likePrediction(likeData));
  };
  const handleDislikePrediction = (userId, predictionId) => {
    const dislikeData = {
      userId,
      predictionId,
    };
    dispatch(dislikePrediction(dislikeData));
  };
  // end

  // get currentselected matches de pe ticket  din localstorage //

  const [evenimentePeBilet, setEvenimentePeBilet] = useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("evenimentePeBilet"));
    if (items) {
      setEvenimentePeBilet(items);
    }
  }, []);
  const [isOpen, setIsOpen] = useState(false);

  // PROFILE START
  const [period, setPeriod] = useState("last30Days");
  useEffect(() => {
    const userData = {
      handle,
      period,
    };

    dispatch(getUserByUsername(userData)).then((response) => {
      if (response.payload === "User not found.") {
        console.log("User doesn't exist");
        navigate("/404"); // Navigate to the 404 page
        return;
      }
      if (predictionsByUser.length > 0) {
        dispatch(RESETPREDICTIONSBYUSER());
      }
      const userId = response.payload._id;
      const predictionsData = {
        predictionStatus: "all",
        totalResults: 5,
        userId,
      };
      dispatch(getAllPredictionsForUser(predictionsData));
    });
  }, [dispatch, navigate, handle, period]);

  //graph
  const [graphData, setGraphData] = useState(userProfile?.daysOfMonthEven);
  const [graphDataLabel, setGraphDataLabel] = useState(0);
  useEffect(() => {
    if (period === "lastWeek") {
      setGraphData(userProfile?.daysOfWeek);
    } else if (period === "last30Days") {
      setGraphData(userProfile?.daysOfMonthEven);
    } else if (period === "allTime") {
      setGraphData(userProfile?.allTime);
    }
  }, [userProfile, period]);

  const dates = graphData?.map((day) => day.date) || [];
  const sumProfits = graphData?.map((day) => day.sumProfit) || [];

  const chartData = {
    series: [
      {
        name: "Profit",
        data: sumProfits,
      },
    ],
    options: {
      chart: {
        height: 320,
        type: "area",
        responsive: true,
        width: "100%",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
        foreColor: "#9ca3af",
      },
      colors: ["#487a6b", "#487a6b"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 5,
        colors: ["#487a6b"],
      },
      grid: {
        borderColor: "#9ca3af25",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: true,
          },
        },
        strokeDashArray: 10, // Set the dash length
      },
      yaxis: {
        axisBorder: {
          show: true,
          color: "#78909C",
          height: 2,
          offsetX: 0,
          offsetY: 0,
        },
        tickAmount: 5,
        show: true,
        labels: {
          formatter: (value) => {
            return value.toFixed(2);
          },
          style: {
            fontSize: "12px",
          },
        },
      },
      xaxis: {
        axisBorder: {
          show: true,
          color: "#78909C",
          height: 2,
          offsetX: 0,
          offsetY: 0,
        },
        labels: {
          style: {
            fontSize: "12px",
          },
        },
        categories: dates.map((date) => {
          const dateObject = new Date(date);
          return `${dateObject.toLocaleString("default", {
            month: "2-digit",
          })}/${dateObject.getDate()} `;
        }),
      },
    },
  };

  const [randament, setRandament] = useState(null);
  const [userProfit, setUserProfit] = useState(null);
  const [lastPredictions, setLastPredictions] = useState(null);

  useEffect(() => {
    setRandament(userProfile?.yield !== "NaN" ? userProfile?.yield : "N/A");
    setLastPredictions(userProfile?.last5Predictions);
    setUserProfit(userProfile?.profit ? userProfile?.profit.toFixed(2) : "N/A");
  }, [userProfile]);
  return (
    <>
      <Helmet>
        <title>
          Profil {userProfile?.name ? `- ${userProfile.name}` : ""} -
          AnalizeSportive
        </title>
        <meta
          name="description"
          content={`Pagina de profil a tipsterului ${userProfile?.name}`}
        />
        <meta name="og:image" content="https://i.ibb.co/FnPJ7dF/cover.jpg" />
      </Helmet>
      <div className="page">
        <div className="page__main">
          <div id="react-modal-screen">
            <div id="modals">
              {showModal && (
                <Modal
                  handleModal={handleModal}
                  showModal={showModal}
                  prediction={prediction}
                  user={user}
                  isLoggedIn={isLoggedIn}
                  isLoading={isLoading}
                  isLoadingActions={isLoadingActions}
                  handleDislikePrediction={handleDislikePrediction}
                  handleLikePrediction={handleLikePrediction}
                  isLoadingPrediction={isLoadingPrediction}
                />
              )}
              {showAuthModal && (
                <ModalLogin
                  showAuthModal={showAuthModal}
                  setShowAuthModal={setShowAuthModal}
                  handleAuthModal={handleAuthModal}
                  handleForgotModal={handleForgotModal}
                  handleRegisterModal={handleRegisterModal}
                />
              )}
              {showForgotModal && (
                <ModalForgot
                  showForgotModal={showForgotModal}
                  setShowForgotModal={setShowForgotModal}
                  handleAuthModal={handleAuthModal}
                  handleForgotModal={handleForgotModal}
                  handleRegisterModal={handleRegisterModal}
                />
              )}
              {showLoginWithCodeModal && (
                <ModalLoginWithCode
                  handleLoginWithCodeModal={handleLoginWithCodeModal}
                  setShowLoginWithCodeModal={setShowLoginWithCodeModal}
                  showLoginWithCodeModal={showLoginWithCodeModal}
                />
              )}
              {showRegisterModal && (
                <ModalRegister
                  handleRegisterModal={handleRegisterModal}
                  showRegisterModal={showRegisterModal}
                  setShowRegisterModal={setShowRegisterModal}
                  setShowAuthModal={setShowAuthModal}
                  handleAuthModal={handleAuthModal}
                />
              )}
              {showVerifyModal && (
                <ModalVerify
                  setShowVerifyModal={setShowVerifyModal}
                  handleVerifyModal={handleVerifyModal}
                />
              )}
              {showResetModal && (
                <ModalReset handleResetModal={handleResetModal} />
              )}

              {showAvatarModal && (
                <ModalChangeAvatar
                  showAvatarModal={showAvatarModal}
                  handleAvatarModal={handleAvatarModal}
                  setProfileImage={setProfileImage}
                  setImagePreview={setImagePreview}
                  imagePreview={imagePreview}
                  saveProfile={saveProfile}
                  setShowAvatarModal={setShowAvatarModal}
                  user={user}
                  isLoading={isLoading}
                  isSuccess={isSuccess}
                />
              )}
              {showChangePasswordModal && (
                <ModalChangePassword
                  showChangePasswordModal={showChangePasswordModal}
                  setShowChangePasswordModal={setShowChangePasswordModal}
                  handleChangePasswordModal={handleChangePasswordModal}
                />
              )}
            </div>
          </div>
          <VerifyAccount
            user={user}
            isLoading={isLoading}
            isLoggedIn={isLoggedIn}
          />
          <Header
            handleShowNotification={handleShowNotification}
            handleShowChat={handleShowChat}
            showChat={showChat}
            showNotification={showNotification}
            handlePanel={handlePanel}
            panel={panel}
            handleClick={handleClick}
            dropdown={dropdown}
            desktopRef={desktopRef}
            onlineUsers={onlineUsers}
            notificationsCount={notificationsCount}
            unreadChatCount={unreadChatCount}
            handleAuthModal={handleAuthModal}
            handleRegisterModal={handleRegisterModal}
            setMobileMenu={setMobileMenu}
            mobileMenu={mobileMenu}
          />
          <div className={`main container ${panel ? "with-overlay" : ""}`}>
            <div className="content">
              <div className="page-block page-block_cms">
                <div
                  id="react-about-token-landing-page"
                  className="about-token-landing-page bonus-landing-pages"
                >
                  <div className="bonus-container-about-token">
                    <div className="content-block welcome-section">
                      <div
                        className="bets-info reversed items-start"
                        style={{ gap: 18 }}
                      >
                        <AvatarBox
                          isLoggedIn={isLoggedIn}
                          user={user}
                          userProfile={userProfile}
                          handleAvatarModal={handleAvatarModal}
                          imagePreview={imagePreview}
                          handleChangePasswordModal={handleChangePasswordModal}
                          loadingProfile={loadingProfile}
                        />

                        <GraphProfile
                          userProfile={userProfile}
                          chartData={chartData}
                          period={period}
                          setPeriod={setPeriod}
                          loadingProfile={loadingProfile}
                        />
                      </div>
                    </div>
                    <div className="my-4">&nbsp;</div>
                    <div className="content-block token-stats-block">
                      {loadingProfile ? (
                        <DuckLoader rowCount={1} />
                      ) : (
                        <>
                          <h2 className="text-center">Statistici</h2>
                          <div className="bonus-stats">
                            <div className="bonus-stats-block _animate _fade-in _active">
                              <div className="bonus-stats-block__info">
                                <span className="label">Predictii</span>
                                <strong className="amount">
                                  <span>{userProfile?.allPredictions}</span>
                                </strong>
                              </div>
                            </div>
                            <div className="bonus-stats-block _animate _fade-in _delay-02 _active">
                              <div className="bonus-stats-block__info">
                                <span className="label">Profit</span>
                                <strong className="amount">
                                  <span>
                                    {userProfit > 0
                                      ? "+" + userProfit
                                      : userProfit}
                                  </span>
                                </strong>
                              </div>
                            </div>
                            <div className="bonus-stats-block _animate _fade-in _delay-04 _active">
                              <div className="bonus-stats-block__info">
                                <span className="label">Randament</span>
                                <strong className="amount">
                                  <div
                                    className={`box-container__value h-5 flex items-center justify-center ${
                                      randament < 0
                                        ? "text-red-500"
                                        : randament > 0
                                        ? "text-green-500"
                                        : ""
                                    }`}
                                  >
                                    {loadingProfile ? (
                                      <p className="animate-pulse w-24 h-5 bg-slate-300 dark:bg-slate-200 rounded"></p>
                                    ) : (
                                      randament + "%"
                                    )}
                                  </div>
                                </strong>
                              </div>
                            </div>
                            <div className="bonus-stats-block _animate _fade-in _delay-06 _active">
                              <div className="bonus-stats-block__info">
                                <span className="label">
                                  Ultimele rezultate
                                </span>
                                <strong className="amount">
                                  {loadingProfile ? (
                                    <p className="animate-pulse w-24 h-5 bg-slate-300 dark:bg-slate-200 rounded"></p>
                                  ) : lastPredictions?.length > 1 ? (
                                    <div className="team-form team-form--left team-form--sm">
                                      {lastPredictions.map(
                                        (prediction, index) => (
                                          <span
                                            key={index}
                                            className={`team-form__item ${
                                              prediction === 1
                                                ? "team-form__item--l"
                                                : "team-form__item--w"
                                            }`}
                                          ></span>
                                        )
                                      )}
                                      <div className="sr-only">
                                        {lastPredictions.map((prediction) =>
                                          prediction === 1 ? "L" : "W"
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    "N/A"
                                  )}
                                </strong>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="content-block">
                      <div className="token-info">
                        <div
                          className="token-info__item _animate _fade-in _active"
                          style={{ width: 350 }}
                        >
                          <h2>In curand</h2>
                          <div className="token-info__tile">In curand</div>
                        </div>
                        {loadingProfile ? (
                          <DuckLoader rowCount={7} />
                        ) : (
                          <UserPredictions
                            predictionsByUser={predictionsByUser}
                            userProfile={userProfile}
                            user={user}
                            isLoggedIn={isLoggedIn}
                            handleModal={handleModal}
                            handleLikePrediction={handleLikePrediction}
                            isLoadingActions={isLoadingActions}
                            onlineUsers={onlineUsers}
                            setMessages={setMessages}
                            messages={messages}
                            setShowChat={setShowChat}
                            showChat={showChat}
                            newMessage={newMessage}
                            setNewMessage={setNewMessage}
                            isSharedPrediction={isSharedPrediction}
                            setIsSharedPrediction={setIsSharedPrediction}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
            <div
              className={`page__panel ${
                showNotification || showMatches || showChat
                  ? ""
                  : "page__panel_hidden"
              }`}
            >
              <div id="react-toolbar-right">
                <div
                  className={`side-panel ${
                    showNotification || showMatches || showChat
                      ? ""
                      : "side-panel_hidden"
                  }`}
                >
                  {showChat && (
                    <div className="chat" id="chat">
                      <Chat
                        user={user}
                        isLoggedIn={isLoggedIn}
                        onlineUsers={onlineUsers}
                        users={users}
                        setMessages={setMessages}
                        messages={messages}
                        newMessage={newMessage}
                        setNewMessage={setNewMessage}
                        predictions={predictionsByUser}
                        isSharedPrediction={isSharedPrediction}
                        setIsSharedPrediction={setIsSharedPrediction}
                        showChat={showChat}
                        getUser={getUser}
                        handleAuthModal={handleAuthModal}
                      />
                    </div>
                  )}

                  {showNotification && (
                    <div className="notification">
                      <Notifications user={user} handleModal={handleModal} />
                    </div>
                  )}
                  {showMatches && (
                    <div className="notification notification_game">
                      <CreatePrediction
                        evenimentePeBilet={evenimentePeBilet}
                        setEvenimentePeBilet={setEvenimentePeBilet}
                        toggleAccumulator={toggleAccumulator}
                        setToggleAccumulator={setToggleAccumulator}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        showMatches={showMatches}
                        handleShowMatches={handleShowMatches}
                        startDate={startDate}
                        setStartDate={setStartDate}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <BottomToolbar
            handleRegisterModal={handleRegisterModal}
            handlePanel={handlePanel}
            handleShowChat={handleShowChat}
            notificationsCount={notificationsCount}
            mobileRef={mobileRef}
            showChat={showChat}
            panel={panel}
            setDropdown={setDropdown}
            dropdown={dropdown}
            isLoggedIn={isLoggedIn}
            toggleMobileMenu={toggleMobileMenu}
            mobileMenu={mobileMenu}
            setMobileMenu={setMobileMenu}
          />
          {!isLoggedIn ? null : (
            <TicketInBottom
              evenimentePeBilet={evenimentePeBilet}
              handleShowMatches={handleShowMatches}
              showMatches={showMatches}
              showNotification={showNotification}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Profile;

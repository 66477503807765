const dark = {
  title: "dark",
  colors: {
    orange: "#fec33c",
    main: "#fff",
    mainDark: "#000",
    mainLight: "#000",
    mainLighter: "#000",
    blackAndWhite: "#000",
    blackAndWhiteR: "#fff",
    textButton: "#fff",
    textSecondaryButton: "#fff",
    textColor: "#fff",
    whiteColor: "#fff",
    shadow: "rgba(0,0,0,.2)",
    cardHeader: "#0e1726",
    bg1: "#1a1d29",
    bg2: "#1f2330",
  },
  backgrounds: {
    bgMain: "#272E48",
    bgHeader: "#1A233A",
    bgCardHome:
      'url("https://www.analizesportive.ro/assets/media/bg/bg-7.jpg")',
  },
  buttons: {
    buttonMain: "rgb(42,113,174)",
  },

  mediaQueries: {
    smallest: `only screen and (max-width: 25em)`,
    small: `only screen and (max-width: 37.5em)`,
    medium: `only screen and (max-width: 56em)`,
    large: `only screen and (max-width: 80em)`,
    largest: `only screen and (max-width: 90em)`,
  },
};

export default dark;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  RESETPREDICTION,
  getPredictionById,
  getAndStorePredictionById,
} from "../../redux/features/predictions/predictionsSlice";
import Header from "../../comp/Header/Header";
import Footer from "../../comp/Footer/Footer";
import Notifications from "../../comp/Notifications/Notifications";
import Chat from "../../comp/Chat/Chat";
import ModalReset from "../../modals/ModalReset";
import ModalVerify from "../../modals/ModalVerify";
import ModalRegister from "../../modals/ModalRegister";
import ModalLoginWithCode from "../../modals/ModalLoginWithCode";
import ModalForgot from "../../modals/ModalForgot";
import ModalLogin from "../../modals/ModalLogin";
import Modal from "../../modals/Modal";
import usePredictionDispatch from "../../utils/LikeAndDislike/LikeAndDislike";
import BottomToolbar from "../../comp/BottomToolbar/BottomToolbar";
import VerifyAccount from "../../comp/VerifyAccount/VerifyAccount";
import { Helmet } from "react-helmet-async";

const Eveniment = ({
  handleShowNotification,
  handleShowChat,
  showChat,
  showNotification,
  handleClick,
  dropdown,
  onlineUsers,
  notificationsCount,
  unreadChatCount,
  showMatches,
  setMessages,
  messages,
  setNewMessage,
  newMessage,
  getUser,
  isSharedPrediction,
  setIsSharedPrediction,
  setDropdown,
  desktopRef,
  mobileRef,
  toggleMobileMenu,
  setMobileMenu,
  mobileMenu,
  setShowChat,
}) => {
  const { id: initialId } = useParams(); // Get the initial id from the URL params
  const [id, setId] = useState(initialId); // State to hold the updated id

  useEffect(() => {
    // This useEffect will be triggered every time the URL params change
    // It will update the 'id' state with the latest value from the URL
    setId(initialId);
  }, [initialId]); // The dependency array ensures that the effect runs whenever 'initialId' changes

  const {
    isLoading,
    isLoadingActions,
    prediction,
    predictionStored,
    predictions,
  } = useSelector((state) => state.predictions);

  const { user, users, isLoggedIn } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  //   useEffect(() => {
  //     const predictionData = {
  //       _id: id,
  //     };
  //     dispatch(getAndStorePredictionById(predictionData));
  //   }, [dispatch, id]);

  // modal
  const searchParams = new URLSearchParams(location.search);
  const [showModal, setShowModal] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showForgotModal, setShowForgotModal] = useState(false);
  const [showLoginWithCodeModal, setShowLoginWithCodeModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);

  useEffect(() => {
    setShowModal(false);
    const newSearchParams = new URLSearchParams(location.search);
    newSearchParams.delete("modal");
    navigate(`${location.pathname}?${newSearchParams.toString()}`);
  }, [id]);

  const handleClickOutside = (e) => {
    if (e.target.id === "modal-target") {
      setShowModal(false);
      setShowAuthModal(false);
      setShowForgotModal(false);
      setShowLoginWithCodeModal(false);
      setShowRegisterModal(false);
      setShowVerifyModal(false);
      setShowResetModal(false);
      dispatch(RESETPREDICTION());

      // Delete modal id parameter from URL
      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.delete("modal");
      newSearchParams.delete("id");
      newSearchParams.delete("tk");
      navigate(`${location.pathname}?${newSearchParams.toString()}`);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const [isLoadingPrediction, setIsLoadingThePrediction] = useState(false);
  const handleModal = (eveniment) => {
    if (!showModal) {
      if (!isLoadingPrediction && location.search === "") {
        // Display a loading indicator for 5 seconds
        setIsLoadingThePrediction(true);
        const predictionData = {
          _id: eveniment._id,
        };
        dispatch(getPredictionById(predictionData));
        setTimeout(() => {
          setIsLoadingThePrediction(false); // Remove loading indicator after dispatch
        }, 2000); // 5000 milliseconds (5 seconds)
      }

      // Add modal id parameter to URL
      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.set("modal", "prediction");
      newSearchParams.set("id", eveniment._id);
      navigate(`${location.pathname}?${newSearchParams.toString()}`);
      setShowModal(true);
    } else {
      if (prediction._id === eveniment._id) {
        // If the modal is already open for the clicked event, do nothing
        return;
      }
      dispatch(RESETPREDICTION());
      setShowModal(false);

      // Delete modal id parameter from URL
      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.delete("modal");
      newSearchParams.delete("id");
      navigate(`${location.pathname}?${newSearchParams.toString()}`);
    }
  };

  useEffect(() => {
    const modalPredictionId = new URLSearchParams(location.search).get("id");

    if (modalPredictionId && prediction === null) {
      setIsLoadingThePrediction(true);
      const predictionData = {
        _id: modalPredictionId,
      };
      setShowModal(true);
      dispatch(getPredictionById(predictionData));
      setTimeout(() => {
        setIsLoadingThePrediction(false); // Remove loading indicator after dispatch
      }, 2000); // 5000 milliseconds (5 seconds)
    }
    dispatch(RESETPREDICTION());

    setShowChat(false);
  }, [location.search, predictions, showModal]);

  useEffect(() => {
    const modalPredictionId = new URLSearchParams(location.search).get("modal");

    if (modalPredictionId === "autentificare") {
      setShowAuthModal(true);
      setShowForgotModal(false);
      setShowRegisterModal(false);
    } else if (modalPredictionId === "recuperare-parola") {
      setShowForgotModal(true);
      setShowAuthModal(false);
      setShowRegisterModal(false);
    } else if (modalPredictionId === "autentificare-cod") {
      setShowLoginWithCodeModal(true);
      setShowAuthModal(false);
      setShowForgotModal(false);
      setShowRegisterModal(false);
    } else if (modalPredictionId === "inregistrare") {
      setShowRegisterModal(true);
      setShowAuthModal(false);
      setShowForgotModal(false);
    } else if (modalPredictionId === "verificare-cont") {
      setShowVerifyModal(true);
    } else if (modalPredictionId === "resetare-parola") {
      setShowResetModal(true);
    }
  }, [
    location.search,
    showAuthModal,
    showForgotModal,
    showLoginWithCodeModal,
    showRegisterModal,
    showVerifyModal,
    showResetModal,
  ]);

  // Update search params and navigate to the updated URL
  useEffect(() => {
    if (prediction) {
      if (showModal) {
        searchParams.set("modal", "prediction");
        searchParams.set("id", prediction._id);
      } else {
        searchParams.delete("modal");
        searchParams.delete("id");
      }
      navigate(`${location.pathname}?${searchParams.toString()}`);
    }
  }, [showModal, isLoadingPrediction, prediction, id]);

  useEffect(() => {
    if (showModal && prediction) {
      const intervalId = setInterval(() => {
        const predictionData = {
          _id: prediction._id,
        };
        dispatch(getPredictionById(predictionData));
      }, 40000);
      return () => clearInterval(intervalId);
    }
  }, [showModal, prediction, id]);

  // auth modal
  const handleAuthenticationModal = (modalId, show, additionalParams = {}) => {
    if (show) {
      // Add modal id parameter to URL
      const newSearchParams = searchParams;
      newSearchParams.set("modal", modalId);
      for (const key in additionalParams) {
        newSearchParams.set(key, additionalParams[key]);
      }
      navigate(`${location.pathname}?${newSearchParams.toString()}`);
    } else {
      // Delete modal id parameter from URL
      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.delete("modal");
      for (const key in additionalParams) {
        newSearchParams.delete(key);
      }
      navigate(`${location.pathname}?${newSearchParams.toString()}`);
    }
  };

  // Example usage
  const handleAuthModal = () => {
    if (!showAuthModal) {
      handleAuthenticationModal("autentificare", true);
      setShowAuthModal(true);
    } else {
      setShowAuthModal(false);
      handleAuthenticationModal("autentificare", false);
    }
  };

  const handleForgotModal = () => {
    handleAuthModal();
    if (!showForgotModal) {
      handleAuthenticationModal("recuperare-parola", true);
      setShowForgotModal(true);
    } else {
      setShowForgotModal(false);
      handleAuthenticationModal("recuperare-parola", false);
    }
  };

  const handleLoginWithCodeModal = (email) => {
    if (!showLoginWithCodeModal) {
      handleAuthenticationModal("autentificare-cod", true, { email });
      setShowLoginWithCodeModal(true);
    } else {
      setShowLoginWithCodeModal(false);
      handleAuthenticationModal("autentificare-cod", false, { email });
    }
  };

  const handleRegisterModal = () => {
    if (!showRegisterModal) {
      handleAuthenticationModal("inregistrare", true);
      setShowRegisterModal(true);
    } else {
      setShowRegisterModal(false);
      handleAuthenticationModal("inregistrare", false);
    }
  };

  const handleVerifyModal = (tk) => {
    if (!showVerifyModal) {
      handleAuthenticationModal("verificare-cont", true, { tk });
      setShowVerifyModal(true);
    } else {
      setShowVerifyModal(false);
      handleAuthenticationModal("verificare-cont", false, { tk });
    }
  };

  const handleResetModal = (tk) => {
    if (!showResetModal) {
      handleAuthenticationModal("resetare-parola", true, { tk });
      setShowResetModal(true);
    } else {
      setShowResetModal(false);
      handleAuthenticationModal("resetare-parola", false, { tk });
    }
  };

  //

  useEffect(() => {
    const body = document.querySelector("body");

    if (
      showModal ||
      showAuthModal ||
      showForgotModal ||
      showLoginWithCodeModal ||
      showRegisterModal ||
      showVerifyModal ||
      showResetModal
    ) {
      body.classList.add("body-scroll-hidden");
      return () => {
        body.classList.remove("body-scroll-hidden");
      };
    } else {
    }
  }, [
    showModal,
    showAuthModal,
    showForgotModal,
    showLoginWithCodeModal,
    showRegisterModal,
    showVerifyModal,
    showResetModal,
  ]);
  // modal

  // like dislike prediction

  const { handleLikePrediction, handleDislikePrediction } =
    usePredictionDispatch();

  // like dislike prediction
  return (
    <>
      {/* <Helmet>
        <title>
          {predictionStored?.evenimente &&
          predictionStored.evenimente.length > 1
            ? `Bilet fotbal `
            : `Predictie fotbal `}
          {predictionStored?.totalOdd
            ? `- cota ${predictionStored.totalOdd}`
            : ""}
          {predictionStored?.createdAt
            ? `- data ${predictionStored.createdAt.split("T")[0]}`
            : ""}
          {predictionStored?.user?.name
            ? ` - tipster ${predictionStored.user.name} - `
            : ""}
          AnalizeSportive
        </title>
        <meta
          name="description"
          content="Vezi clasamentul tipsterilor AnalizeSportive în funcție de profit. Descoperă cei mai buni specialiști la ponturi pariuri sportive online în 2023"
        />
        <meta name="og:image" content="https://i.ibb.co/FnPJ7dF/cover.jpg" />
      </Helmet> */}

      <div className="page">
        <div className="page__main">
          <div id="react-modal-screen">
            <div id="modals">
              {showModal && (
                <Modal
                  handleModal={handleModal}
                  showModal={showModal}
                  prediction={prediction}
                  user={user}
                  isLoggedIn={isLoggedIn}
                  isLoading={isLoading}
                  isLoadingActions={isLoadingActions}
                  handleDislikePrediction={handleDislikePrediction}
                  handleLikePrediction={handleLikePrediction}
                  isLoadingPrediction={isLoadingPrediction}
                />
              )}
              {showAuthModal && (
                <ModalLogin
                  showAuthModal={showAuthModal}
                  setShowAuthModal={setShowAuthModal}
                  handleAuthModal={handleAuthModal}
                  handleForgotModal={handleForgotModal}
                  handleRegisterModal={handleRegisterModal}
                />
              )}
              {showForgotModal && (
                <ModalForgot
                  showForgotModal={showForgotModal}
                  setShowForgotModal={setShowForgotModal}
                  handleAuthModal={handleAuthModal}
                  handleForgotModal={handleForgotModal}
                  handleRegisterModal={handleRegisterModal}
                />
              )}
              {showLoginWithCodeModal && (
                <ModalLoginWithCode
                  handleLoginWithCodeModal={handleLoginWithCodeModal}
                  setShowLoginWithCodeModal={setShowLoginWithCodeModal}
                  showLoginWithCodeModal={showLoginWithCodeModal}
                />
              )}
              {showRegisterModal && (
                <ModalRegister
                  handleRegisterModal={handleRegisterModal}
                  showRegisterModal={showRegisterModal}
                  setShowRegisterModal={setShowRegisterModal}
                  setShowAuthModal={setShowAuthModal}
                  handleAuthModal={handleAuthModal}
                />
              )}
              {showVerifyModal && (
                <ModalVerify
                  setShowVerifyModal={setShowVerifyModal}
                  handleVerifyModal={handleVerifyModal}
                />
              )}
              {showResetModal && (
                <ModalReset handleResetModal={handleResetModal} />
              )}
            </div>
          </div>
          <VerifyAccount
            user={user}
            isLoading={isLoading}
            isLoggedIn={isLoggedIn}
          />
          <Header
            handleShowNotification={handleShowNotification}
            handleShowChat={handleShowChat}
            showChat={showChat}
            showNotification={showNotification}
            handleClick={handleClick}
            dropdown={dropdown}
            desktopRef={desktopRef}
            onlineUsers={onlineUsers}
            notificationsCount={notificationsCount}
            unreadChatCount={unreadChatCount}
            handleAuthModal={handleAuthModal}
            handleRegisterModal={handleRegisterModal}
            setMobileMenu={setMobileMenu}
            mobileMenu={mobileMenu}
          />
          <div className="main container">
            <div className="content">
              <div className="play-block">
                <div className="play-block-content play-block-content_decoration w-100">
                  <div className="play-block-content_left">{id}</div>
                </div>
              </div>
              <Footer />
            </div>
            <div
              className={`page__panel ${
                showNotification || showMatches || showChat
                  ? ""
                  : "page__panel_hidden"
              }`}
            >
              <div id="react-toolbar-right">
                <div
                  className={`side-panel ${
                    showNotification || showMatches || showChat
                      ? ""
                      : "side-panel_hidden"
                  }`}
                >
                  {showChat && (
                    <div className="chat" id="chat">
                      <Chat
                        user={user}
                        isLoggedIn={isLoggedIn}
                        onlineUsers={onlineUsers}
                        users={users}
                        setMessages={setMessages}
                        messages={messages}
                        newMessage={newMessage}
                        setNewMessage={setNewMessage}
                        predictions={predictions}
                        isSharedPrediction={isSharedPrediction}
                        setIsSharedPrediction={setIsSharedPrediction}
                        showChat={showChat}
                        getUser={getUser}
                        handleAuthModal={handleAuthModal}
                      />
                    </div>
                  )}

                  {showNotification && (
                    <div className="notification">
                      <Notifications user={user} handleModal={handleModal} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <BottomToolbar
            handleRegisterModal={handleRegisterModal}
            handleShowChat={handleShowChat}
            notificationsCount={notificationsCount}
            mobileRef={mobileRef}
            showChat={showChat}
            setDropdown={setDropdown}
            dropdown={dropdown}
            isLoggedIn={isLoggedIn}
            toggleMobileMenu={toggleMobileMenu}
            mobileMenu={mobileMenu}
            setMobileMenu={setMobileMenu}
          />
        </div>
      </div>
    </>
  );
};

export default Eveniment;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import cotadedoiService from "./cotadedoiService";
import { toast } from "react-toastify";

const initialState = {
  cotadedoi: null,
  allcotadedoi: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  totalCotadedoi: null,
  averageTotalOdd: null,
  last5StatusValues: [],
  averageYield: null,
  last5Cotadedoi: [],
};

// Add cota de doi

export const addCotadedoi = createAsyncThunk(
  "cotadedoi/cotadedoi",
  async (cotadedoiData, thunkAPI) => {
    try {
      return await cotadedoiService.addCotadedoi(cotadedoiData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get cota de doi by date
export const getCotadedoiBydate = createAsyncThunk(
  "cotadedoi/getCotadedoiBydate",
  async (cotadedoiData, thunkAPI) => {
    try {
      return await cotadedoiService.getCotadedoiBydate(cotadedoiData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get all cota de doi
export const getAllCotadedoi = createAsyncThunk(
  "cotadedoi/getAllCotadedoi",
  async (params, thunkAPI) => {
    const { totalResults, cotadedoiStatus } = params;
    try {
      return await cotadedoiService.getAllCotadedoi({
        totalResults,
        cotadedoiStatus,
      });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Approve eveniment Cota de doi
export const approveCotadedoi = createAsyncThunk(
  "cotadedoi/approveCotadedoi",
  async (matchData, thunkAPI) => {
    try {
      return await cotadedoiService.approveCotadedoi(matchData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Approve cota de doi ticket
export const approveCotadedoiTicket = createAsyncThunk(
  "cotadedoi/approveCotadedoiTicket",
  async (matchData, thunkAPI) => {
    try {
      return await cotadedoiService.approveCotadedoiTicket(matchData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

const cotadedoiSlice = createSlice({
  name: "cotadedoi",
  initialState,
  reducers: {
    RESET(state) {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      // Add cota de doi
      .addCase(addCotadedoi.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addCotadedoi.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.cotadedoi = action.payload;
        state.message = "success";
        toast.success(action.payload);
      })
      .addCase(addCotadedoi.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      // Get cota de doi by date
      .addCase(getCotadedoiBydate.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCotadedoiBydate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.cotadedoi = action.payload;
        state.message = "success";
      })
      .addCase(getCotadedoiBydate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.cotadedoi = null;
        toast.error(action.payload);
      })
      // Get all cota de doi
      .addCase(getAllCotadedoi.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllCotadedoi.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allcotadedoi = action.payload.cotadedoisFinal;
        state.totalCotadedoi = action.payload.noTotalCotadedoi;
        state.averageTotalOdd = action.payload.averageTotalOdd;
        state.last5StatusValues = action.payload.last5StatusValues;
        state.averageYield = action.payload.averageYield;
        state.last5Cotadedoi = action.payload.last5Cotadedoi;
      })
      .addCase(getAllCotadedoi.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      // Approve cota de doi
      .addCase(approveCotadedoi.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(approveCotadedoi.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        toast.success(action.payload.message);
      })
      .addCase(approveCotadedoi.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      // Approve cota de doi ticket
      .addCase(approveCotadedoiTicket.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(approveCotadedoiTicket.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        toast.success(action.payload.message);
      })
      .addCase(approveCotadedoiTicket.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      });
  },
});

export const { RESET } = cotadedoiSlice.actions;

export default cotadedoiSlice.reducer;

import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { BsCheck2All } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import PasswordInput from "../utils/PasswordInput/PasswordInput";
import { validateEmail } from "../redux/features/auth/authService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import {
  register,
  RESET,
  sendVerificationEmail,
} from "../redux/features/auth/authSlice";
import { CgSpinner } from "react-icons/cg";

const initialState = {
  name: "",
  email: "",
  password: "",
  password2: "",
};

const ModalRegister = ({
  handleRegisterModal,
  handleAuthModal,
  setShowRegisterModal,
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [formData, setFormData] = useState(initialState);
  const { name, email, password, password2 } = formData;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, isLoggedIn, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  const [uCase, setUCase] = useState(false);
  const [num, setNum] = useState(false);
  const [passLength, setPassLength] = useState(false);

  const timesIcon = <FaTimes color="red" size={15} />;
  const checkIcon = <BsCheck2All color="green" size={15} />;

  const switchIcon = (condition) => {
    if (condition) {
      return checkIcon;
    }
    return timesIcon;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
      setUCase(true);
    } else {
      setUCase(false);
    }
    if (password.match(/([0-9])/)) {
      setNum(true);
    } else {
      setNum(false);
    }
    if (password.length > 5) {
      setPassLength(true);
    } else {
      setPassLength(false);
    }
  }, [password]);

  const registerUser = async (e) => {
    e.preventDefault();

    if (!name || !email || !password) {
      return toast.error("Toate campurile sunt obligatorii");
    }

    if (password.length < 6) {
      return toast.error("Minim 6 caractere trebuie sa contina parola");
    }

    if (!validateEmail(email)) {
      return toast.error("Te rugam sa introduci un email valid");
    }

    if (password != password2) {
      return toast.error("Parolele nu se potrivesc");
    }

    const userData = {
      name,
      email,
      password,
    };

    await dispatch(register(userData));

    await dispatch(sendVerificationEmail());
  };

  useEffect(() => {
    if (isSuccess && isLoggedIn) {
      // Delete modal id parameter from URL
      setShowRegisterModal(false);
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete("modal");
      navigate("/");
    }
    dispatch(RESET());
  }, [isLoggedIn, isSuccess, dispatch, navigate]);

  return (
    <div className="modal-screen modal-screen_login showModalBg">
      <div className="modal-screen-wrap" id="modal-target">
        <div className="modal-window showModalWindow">
          <div className="modal-header flex-row flex-row_center flex-row_space-between">
            <div className="modal-title">
              <div className="flex-row flex-row_space-between align-center full-width">
                <span>Inregistrare</span>
              </div>
            </div>
            <div className="flex-row flex-row_center">
              <div className="btn-close p-4" onClick={handleRegisterModal}>
                <FaTimes className="text-lg text-gray-500" />
              </div>
            </div>
          </div>
          <div className="modal-content">
            <div className="modal-window__content">
              <div className="welcome-logo">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/socialape-bea5b.appspot.com/o/logo.png?alt=media"
                  alt="AnalizeSportive"
                  title="AnalizeSportive"
                />
              </div>
              <div>
                <div className="welcome-text flex-row flex-row_center">
                  <span>Inregistrare</span>
                </div>
                <form
                  className="space-y-4 md:space-y-6"
                  onSubmit={registerUser}
                >
                  <div>
                    <label htmlFor="name">Nume</label>
                    <div className="element-input__group">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Nume"
                        required=""
                        value={name}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="email">Adresa de Email</label>
                    <div className="element-input__group">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="nume@email.com"
                        required=""
                        value={email}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="password">Parola</label>
                    <div className="element-input__group">
                      <PasswordInput
                        type="password"
                        name="password"
                        id="password"
                        placeholder="••••••••"
                        required=""
                        value={password}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="password">Confirmare parola</label>
                    <div className="element-input__group">
                      <PasswordInput
                        type="password"
                        name="password2"
                        id="password2"
                        placeholder="••••••••"
                        required=""
                        value={password2}
                        onChange={handleInputChange}
                        onPaste={(e) => {
                          e.preventDefault();
                          toast.error("Parola nu se poate lipi");
                          return false;
                        }}
                      />
                    </div>
                  </div>

                  <div className="block-row">
                    <div className="info-block-component default-color">
                      <div className="info-block-component__icon">
                        <span className="icon-info_outline"></span>
                      </div>
                      <div
                        className="flex p-4 w-full flex-col"
                        style={{ gap: 8 }}
                      >
                        <div className="flex items-center">
                          {switchIcon(uCase)}
                          <span className="ml-2">Litere mici si majuscule</span>
                        </div>
                        <div className="flex items-center">
                          {switchIcon(num)}
                          <span className="ml-2">Numar (0-9)</span>
                        </div>
                        <div className="flex items-center">
                          {switchIcon(passLength)}
                          <span className="ml-2">Cel putin 6 caractere</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="block-row_custom-middle button-flex-holder">
                    <button disabled={isLoading} type="submit">
                      {isLoading ? (
                        <>
                          <span className="flex items-center justify-center">
                            <CgSpinner className="animate-spin h-5 w-5 mr-3" />
                            Inregistrare...
                          </span>
                        </>
                      ) : (
                        "Inregistrare"
                      )}
                    </button>
                  </div>

                  <div className="help-text text-center">
                    <span>
                      Ai deja cont?
                      <span onClick={handleAuthModal} className="ml-1 link">
                        Autentificare
                      </span>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalRegister;

import React, { useContext, useState, useEffect, useRef } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUser, logout, RESET } from "../../redux/features/auth/authSlice";
import { MdNotifications, MdNotificationsOff } from "react-icons/md";
import { RiChat4Fill, RiChatOffFill } from "react-icons/ri";
import { IoMdLogOut } from "react-icons/io";
import {
  FaAngleDown,
  FaAngleUp,
  FaStar,
  FaClock,
  FaAward,
  FaUsers,
  FaCheckDouble,
  FaStream,
  FaFutbol,
} from "react-icons/fa";
import UserAvatar from "../../utils/UserAvatar/UserAvatar";
import { useMedia } from "react-use";
import socket from "../../utils/SocketDotIo/Socket";
import { ThemeContext } from "styled-components";
import DarkModeSwitch from "../../utils/DarkModeSwitch/DarkModeSwitch";
import DuckLoader from "../../utils/Loaders/DuckLoader";

const Header = ({
  handleShowNotification,
  showNotification,
  handlePanel,
  panel,
  desktopRef,
  handleClick,
  dropdown,
  handleShowChat,
  onlineUsers,
  notificationsCount,
  unreadChatCount,
  showChat,
  handleAuthModal,
  handleRegisterModal,
  toggleTheme,
  setMobileMenu,
  mobileMenu,
}) => {
  const { colors, title } = useContext(ThemeContext);
  const { isLoading, isLoggedIn, user } = useSelector((state) => state.auth);
  const { isLoadingPredictionProfit10, predictionProfit10 } = useSelector(
    (state) => state.predictions
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDesktop = useMedia("(min-width: 975px)");

  // logout user
  const logoutUser = async () => {
    dispatch(RESET());
    await dispatch(logout());
    navigate("/");
  };

  //
  // update user every minute
  useEffect(() => {
    if (isLoggedIn) {
      const interval = setInterval(() => {
        dispatch(getUser(user?._id));
      }, 30000);

      return () => clearInterval(interval);
    }
  }, [dispatch, user]);

  //
  // notifications
  const [unreadNotifications, setUnreadNotifications] = useState(null);

  useEffect(() => {
    setUnreadNotifications(user?.unreadNotifications);
  }, [user]);

  const urllocation = window.location;
  const gmtPlus3Offset = 3 * 60 * 60 * 1000; // 3 hours in milliseconds
  const currentDateInGMTPlus3 = new Date(new Date().getTime() + gmtPlus3Offset);

  const [currentDate, setCurrentDate] = useState(currentDateInGMTPlus3);
  const formattedDate = currentDate.toISOString().split("T")[0];
  return (
    <div className="header-sticky">
      <div className="container">
        <div className="header-news">
          {!isDesktop ? (
            <div className="header-news__wrapper-small">
              <div className="header-news__stats">
                <div className="responsive-view">
                  <div className="responsive-view__block">
                    {isLoadingPredictionProfit10 ? (
                      "..."
                    ) : (
                      <div className="stats-block">
                        <div className="winner-block">
                          <span className="icon-ico_star">
                            <FaStar className="w-2 h-2" />
                          </span>
                          <span className="winner-message">
                            <span>Felicitari </span>
                            <Link
                              to={`/p/${predictionProfit10?.user?.handle}`}
                              className="flex winner-name"
                            >
                              {predictionProfit10?.user?.name}
                            </Link>
                          </span>
                          <span className="winner-odd">
                            Cota {predictionProfit10?.totalOdd}
                          </span>
                          <Link
                            to={`?modal=prediction&id=${predictionProfit10?._id}`}
                            id="winner-amount"
                            className="winner-amount"
                          >
                            +{predictionProfit10?.profit}
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                  <span className="responsive-view__arrow-left icon-keyboard_arrow_left"></span>
                  <span className="responsive-view__arrow-right icon-keyboard_arrow_right"></span>
                </div>
              </div>
            </div>
          ) : (
            <div className="header-news__wrapper-big">
              <div className="header-news__left-block">
                {isLoadingPredictionProfit10 ? (
                  "..."
                ) : (
                  <div className="winner-block">
                    <span className="icon-ico_star">
                      <FaStar />
                    </span>
                    <span className="winner-message">
                      <span>Felicitari </span>
                      <Link
                        to={`/p/${predictionProfit10?.user?.handle}`}
                        className="flex winner-name"
                      >
                        {predictionProfit10?.user?.name}
                      </Link>
                    </span>
                    <span className="winner-odd">
                      Cota {predictionProfit10?.totalOdd}
                    </span>
                    <Link
                      to={`?modal=prediction&id=${predictionProfit10?._id}`}
                      id="winner-amount"
                      className="winner-amount"
                    >
                      +{predictionProfit10?.profit}
                    </Link>
                  </div>
                )}
              </div>
              <div className="header-news__right-block">
                {/* <div className="header-news__stats">da</div> */}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="container">
        <header className="header">
          <div className="header-holder">
            <div className="header_left-block">
              <div className="header-logo-block relative">
                <div className="logo">
                  <Link to="/">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/socialape-bea5b.appspot.com/o/logo.png?alt=media"
                      alt="AnalizeSportive"
                      title="AnalizeSportive"
                    />
                    <div className="flex flex-col items-end">
                      <span>AnalizeSportive</span>
                      <span className="text-xs font-bold absolute bottom-2 beta-indicator">
                        SURSA TA DE INSPIRATIE
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
              <div
                id="navigation-mobile"
                className="navigation-mobile"
                style={{ display: mobileMenu === true ? "block" : "none" }}
              >
                <div className="navigation">
                  <div className="navigation__top">
                    <div className="logo">
                      <a href="/">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/socialape-bea5b.appspot.com/o/logo.png?alt=media"
                          alt="AnalizeSportive"
                          title="AnalizeSportive"
                        />
                      </a>
                    </div>
                    <span className="menu"></span>
                    <div id="navigation-modal-close" className="btn-close">
                      <span className="icon-close"></span>
                    </div>
                  </div>
                  <div className="navigation__bottom">
                    <nav className="navigation-menu navigation-menu_mobile">
                      <ul>
                        <li
                          className={`${
                            urllocation.pathname == "/biletul-zilei"
                              ? "active"
                              : ""
                          }`}
                        >
                          <Link
                            to={`/biletul-zilei/biletul-zilei-fotbal-${formattedDate}`}
                            className="navigation-link"
                            onClick={() => setMobileMenu(false)}
                          >
                            <span className="icon">
                              <FaStream className="text-2xl" />
                            </span>
                            Biletul zilei
                          </Link>
                        </li>
                        <li
                          className={`${
                            urllocation.pathname == "/cotadedoi" ? "active" : ""
                          }`}
                        >
                          <Link
                            to={`/cotadedoi/cota-de-doi-fotbal-${formattedDate}`}
                            className="navigation-link"
                            onClick={() => setMobileMenu(false)}
                          >
                            <span className="icon">
                              <FaCheckDouble className="text-2xl" />
                            </span>
                            Cota de doi
                          </Link>
                        </li>
                        <li
                          className={`${
                            urllocation.pathname == "/tipsteri" ? "active" : ""
                          }`}
                        >
                          <Link
                            to="/evenimente"
                            className="navigation-link"
                            onClick={() => setMobileMenu(false)}
                          >
                            <span className="icon">
                              <FaFutbol className="text-2xl" />
                            </span>
                            Evenimente
                          </Link>
                        </li>
                        <li
                          className={`${
                            urllocation.pathname == "/tipsteri" ? "active" : ""
                          }`}
                        >
                          <Link
                            to="/tipsteri"
                            className="navigation-link"
                            onClick={() => setMobileMenu(false)}
                          >
                            <span className="icon">
                              <FaUsers className="text-2xl" />
                            </span>
                            Tipsteri
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
              <nav className="navigation-menu navigation-menu_desktop">
                <ul>
                  <li
                    className={
                      urllocation.pathname.split("/")[1] === "biletul-zilei"
                        ? "navigation-button active"
                        : "navigation-button"
                    }
                  >
                    <NavLink
                      to={`/biletul-zilei/biletul-zilei-fotbal-${formattedDate}`}
                      className="navigation-link navigation-link_game"
                    >
                      <FaStream className="icon_button" />
                      Biletul zilei
                    </NavLink>
                  </li>
                  <li
                    className={
                      urllocation.pathname.split("/")[1] === "cotadedoi"
                        ? "navigation-button active"
                        : "navigation-button"
                    }
                  >
                    <NavLink
                      to={`/cotadedoi/cota-de-doi-fotbal-${formattedDate}`}
                      className="navigation-link navigation-link_game"
                    >
                      <FaCheckDouble className="icon_button" />
                      Cota de doi
                    </NavLink>
                  </li>
                  <li
                    className={
                      urllocation.pathname.split("/")[1] === "evenimente"
                        ? "navigation-button active"
                        : "navigation-button"
                    }
                  >
                    <NavLink
                      to={`/evenimente`}
                      className="navigation-link navigation-link_game"
                    >
                      <FaFutbol className="icon_button" />
                      Evenimente
                    </NavLink>
                  </li>
                  <li
                    className={
                      urllocation.pathname === "/tipsteri"
                        ? "navigation-button active"
                        : "navigation-button"
                    }
                  >
                    <NavLink
                      to="/tipsteri"
                      className="navigation-link navigation-link_game"
                    >
                      <FaUsers className="icon_button" />
                      Tipsteri
                    </NavLink>
                  </li>
                </ul>
              </nav>
            </div>
            <div id="react-toolbar-section">
              <div className="toolbar-section toolbar-section__sticky main-mode">
                <div className="toolbar-section__left"></div>
                <div className="toolbar-section__right"></div>
              </div>
            </div>
          </div>
          <div className="header_right-block">
            {/* <DarkModeSwitch toggleTheme={toggleTheme} title={title} /> */}
            {!isLoggedIn ? (
              <>
                <div id="react-header-guest" className="header-guest">
                  {!isLoading && (
                    <>
                      <button
                        onClick={handleRegisterModal}
                        className="button registration button_medium "
                      >
                        Inregistrare
                      </button>
                      <button
                        onClick={handleAuthModal}
                        className="button button_secondary button_medium "
                      >
                        Autentificare
                      </button>
                    </>
                  )}
                </div>
                <div id="react-header-chat-switcher" onClick={handleShowChat}>
                  <div
                    id="chat-switcher"
                    className={`header-switcher ${showChat ? "active" : ""}`}
                  >
                    {unreadChatCount === 0 ? null : (
                      <span className="animation-element chat-messages-counter">
                        {unreadChatCount}
                      </span>
                    )}
                    <span className="chat-icon-holder">
                      {showChat ? (
                        <span className="icon-chat_bubble">
                          <RiChatOffFill className="text-xl" />
                        </span>
                      ) : (
                        <span
                          className={`icon-chat_bubble ${
                            unreadChatCount === 0 ? "" : "animation-element"
                          } `}
                        >
                          <RiChat4Fill className="text-xl" />
                        </span>
                      )}
                      {notificationsCount === 0 ? null : (
                        <span class="btn__label bg-danger">
                          {notificationsCount}
                        </span>
                      )}
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div id="react-header-chat-switcher" onClick={handleShowChat}>
                  <div
                    id="chat-switcher"
                    className={`header-switcher ${showChat ? "active" : ""}`}
                  >
                    {unreadChatCount === 0 ? null : (
                      <span className="animation-element chat-messages-counter">
                        {unreadChatCount}
                      </span>
                    )}
                    <span className="chat-icon-holder">
                      {showChat ? (
                        <span className="icon-chat_bubble">
                          <RiChatOffFill className="text-xl" />
                        </span>
                      ) : (
                        <span
                          className={`icon-chat_bubble ${
                            unreadChatCount === 0 ? "" : "animation-element"
                          } `}
                        >
                          <RiChat4Fill className="text-xl" />
                        </span>
                      )}
                      {notificationsCount === 0 ? null : (
                        <span class="btn__label bg-danger">
                          {notificationsCount}
                        </span>
                      )}
                    </span>
                  </div>
                </div>
                <div
                  id="react-header-notifications"
                  onClick={handleShowNotification}
                >
                  <div
                    id="header-notifications"
                    className={`header-switcher ${
                      showNotification ? "active" : ""
                    }`}
                  >
                    <div className="header-notifications__wrapper ">
                      {showNotification ? (
                        <MdNotificationsOff className="text-xl" />
                      ) : (
                        <MdNotifications className="text-xl" />
                      )}
                      {unreadNotifications === 0 ? null : (
                        <span class="btn__label bg-danger">
                          {unreadNotifications}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div id="react-header-profile">
                  {user === null && isLoggedIn ? (
                    <div className="flex items-center justify-center animate-pulse">
                      <div className="h-9 bg-slate-200 rounded-full dark:bg-gray-700 w-9"></div>
                    </div>
                  ) : (
                    <div ref={desktopRef}>
                      <div id="profile" onClick={handleClick}>
                        <div className="profile-wrapper">
                          <div
                            className={`profile-name drop-select ${
                              dropdown ? "opened" : ""
                            }`}
                          >
                            <div className="profile-name__wrapper">
                              <div className="profile-block-avatar flex-column">
                                <UserAvatar
                                  name={user?.name}
                                  level={user?.level}
                                  levelXp={user?.levelXp}
                                  photo={user?.photo}
                                  onlineUsers={onlineUsers}
                                />
                              </div>
                              <div className="profile-name__holder">
                                <span className="name">{user?.name}</span>
                              </div>
                            </div>
                            {dropdown ? (
                              <FaAngleUp className="text-2xl" />
                            ) : (
                              <FaAngleDown className="text-2xl" />
                            )}
                            <span className="new-label"></span>
                          </div>
                          <div
                            className={`dropdown-default ${
                              dropdown ? "dropdown-opened" : ""
                            } `}
                          >
                            <Link
                              to={`/p/${user.handle}`}
                              className="dropdown-default__block"
                            >
                              <span className="ico-user-drop ico-user-drop_setting"></span>
                              Profilul meu
                            </Link>
                            <div
                              className="dropdown-default__block dropdown-default__block_logout"
                              onClick={logoutUser}
                            >
                              Delogare
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </header>
      </div>
    </div>
  );
};

export default Header;

import React from "react";
import { Avatar } from "flowbite-react";
import Moment from "react-moment";
import "moment/locale/ro";
import { Link } from "react-router-dom";
import UserAvatar from "../../utils/UserAvatar/UserAvatar";
import { MdVerified } from "react-icons/md";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const HeaderPrediction = ({ eveniment, onlineUsers }) => {
  return (
    <div className="flex justify-between items-center">
      <Link
        to={`/p/${eveniment?.user?.handle}`}
        className="flex user-prediction"
      >
        <div className="profile-block-avatar flex-column">
          <UserAvatar
            name={eveniment?.user?.name}
            level={eveniment?.user?.level}
            levelXp={eveniment?.user?.levelXp}
            photo={eveniment !== undefined ? eveniment?.user?.photo : null}
            onlineUsers={onlineUsers}
          />
        </div>
        <div className="flex flex-col justify-center" style={{ gap: 4 }}>
          <div className="flex gap-2">
            <span>{eveniment?.user?.name}</span>
            <span>
              {eveniment?.user?.role === "tipster" ||
              eveniment?.user?.role === "admin" ? (
                <MdVerified
                  data-tooltip-id="tooltip-verified-tipster"
                  data-tooltip-content="Tipster verificat"
                  className="text-green-400"
                />
              ) : null}
            </span>
            <Tooltip
              id="tooltip-verified-tipster"
              data-tooltip-delay-show={200}
              place="right"
              style={{
                fontSize: 12,
                paddingTop: 0,
                paddingBottom: 0,
                textTransform: "none",
                borderRadius: "12px",
                color: "#fff",
                lineHeight: "32px",
                backgroundColor: "#3a434b",
              }}
            />
          </div>
          <div className="flex items-center justify-center">
            <span
              style={{ fontWeight: "600" }}
              className={`text-sm uppercase ${
                eveniment?.user?.profit === 0
                  ? "gray-color"
                  : eveniment?.user?.profit > 0
                  ? "green-color"
                  : "red-color"
              } mr-1`}
            >
              {eveniment?.user?.profit > 0
                ? "+" + eveniment?.user?.profit?.toFixed(2)
                : eveniment?.user?.profit?.toFixed(2)}
            </span>
            <span className="text-sm text-gray-500 tipster-info__tips-count flex items-center">
              {eveniment?.user?.predictions > 1
                ? eveniment?.user?.predictions + " Predictii"
                : eveniment?.user?.predictions + " Predictie"}
            </span>
          </div>
        </div>
      </Link>
      <div className="flex items-center justify-center mr-1">
        <Moment locale="ro" className="text-sm text-gray-500" fromNow>
          {eveniment?.createdAt}
        </Moment>
      </div>
    </div>
  );
};

export default HeaderPrediction;

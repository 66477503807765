import React from "react";
import PredictionBox from "../../comp/Timeline/PredictionBox";

const UserPredictions = ({
  predictionsByUser,
  userProfile,
  user,
  isLoggedIn,
  handleModal,
  handleLikePrediction,
  isLoadingActions,
  onlineUsers,
  setMessages,
  messages,
  setShowChat,
  showChat,
  newMessage,
  setNewMessage,
  isSharedPrediction,
  setIsSharedPrediction,
}) => {
  return (
    <div className="token-info__item token-info__item-profile _animate _fade-in _active">
      <h2>Predictii</h2>
      <div className="flex-col">
        {predictionsByUser?.map((eveniment, index) => (
          <PredictionBox
            key={eveniment._id}
            eveniment={eveniment}
            user={user}
            isLoggedIn={isLoggedIn}
            handleModal={handleModal}
            handleLikePrediction={handleLikePrediction}
            isLoadingActions={isLoadingActions}
            predictions={predictionsByUser}
            onlineUsers={onlineUsers}
            setMessages={setMessages}
            messages={messages}
            showChat={showChat}
            setShowChat={setShowChat}
            newMessage={newMessage}
            setNewMessage={setNewMessage}
            isSharedPrediction={isSharedPrediction}
            setIsSharedPrediction={setIsSharedPrediction}
          />
        ))}
      </div>
    </div>
  );
};

export default UserPredictions;

import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Prediction = ({ eveniment, handleModal }) => {
  return (
    <div
      onClick={() => handleModal(eveniment)}
      className={`flex w-full rounded-lg my-4 cursor-pointer ${
        eveniment.status === 1
          ? "prediction-block-lost"
          : eveniment.status === 2
          ? "prediction-block-won"
          : eveniment.status === 3
          ? "prediction-block-void"
          : "prediction-block-ns"
      }`}
    >
      <div
        className={`flex w-full p-2 items-center justify-between ${
          eveniment.status === 1
            ? "prediction-details-lost"
            : eveniment.status === 2
            ? "prediction-details-won"
            : eveniment.status === 3
            ? "prediction-details-void"
            : "prediction-details-ns"
        } prediction-details`}
      >
        <div className="flex grow flex-1 w-full">
          <div className="flex items-center justify-center mr-2 w-14">
            <div
              className="dark:border-gray-900 mr-1 w-8 h-8 flex items-center justify-center relative rounded-full"
              style={{ background: "#24262b" }}
            >
              <LazyLoadImage
                alt={eveniment?.evenimente[0].eveniment.teams.home.name}
                height="16"
                src={eveniment?.evenimente[0].eveniment.teams.home.logo} // use normal <img> attributes as props
                width="16"
                className="h-5"
              />
            </div>
            <div
              style={{ marginLeft: "-10px", background: "#24262b" }}
              className="dark:border-gray-900 w-8 h-8 flex items-center justify-center relative rounded-full"
            >
              <LazyLoadImage
                alt={eveniment?.evenimente[0].eveniment.teams.away.name}
                height="16"
                src={eveniment?.evenimente[0].eveniment.teams.away.logo} // use normal <img> attributes as props
                width="16"
                className="h-5"
              />
            </div>
          </div>
          <div className="flex flex-col justify-center" style={{ gap: 4 }}>
            <div className="flex text-sm gray-color">
              <span>{eveniment.evenimente[0].eveniment.teams.home.name}</span>
              <span
                className={`mx-1 font-bold ${
                  eveniment?.evenimente[0]?.eveniment.fixture?.status?.long ===
                    "Match Finished" ||
                  eveniment?.evenimente[0]?.eveniment.fixture?.status?.long ===
                    "Match Suspended" ||
                  eveniment?.evenimente[0]?.eveniment.fixture?.status?.long ===
                    "Match Abandoned"
                    ? "text-black"
                    : "orange-color"
                }
                `}
              >
                {eveniment?.evenimente[0]?.eveniment.fixture?.status?.long ===
                "Match Abandoned"
                  ? ""
                  : eveniment?.evenimente[0].eveniment.goals.home}
              </span>
              <span>-</span>
              <span
                className={`mx-1 font-bold ${
                  eveniment?.evenimente[0]?.eveniment.fixture?.status?.long ===
                    "Match Finished" ||
                  eveniment?.evenimente[0]?.eveniment.fixture?.status?.long ===
                    "Match Suspended" ||
                  eveniment?.evenimente[0]?.eveniment.fixture?.status?.long ===
                    "Match Abandoned"
                    ? "text-black"
                    : "orange-color"
                }
                `}
              >
                {eveniment?.evenimente[0]?.eveniment.fixture?.status?.long ===
                "Match Abandoned"
                  ? ""
                  : eveniment?.evenimente[0]?.eveniment.goals.away}
              </span>
              <span>{eveniment.evenimente[0].eveniment.teams.away.name}</span>
            </div>
            <div className="flex text-white" style={{ fontSize: "13px" }}>
              {eveniment.evenimente[0].prediction.name +
                " - " +
                eveniment.evenimente[0].prediction.value}
            </div>
          </div>
        </div>
        <div
          className={`flex relative items-center shadow-sm flex-col justify-center h-11 m-0 px-0.5 rounded-lg text-center ${
            eveniment.status === 0
              ? "gray-bg-color"
              : eveniment.status === 2
              ? "green-bg-color"
              : eveniment.status === 1
              ? "red-bg-color"
              : eveniment.status === 3
              ? "bg-yellow-300"
              : null
          } text-white text-roboto`}
          style={{ fontSize: "13px", minWidth: "46px" }}
        >
          {eveniment.totalOdd.toFixed(2)}
          {eveniment.status === 0 ? null : eveniment.status === 2 ? (
            <span
              style={{
                fontSize: "8px",
                opacity: ".8",
                fontWeight: "400",
                textTransform: "uppercase",
                marginTop: 2,
              }}
            >
              CASTIGAT
            </span>
          ) : eveniment.status === 1 ? (
            <span
              style={{
                fontSize: "8px",
                opacity: ".8",
                fontWeight: "400",
                textTransform: "uppercase",
                marginTop: 2,
              }}
            >
              PIERDUT
            </span>
          ) : eveniment.status === 3 ? (
            <span
              style={{
                fontSize: "8px",
                opacity: ".8",
                fontWeight: "400",
                textTransform: "uppercase",
                marginTop: 2,
              }}
            >
              ANULAT
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Prediction;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import useRedirectLoggedOutUser from "../../utils/useRedirectLoggedOutUser";
import AllMatches from "./allMatches/AllMatches";
import AllCotadedoi from "./allMatches/AllCotadedoi";
import AllBiletulZilei from "./allMatches/AllBiletulZilei";

const Admin = () => {
  useRedirectLoggedOutUser("/login");

  const { user, isLoading, isLoggedIn, isSuccess, message } = useSelector(
    (state) => state.auth
  );
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  if (user?.role !== "admin") {
    return <div>No access</div>;
  } else {
    return (
      <div className="container max-w-7xl mx-auto my-3 px-4">
        <div className="w-full flex lg:flex-row flex-col  justify-center">
          <div className="flex flex-col items-center py-5 px-4  bg-white dark:bg-bgMain border border-gray-200 rounded-lg dark:border-gray-900">
            <ul className="space-y-2 bloc-tabs">
              <li
                onClick={() => toggleTab(1)}
                className={`${
                  toggleState === 1 ? "active-tabs" : ""
                } tabs flex items-center p-2 text-base font-normal rounded-lg hover:bg-gray-400 hover:text-white dark:hover:bg-gray-700 dark:text-white cursor-pointer`}
              >
                <svg
                  aria-hidden="true"
                  className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                  <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                </svg>
                <span className="ml-3">Evenimente</span>
              </li>
              <li
                onClick={() => toggleTab(2)}
                className={`${
                  toggleState === 2 ? "active-tabs" : ""
                } tabs flex items-center p-2 text-base font-normal rounded-lg hover:bg-gray-400 hover:text-white dark:hover:bg-gray-700 dark:text-white cursor-pointer`}
              >
                <svg
                  aria-hidden="true"
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>
                </svg>
                <span className="flex-1 ml-3 whitespace-nowrap">
                  Adauga eveniment
                </span>
              </li>
            </ul>
          </div>
          <div className="flex flex-col bg-white dark:bg-bgMain border border-gray-200 rounded-lg dark:border-gray-900 flex-1 grow lg:ml-4 lg:mt-0 mt-4  max-w-full">
            <div className="content-tabs">
              <div
                className={
                  toggleState === 1 ? "content active-content" : "content"
                }
              >
                <AllMatches />
              </div>
            </div>
            <div className="content-tabs">
              <div
                className={
                  toggleState === 2 ? "content active-content" : "content"
                }
              >
                <AllCotadedoi />
              </div>
            </div>
            <div className="content-tabs">
              <div
                className={
                  toggleState === 2 ? "content active-content" : "content"
                }
              >
                <AllBiletulZilei />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Admin;

import React from "react";

const NoResultsFilter = ({ setSearch, setPredictionStatus }) => {
  const handleResetAllFilters = () => {
    setSearch("");
    setPredictionStatus("all");
  };
  return (
    <div className="list-empty-message flex">
      <div className="list-empty-message__img-wrap">
        <img
          alt="list-empty-message"
          className="list-empty-message__img lazyLoad isLoaded"
          src="https://cdn.oddspedia.com/images/static/user-profile/empty-teams.svg"
        />
      </div>
      <div className="list-empty-message__text">
        <p className="text-sm text-gray-500 mb-2">
          Nu au fost gasite rezultate pentru filtrul tau.
        </p>
        <button
          onClick={handleResetAllFilters}
          type="button"
          className="btn btn--primary-dark"
        >
          Reseteaza filtrele
        </button>
      </div>
    </div>
  );
};

export default NoResultsFilter;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import biletulzileiService from "./biletulzileiService";
import { toast } from "react-toastify";

const initialState = {
  biletulzilei: null,
  allbiletulzilei: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  totalBiletulzilei: null,
  averageTotalOdd: null,
  last5StatusValues: [],
  averageYield: null,
  last5Biletulzilei: [],
  firstMatchStart: null,
};

// Add cota de doi

export const addBiletulzilei = createAsyncThunk(
  "biletulzilei/biletulzilei",
  async (biletulzileiData, thunkAPI) => {
    try {
      return await biletulzileiService.addBiletulzilei(biletulzileiData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get biletul zilei by date
export const getBiletulzileiBydate = createAsyncThunk(
  "biletulzilei/getBiletulzileiBydate",
  async (biletulzileiData, thunkAPI) => {
    try {
      return await biletulzileiService.getBiletulzileiBydate(biletulzileiData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get all biletul zilei
export const getAllBiletulzilei = createAsyncThunk(
  "biletulzilei/getAllBiletulzilei",
  async (params, thunkAPI) => {
    const { totalResults, biletulzileiStatus } = params;
    try {
      return await biletulzileiService.getAllBiletulzilei({
        totalResults,
        biletulzileiStatus,
      });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Approve eveniment Biletul zilei
export const approveBiletulzilei = createAsyncThunk(
  "biletulzilei/approveBiletulzilei",
  async (matchData, thunkAPI) => {
    try {
      return await biletulzileiService.approveBiletulzilei(matchData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Approve biletul zilei ticket
export const approveBiletulzileiTicket = createAsyncThunk(
  "biletulzilei/approveBiletulzileiiTicket",
  async (matchData, thunkAPI) => {
    try {
      return await biletulzileiService.approveBiletulzileiTicket(matchData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

const biletulzileiSlice = createSlice({
  name: "biletulzilei",
  initialState,
  reducers: {
    RESET(state) {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      // Add cota de doi
      .addCase(addBiletulzilei.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addBiletulzilei.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.biletulzilei = action.payload;
        state.message = "success";
        toast.success(action.payload);
      })
      .addCase(addBiletulzilei.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      // Get biletul zilei by date
      .addCase(getBiletulzileiBydate.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getBiletulzileiBydate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.biletulzilei = action.payload.biletulzilei;
        state.firstMatchStart = action.payload.firstMatchStart;
        state.message = "success";
      })
      .addCase(getBiletulzileiBydate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.biletulzilei = null;
      })
      // Get all biletul zilei
      .addCase(getAllBiletulzilei.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllBiletulzilei.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allbiletulzilei = action.payload.biletulzileisFinal;
        state.totalBiletulzilei = action.payload.noTotalBiletulzilei;
        state.averageTotalOdd = action.payload.averageTotalOdd;
        state.last5StatusValues = action.payload.last5StatusValues;
        state.averageYield = action.payload.averageYield;
        state.last5Biletulzilei = action.payload.last5Biletulzilei;
      })
      .addCase(getAllBiletulzilei.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      // Approve biletul zilei
      .addCase(approveBiletulzilei.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(approveBiletulzilei.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        toast.success(action.payload.message);
      })
      .addCase(approveBiletulzilei.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      // Approve biletul zilei ticket
      .addCase(approveBiletulzileiTicket.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(approveBiletulzileiTicket.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        toast.success(action.payload.message);
      })
      .addCase(approveBiletulzileiTicket.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      });
  },
});

export const { RESET } = biletulzileiSlice.actions;

export default biletulzileiSlice.reducer;

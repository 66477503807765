import React from "react";

const CircleLoad = () => {
  return (
    <div className="flex flex-col w-full items-center justify-center h-52">
      <div className="lds-circle">
        <div></div>
      </div>
    </div>
  );
};

export default CircleLoad;

import React, { useEffect, useState } from "react";
import {
  FaClock,
  FaEnvelope,
  FaFacebookF,
  FaFacebookSquare,
  FaInstagram,
  FaTelegramPlane,
  FaTwitter,
} from "react-icons/fa";

const Footer = () => {
  const [dateState, setDateState] = useState(new Date());
  useEffect(() => {
    setInterval(() => setDateState(new Date()), 30000);
  }, []);

  return (
    <footer className="footer">
      <div className="footer__wrap">
        <div className="footer__top">
          <div className="footer__top-categories">
            <div className="our-links-block">
              {/* <div className="our-links">
                <ul>
                  <li>
                    <a
                      href="/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link-name"
                    >
                      Link
                    </a>
                  </li>
                  <li>
                    <a
                      href="/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link-name"
                    >
                      Link
                    </a>
                  </li>
                  <li>
                    <a
                      href="/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link-name"
                    >
                      Link
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
            {/* <div className="review-rating-block">da</div> */}
          </div>
          <address className="address-block">
            <p>
              Acest website de analiză sportivă îți pune la dispoziție cele mai
              recente informații despre evenimentele sportive, statistici, și
              ponturi adăugate de tipsterii noștri cu experiență.
              <br />
              Vei găsi aici informații și pronosticuri detaliate care te vor
              ajuta să îți optimizezi strategia de pariere.
              <br />
              În plus, comunitatea noastră de pariori îți oferă posibilitatea de
              a împărtăși idei și sfaturi cu alți fani ai sportului și de a-ți
              îmbunătăți astfel cunoștințele și strategiile de pariere.
              <br />
              Alătură-te comunității noastre și bucură-te de succesul tău în
              <b> pariuri sportive</b>!
            </p>
          </address>
        </div>
        <div className="footer__center py-6">
          <div className="certificate-holder ">
            <div
              className="certificate-block certificate-block_payop"
              data-ael="certificate"
            >
              Accesul persoanelor sub 18 ani este strict interzis.
            </div>
          </div>
        </div>
      </div>
      <div className="footer__bottom">
        <div className="footer__bottom-holder">
          <span className="footer-time" id="react-footer-time">
            <div className="flex items-center justify-end text-sm">
              <span className="flex">Europe (UTC +02:00)</span>
              <span className="mx-2 opacity-50">|</span>
              <span className="flex">
                {dateState.toLocaleDateString("en-GB", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })}
              </span>
              <span className="mx-2 opacity-50">|</span>
              <span className="flex items-center justify-center">
                <FaClock className="text-gray-400 mr-1" />
                {dateState.toLocaleString("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                })}
              </span>
            </div>
          </span>
        </div>
        <div className="footer__social">
          <ul className="social-links">
            <li className="facebook">
              <a
                className="social-links__item"
                data-for="main"
                data-tip="Facebook"
                href="https://www.facebook.com/ROanalizesportive"
                target="_blank"
                rel="noopener noreferrer"
                title="Facebook"
                currentitem="false"
              >
                <FaFacebookF />
              </a>
            </li>
            <li className="telegram">
              <a
                className="social-links__item"
                data-for="main"
                data-tip="Telegram"
                href="https://t.me/ROanalizesportive"
                target="_blank"
                rel="noopener noreferrer"
                title="Telegram"
                currentitem="false"
              >
                <FaTelegramPlane />
              </a>
            </li>
            <li className="twitter">
              <a
                className="social-links__item"
                data-for="main"
                data-tip="Twitter page"
                href="/"
                target="_blank"
                rel="noopener noreferrer"
                title="Twitter"
                currentitem="false"
              >
                <FaTwitter />
              </a>
            </li>
            <li className="Instagram">
              <a
                className="social-links__item"
                data-for="main"
                data-tip="Instagram page"
                href="/"
                target="_blank"
                rel="noopener noreferrer"
                title="Instagram"
                currentitem="false"
              >
                <FaInstagram />
              </a>
            </li>
            <li className="email">
              <a
                className="social-links__item"
                data-for="main"
                data-tip="Email"
                href="mailto:office@analizesportive.ro"
                target="_blank"
                rel="noopener noreferrer"
                title="Email"
                currentitem="false"
              >
                <FaEnvelope />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

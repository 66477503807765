import React, { useState, useEffect, useRef } from "react";
import "../../assets/css/ansp.css";
import { useDispatch, useSelector } from "react-redux";
import CotaDeDoi from "../../page/CotaDeDoi/CotaDeDoi";
import BiletulZilei from "../../page/BiletulZilei/BiletulZilei";
import CaseDePariuri from "../CaseDePariuri/CaseDePariuri";
import Header from "../../comp/Header/Header";
import Modal from "../../modals/Modal";
import Notifications from "../../comp/Notifications/Notifications";
import {
  getPredictionById,
  RESETPREDICTION,
  RESETPREDICTIONSBYUSER,
} from "../../redux/features/predictions/predictionsSlice";
import Timeline from "../../comp/Timeline/Timeline";
import Tipsteri from "../Tipsteri/Tipsteri";
import CreatePrediction from "../../comp/CreatePrediction/CreatePrediction";
import TicketInBottom from "../../comp/CreatePrediction/TicketInBottom";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useMedia } from "react-use";
import Chat from "../../comp/Chat/Chat";
import socket from "../../utils/SocketDotIo/Socket";
import { toast } from "react-toastify";
import ModalLogin from "../../modals/ModalLogin";
import ModalForgot from "../../modals/ModalForgot";
import ModalLoginWithCode from "../../modals/ModalLoginWithCode";
import ModalRegister from "../../modals/ModalRegister";
import ModalVerify from "../../modals/ModalVerify";
import VerifyAccount from "../../comp/VerifyAccount/VerifyAccount";
import ModalReset from "../../modals/ModalReset";
import Footer from "../../comp/Footer/Footer";
import BottomToolbar from "../../comp/BottomToolbar/BottomToolbar";
import usePredictionDispatch from "../../utils/LikeAndDislike/LikeAndDislike";
import { Helmet } from "react-helmet-async";

const Dashboard = ({
  onlineUsers,
  setMessages,
  messages,
  showChat,
  setShowChat,
  setNewMessage,
  newMessage,
  setToggleAccumulator,
  toggleAccumulator,
  handleShowMatches,
  isSharedPrediction,
  setIsSharedPrediction,
  getUser,
  handleShowNotification,
  handleShowChat,
  showNotification,
  handleClick,
  dropdown,
  setDropdown,
  showMatches,
  setShowMatches,
  setNotificationsCount,
  unreadChatCount,
  setUnreadChatCount,
  notificationsCount,
  desktopRef,
  mobileRef,
  toggleTheme,
  toggleMobileMenu,
  setMobileMenu,
  mobileMenu,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isDesktop = useMedia("(min-width: 1800px)");

  useEffect(() => {
    dispatch(RESETPREDICTIONSBYUSER());
  }, []);

  const { isLoading, isLoggedIn, isSuccess, userProfile, user, users } =
    useSelector((state) => state.auth);

  const {
    prediction,
    predictions,
    isLoadingActions,
    isLoadingPrediction: loadingPrediction,
  } = useSelector((state) => state.predictions);

  const { matches } = useSelector((state) => state.apiMatches);

  const [startDate, setStartDate] = useState(new Date());

  // like dislike prediction

  const { handleLikePrediction, handleDislikePrediction } =
    usePredictionDispatch();

  // like dislike prediction

  // SIDEBAR
  const [sidebarWidth, setSidebarWidth] = useState(undefined);
  const [sidebarTop, setSidebarTop] = useState(undefined);

  useEffect(() => {
    const sidebarEl = document
      .querySelector(".sidebar")
      .getBoundingClientRect();
    setSidebarWidth(sidebarEl.width);
    setSidebarTop(sidebarEl.top);
  }, []);

  useEffect(() => {
    if (!sidebarTop) return;

    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, [sidebarTop]);

  const isSticky = (e) => {
    const sidebarEl = document.querySelector(".sidebar");
    const scrollTop = window.scrollY;
    if (scrollTop >= sidebarTop - 800) {
      sidebarEl.classList.add("is-sticky");
    } else {
      sidebarEl.classList.remove("is-sticky");
    }
  };
  // SIDEBAR
  // modal
  const [showModal, setShowModal] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showForgotModal, setShowForgotModal] = useState(false);
  const [showLoginWithCodeModal, setShowLoginWithCodeModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);

  const handleClickOutside = (e) => {
    if (e.target.id === "modal-target") {
      setShowModal(false);
      setShowAuthModal(false);
      setShowForgotModal(false);
      setShowLoginWithCodeModal(false);
      setShowRegisterModal(false);
      setShowVerifyModal(false);
      setShowResetModal(false);
      dispatch(RESETPREDICTION());

      // Delete modal id parameter from URL
      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.delete("modal");
      newSearchParams.delete("id");
      newSearchParams.delete("tk");
      navigate(`${location.pathname}?${newSearchParams.toString()}`);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const [isLoadingPrediction, setIsLoadingThePrediction] = useState(false);

  const handleModal = (eveniment) => {
    if (!showModal) {
      if (!isLoadingPrediction && location.search === "") {
        // Display a loading indicator for 5 seconds
        setIsLoadingThePrediction(true);
        const predictionData = {
          _id: eveniment._id,
        };
        dispatch(getPredictionById(predictionData));
        setTimeout(() => {
          if (!loadingPrediction) {
            setIsLoadingThePrediction(false); // Remove loading indicator after dispatch
          }
        }, 2000); // 5000 milliseconds (5 seconds)
      }
      console.log(loadingPrediction);
      // Add modal id parameter to URL
      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.set("modal", "prediction");
      newSearchParams.set("id", eveniment._id);
      navigate(`${location.pathname}?${newSearchParams.toString()}`);
      setShowModal(true);
    } else {
      if (prediction._id === eveniment._id) {
        // If the modal is already open for the clicked event, do nothing
        return;
      }
      dispatch(RESETPREDICTION());
      setShowModal(false);

      // Delete modal id parameter from URL
      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.delete("modal");
      newSearchParams.delete("id");
      navigate(`${location.pathname}?${newSearchParams.toString()}`);
    }
  };

  useEffect(() => {
    const modalPredictionId = new URLSearchParams(location.search).get("id");

    if (modalPredictionId) {
      setIsLoadingThePrediction(true);
      const predictionData = {
        _id: modalPredictionId,
      };

      setShowModal(true);
      dispatch(getPredictionById(predictionData));
      setTimeout(() => {
        setIsLoadingThePrediction(false); // Remove loading indicator after dispatch
      }, 2000); // 5000 milliseconds (5 seconds)
    }
  }, [location.search, predictions, showModal]);

  useEffect(() => {
    const modalPredictionId = new URLSearchParams(location.search).get("modal");

    if (modalPredictionId === "autentificare") {
      setShowAuthModal(true);
      setShowForgotModal(false);
      setShowRegisterModal(false);
    } else if (modalPredictionId === "recuperare-parola") {
      setShowForgotModal(true);
      setShowAuthModal(false);
      setShowRegisterModal(false);
    } else if (modalPredictionId === "autentificare-cod") {
      setShowLoginWithCodeModal(true);
      setShowAuthModal(false);
      setShowForgotModal(false);
      setShowRegisterModal(false);
    } else if (modalPredictionId === "inregistrare") {
      setShowRegisterModal(true);
      setShowAuthModal(false);
      setShowForgotModal(false);
    } else if (modalPredictionId === "verificare-cont") {
      setShowVerifyModal(true);
    } else if (modalPredictionId === "resetare-parola") {
      setShowResetModal(true);
    }
  }, [
    location.search,
    showAuthModal,
    showForgotModal,
    showLoginWithCodeModal,
    showRegisterModal,
    showVerifyModal,
    showResetModal,
  ]);

  // Update search params and navigate to the updated URL
  useEffect(() => {
    if (prediction) {
      if (showModal) {
        searchParams.set("modal", "prediction");
        searchParams.set("id", prediction._id);
      } else {
        searchParams.delete("modal");
        searchParams.delete("id");
      }
      navigate(`${location.pathname}?${searchParams.toString()}`);
    }
  }, [showModal, isLoadingPrediction, prediction]);

  useEffect(() => {
    if (showModal && prediction) {
      const intervalId = setInterval(() => {
        const predictionData = {
          _id: prediction._id,
        };
        dispatch(getPredictionById(predictionData));
      }, 40000);
      return () => clearInterval(intervalId);
    }
  }, [showModal, prediction]);

  // auth modal
  const handleAuthenticationModal = (modalId, show, additionalParams = {}) => {
    if (show) {
      // Add modal id parameter to URL
      const newSearchParams = searchParams;
      newSearchParams.set("modal", modalId);
      for (const key in additionalParams) {
        newSearchParams.set(key, additionalParams[key]);
      }
      navigate(`${location.pathname}?${newSearchParams.toString()}`);
    } else {
      // Delete modal id parameter from URL
      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.delete("modal");
      for (const key in additionalParams) {
        newSearchParams.delete(key);
      }
      navigate(`${location.pathname}?${newSearchParams.toString()}`);
    }
  };

  // Example usage
  const handleAuthModal = () => {
    if (!showAuthModal) {
      handleAuthenticationModal("autentificare", true);
      setShowAuthModal(true);
    } else {
      setShowAuthModal(false);
      handleAuthenticationModal("autentificare", false);
    }
  };

  const handleForgotModal = () => {
    handleAuthModal();
    if (!showForgotModal) {
      handleAuthenticationModal("recuperare-parola", true);
      setShowForgotModal(true);
    } else {
      setShowForgotModal(false);
      handleAuthenticationModal("recuperare-parola", false);
    }
  };

  const handleLoginWithCodeModal = (email) => {
    if (!showLoginWithCodeModal) {
      handleAuthenticationModal("autentificare-cod", true, { email });
      setShowLoginWithCodeModal(true);
    } else {
      setShowLoginWithCodeModal(false);
      handleAuthenticationModal("autentificare-cod", false, { email });
    }
  };

  const handleRegisterModal = () => {
    if (!showRegisterModal) {
      handleAuthenticationModal("inregistrare", true);
      setShowRegisterModal(true);
    } else {
      setShowRegisterModal(false);
      handleAuthenticationModal("inregistrare", false);
    }
  };

  const handleVerifyModal = (tk) => {
    if (!showVerifyModal) {
      handleAuthenticationModal("verificare-cont", true, { tk });
      setShowVerifyModal(true);
    } else {
      setShowVerifyModal(false);
      handleAuthenticationModal("verificare-cont", false, { tk });
    }
  };

  const handleResetModal = (tk) => {
    if (!showResetModal) {
      handleAuthenticationModal("resetare-parola", true, { tk });
      setShowResetModal(true);
    } else {
      setShowResetModal(false);
      handleAuthenticationModal("resetare-parola", false, { tk });
    }
  };

  //

  useEffect(() => {
    const body = document.querySelector("body");

    if (
      showModal ||
      showAuthModal ||
      showForgotModal ||
      showLoginWithCodeModal ||
      showRegisterModal ||
      showVerifyModal ||
      showResetModal
    ) {
      body.classList.add("body-scroll-hidden");
      return () => {
        body.classList.remove("body-scroll-hidden");
      };
    } else {
    }
  }, [
    showModal,
    showAuthModal,
    showForgotModal,
    showLoginWithCodeModal,
    showRegisterModal,
    showVerifyModal,
    showResetModal,
  ]);
  // modal

  const [display, setDisplay] = useState(false);
  const [eveniment, setEveniment] = useState([]);

  // get currentselected matches de pe ticket  din localstorage //

  const [evenimentePeBilet, setEvenimentePeBilet] = useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("evenimentePeBilet"));
    if (items) {
      setEvenimentePeBilet(items);
    }
  }, []);
  const [isOpen, setIsOpen] = useState(false);

  // end
  // socket io chat notif

  useEffect(() => {
    if (!showChat) {
      socket.on("notification", ({ type, username, taggedUsername, text }) => {
        console.log(type, username, taggedUsername, text);
        setNotificationsCount((count) => count + 1);
        toast.info(`Ai fost mentionat in chat de catre ${username}!`);
      });
    }

    return () => {
      socket.off("notification");
    };
  }, [socket, showChat]);

  useEffect(() => {
    if (!showChat) {
      socket.on("message received", (newMessageReceived) => {
        if (unreadChatCount > 98 || unreadChatCount === "99+") {
          setUnreadChatCount("99+");
        } else {
          setUnreadChatCount((count) => count + 1);
        }
      });
    }

    return () => {
      socket.off("message received");
    };
  }, [socket, showChat, unreadChatCount]);

  return (
    <>
      <Helmet>
        <title>
          AnalizeSportive - Predictii de Fotbal | Ponturi Online | Bilete Fotbal
        </title>
        <link rel="canonical" href=" https://www.analizesportive.ro" />
        <meta
          name="description"
          content="Descoperiti cele mai bune sfaturi gratuite de pariuri si predictii de fotbal. Analize complete pentru meciurile din Liga Campionilor, Europa League, si campionatele nationale. Obtineti ponturi pentru bilete fotbal cu sanse mari de succes"
        />
        <meta name="og:image" content="https://i.ibb.co/FnPJ7dF/cover.jpg" />
      </Helmet>
      <div className="page">
        <div className="page__main ">
          <div id="react-modal-screen">
            <div id="modals">
              {showModal && (
                <Modal
                  handleModal={handleModal}
                  showModal={showModal}
                  setShowModal={setShowModal}
                  prediction={prediction}
                  user={user}
                  isLoggedIn={isLoggedIn}
                  isLoading={isLoading}
                  isLoadingActions={isLoadingActions}
                  handleDislikePrediction={handleDislikePrediction}
                  handleLikePrediction={handleLikePrediction}
                  isLoadingPrediction={isLoadingPrediction}
                />
              )}
              {showAuthModal && (
                <ModalLogin
                  showAuthModal={showAuthModal}
                  setShowAuthModal={setShowAuthModal}
                  handleAuthModal={handleAuthModal}
                  handleForgotModal={handleForgotModal}
                  handleRegisterModal={handleRegisterModal}
                />
              )}
              {showForgotModal && (
                <ModalForgot
                  showForgotModal={showForgotModal}
                  setShowForgotModal={setShowForgotModal}
                  handleAuthModal={handleAuthModal}
                  handleForgotModal={handleForgotModal}
                  handleRegisterModal={handleRegisterModal}
                />
              )}
              {showLoginWithCodeModal && (
                <ModalLoginWithCode
                  handleLoginWithCodeModal={handleLoginWithCodeModal}
                  setShowLoginWithCodeModal={setShowLoginWithCodeModal}
                  showLoginWithCodeModal={showLoginWithCodeModal}
                />
              )}
              {showRegisterModal && (
                <ModalRegister
                  handleRegisterModal={handleRegisterModal}
                  showRegisterModal={showRegisterModal}
                  setShowRegisterModal={setShowRegisterModal}
                  setShowAuthModal={setShowAuthModal}
                  handleAuthModal={handleAuthModal}
                />
              )}
              {showVerifyModal && (
                <ModalVerify
                  setShowVerifyModal={setShowVerifyModal}
                  handleVerifyModal={handleVerifyModal}
                />
              )}
              {showResetModal && (
                <ModalReset handleResetModal={handleResetModal} />
              )}
            </div>
          </div>
          <VerifyAccount
            user={user}
            isLoading={isLoading}
            isLoggedIn={isLoggedIn}
          />
          <Header
            handleShowNotification={handleShowNotification}
            handleShowChat={handleShowChat}
            showChat={showChat}
            showNotification={showNotification}
            handleClick={handleClick}
            dropdown={dropdown}
            desktopRef={desktopRef}
            onlineUsers={onlineUsers}
            notificationsCount={notificationsCount}
            unreadChatCount={unreadChatCount}
            handleAuthModal={handleAuthModal}
            handleRegisterModal={handleRegisterModal}
            toggleTheme={toggleTheme}
            setMobileMenu={setMobileMenu}
            mobileMenu={mobileMenu}
          />
          <div className="main container">
            <div className="content">
              <div className="play-block flex-col relative">
                {/* <div className="flex mb-4">
                <div className="">
                  <img
                    src="https://yt3.googleusercontent.com/DWKbQP6JhmMThZRzoEVaBPuvtC4JFuWSsS26KzNlPRoJ5YNPzepc-gcilw91tTeE5jYny9US=w1060-fcrop64=1,00005a57ffffa5a8-k-c0xffffffff-no-nd-rj"
                    alt="Advertisement"
                  />
                </div>
              </div>
              <div
                className="flex mb-4 fixed side-banner"
                style={{ marginLeft: "-180px" }}
              >
                <div className="">
                  <img
                    src="https://betromania.com/_nuxt/img/fortuna-banner.5321eac.jpg"
                    alt="Advertisement"
                  />
                </div>
              </div>
              <div
                className="flex mb-4 fixed side-banner"
                style={{ marginLeft: "920px" }}
              >
                <div className="">
                  <img
                    src="https://betromania.com/_nuxt/img/fortuna-banner.5321eac.jpg"
                    alt="Advertisement"
                  />
                </div>
              </div> */}
                <div className="play-block-content play-block-content_decoration w-full about-token">
                  <div
                    className={`token-info__item _animate _fade-in _active `}
                  >
                    <CotaDeDoi />
                    <BiletulZilei handleModal={handleModal} />
                    {/* <CaseDePariuri /> */}
                    <div className=" sidebar">
                      <Tipsteri display={display} />
                    </div>
                  </div>
                  <div className="token-info__item _animate _fade-in _active">
                    <Timeline
                      handleLikePrediction={handleLikePrediction}
                      showModal={showModal}
                      handleModal={handleModal}
                      setEveniment={setEveniment}
                      setDisplay={setDisplay}
                      showMatches={showMatches}
                      handleShowMatches={handleShowMatches}
                      onlineUsers={onlineUsers}
                      setMessages={setMessages}
                      messages={messages}
                      showChat={showChat}
                      setShowChat={setShowChat}
                      setNewMessage={setNewMessage}
                      newMessage={newMessage}
                      isSharedPrediction={isSharedPrediction}
                      setIsSharedPrediction={setIsSharedPrediction}
                    />
                  </div>
                </div>
                <div
                  className="flex flex-col bets-info__text"
                  style={{ lineHeight: "initial", padding: 15 }}
                >
                  <h1>
                    <strong>Sfaturi Gratuite de Pariuri</strong> - Predictii de
                    la Experti si Tipsteri Profesionisti
                  </h1>
                  <p>
                    Pariurile sportive pe fotbal au fost intotdeauna{" "}
                    <strong>foarte populare</strong> si vor ramane in continuare
                    in topul preferintelor cititorilor nostri. Prin urmare,
                    alocam cea mai mare parte a resurselor noastre pentru a
                    acoperi acest sport. Ne concentram pe publicarea articolelor
                    promotionale, stiri si previziuni pentru meciurile de
                    fotbal, furnizand <strong>informatii valoroase</strong> care
                    sa va ajute sa plasati pariuri cu{" "}
                    <strong>sanse mai mari de succes</strong>.
                  </p>
                  <p>
                    Aici, veti gasi{" "}
                    <strong>
                      analizele noastre pentru pariurile fotbalistice
                    </strong>
                    , care includ <strong>statistici</strong>,{" "}
                    <strong>declaratii din presa</strong>,{" "}
                    <strong>informatii despre jucatorii cu probleme</strong> si{" "}
                    <strong>posibilele formatii de start</strong>. Nu vom omite
                    nicio competitie importanta, indiferent daca este vorba
                    despre Liga Campionilor, Europa League, Conference League,
                    Campionatul European, Cupa Mondiala sau campionatele
                    nationale, precum Liga 1, Liga 2, Premier League, La Liga,
                    Serie A, Bundesliga sau Ligue 1. Fiecare meci de top este
                    supus unei <strong>analize detaliate</strong> in aceasta
                    pagina.
                  </p>
                  <p>
                    in plus fata de recomandari pentru meciuri din campionatele
                    de fotbal din Romania, cele mai prestigioase ligi europene
                    si competitiile internationale de cluburi si echipe
                    nationale, va punem la dispozitie si sugestii pentru meciuri
                    din Olanda, Belgia, Scotia si alte ligi mai putin urmarite.
                  </p>
                  <h1>
                    <strong>Sunt ponturi sigure?</strong>
                  </h1>
                  <p>
                    Pe piata pariurilor sportive pre-meci,{" "}
                    <strong>
                      nimic nu poate fi considerat cu adevarat sigur
                    </strong>{" "}
                    (cu exceptia partidelor aranjate, care sunt sanctionate de
                    instante). Pentru fiecare meci, va prezentam cel putin un{" "}
                    <strong>pont</strong> sau chiar mai multe, cu sanse mari de
                    reusita. Fiecare pont propus de tipsteri este rezultatul
                    unei <strong>analize detaliate</strong> a meciurilor de
                    fotbal si a <strong>statisticilor asociate</strong>. Cu
                    toate acestea, trebuie sa intelegem ca, din pacate,{" "}
                    <strong>
                      nu toate ponturile propuse vor fi castigatoare
                    </strong>
                    . Cu toate acestea, va garantam ca depunem toate eforturile
                    pentru a va furniza cat mai multe{" "}
                    <strong>ponturi fotbal reusite</strong>. Scopul nostru este
                    sa va aducem <strong>profit</strong> la finalul fiecarei
                    luni, atunci cand tragem linie.
                  </p>
                  <h1>
                    <strong>Meciul zilei la pariuri astazi</strong>
                  </h1>
                  <p>
                    Echipa noastra selecteaza o lista de{" "}
                    <strong>meciuri pentru ziua</strong> respectiva, care are
                    potentialul de a aduce <strong>profit</strong>, si le
                    analizeaza in detaliu. Tipsterii nostri se mandresc cu{" "}
                    <strong>rezultate excelente</strong> in aceasta categorie,
                    chiar daca in general cotele nu sunt foarte mari. Aceste
                    selectii pot fi usor jucate ca pariuri simple pe bilete sau,
                    daca avem si alte meciuri bune, le putem combina pentru a ne{" "}
                    <strong>dubla investitia</strong>.
                  </p>
                  <h1>
                    <strong>Ce meciuri se joaca azi</strong>
                  </h1>
                  <p>
                    Fiecare meci programat in ziua respectiva este supus
                    analizei; ne concentram in principal pe evenimentele de top
                    din competitiile importante. Cu siguranta veti gasi{" "}
                    <strong>meciuri de fotbal astazi</strong> care merita sa fie
                    luate in considerare pentru biletele dvs. Fiecare pronostic
                    este rezultatul unei <strong>analize amanuntite</strong>, si
                    obiectivul nostru este sa obtinem rezultate cat mai bune
                    posibile. in weekend, avem meciuri din campionatele
                    nationale, in timp ce in mijlocul saptamanii, de obicei,
                    avem meciuri din competitiile europene precum Liga
                    Campionilor, Europa League sau Conference League. Este
                    important sa acordati atentie atenta parierilor si sa fiti
                    precauti, deoarece, in ciuda statutului sau de cea mai
                    puternica competitie, Liga Campionilor a avut multe surprize
                    in ultimele sezoane.
                  </p>
                </div>
              </div>
              <Footer />
            </div>
            <div
              className={`page__panel ${
                showNotification || showMatches || showChat
                  ? ""
                  : "page__panel_hidden"
              }`}
            >
              <div id="react-toolbar-right">
                <div
                  className={`side-panel ${
                    showNotification || showMatches || showChat
                      ? ""
                      : "side-panel_hidden"
                  }`}
                >
                  {showChat && (
                    <div className="chat" id="chat">
                      <Chat
                        user={user}
                        isLoggedIn={isLoggedIn}
                        onlineUsers={onlineUsers}
                        users={users}
                        setMessages={setMessages}
                        messages={messages}
                        newMessage={newMessage}
                        setNewMessage={setNewMessage}
                        predictions={predictions}
                        isSharedPrediction={isSharedPrediction}
                        setIsSharedPrediction={setIsSharedPrediction}
                        showChat={showChat}
                        getUser={getUser}
                        handleAuthModal={handleAuthModal}
                      />
                    </div>
                  )}

                  {showNotification && (
                    <div className="notification">
                      <Notifications user={user} handleModal={handleModal} />
                    </div>
                  )}
                  {showMatches && (
                    <div className="notification notification_game">
                      <CreatePrediction
                        evenimentePeBilet={evenimentePeBilet}
                        setEvenimentePeBilet={setEvenimentePeBilet}
                        toggleAccumulator={toggleAccumulator}
                        setToggleAccumulator={setToggleAccumulator}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        showMatches={showMatches}
                        handleShowMatches={handleShowMatches}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        setShowMatches={setShowMatches}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <BottomToolbar
            handleRegisterModal={handleRegisterModal}
            handleShowChat={handleShowChat}
            notificationsCount={notificationsCount}
            mobileRef={mobileRef}
            showChat={showChat}
            setDropdown={setDropdown}
            dropdown={dropdown}
            isLoggedIn={isLoggedIn}
            toggleMobileMenu={toggleMobileMenu}
            mobileMenu={mobileMenu}
            setMobileMenu={setMobileMenu}
          />
          {!isLoggedIn ? null : (
            <TicketInBottom
              evenimentePeBilet={evenimentePeBilet}
              handleShowMatches={handleShowMatches}
              showMatches={showMatches}
              showNotification={showNotification}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;

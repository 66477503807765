import React from "react";
import { shortenText } from "../../utils/ShortText/shortenText";
import {
  FaAngleDown,
  FaAngleUp,
  FaEquals,
  FaInfoCircle,
  FaPlus,
  FaTimes,
} from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { FaCheckSquare, FaSquare } from "react-icons/fa";

const TipAcumulator = ({
  evenimentePeBilet,
  toggleAccumulator,
  setToggleAccumulator,
  handleDeleteTicket,
  disabledEvents,
  stake,
  description,
  setDescription,
  handleStakeChange,
  handlePublicBilet,
  handleGoBack,
  totalReturn,
  totalOdd,
  handleDeleteEvenimentDePeBilet,
  setCotaDeDoiDate,
  setIsCotaDeDoi,
  isCotaDeDoi,
  setBiletulZileiDate,
  setIsBiletulZilei,
  isBiletulZilei,
  startDate,
  user,
}) => {
  const tipText =
    "Miza maxima in functie de total cota:\n\n1.01 - 2.49 miza maxima 10\n2.50 - 2.74 miza maxima 9\n2.75 - 2.99 miza maxima 8\n3.00 - 3.99 miza maxima 7\n4.00 - 4.99 miza maxima 6\n5.00 - 5.99 miza maxima 5\n6.00 - 7.99 miza maxima 4\n8.00 - 9.99 miza maxima 3\n10.00 - 19.99 miza maxima 2\n20+ miza maxima 1";

  const handleCotaDeDoi = () => {
    setIsCotaDeDoi(!isCotaDeDoi);
    setCotaDeDoiDate(startDate);
    setIsBiletulZilei(false);
  };

  const handleBiletulZilei = () => {
    setIsBiletulZilei(!isBiletulZilei);
    setBiletulZileiDate(startDate);
    setIsCotaDeDoi(false);
  };
  return (
    <div
      className={`create-tip-accumulator ${toggleAccumulator ? "active" : ""}`}
      style={{
        display: evenimentePeBilet.length === 0 ? "none" : "flex",
      }}
    >
      <div
        onClick={() => setToggleAccumulator(!toggleAccumulator)}
        className={`create-tip-accumulator__header ${
          toggleAccumulator
            ? "create-tip-accumulator__header--light"
            : "create-tip-accumulator__header--dark"
        }`}
      >
        <div className="tip-header">
          <div className="create-tip-accumulator__header__count">
            <img
              width="18"
              height="24"
              className="create-tip-accumulator__header__count__img lazyLoad isLoaded"
              src="https://cdn.oddspedia.com/images/static/icons/bet-summary-white.svg"
            />
            <div className="create-tip-accumulator__header__count__val">
              {evenimentePeBilet.length}
            </div>
          </div>
          <div
            className="create-tip-accumulator__header__text uppercase font-semibold flex flex-col"
            style={{ fontSize: "12px", lineHeight: "1.38" }}
          >
            <span>
              {evenimentePeBilet.length === 1
                ? "eveniment adaugat"
                : "evenimente adaugate"}
            </span>
            <span className="uppercase" style={{ fontSize: "10px" }}>
              TIP: {evenimentePeBilet.length === 1 ? "SIMPLU" : "MULTIPLU"}
            </span>
          </div>
        </div>
        <div className="create-tip-accumulator__header__actions">
          <div className="button_square button_square-xs">
            <span className="icon-keyboard_arrow_left">
              {toggleAccumulator ? (
                <FaAngleDown className="text-xl icon-down" />
              ) : (
                <FaAngleUp className="text-xl icon-up" />
              )}
            </span>
          </div>
          <div
            className="button_square button_square-xs"
            onClick={handleDeleteTicket}
          >
            <span className="icon-keyboard_arrow_left">
              <FaTimes className="text-sm icon-close" />
            </span>
          </div>
        </div>
      </div>
      <div className="create-tip-accumulator__body o-scrollbar scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-gray-100">
        {evenimentePeBilet?.map((e, index) => (
          <div
            key={e.eveniment.fixture.id}
            className={`tip-event flex ${
              disabledEvents.includes(e) ? "disabled" : ""
            }`}
          >
            <div className="tip-match__teams">
              <div className="tip-match__teams__item-home u-circle">
                <img
                  src={e.eveniment.teams.home.logo}
                  width="16"
                  height="16"
                  className="lazyload isLoaded"
                />
              </div>
              <div className="tip-match__teams__item-away u-circle">
                <img
                  src={e.eveniment.teams.away.logo}
                  width="16"
                  height="16"
                  className="lazyload isLoaded"
                />
              </div>
            </div>
            <div className="flex flex-col w-full" style={{ marginLeft: 24 }}>
              <div className="tip-match__name t-ellipsis">
                <span className="gray-color" style={{ fontSize: 12 }}>
                  {shortenText(
                    e.eveniment.teams.home.name +
                      " - " +
                      e.eveniment.teams.away.name,
                    26
                  )}
                </span>
              </div>
              <div
                className="tip-match__market t-ellipsis  text-white font-semibold uppercase"
                style={{ fontSize: 10 }}
              >
                {shortenText(e.prediction.name, 26)}
                <br />
                {shortenText(e.prediction.value, 26)}
              </div>
            </div>
            <div
              className={`accumulator-bet-offer__odd mt-2 ${
                disabledEvents.includes(e) ? "event-started font-bold" : ""
              }`}
            >
              {disabledEvents.includes(e) ? "INCEPUT" : e.prediction.odd}{" "}
            </div>
            <div className="accumulator-bet-offer__actions">
              <div
                className="button_square button_square-xs"
                onClick={() =>
                  handleDeleteEvenimentDePeBilet(e.eveniment.fixture.id)
                }
              >
                <span className="icon-keyboard_arrow_left">
                  <FaTimes className="text-sm icon-close" />
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="create-tip-accumulator__calculator">
        <div className="tips-stake-calculator">
          <div className="tips-stake-calculator__item">
            <div className="tips-stake-calculator__item__head">Total cota</div>
            <div className="tips-stake-calculator__item__box">
              <span
                className="text-roboto font-bold"
                style={{ fontSize: "13px" }}
              >
                {totalOdd}
              </span>
            </div>
          </div>
          <div className="tips-stake-calculator__sign tips-stake-calculator__sign--rotate">
            <FaTimes className="text-white" />
          </div>
          <div className="tips-stake-calculator__item">
            <div
              className="tips-stake-calculator__item__head tips-stake-calculator__tooltip flex"
              data-tooltip-id="tooltip-stake"
            >
              <span>Miza</span>

              <FaInfoCircle className="ml-1 text-blue-500 text-xs" />
            </div>
            <Tooltip
              id="tooltip-stake"
              place="top"
              style={{
                fontSize: 12,
                paddingTop: 0,
                paddingBottom: 0,
                textTransform: "none",
                borderRadius: "12px",
                color: "#000",
                lineHeight: "32px",
                backgroundColor: "#fff",
                opacity: 1,
              }}
              html="<center>Miza maxima in functie de total cota<br /><b>1.01 - 2.49</b> miza maxima 10<br /><b>2.50 - 2.74</b> miza maxima 9<br /><b>2.75 - 2.99</b> miza maxima 8<br /><b>3.00 - 3.99</b> miza maxima 7<br /><b>4.00 - 4.99</b> miza maxima 5<br><b>5.00 - 5.99</b> miza maxima 6<br><b>6.00 - 7.99</b> miza maxima 4<br /><b>8.00 - 9.99</b> miza maxima 3<br /><b>10.00 - 19.99</b> miza maxima 2<br /><b>20+</b> miza maxima 1</center>"
            />
            <div className="tips-stake-calculator__item__box tips-stake-calculator__item__box--input">
              <input
                value={stake}
                min="1"
                onChange={handleStakeChange}
                className="stake-input"
                name="stake"
                type="number"
              />
            </div>
          </div>
          <div className="tips-stake-calculator__sign">
            <FaEquals className="text-white" />
          </div>
          <div className="tips-stake-calculator__item">
            <div className="tips-stake-calculator__item__head">Castig</div>
            <div className="tips-stake-calculator__item__box">
              <span
                className="text-roboto font-bold"
                style={{ fontSize: "13px" }}
              >
                {totalReturn}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="create-tip-accumulator__textarea">
        <div className="form-textarea">
          <textarea
            rows="5"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Adauga cateva linii de informatii pentru a face predictia ta mai atractiva si vei castiga mai multi urmaritori"
            className="rounded-lg  bg-slate-100 border-none focus:border-none focus:ring-0 border-none text-gray-900 text-sm w-full placeholder:text-xs dark:bg-bgMain2 dark:placeholder-gray-400 dark:text-white mb-0 hover:ring-white"
          ></textarea>
        </div>
      </div>
      {user?.role === "admin" ? (
        <div className="create-tip-accumulator__actions justify-between">
          <button
            onClick={handleCotaDeDoi}
            className={`button_medium button_secondary bg-red-500 items-center justify-center flex ${
              isCotaDeDoi ? "active" : ""
            }`}
          >
            Cota de doi{" "}
            {isCotaDeDoi ? (
              <FaCheckSquare className="ml-2" />
            ) : (
              <FaSquare className="ml-2" />
            )}
          </button>
          <button
            onClick={handleBiletulZilei}
            className={`button_medium button_secondary bg-red-500 items-center justify-center flex ${
              isBiletulZilei ? "active" : ""
            }`}
          >
            Biletul zilei{" "}
            {isBiletulZilei ? (
              <FaCheckSquare className="ml-2" />
            ) : (
              <FaSquare className="ml-2" />
            )}
          </button>
        </div>
      ) : null}

      <div className="create-tip-accumulator__actions justify-between">
        <button
          className="button_medium button_secondary"
          style={{ marginRight: 4 }}
          onClick={handleGoBack}
        >
          <span className="ico-button">
            <FaPlus className="mr-2" />
          </span>
          <span className="button-text">Adauga mai mult</span>
        </button>
        <button
          disabled={disabledEvents.length > 0}
          onClick={handlePublicBilet}
          type="button"
          className={`button button_medium font-bold ${
            disabledEvents.length > 0 ? "button_danger" : "button_primary"
          }`}
          style={{ minWidth: 140 }}
        >
          {disabledEvents.length > 0
            ? "Eveniment inceput pe bilet"
            : `Publica ${
                evenimentePeBilet.length === 1 ? "eveniment" : "bilet"
              }`}
        </button>
      </div>
      <div className="create-tip-accumulator__footer">
        <div className="betslip-gambling-regulators text-xs text-gray-400">
          Accesul persoanelor sub 18 ani este strict interzis.
        </div>
      </div>
    </div>
  );
};

export default TipAcumulator;

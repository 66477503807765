import React, { useState, useEffect } from "react";
import { GoSearch } from "react-icons/go";
import { MdClose } from "react-icons/md";
import { useDispatch } from "react-redux";
const Search = ({
  search,
  setSearch,
  setSearchLoading,
  totalResults,
  predictionStatus,
  setPredictionStatus,
  getAllPredictions,
  onlyFollowedUsers,
  user,
}) => {
  const dispatch = useDispatch();
  const [timer, setTimer] = useState(null);

  const handleSearch = (event) => {
    setPredictionStatus("all");
    setSearchLoading(true);
    clearTimeout(timer);
    setSearch(event.target.value);

    if (event.target.value === "") {
      dispatch(
        getAllPredictions({
          totalResults,
          predictionStatus: "all",
          searchPrediction: "",
          onlyFollowedUsers,
          userId: user ? user?._id : "",
        })
      ).then(() => {
        setSearchLoading(false);
        setPredictionStatus("all");
      });
    } else {
      setTimer(
        setTimeout(() => {
          dispatch(
            getAllPredictions({
              totalResults,
              predictionStatus: "all",
              searchPrediction: search,
              onlyFollowedUsers,
              userId: user ? user?._id : "",
            })
          ).then(() => {
            setSearchLoading(false);
          });
        }, 1500)
      );
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleDeleteSearch = () => {
    setSearchLoading(true);
    setSearch("");
    setPredictionStatus("all");
    dispatch(
      getAllPredictions({
        totalResults,
        predictionStatus: "all",
        searchPrediction: "",
        onlyFollowedUsers,
        userId: user ? user?._id : "",
      })
    ).then(() => {
      setSearchLoading(false);
    });
  };

  // handle search prediction
  return (
    <form className="items-center flex-1 flex mr-2 ">
      <label htmlFor="simple-search" className="sr-only">
        Cauta dupa eveniment sau tipster
      </label>
      <div className="relative w-full">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <GoSearch className="text-blue-400" />
        </div>
        <input
          type="text"
          style={{
            height: 36,
            border: "none",
            borderRadius: 20,
            background: "linear-gradient(90deg,#24262b 100%,#3e4851 0)",
          }}
          value={search}
          onChange={(e) => handleSearch(e)}
          id="simple-search"
          className="bg-slate-100 border-none focus:border-none focus:ring-0 border-none text-gray-900 text-sm rounded-full w-full pl-10 placeholder:text-xs dark:bg-bgMain2 dark:placeholder-gray-400 dark:text-white mb-0 hover:ring-white"
          placeholder="Cauta dupa eveniment sau tipster"
          required
        />
        <div
          disabled={!search}
          onClick={handleDeleteSearch}
          type="submit"
          style={{ top: 4, right: 4, width: 28, height: 28 }}
          className="bg-white flex items-center justify-center text-sm text-blue-400 absolute rounded-full"
        >
          <MdClose className="text-blue-400 text-xl" />
          <span className="sr-only">Cauta eveniment</span>
        </div>
      </div>
    </form>
  );
};

export default Search;

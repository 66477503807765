import React from "react";
import Moment from "react-moment";
import { BiFootball } from "react-icons/bi";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

const PredictionBody = ({ predictionStored }) => {
  const formatTeamNames = (team1, team2) => {
    const replaceSpacesWithHyphens = (str) => str.replace(/\s+/g, "-");

    const formattedTeam1 = replaceSpacesWithHyphens(team1 ?? "").toLowerCase();
    const formattedTeam2 = replaceSpacesWithHyphens(team2 ?? "").toLowerCase();

    return `${formattedTeam1}-vs-${formattedTeam2}`;
  };

  console.log(predictionStored);
  return (
    <div className="ticket-predictions w-full">
      {predictionStored?.evenimente?.map((eveniment, index) => (
        <Link
          to={`/${eveniment?.eveniment?.fixture?.id}/${formatTeamNames(
            eveniment?.eveniment?.teams?.home?.name,
            eveniment?.eveniment?.teams?.away?.name
          )}`}
          key={index}
          className={`no-underline-link flex flex-col w-full rounded-lg my-4 cursor-pointer  rounded-lg mt-2 first:mt-0 cursor-pointer overflow-hidden shadow-lg`}
        >
          <div className="flex w-full darker-bg rounded-t items-center justify-between p-2 overflow-hidden">
            <div
              className="text-white text-xs flex items-center justify-center"
              style={{ gap: "4px" }}
            >
              <BiFootball className="text-white text-sm" />
              <span className=" uppercase">
                {eveniment?.eveniment?.league?.country}
              </span>
              <span className="match_info-league flex items-center justify-center">
                {eveniment?.eveniment?.league?.name}
              </span>
            </div>
            <div className="flex items-center justify-center">
              <Moment
                locale="ro"
                className="text-white text-xs uppercase mr-2"
                format="DD.MM.YYYY"
              >
                {eveniment?.eveniment?.fixture?.timestamp * 1000}
              </Moment>
              {eveniment?.eveniment?.fixture?.status?.long === "Not Started" ? (
                <Moment
                  locale="ro"
                  className="text-white text-xs uppercase"
                  format="HH:mm"
                >
                  {eveniment?.eveniment?.fixture?.timestamp * 1000}
                </Moment>
              ) : eveniment?.eveniment?.fixture?.status?.short === "FT" ? (
                <div className="text-white text-xs uppercase">
                  <span>Final</span>
                </div>
              ) : eveniment?.eveniment?.fixture?.status?.short === "HT" ? (
                <span className="text-red-500 text-xs uppercase font-bold">
                  Pauza
                </span>
              ) : eveniment?.eveniment?.fixture?.status?.short === "PST" ? (
                <span className="text-yellow-500 text-xs uppercase font-bold">
                  Amanat
                </span>
              ) : eveniment?.eveniment?.fixture?.status?.short === "ABD" ? (
                <span className="text-yellow-500 text-xs uppercase font-bold">
                  Abandon
                </span>
              ) : eveniment?.eveniment?.fixture?.status?.short === "SUSP" ? (
                <span className="text-yellow-500 text-xs uppercase font-bold">
                  Suspendat
                </span>
              ) : eveniment?.eveniment?.fixture?.status?.short === "P" ? (
                <span className="text-sky-500 text-xs uppercase font-bold">
                  Penalty
                </span>
              ) : eveniment?.eveniment?.fixture?.status?.short === "PEN" ? (
                <span className="text-white text-xs uppercase font-bold">
                  Final (Penalty)
                </span>
              ) : (
                <span className="text-red-500 text-xs font-bold">
                  LIVE {eveniment?.eveniment?.fixture?.status?.elapsed}
                  <b className="text-red-500 font-bold animate-ping">'</b>
                </span>
              )}
            </div>
          </div>
          <div
            className={`flex w-full p-2 items-center justify-between ${
              eveniment?.prediction?.status === 1
                ? "prediction-block-lost"
                : eveniment?.prediction?.status === 2
                ? "prediction-block-won"
                : eveniment?.prediction?.status === 3
                ? "prediction-block-void"
                : "prediction-block-ns"
            }`}
          >
            <div className="flex grow flex-1 w-full">
              <div className="flex items-center justify-center mr-2 w-14">
                <div
                  className="border border-gray-300 dark:border-gray-900 mr-1 w-8 h-8 flex items-center justify-center relative rounded-full"
                  style={{
                    background: "#24262b",
                  }}
                >
                  <LazyLoadImage
                    alt={eveniment?.eveniment.teams.home.name}
                    src={eveniment?.eveniment.teams.home.logo}
                    className="h-5"
                  />
                </div>
                <div
                  style={{
                    marginLeft: "-10px",
                    background: "#24262b",
                  }}
                  className="border border-gray-300 dark:border-gray-900 w-8 h-8 flex items-center justify-center relative rounded-full"
                >
                  <LazyLoadImage
                    alt={eveniment?.eveniment.teams.away.name}
                    src={eveniment?.eveniment.teams.away.logo}
                    className="h-5"
                  />
                </div>
              </div>
              <div className="flex flex-col justify-center" style={{ gap: 4 }}>
                <div className="flex text-sm gray-color items-center">
                  <span>{eveniment.eveniment.teams.home.name}</span>
                  <span
                    className={`mx-1 font-bold ${
                      eveniment?.eveniment.fixture?.status?.long ===
                        "Match Finished" ||
                      eveniment?.eveniment.fixture?.status?.long ===
                        "Match Suspended" ||
                      eveniment?.eveniment.fixture?.status?.long ===
                        "Match Abandoned"
                        ? "text-black"
                        : "orange-color"
                    }
                    `}
                  >
                    {}
                    {eveniment?.eveniment?.fixture?.status?.long ===
                    "Match Abandoned"
                      ? ""
                      : eveniment?.eveniment.goals.home}
                  </span>
                  <span>-</span>
                  <span
                    className={`mx-1 font-bold ${
                      eveniment?.eveniment.fixture?.status?.long ===
                        "Match Finished" ||
                      eveniment?.eveniment.fixture?.status?.long ===
                        "Match Suspended" ||
                      eveniment?.eveniment.fixture?.status?.long ===
                        "Match Abandoned"
                        ? "text-black"
                        : "orange-color"
                    }
                    `}
                  >
                    {eveniment?.eveniment?.fixture?.status?.long ===
                    "Match Abandoned"
                      ? ""
                      : eveniment?.eveniment.goals.away}
                  </span>
                  {eveniment?.eveniment?.fixture?.status?.short === "PEN" ||
                  eveniment?.eveniment?.fixture?.status?.short === "P" ? (
                    <div className="flex text-xs text-sky-500">
                      <span className={`mx-1 font-bold `}>
                        ({eveniment?.eveniment.score.penalty.home}
                      </span>
                      <span>-</span>
                      <span className={`mx-1 font-bold `}>
                        {eveniment?.eveniment.score.penalty.away})
                      </span>
                    </div>
                  ) : null}
                  <span>{eveniment.eveniment.teams.away.name}</span>
                </div>
                <div className="flex text-white" style={{ fontSize: "13px" }}>
                  {eveniment.prediction.name +
                    " - " +
                    eveniment.prediction.value}
                </div>
              </div>
            </div>
            <div
              className={`flex relative items-center shadow-sm flex-col justify-center w-14 h-11 m-0 px-0.5 rounded-lg text-center ${
                eveniment.prediction.status === 0
                  ? "gray-bg-color"
                  : eveniment.prediction.status === 2
                  ? "green-bg-color"
                  : eveniment.prediction.status === 1
                  ? "red-bg-color"
                  : eveniment.prediction.status === 3
                  ? "bg-yellow-300"
                  : null
              } text-white text-roboto`}
              style={{ fontSize: "13px" }}
            >
              {eveniment.prediction.odd.toFixed(2)}
              {eveniment.prediction.status === 0 ? null : eveniment.prediction
                  .status === 2 ? (
                <span
                  style={{
                    fontSize: "8px",
                    opacity: ".8",
                    fontWeight: "400",
                    textTransform: "uppercase",
                    marginTop: 2,
                  }}
                >
                  CASTIGAT
                </span>
              ) : eveniment.prediction.status === 1 ? (
                <span
                  style={{
                    fontSize: "8px",
                    opacity: ".8",
                    fontWeight: "400",
                    textTransform: "uppercase",
                    marginTop: 2,
                  }}
                >
                  PIERDUT
                </span>
              ) : eveniment.prediction.status === 3 ? (
                <span
                  style={{
                    fontSize: "8px",
                    opacity: ".8",
                    fontWeight: "400",
                    textTransform: "uppercase",
                    marginTop: 2,
                  }}
                >
                  ANULAT
                </span>
              ) : null}
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default PredictionBody;

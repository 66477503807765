import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  RESETPREDICTION,
  getPredictionById,
  getAndStorePredictionById,
} from "../../redux/features/predictions/predictionsSlice";
import {
  getAllMatchesV2,
  getMatchesEvenimente,
  getMatchOdds,
  RESETODDS,
} from "../../redux/features/rapidapi/rapidApiSlice";
import { convertDate } from "../../utils/ConvertDate/convertDate";
import Header from "../../comp/Header/Header";
import Footer from "../../comp/Footer/Footer";
import Notifications from "../../comp/Notifications/Notifications";
import Chat from "../../comp/Chat/Chat";
import ModalReset from "../../modals/ModalReset";
import ModalVerify from "../../modals/ModalVerify";
import ModalRegister from "../../modals/ModalRegister";
import ModalLoginWithCode from "../../modals/ModalLoginWithCode";
import ModalForgot from "../../modals/ModalForgot";
import ModalLogin from "../../modals/ModalLogin";
import Modal from "../../modals/Modal";
import usePredictionDispatch from "../../utils/LikeAndDislike/LikeAndDislike";
import BottomToolbar from "../../comp/BottomToolbar/BottomToolbar";
import VerifyAccount from "../../comp/VerifyAccount/VerifyAccount";
import { Helmet } from "react-helmet-async";
import { BiTime, BiTimer } from "react-icons/bi";
import DatePicker from "react-datepicker";
import { ro } from "date-fns/locale";
import { MdCalendarToday, MdOutlineTipsAndUpdates } from "react-icons/md";
import EvenimenteBox from "./EvenimenteBox";
import Tipsteri from "../Tipsteri/Tipsteri";

const Evenimente = ({
  handleShowNotification,
  handleShowChat,
  showChat,
  showNotification,
  handleClick,
  dropdown,
  onlineUsers,
  notificationsCount,
  unreadChatCount,
  showMatches,
  setMessages,
  messages,
  setNewMessage,
  newMessage,
  getUser,
  isSharedPrediction,
  setIsSharedPrediction,
  setDropdown,
  desktopRef,
  mobileRef,
  toggleMobileMenu,
  setMobileMenu,
  mobileMenu,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const [startDate, setStartDate] = useState(new Date());
  const todayDateIs = formatDate(new Date());
  const [liveEvenimente, setLiveEvenimente] = useState(false);
  const [predictionsEvenimente, setPredictionsEvenimente] = useState(false);

  const {
    isLoading,
    isLoadingActions,
    prediction,
    predictionStored,
    predictions,
  } = useSelector((state) => state.predictions);

  const { user, users, isLoggedIn } = useSelector((state) => state.auth);

  const {
    matches,
    odds,
    isError,
    message,
    isErrorOdds,
    bookmaker,
    liveMatches,
    isLoading: isLoadingMatches,
  } = useSelector((state) => state.apiMatches);

  // modal
  const searchParams = new URLSearchParams(location.search);
  const [showModal, setShowModal] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showForgotModal, setShowForgotModal] = useState(false);
  const [showLoginWithCodeModal, setShowLoginWithCodeModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);

  const handleClickOutside = (e) => {
    if (e.target.id === "modal-target") {
      setShowModal(false);
      setShowAuthModal(false);
      setShowForgotModal(false);
      setShowLoginWithCodeModal(false);
      setShowRegisterModal(false);
      setShowVerifyModal(false);
      setShowResetModal(false);
      dispatch(RESETPREDICTION());

      // Delete modal id parameter from URL
      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.delete("modal");
      newSearchParams.delete("id");
      newSearchParams.delete("tk");
      navigate(`${location.pathname}?${newSearchParams.toString()}`);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const [isLoadingPrediction, setIsLoadingThePrediction] = useState(false);
  const handleModal = (eveniment) => {
    if (!showModal) {
      if (!isLoadingPrediction && location.search === "") {
        // Display a loading indicator for 5 seconds
        setIsLoadingThePrediction(true);
        const predictionData = {
          _id: eveniment._id,
        };
        dispatch(getPredictionById(predictionData));
        setTimeout(() => {
          setIsLoadingThePrediction(false); // Remove loading indicator after dispatch
        }, 2000); // 5000 milliseconds (5 seconds)
      }

      // Add modal id parameter to URL
      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.set("modal", "prediction");
      newSearchParams.set("id", eveniment._id);
      navigate(`${location.pathname}?${newSearchParams.toString()}`);
      setShowModal(true);
    } else {
      if (prediction._id === eveniment._id) {
        // If the modal is already open for the clicked event, do nothing
        return;
      }
      dispatch(RESETPREDICTION());
      setShowModal(false);

      // Delete modal id parameter from URL
      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.delete("modal");
      newSearchParams.delete("id");
      navigate(`${location.pathname}?${newSearchParams.toString()}`);
    }
  };

  useEffect(() => {
    const modalPredictionId = new URLSearchParams(location.search).get("id");

    if (modalPredictionId && prediction === null) {
      setIsLoadingThePrediction(true);
      const predictionData = {
        _id: modalPredictionId,
      };
      setShowModal(true);
      dispatch(getPredictionById(predictionData));
      setTimeout(() => {
        setIsLoadingThePrediction(false); // Remove loading indicator after dispatch
      }, 2000); // 5000 milliseconds (5 seconds)
    }
  }, [location.search, predictions, showModal]);

  useEffect(() => {
    const modalPredictionId = new URLSearchParams(location.search).get("modal");

    if (modalPredictionId === "autentificare") {
      setShowAuthModal(true);
      setShowForgotModal(false);
      setShowRegisterModal(false);
    } else if (modalPredictionId === "recuperare-parola") {
      setShowForgotModal(true);
      setShowAuthModal(false);
      setShowRegisterModal(false);
    } else if (modalPredictionId === "autentificare-cod") {
      setShowLoginWithCodeModal(true);
      setShowAuthModal(false);
      setShowForgotModal(false);
      setShowRegisterModal(false);
    } else if (modalPredictionId === "inregistrare") {
      setShowRegisterModal(true);
      setShowAuthModal(false);
      setShowForgotModal(false);
    } else if (modalPredictionId === "verificare-cont") {
      setShowVerifyModal(true);
    } else if (modalPredictionId === "resetare-parola") {
      setShowResetModal(true);
    }
  }, [
    location.search,
    showAuthModal,
    showForgotModal,
    showLoginWithCodeModal,
    showRegisterModal,
    showVerifyModal,
    showResetModal,
  ]);

  // Update search params and navigate to the updated URL
  useEffect(() => {
    if (prediction) {
      if (showModal) {
        searchParams.set("modal", "prediction");
        searchParams.set("id", prediction._id);
      } else {
        searchParams.delete("modal");
        searchParams.delete("id");
      }
      navigate(`${location.pathname}?${searchParams.toString()}`);
    }
  }, [showModal, isLoadingPrediction, prediction]);

  useEffect(() => {
    if (showModal && prediction) {
      const intervalId = setInterval(() => {
        const predictionData = {
          _id: prediction._id,
        };
        dispatch(getPredictionById(predictionData));
      }, 40000);
      return () => clearInterval(intervalId);
    }
  }, [showModal, prediction]);

  // auth modal
  const handleAuthenticationModal = (modalId, show, additionalParams = {}) => {
    if (show) {
      // Add modal id parameter to URL
      const newSearchParams = searchParams;
      newSearchParams.set("modal", modalId);
      for (const key in additionalParams) {
        newSearchParams.set(key, additionalParams[key]);
      }
      navigate(`${location.pathname}?${newSearchParams.toString()}`);
    } else {
      // Delete modal id parameter from URL
      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.delete("modal");
      for (const key in additionalParams) {
        newSearchParams.delete(key);
      }
      navigate(`${location.pathname}?${newSearchParams.toString()}`);
    }
  };

  // Example usage
  const handleAuthModal = () => {
    if (!showAuthModal) {
      handleAuthenticationModal("autentificare", true);
      setShowAuthModal(true);
    } else {
      setShowAuthModal(false);
      handleAuthenticationModal("autentificare", false);
    }
  };

  const handleForgotModal = () => {
    handleAuthModal();
    if (!showForgotModal) {
      handleAuthenticationModal("recuperare-parola", true);
      setShowForgotModal(true);
    } else {
      setShowForgotModal(false);
      handleAuthenticationModal("recuperare-parola", false);
    }
  };

  const handleLoginWithCodeModal = (email) => {
    if (!showLoginWithCodeModal) {
      handleAuthenticationModal("autentificare-cod", true, { email });
      setShowLoginWithCodeModal(true);
    } else {
      setShowLoginWithCodeModal(false);
      handleAuthenticationModal("autentificare-cod", false, { email });
    }
  };

  const handleRegisterModal = () => {
    if (!showRegisterModal) {
      handleAuthenticationModal("inregistrare", true);
      setShowRegisterModal(true);
    } else {
      setShowRegisterModal(false);
      handleAuthenticationModal("inregistrare", false);
    }
  };

  const handleVerifyModal = (tk) => {
    if (!showVerifyModal) {
      handleAuthenticationModal("verificare-cont", true, { tk });
      setShowVerifyModal(true);
    } else {
      setShowVerifyModal(false);
      handleAuthenticationModal("verificare-cont", false, { tk });
    }
  };

  const handleResetModal = (tk) => {
    if (!showResetModal) {
      handleAuthenticationModal("resetare-parola", true, { tk });
      setShowResetModal(true);
    } else {
      setShowResetModal(false);
      handleAuthenticationModal("resetare-parola", false, { tk });
    }
  };

  //

  useEffect(() => {
    const body = document.querySelector("body");

    if (
      showModal ||
      showAuthModal ||
      showForgotModal ||
      showLoginWithCodeModal ||
      showRegisterModal ||
      showVerifyModal ||
      showResetModal
    ) {
      body.classList.add("body-scroll-hidden");
      return () => {
        body.classList.remove("body-scroll-hidden");
      };
    } else {
    }
  }, [
    showModal,
    showAuthModal,
    showForgotModal,
    showLoginWithCodeModal,
    showRegisterModal,
    showVerifyModal,
    showResetModal,
  ]);
  // modal

  // like dislike prediction

  const { handleLikePrediction, handleDislikePrediction } =
    usePredictionDispatch();

  // like dislike prediction

  const getMatchesSelect = (date) => {
    setStartDate(date);
    setLiveEvenimente(false);

    const matchDate = {
      date: convertDate(date),
      status: liveEvenimente,
      predictions: predictionsEvenimente,
    };
    dispatch(getMatchesEvenimente(matchDate));
  };

  useEffect(() => {
    const matchDate = {
      date: convertDate(startDate),
      status: liveEvenimente,
      predictions: predictionsEvenimente,
    };

    // Initial dispatch
    dispatch(getMatchesEvenimente(matchDate));

    // Set up an interval to dispatch every 1 minute
    const intervalId = setInterval(() => {
      dispatch(getMatchesEvenimente(matchDate));
    }, 60000); // 60000 milliseconds = 1 minute

    // Clean up the interval on unmount or when liveEvenimente changes
    return () => {
      clearInterval(intervalId);
    };
  }, [liveEvenimente, predictionsEvenimente, startDate]);

  const [leagues, setLeagues] = useState([]);
  const [loadingLeagues, setLoadingLeagues] = useState(false);
  const [filteredMatch, setFilteredMatch] = useState();
  const [search, setSearch] = useState("");

  // Filtrare
  const filteredMatches = matches?.filter((eveniment) => {
    const evenimentNume =
      eveniment.teams.home.name + " vs " + eveniment.teams.away.name;

    return evenimentNume.toLowerCase().includes(search.toLowerCase());
  });

  useEffect(() => {
    setFilteredMatch(matches);
  }, [isLoadingMatches, startDate]);

  useEffect(() => {
    setFilteredMatch(filteredMatches);
  }, [search]);
  // Filtrare

  console.log(leagues);
  useEffect(() => {
    setLeagues([]);
    setLeagues((leagues) => {
      if (!filteredMatch) {
        return leagues;
      }
      const mappedLeagues = new Map();
      filteredMatch.forEach((fixture) => {
        if (!mappedLeagues.has(fixture.league.id)) {
          mappedLeagues.set(fixture.league.id, {
            leagueId: fixture.league.id,
            leagueLogo: fixture.league.logo,
            leagueFlag: fixture.league.flag,
            leagueCountry: fixture.league.country,
            leagueName: fixture.league.name,
            fixtures: [],
          });
        }

        mappedLeagues.get(fixture.league.id).fixtures.push(fixture);
      });
      return [...mappedLeagues.values()];
    });
  }, [filteredMatch]);

  useEffect(() => {
    setLoadingLeagues(true);
  }, [startDate, predictionsEvenimente, liveEvenimente]);

  useEffect(() => {
    setTimeout(() => {
      setLoadingLeagues(false);
    }, 1000);
  }, [leagues]);

  // get live evenimente

  const getMatchesLive = () => {
    setLiveEvenimente(!liveEvenimente);
  };

  const getPredictionsEvenimente = () => {
    setPredictionsEvenimente(!predictionsEvenimente);
  };
  return (
    <>
      <Helmet>
        <title>AnalizeSportive</title>
        <meta
          name="description"
          content="Vezi clasamentul tipsterilor AnalizeSportive în funcție de profit. Descoperă cei mai buni specialiști la ponturi pariuri sportive online în 2023"
        />
        <meta name="og:image" content="https://i.ibb.co/FnPJ7dF/cover.jpg" />
      </Helmet>

      <div className="page">
        <div className="page__main">
          <div id="react-modal-screen">
            <div id="modals">
              {showModal && (
                <Modal
                  handleModal={handleModal}
                  showModal={showModal}
                  prediction={prediction}
                  user={user}
                  isLoggedIn={isLoggedIn}
                  isLoading={isLoading}
                  isLoadingActions={isLoadingActions}
                  handleDislikePrediction={handleDislikePrediction}
                  handleLikePrediction={handleLikePrediction}
                  isLoadingPrediction={isLoadingPrediction}
                />
              )}
              {showAuthModal && (
                <ModalLogin
                  showAuthModal={showAuthModal}
                  setShowAuthModal={setShowAuthModal}
                  handleAuthModal={handleAuthModal}
                  handleForgotModal={handleForgotModal}
                  handleRegisterModal={handleRegisterModal}
                />
              )}
              {showForgotModal && (
                <ModalForgot
                  showForgotModal={showForgotModal}
                  setShowForgotModal={setShowForgotModal}
                  handleAuthModal={handleAuthModal}
                  handleForgotModal={handleForgotModal}
                  handleRegisterModal={handleRegisterModal}
                />
              )}
              {showLoginWithCodeModal && (
                <ModalLoginWithCode
                  handleLoginWithCodeModal={handleLoginWithCodeModal}
                  setShowLoginWithCodeModal={setShowLoginWithCodeModal}
                  showLoginWithCodeModal={showLoginWithCodeModal}
                />
              )}
              {showRegisterModal && (
                <ModalRegister
                  handleRegisterModal={handleRegisterModal}
                  showRegisterModal={showRegisterModal}
                  setShowRegisterModal={setShowRegisterModal}
                  setShowAuthModal={setShowAuthModal}
                  handleAuthModal={handleAuthModal}
                />
              )}
              {showVerifyModal && (
                <ModalVerify
                  setShowVerifyModal={setShowVerifyModal}
                  handleVerifyModal={handleVerifyModal}
                />
              )}
              {showResetModal && (
                <ModalReset handleResetModal={handleResetModal} />
              )}
            </div>
          </div>
          <VerifyAccount
            user={user}
            isLoading={isLoading}
            isLoggedIn={isLoggedIn}
          />
          <Header
            handleShowNotification={handleShowNotification}
            handleShowChat={handleShowChat}
            showChat={showChat}
            showNotification={showNotification}
            handleClick={handleClick}
            dropdown={dropdown}
            desktopRef={desktopRef}
            onlineUsers={onlineUsers}
            notificationsCount={notificationsCount}
            unreadChatCount={unreadChatCount}
            handleAuthModal={handleAuthModal}
            handleRegisterModal={handleRegisterModal}
            setMobileMenu={setMobileMenu}
            mobileMenu={mobileMenu}
          />
          <div className="main container">
            <div className="content">
              <div className="page-block page-block_cms">
                <div className="content-block  evenimente-content-block">
                  <div className="evenimente-block">
                    <section className="accordion-section__block evenimente-accordion-box">
                      <h2>Evenimente Fotbal</h2>
                      <div>{formatDate(startDate)}</div>
                    </section>
                    <div className="match-list-sub">
                      <div className="match-list-filter-buttons">
                        <button
                          disabled={
                            todayDateIs !== formatDate(startDate) ||
                            liveMatches === 0
                          }
                          onClick={getMatchesLive}
                          type="button"
                          className={`button_medium button_secondary relative ${
                            liveEvenimente ? "button_active" : ""
                          }`}
                        >
                          <BiTimer className="btn-filter__icon icon-timer" />
                          Live
                          {leagues.length > 0 &&
                          liveMatches > 0 &&
                          todayDateIs === formatDate(startDate) ? (
                            <span class="btn__label bg-danger">
                              {liveMatches}
                            </span>
                          ) : null}
                        </button>
                        <button
                          onClick={getPredictionsEvenimente}
                          type="button"
                          className={`button_medium button_secondary ${
                            predictionsEvenimente ? "button_active" : ""
                          }`}
                        >
                          <MdOutlineTipsAndUpdates className="btn-filter__icon icon-timer" />
                          Predictii
                        </button>
                      </div>
                      <div className="match-list-date-picker">
                        <button
                          id="today-button"
                          type="button"
                          className="button_medium button_secondary "
                          onClick={() => getMatchesSelect(new Date())}
                        >
                          <MdCalendarToday className="btn-filter__icon icon-timer" />
                          Astazi
                        </button>
                        <DatePicker
                          className="button_icon-date button_icon-custom focus:ring-0 focus:ring-offset-0 focus:border-transparent pointer mt-1"
                          selected={startDate}
                          onChange={(date: Date) => getMatchesSelect(date)}
                          dateFormat="yyyy-MM-dd"
                          locale={ro}
                        />
                      </div>
                    </div>
                    <EvenimenteBox
                      leagues={leagues}
                      isLoadingMatches={isLoadingMatches}
                      loadingLeagues={loadingLeagues}
                    />
                    {leagues.length === 0 &&
                    !loadingLeagues &&
                    !isLoadingMatches ? (
                      <div className="flex items-center justify-center p-4">
                        Nu sunt evenimente
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="evenimente-aside ">
                    <div className="aside__content aside__default">
                      <div class="unique-selling-points">
                        <div class="unique-selling-points__header unique-selling-points__header--top t-uppercase text-center">
                          Alaturati-va comunitatii noastre
                        </div>
                        <div class="unique-selling-points__content text-center">
                          <div class="unique-selling-points__content-row">
                            <div class="unique-selling-points__content-column flex justify-center items-start">
                              <div class="unique-selling-points__content-column-stats">
                                N/A
                              </div>
                              <div class="unique-selling-points__content-column-text t-uppercase">
                                Total membrii
                              </div>
                            </div>
                            <div class="unique-selling-points__content-column flex justify-center items-start">
                              <div class="unique-selling-points__content-column-stats">
                                N/A
                              </div>
                              <div class="unique-selling-points__content-column-text t-uppercase">
                                Tipsteri activi
                              </div>
                            </div>
                            <div class="unique-selling-points__content-column flex justify-center items-start">
                              <div class="unique-selling-points__content-column-stats">
                                N/A
                              </div>
                              <div class="unique-selling-points__content-column-text t-uppercase">
                                Evenimente
                              </div>
                            </div>
                            <div class="unique-selling-points__content-column flex justify-center items-start">
                              <div class="unique-selling-points__content-column-stats">
                                N/A
                              </div>
                              <div class="unique-selling-points__content-column-text t-uppercase">
                                Predictii
                              </div>
                            </div>
                          </div>
                          {isLoggedIn ? null : (
                            <>
                              <button class="unique-selling-points__btn btn btn--primary unique-selling-points__btn--large">
                                Inregistreaza-te
                              </button>
                              <div class="unique-selling-points__sign-up-text">
                                Dureaza maxim 30 secunde.
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
            <div
              className={`page__panel ${
                showNotification || showMatches || showChat
                  ? ""
                  : "page__panel_hidden"
              }`}
            >
              <div id="react-toolbar-right">
                <div
                  className={`side-panel ${
                    showNotification || showMatches || showChat
                      ? ""
                      : "side-panel_hidden"
                  }`}
                >
                  {showChat && (
                    <div className="chat" id="chat">
                      <Chat
                        user={user}
                        isLoggedIn={isLoggedIn}
                        onlineUsers={onlineUsers}
                        users={users}
                        setMessages={setMessages}
                        messages={messages}
                        newMessage={newMessage}
                        setNewMessage={setNewMessage}
                        predictions={predictions}
                        isSharedPrediction={isSharedPrediction}
                        setIsSharedPrediction={setIsSharedPrediction}
                        showChat={showChat}
                        getUser={getUser}
                        handleAuthModal={handleAuthModal}
                      />
                    </div>
                  )}

                  {showNotification && (
                    <div className="notification">
                      <Notifications user={user} handleModal={handleModal} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <BottomToolbar
            handleRegisterModal={handleRegisterModal}
            handleShowChat={handleShowChat}
            notificationsCount={notificationsCount}
            mobileRef={mobileRef}
            showChat={showChat}
            setDropdown={setDropdown}
            dropdown={dropdown}
            isLoggedIn={isLoggedIn}
            toggleMobileMenu={toggleMobileMenu}
            mobileMenu={mobileMenu}
            setMobileMenu={setMobileMenu}
          />
        </div>
      </div>
    </>
  );
};

export default Evenimente;

import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  RESET,
  sendVerificationEmail,
} from "../../redux/features/auth/authSlice";

const VerifyAccount = ({ user, isLoading, isLoggedIn }) => {
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const isEmailConfirmationDismissed = localStorage.getItem(
      "isEmailConfirmationDismissed"
    );
    if (!user?.isVerified && !isEmailConfirmationDismissed && !isLoading) {
      const timeoutId = setTimeout(() => {
        setShowAlert(true);
      }, 1000);
      return () => {
        clearTimeout(timeoutId);
      };
    } else {
      setShowAlert(false);
    }
  }, [user, isLoading, isLoggedIn]);

  const handleDismissEmailConfirmation = () => {
    localStorage.setItem("isEmailConfirmationDismissed", "true");
    setShowAlert(false);
  };

  const dispatch = useDispatch();
  const sendVerEmail = async () => {
    await dispatch(sendVerificationEmail());
    await dispatch(RESET());
  };
  return (
    <>
      {showAlert && !isLoading && isLoggedIn ? (
        <div className="header-announcements-container">
          <div>
            <div className="mail-promo">
              <div className="hold">
                Confirma adresa de email chiar acum!
                <span
                  className="ml-2 link"
                  style={{ color: "#ffffff" }}
                  onClick={sendVerEmail}
                >
                  Retrimite codul pe email
                </span>
              </div>

              <div
                className="btn-close"
                onClick={handleDismissEmailConfirmation}
              >
                <span className="icon-close">
                  <FaTimes className="text-base" />
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default VerifyAccount;

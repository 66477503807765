import React, { useRef, useState, useEffect } from "react";
import { FaEquals, FaExpand, FaTimes } from "react-icons/fa";
import { BiFootball } from "react-icons/bi";
import {
  BsFillHandThumbsUpFill,
  BsFillHandThumbsDownFill,
} from "react-icons/bs";
import Moment from "react-moment";
import "moment/locale/ro";
import ShowMoreText from "react-show-more-text";
import { Avatar, Tooltip } from "flowbite-react";
import { Link } from "react-router-dom";
import Countdown from "react-countdown";
import { BsDash } from "react-icons/bs";
import {
  postCommentPrediction,
  deleteCommentPrediction,
  editCommentPrediction,
  addReplyToComment,
  deleteReplyCommentPrediction,
  editReplyCommentPrediction,
  likeCommentPrediction,
} from "../redux/features/predictions/predictionsSlice";
import Comment from "../comp/Timeline/Comment";
import { useDispatch } from "react-redux";
import UserAvatar from "../utils/UserAvatar/UserAvatar";
import DuckLoader from "../utils/Loaders/DuckLoader";
import { MdVerified } from "react-icons/md";
import { LazyLoadImage } from "react-lazy-load-image-component";

const calculatePercentages = (likes, dislikes) => {
  if (likes === 0 && dislikes === 0) {
    const likePercentage = 0;
    const dislikePercentage = 0;
    return { likePercentage, dislikePercentage };
  } else {
    const total = likes + dislikes;
    const likePercentage = (likes / total) * 100;
    const dislikePercentage = (dislikes / total) * 100;
    return { likePercentage, dislikePercentage };
  }
};

const Modal = ({
  prediction,
  handleModal,
  showModal,
  setShowModal,
  user,
  isLoggedIn,
  isLoadingActions,
  isLoading,
  handleLikePrediction,
  handleDislikePrediction,
  isLoadingPrediction,
}) => {
  const formatTeamNames = (team1, team2) => {
    const replaceSpacesWithHyphens = (str) => str.replace(/\s+/g, "-");

    const formattedTeam1 = replaceSpacesWithHyphens(team1 ?? "").toLowerCase();
    const formattedTeam2 = replaceSpacesWithHyphens(team2 ?? "").toLowerCase();

    return `${formattedTeam1}-vs-${formattedTeam2}`;
  };

  const { likePercentage, dislikePercentage } = calculatePercentages(
    prediction?.likes,
    prediction?.dislikes
  );
  // comments

  const dispatch = useDispatch();

  const reversedComments = prediction?.commentedBy;
  const [isComments, setIsComments] = useState(false);
  const [comment, setComment] = useState("");
  const [repliesShown, setRepliesShown] = useState(3);

  const handleAddComment = (e) => {
    setComment(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const commentData = {
      predictionId: prediction._id,
      comment: comment,
      userId: user._id,
      name: user.name,
      photo: user.photo,
    };
    console.log(commentData);
    dispatch(postCommentPrediction(commentData));
    setComment("");
  };

  const handleDeleteComment = (id) => {
    const commentData = {
      predictionId: prediction._id,
      user: user,
      commentId: id,
    };
    console.log(commentData);
    dispatch(deleteCommentPrediction(commentData));
  };

  const handleShowMoreComments = () => {
    if (repliesShown < prediction?.comments) {
      setRepliesShown(prediction?.comments);
    } else {
      setIsComments(false);
      setRepliesShown(3);
    }
  };
  //

  // focus textarea
  const modalRef = useRef(null);
  const textareaRef = useRef(null);
  useEffect(() => {
    if (showModal && !isLoadingPrediction) {
      const target = document.querySelector("#scroll-target");
      textareaRef.current.focus();
      if (prediction?.totalEvenimente > 1) {
        target.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [isLoadingPrediction]);
  //
  return (
    <div className="modal-screen modal-screen_prediction-details wallet-modal showModalBg">
      <div className="modal-screen-wrap" id="modal-target">
        <div className="modal-window showModalWindow">
          <div className="modal-header flex-row flex-row_center flex-row_space-between">
            <div className="modal-title">
              <div className="flex-row flex-row_space-between align-center full-width">
                <span>Detalii eveniment</span>
              </div>
            </div>
            <div className="flex-row flex-row_center gap-2">
              <Link
                onClick={() => setShowModal(false)}
                to={`/predictie/${prediction?._id}`}
              >
                <FaExpand className="text-lg text-gray-500" />
              </Link>

              <div className="btn-close" onClick={handleModal}>
                <FaTimes className="text-lg text-gray-500" />
              </div>
            </div>
          </div>
          <div className="modal-content" ref={modalRef}>
            <div className="my-info-modal h-full">
              <div className="flex flex-col w-full z-10 relative h-full">
                {isLoadingPrediction ? (
                  <div className="p-4">
                    <DuckLoader rowCount={6} />
                  </div>
                ) : (
                  <>
                    {" "}
                    <div
                      className="flex justify-between items-center w-full header_popup_modal"
                      style={{ padding: "10px 30px 10px 30px" }}
                    >
                      <Link
                        to={`/p/${prediction?.user?.handle}`}
                        className="flex user-prediction"
                      >
                        <div className="profile-block-avatar flex-column">
                          <UserAvatar
                            name={prediction?.user?.name}
                            level={prediction?.user?.level}
                            levelXp={prediction?.user?.levelXp}
                            photo={
                              prediction !== undefined
                                ? prediction?.user?.photo
                                : null
                            }
                          />
                        </div>
                        <div
                          className="flex flex-col justify-center"
                          style={{ gap: 4 }}
                        >
                          <div className="flex gap-2">
                            <span>{prediction?.user?.name}</span>
                            <span className="pointer">
                              {prediction?.user?.role === "tipster" ||
                              prediction?.user?.role === "admin" ? (
                                <MdVerified className="text-green-400" />
                              ) : null}
                            </span>
                          </div>
                          <div className="flex items-center justify-center">
                            <span
                              style={{ fontWeight: "600" }}
                              className={`text-sm uppercase ${
                                prediction?.user.profit === 0
                                  ? "gray-color"
                                  : prediction?.user.profit > 0
                                  ? "green-color"
                                  : "red-color"
                              } mr-1`}
                            >
                              {prediction?.user.profit > 0
                                ? "+" + prediction?.user.profit.toFixed(2)
                                : prediction?.user.profit.toFixed(2)}
                            </span>
                            <span className="text-sm text-gray-400 tipster-info__tips-count flex items-center">
                              {prediction?.user.predictions} Predictii
                            </span>
                          </div>
                        </div>
                      </Link>
                      <div className="flex items-center justify-center select-none">
                        <Moment
                          locale="ro"
                          className="text-xs text-gray-400"
                          fromNow
                        >
                          {prediction?.createdAt}
                        </Moment>
                      </div>
                    </div>
                    {prediction?.description !== "" ? (
                      <div
                        className="mt-4 support-textarea"
                        style={{ padding: "10px 30px 10px 30px" }}
                      >
                        <span className="text-xs text-gray-500">
                          <ShowMoreText
                            lines={2}
                            more="vezi mai mult"
                            less="vezi mai putin"
                            className="w-full content-css text-sm text-gray support-textarea"
                            anchorclassName="read-more-button text-blue-500 font-semibold"
                            expanded={false}
                            truncatedEndingComponent={"... "}
                          >
                            {prediction?.description}
                          </ShowMoreText>
                        </span>
                      </div>
                    ) : null}
                    <div
                      className="space-y-6 w-full header_popup_modal_body"
                      style={{ padding: "10px 30px 10px 30px", flex: 1 }}
                    >
                      <div className="flex flex-col items-center justify-center w-full">
                        {prediction?.totalEvenimente > 1 ? (
                          <>
                            <span className="text-sm text-slate-900 font-bold uppercase italic select-none">
                              Bilet {prediction?.totalEvenimente} evenimente
                              multiple
                            </span>
                            <div className="ticket-predictions mt-4 w-full">
                              {prediction?.evenimente?.map(
                                (eveniment, index) => (
                                  <Link
                                    to={`/${
                                      eveniment?.eveniment?.fixture?.id
                                    }/${formatTeamNames(
                                      eveniment?.eveniment?.teams?.home?.name,
                                      eveniment?.eveniment?.teams?.away?.name
                                    )}`}
                                    key={index}
                                    className={`flex flex-col w-full rounded-lg my-4 cursor-pointer  rounded-lg mt-2 first:mt-0 cursor-pointer overflow-hidden shadow-lg`}
                                  >
                                    <div className="flex w-full darker-bg rounded-t items-center justify-between p-2 overflow-hidden">
                                      <div
                                        className="text-white text-xs flex items-center justify-center"
                                        style={{ gap: "4px" }}
                                      >
                                        <BiFootball className="text-white text-sm" />
                                        <span className=" uppercase">
                                          {
                                            eveniment?.eveniment?.league
                                              ?.country
                                          }
                                        </span>
                                        <span className="match_info-league flex items-center justify-center">
                                          {eveniment?.eveniment?.league?.name}
                                        </span>
                                      </div>
                                      <div className="flex items-center justify-center">
                                        <Moment
                                          locale="ro"
                                          className="text-white text-xs uppercase mr-2"
                                          format="DD.MM.YYYY"
                                        >
                                          {eveniment?.eveniment?.fixture
                                            ?.timestamp * 1000}
                                        </Moment>
                                        {eveniment?.eveniment?.fixture?.status
                                          ?.long === "Not Started" ? (
                                          <Moment
                                            locale="ro"
                                            className="text-white text-xs uppercase"
                                            format="HH:mm"
                                          >
                                            {eveniment?.eveniment?.fixture
                                              ?.timestamp * 1000}
                                          </Moment>
                                        ) : eveniment?.eveniment?.fixture
                                            ?.status?.short === "FT" ? (
                                          <div className="text-white text-xs uppercase">
                                            <span>Final</span>
                                          </div>
                                        ) : eveniment?.eveniment?.fixture
                                            ?.status?.short === "HT" ? (
                                          <span className="text-red-500 text-xs uppercase font-bold">
                                            Pauza
                                          </span>
                                        ) : eveniment?.eveniment?.fixture
                                            ?.status?.short === "PST" ? (
                                          <span className="text-yellow-500 text-xs uppercase font-bold">
                                            Amanat
                                          </span>
                                        ) : eveniment?.eveniment?.fixture
                                            ?.status?.short === "ABD" ? (
                                          <span className="text-yellow-500 text-xs uppercase font-bold">
                                            Abandon
                                          </span>
                                        ) : eveniment?.eveniment?.fixture
                                            ?.status?.short === "P" ? (
                                          <span className="text-sky-500 text-xs uppercase font-bold">
                                            Penalty
                                          </span>
                                        ) : eveniment?.eveniment?.fixture
                                            ?.status?.short === "PEN" ? (
                                          <span className="text-white text-xs uppercase font-bold">
                                            Final (Penalty)
                                          </span>
                                        ) : (
                                          <span className="text-red-500 text-xs font-bold">
                                            LIVE{" "}
                                            {
                                              eveniment?.eveniment?.fixture
                                                ?.status?.elapsed
                                            }
                                            <b className="text-red-500 font-bold animate-ping">
                                              '
                                            </b>
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div
                                      className={`flex w-full p-2 items-center justify-between ${
                                        eveniment?.prediction?.status === 1
                                          ? "prediction-block-lost"
                                          : eveniment?.prediction?.status === 2
                                          ? "prediction-block-won"
                                          : eveniment?.prediction?.status === 3
                                          ? "prediction-block-void"
                                          : "prediction-block-ns"
                                      }`}
                                    >
                                      <div className="flex grow flex-1 w-full">
                                        <div className="flex items-center justify-center mr-2 w-14">
                                          <div
                                            className="border border-gray-300 dark:border-gray-900 mr-1 w-8 h-8 flex items-center justify-center relative rounded-full"
                                            style={{
                                              background: "#24262b",
                                            }}
                                          >
                                            <LazyLoadImage
                                              alt={
                                                eveniment?.eveniment.teams.home
                                                  .name
                                              }
                                              src={
                                                eveniment?.eveniment.teams.home
                                                  .logo
                                              } // use normal <img> attributes as props
                                              className="h-5"
                                            />
                                          </div>
                                          <div
                                            style={{
                                              marginLeft: "-10px",
                                              background: "#24262b",
                                            }}
                                            className="border border-gray-300 dark:border-gray-900 w-8 h-8 flex items-center justify-center relative rounded-full"
                                          >
                                            <LazyLoadImage
                                              alt={
                                                eveniment?.eveniment.teams.away
                                                  .name
                                              }
                                              src={
                                                eveniment?.eveniment.teams.away
                                                  .logo
                                              } // use normal <img> attributes as props
                                              className="h-5"
                                            />
                                          </div>
                                        </div>
                                        <div
                                          className="flex flex-col justify-center"
                                          style={{ gap: 4 }}
                                        >
                                          <div className="flex text-sm gray-color items-center">
                                            <span>
                                              {
                                                eveniment.eveniment.teams.home
                                                  .name
                                              }
                                            </span>
                                            <span
                                              className={`mx-1 font-bold ${
                                                eveniment?.eveniment.fixture
                                                  ?.status?.long ===
                                                  "Match Finished" ||
                                                eveniment?.eveniment.fixture
                                                  ?.status?.long ===
                                                  "Match Suspended" ||
                                                eveniment?.eveniment.fixture
                                                  ?.status?.long ===
                                                  "Match Abandoned"
                                                  ? "text-black"
                                                  : "orange-color"
                                              }`}
                                            >
                                              {eveniment?.eveniment?.fixture
                                                ?.status?.long ===
                                                "Match Abandoned" ||
                                              eveniment?.eveniment.fixture
                                                ?.status?.long ===
                                                "Match Suspended"
                                                ? ""
                                                : eveniment?.eveniment.goals
                                                    .home}
                                            </span>
                                            <span>-</span>
                                            <span
                                              className={`mx-1 font-bold ${
                                                eveniment?.eveniment.fixture
                                                  ?.status?.long ===
                                                  "Match Finished" ||
                                                eveniment?.eveniment.fixture
                                                  ?.status?.long ===
                                                  "Match Suspended" ||
                                                eveniment?.eveniment.fixture
                                                  ?.status?.long ===
                                                  "Match Abandoned"
                                                  ? "text-black"
                                                  : "orange-color"
                                              }`}
                                            >
                                              {eveniment?.eveniment?.fixture
                                                ?.status?.long ===
                                                "Match Abandoned" ||
                                              eveniment?.eveniment.fixture
                                                ?.status?.long ===
                                                "Match Suspended"
                                                ? ""
                                                : eveniment?.eveniment.goals
                                                    .away}
                                            </span>
                                            {eveniment?.eveniment?.fixture
                                              ?.status?.short === "PEN" ||
                                            eveniment?.eveniment?.fixture
                                              ?.status?.short === "P" ? (
                                              <div className="flex text-xs text-sky-500">
                                                <span
                                                  className={`mx-1 font-bold `}
                                                >
                                                  (
                                                  {
                                                    eveniment?.eveniment.score
                                                      .penalty.home
                                                  }
                                                </span>
                                                <span>-</span>
                                                <span
                                                  className={`mx-1 font-bold `}
                                                >
                                                  {
                                                    eveniment?.eveniment.score
                                                      .penalty.away
                                                  }
                                                  )
                                                </span>
                                              </div>
                                            ) : null}
                                            <span>
                                              {
                                                eveniment.eveniment.teams.away
                                                  .name
                                              }
                                            </span>
                                          </div>
                                          <div
                                            className="flex text-white"
                                            style={{ fontSize: "13px" }}
                                          >
                                            {eveniment.prediction.name +
                                              " - " +
                                              eveniment.prediction.value}
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className={`flex relative items-center shadow-sm flex-col justify-center w-14 h-11 m-0 px-0.5 rounded-lg text-center ${
                                          eveniment.prediction.status === 0
                                            ? "gray-bg-color"
                                            : eveniment.prediction.status === 2
                                            ? "green-bg-color"
                                            : eveniment.prediction.status === 1
                                            ? "red-bg-color"
                                            : eveniment.prediction.status === 3
                                            ? "bg-yellow-300"
                                            : null
                                        } text-white text-roboto`}
                                        style={{ fontSize: "13px" }}
                                      >
                                        {eveniment.prediction.odd.toFixed(2)}
                                        {eveniment.prediction.status ===
                                        0 ? null : eveniment.prediction
                                            .status === 2 ? (
                                          <span
                                            style={{
                                              fontSize: "8px",
                                              opacity: ".8",
                                              fontWeight: "400",
                                              textTransform: "uppercase",
                                              marginTop: 2,
                                            }}
                                          >
                                            CASTIGAT
                                          </span>
                                        ) : eveniment.prediction.status ===
                                          1 ? (
                                          <span
                                            style={{
                                              fontSize: "8px",
                                              opacity: ".8",
                                              fontWeight: "400",
                                              textTransform: "uppercase",
                                              marginTop: 2,
                                            }}
                                          >
                                            PIERDUT
                                          </span>
                                        ) : eveniment.prediction.status ===
                                          3 ? (
                                          <span
                                            style={{
                                              fontSize: "8px",
                                              opacity: ".8",
                                              fontWeight: "400",
                                              textTransform: "uppercase",
                                              marginTop: 2,
                                            }}
                                          >
                                            ANULAT
                                          </span>
                                        ) : null}
                                      </div>
                                    </div>
                                  </Link>
                                )
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            <span className="text-sm text-slate-900 font-bold uppercase italic select-none">
                              Eveniment single
                            </span>
                            <Link
                              to={`/${
                                prediction?.evenimente[0]?.eveniment?.fixture
                                  ?.id
                              }/${formatTeamNames(
                                prediction?.evenimente[0]?.eveniment?.teams
                                  ?.home?.name,
                                prediction?.evenimente[0]?.eveniment?.teams
                                  ?.away?.name
                              )}`}
                              className="ticket-predictions mt-4 w-full no-underline-link"
                            >
                              <div className="flex mt-2 w-full items-start justify-between">
                                <div className="flex flex-col justify-center w-full">
                                  <div className="team-prediction-logo flex justify-center w-full">
                                    <LazyLoadImage
                                      alt={
                                        prediction?.totalEvenimente > 0
                                          ? prediction?.evenimente[0].eveniment
                                              ?.teams?.home?.name
                                          : null
                                      }
                                      src={
                                        prediction?.totalEvenimente > 0
                                          ? prediction?.evenimente[0].eveniment
                                              ?.teams?.home?.logo
                                          : null
                                      }
                                      className="h-16"
                                    />
                                  </div>
                                  <span
                                    className="flex mt-2 items-center justify-center text-center text-white"
                                    style={{ fontSize: "13px" }}
                                  >
                                    {prediction?.totalEvenimente > 0
                                      ? prediction?.evenimente[0].eveniment
                                          ?.teams?.home?.name
                                      : null}
                                  </span>
                                </div>
                                <div className="flex flex-col w-full items-center justify-center">
                                  <span className="flex text-sm text-white justify-center w-full uppercase">
                                    {prediction?.totalEvenimente > 0 ? (
                                      prediction?.evenimente[0]?.eveniment
                                        ?.fixture?.status?.long ===
                                      "Not Started" ? (
                                        <div className="text-white text-xs flex flex-col items-center justify-center mb-4">
                                          <span className=" uppercase">
                                            {
                                              prediction?.evenimente[0]
                                                ?.eveniment?.league?.country
                                            }
                                          </span>
                                          <span className="flex items-center justify-center">
                                            {
                                              prediction?.evenimente[0]
                                                ?.eveniment?.league?.name
                                            }
                                          </span>
                                        </div>
                                      ) : prediction?.evenimente[0]?.eveniment
                                          ?.fixture?.status?.short === "FT" ? (
                                        "Final"
                                      ) : prediction?.evenimente[0]?.eveniment
                                          ?.fixture?.status?.long ===
                                        "Match Postponed" ? (
                                        "AMANAT"
                                      ) : prediction?.evenimente[0]?.eveniment
                                          ?.fixture?.status?.long ===
                                        "Match Suspended" ? (
                                        "Suspendat"
                                      ) : prediction?.evenimente[0]?.eveniment
                                          ?.fixture?.status?.long ===
                                        "Match Abandoned" ? (
                                        "Abandonat"
                                      ) : prediction?.evenimente[0]?.eveniment
                                          ?.fixture?.status?.short === "HT" ? (
                                        <span className="text-red-500 font-bold">
                                          Pauza
                                        </span>
                                      ) : prediction?.evenimente[0]?.eveniment
                                          ?.fixture?.status?.short === "P" ? (
                                        <span className="text-red-500 font-bold">
                                          Lovituri de penalty
                                        </span>
                                      ) : prediction?.evenimente[0]?.eveniment
                                          ?.fixture?.status?.short === "PEN" ? (
                                        <span className="text-red-500 font-bold">
                                          Final (Penalty)
                                        </span>
                                      ) : (
                                        <span
                                          className="text-red-500 font-bold"
                                          style={{ marginLeft: "2px" }}
                                        >
                                          {
                                            prediction?.evenimente[0]?.eveniment
                                              ?.fixture?.status?.elapsed
                                          }
                                          <b className="text-red-500 font-bold animate-ping">
                                            '
                                          </b>
                                        </span>
                                      )
                                    ) : null}
                                  </span>
                                  <span className="text-3xl">
                                    {prediction?.evenimente[0].eveniment
                                      ?.fixture?.status?.long ===
                                    "Not Started" ? (
                                      <div className="flex flex-col items-center justify-center">
                                        <Countdown
                                          className="text-3xl font-bold"
                                          date={
                                            prediction?.evenimente[0]?.eveniment
                                              ?.fixture?.timestamp * 1000
                                          }
                                        />

                                        <Moment
                                          locale="ro"
                                          className="gray-color font-bold text-xs uppercase"
                                          format="DD:MM:YYYY - HH:mm"
                                        >
                                          {prediction?.evenimente[0]?.eveniment
                                            ?.fixture?.timestamp * 1000}
                                        </Moment>
                                      </div>
                                    ) : prediction?.evenimente[0].eveniment
                                        ?.fixture?.status?.long ===
                                      "Match Postponed" ? (
                                      <div className="flex flex-col items-center justify-center text-base">
                                        <span>Meci amanat</span>
                                      </div>
                                    ) : prediction?.evenimente[0].eveniment
                                        ?.fixture?.status?.long ===
                                      "Match Abandoned" ? (
                                      <div className="flex flex-col items-center justify-center text-base">
                                        <span>Meci abandonat</span>
                                      </div>
                                    ) : prediction?.evenimente[0].eveniment
                                        ?.fixture?.status?.long ===
                                      "Match Suspended" ? (
                                      <div className="flex flex-col items-center justify-center text-base">
                                        <span>Meci suspendat</span>
                                      </div>
                                    ) : prediction?.evenimente[0].eveniment
                                        ?.fixture?.status?.long ===
                                      "Match Finished" ? (
                                      <div className="flex flex-col">
                                        <div className="text-black font-bold text-4xl font-roboto-mono flex items-center justify-center">
                                          <span>
                                            {prediction?.totalEvenimente > 0
                                              ? prediction?.evenimente[0]
                                                  ?.eveniment?.goals?.home ===
                                                null
                                                ? "-"
                                                : prediction?.evenimente[0]
                                                    ?.eveniment?.goals?.home
                                              : null}
                                          </span>
                                          <BsDash />
                                          <span>
                                            {prediction?.totalEvenimente > 0
                                              ? prediction?.evenimente[0]
                                                  ?.eveniment?.goals?.away ===
                                                null
                                                ? "-"
                                                : prediction?.evenimente[0]
                                                    ?.eveniment?.goals?.away
                                              : null}
                                          </span>
                                        </div>
                                        <div className="text-sm py-1 text-white flex items-center justify-center">
                                          (
                                          {prediction?.evenimente[0]?.eveniment
                                            ?.score?.halftime?.home +
                                            " - " +
                                            prediction?.evenimente[0]?.eveniment
                                              ?.score?.halftime?.away}
                                          )
                                        </div>
                                        <div className="flex">
                                          <Moment
                                            locale="ro"
                                            className="text-slate-500 text-xs uppercase"
                                            format="DD:MM:YYYY - HH:mm"
                                          >
                                            {prediction?.evenimente[0]
                                              ?.eveniment?.fixture?.timestamp *
                                              1000}
                                          </Moment>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="orange-color font-bold text-4xl font-roboto-mono flex items-center justify-center">
                                        <span>
                                          {prediction?.totalEvenimente > 0
                                            ? prediction?.evenimente[0]
                                                ?.eveniment?.goals?.home ===
                                              null
                                              ? "-"
                                              : prediction?.evenimente[0]
                                                  ?.eveniment?.goals?.home
                                            : null}
                                        </span>
                                        <BsDash />
                                        <span>
                                          {prediction?.totalEvenimente > 0
                                            ? prediction?.evenimente[0]
                                                ?.eveniment?.goals?.away ===
                                              null
                                              ? "-"
                                              : prediction?.evenimente[0]
                                                  ?.eveniment?.goals?.away
                                            : null}
                                        </span>
                                      </div>
                                    )}
                                  </span>
                                </div>
                                <div className="flex flex-col justify-center w-full">
                                  <div className="team-prediction-logo flex justify-center w-full">
                                    <LazyLoadImage
                                      alt={
                                        prediction?.totalEvenimente > 0
                                          ? prediction?.evenimente[0].eveniment
                                              ?.teams?.away?.name
                                          : null
                                      }
                                      src={
                                        prediction?.totalEvenimente > 0
                                          ? prediction?.evenimente[0].eveniment
                                              ?.teams?.away?.logo
                                          : null
                                      }
                                      className="h-16"
                                    />
                                  </div>
                                  <span
                                    className="flex mt-2 items-center justify-center text-center text-white"
                                    style={{ fontSize: "13px" }}
                                  >
                                    {prediction?.totalEvenimente > 0
                                      ? prediction?.evenimente[0].eveniment
                                          ?.teams?.away?.name
                                      : null}
                                  </span>
                                </div>
                              </div>
                              <div className="w-full tip-expanded__heading tip-expanded__heading--dashed before:border before:border-dashed before:border-slate-400 before:dark:border-gray-500">
                                <span className=" italic font-bold popup_modal_prediction">
                                  {prediction?.totalEvenimente > 0
                                    ? prediction?.evenimente[0]?.prediction.name
                                    : null}{" "}
                                  -{" "}
                                  {prediction?.totalEvenimente > 0
                                    ? prediction?.evenimente[0]?.prediction
                                        .value
                                    : null}
                                </span>
                              </div>
                            </Link>
                          </>
                        )}
                        <div
                          className="w-full flex mt-4 pb-4 px-4 items-center justify-center"
                          id="scroll-target"
                        >
                          <div
                            className="flex flex-col items-center"
                            style={{
                              flex: "1 0 82px",
                              width: "82px",
                              gap: "4px",
                            }}
                          >
                            <div className="text-center mb-1 uppercase leading-none text-xs select-none text-slate-900">
                              Total cota
                            </div>
                            <div className="flex flex-col bg-white shadow-lg flex items-center justify-center w-full h-9 px-2.5 text-sm leading-tight rounded-lg">
                              <span
                                className="text-roboto gray2nd-color font-bold"
                                style={{ fontSize: "13px" }}
                              >
                                {prediction?.totalOdd?.toFixed(2)}
                              </span>
                            </div>
                          </div>
                          <div
                            className="mx-4 relative top-2.5 flex items-center justify-center"
                            style={{ flex: "0 0 auto" }}
                          >
                            <FaTimes className="text-gray-500" />
                          </div>
                          <div
                            className="flex flex-col items-center"
                            style={{
                              flex: "1 0 82px",
                              width: "82px",
                              gap: "4px",
                            }}
                          >
                            <div className="text-center mb-1 uppercase leading-none text-xs select-none text-slate-900">
                              Miza (1-10)
                            </div>
                            <div className="flex flex-col bg-white shadow-lg flex items-center justify-center w-full h-9 px-2.5 text-sm leading-tight rounded-lg">
                              <span
                                className="text-roboto gray2nd-color font-bold"
                                style={{ fontSize: "13px" }}
                              >
                                {prediction?.stake}
                              </span>
                            </div>
                          </div>
                          <div
                            className="mx-4 relative top-2.5 flex items-center justify-center"
                            style={{ flex: "0 0 auto" }}
                          >
                            <FaEquals className="text-gray-500" />
                          </div>
                          {prediction?.status === 2 ? (
                            <div
                              className="flex flex-col items-center"
                              style={{
                                flex: "1 0 82px",
                                width: "82px",
                                gap: "4px",
                              }}
                            >
                              <div className="text-center mb-1 uppercase leading-none text-xs select-none text-slate-900">
                                Profit
                              </div>
                              <div className="flex flex-col green-bg-color text-white shadow-lg flex items-center justify-center w-full h-9 px-2.5 text-sm leading-tight rounded-lg">
                                <span
                                  className="text-roboto font-bold"
                                  style={{ fontSize: "13px" }}
                                >
                                  {(
                                    prediction?.totalOdd * prediction?.stake -
                                    prediction?.stake
                                  ).toFixed(2)}
                                </span>
                                <span
                                  style={{
                                    fontSize: "8px",
                                    opacity: ".8",
                                    fontWeight: "400",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  CASTIGAT
                                </span>
                              </div>
                            </div>
                          ) : prediction?.status === 1 ? (
                            <div
                              className="flex flex-col items-center"
                              style={{
                                flex: "1 0 82px",
                                width: "82px",
                                gap: "4px",
                              }}
                            >
                              <div className="text-center mb-1 uppercase leading-none text-xs">
                                Profit
                              </div>
                              <div className="flex flex-col red-bg-color text-white shadow-lg flex items-center justify-center w-full h-9 px-2.5 text-sm leading-tight rounded-lg">
                                <span
                                  className="text-roboto font-bold"
                                  style={{ fontSize: "13px" }}
                                >
                                  -{prediction?.stake}
                                </span>
                                <span
                                  style={{
                                    fontSize: "8px",
                                    opacity: ".8",
                                    fontWeight: "400",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  PIERDUT
                                </span>
                              </div>
                            </div>
                          ) : prediction?.status === 3 ? (
                            <div
                              className="flex flex-col items-center"
                              style={{
                                flex: "1 0 82px",
                                width: "82px",
                                gap: "4px",
                              }}
                            >
                              <div className="text-center mb-1 uppercase leading-none text-xs">
                                Profit
                              </div>
                              <div className="flex flex-col bg-yellow-300 text-white shadow-lg flex items-center justify-center w-full h-9 px-2.5 text-sm leading-tight rounded-lg">
                                <span
                                  className="text-roboto font-bold"
                                  style={{ fontSize: "13px" }}
                                >
                                  -
                                </span>
                                <span
                                  style={{
                                    fontSize: "8px",
                                    opacity: ".8",
                                    fontWeight: "400",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  ANULAT
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="flex flex-col items-center"
                              style={{
                                flex: "1 0 82px",
                                width: "82px",
                                gap: "4px",
                              }}
                            >
                              <div className="text-center mb-1 uppercase leading-none text-xs">
                                Potential
                              </div>
                              <div className="flex flex-col bg-white gray2nd-color  shadow-lg flex items-center justify-center w-full h-9 px-2.5 text-sm leading-tight rounded-lg">
                                <span
                                  className="text-roboto font-bold"
                                  style={{ fontSize: "13px" }}
                                >
                                  {(
                                    prediction?.totalOdd * prediction?.stake
                                  ).toFixed(2)}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className="flex flex-col mb-4 w-full items-center justify-center"
                      style={{ padding: "10px 30px 10px 30px" }}
                    >
                      <span className="py-4 text-sm text-slate-900 font-bold uppercase italic select-none">
                        Esti de acord cu aceasta predictie?
                      </span>
                      <div
                        className={`vote-reactions__actions w-full ${
                          prediction?.likes !== 0 || prediction?.dislikes !== 0
                            ? " is-voted"
                            : ""
                        }`}
                      >
                        <button
                          onClick={() =>
                            handleLikePrediction(user?._id, prediction._id)
                          }
                          className={`vote-reactions__btn vote-reactions__btn--left ${
                            prediction && prediction.likedBy
                              ? prediction.likedBy.findIndex(
                                  (x) =>
                                    x._id.toString() === user?._id.toString()
                                ) !== -1
                                ? "active"
                                : ""
                              : ""
                          }`}
                          style={{
                            width: `${likePercentage}%`,
                          }}
                        >
                          <i className=" u-circle">
                            <BsFillHandThumbsUpFill className="text-xl u-circle" />
                          </i>
                          <span>
                            {prediction?.likes === 0 &&
                            prediction?.dislikes !== 0
                              ? ""
                              : "Da"}
                          </span>
                        </button>
                        <button
                          onClick={() =>
                            handleDislikePrediction(user?._id, prediction._id)
                          }
                          className={`vote-reactions__btn vote-reactions__btn--right ${
                            prediction && prediction.dislikedBy
                              ? prediction?.dislikedBy.findIndex(
                                  (x) =>
                                    x._id.toString() === user?._id.toString()
                                ) !== -1
                                ? "active"
                                : ""
                              : ""
                          }`}
                          style={{
                            width: `${dislikePercentage}%`,
                          }}
                        >
                          <span>
                            {prediction?.dislikes === 0 &&
                            prediction?.likes !== 0
                              ? ""
                              : "Nu"}
                          </span>
                          <i className=" u-circle">
                            <BsFillHandThumbsDownFill className="text-xl" />
                          </i>
                        </button>
                      </div>
                      {prediction?.likes !== 0 || prediction?.dislikes !== 0 ? (
                        <div className="vote-reactions__stats w-full px-2">
                          <div className="vote-reactions__stats__col">
                            <div className="user-avatars-stack">
                              {prediction && prediction.likedBy
                                ? prediction?.likedBy
                                    .slice(0, 3)
                                    .map((user, i) => (
                                      <Link
                                        key={i}
                                        to={`/p/${user?.handle}`}
                                        title={user?.name}
                                        className="user-avatar hover:z-10 cursor-pointer bg-gray-200   dark:bg-gray-900 dark:border-gray-900"
                                      >
                                        <Tooltip
                                          className={`bg-bgMain w-max `}
                                          content={user.name}
                                        >
                                          <img
                                            key={user._id}
                                            src={user.photo}
                                            alt={`${user.name}'s photo`}
                                            className="w-6 h-6 rounded-full avatar-abbreviation"
                                          />
                                        </Tooltip>
                                      </Link>
                                    ))
                                : ""}
                            </div>
                            <span className="text-xs">
                              <b className="mr-1">{prediction?.likes}</b>
                              {prediction && prediction.likes
                                ? prediction?.likes === 1
                                  ? "apreciere"
                                  : "aprecieri"
                                : "aprecieri"}

                              {prediction && prediction.likedBy ? (
                                prediction?.likedBy.findIndex(
                                  (x) =>
                                    x._id.toString() === user?._id.toString()
                                ) !== -1 ? (
                                  <span className="ml-2 orange-color uppercase">
                                    (Alegerea mea)
                                  </span>
                                ) : null
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                          <div className="vote-reactions__stats__col">
                            <span className="text-xs">
                              {prediction && prediction.dislikedBy ? (
                                prediction?.dislikedBy.findIndex(
                                  (x) =>
                                    x._id.toString() === user?._id.toString()
                                ) !== -1 ? (
                                  <span className="mr-2 orange-color uppercase">
                                    (Alegerea mea)
                                  </span>
                                ) : null
                              ) : (
                                ""
                              )}
                              {prediction?.dislikes === 0 ? null : (
                                <>
                                  <b className="mr-1">{prediction?.dislikes}</b>
                                </>
                              )}
                            </span>
                            <div className="user-avatars-stack">
                              {prediction && prediction.dislikedBy
                                ? prediction?.dislikedBy
                                    .slice(0, 3)
                                    .map((user, i) => (
                                      <Link
                                        key={i}
                                        to={`/p/${user?.handle}`}
                                        title={user?.name}
                                        className="user-avatar hover:z-10 cursor-pointer bg-gray-200   dark:bg-gray-900 dark:border-gray-900"
                                      >
                                        <Tooltip
                                          className={`bg-bgMain w-max `}
                                          content={user.name}
                                        >
                                          <img
                                            key={user._id}
                                            src={user.photo}
                                            alt={`${user.name}'s photo`}
                                            className="w-6 h-6 rounded-full avatar-abbreviation"
                                          />
                                        </Tooltip>
                                      </Link>
                                    ))
                                : ""}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="flex items-center text-slate-500 mt-2 justify-center w-full h-6 px-4 select-none"
                          style={{ fontSize: "12px" }}
                        >
                          Fi primul care voteaza!
                        </div>
                      )}
                    </div>
                    <div
                      className="tip-expanded__comments rounded-b bg-slate-100 darker-bg"
                      style={{ padding: "10px 30px 10px 30px" }}
                    >
                      <div className="comments-wrapper w-full">
                        <div className="comments-form bg-slate-100">
                          <form
                            className="comments-form__form"
                            onSubmit={handleSubmit}
                          >
                            <div className="form-textarea form-textarea--resizable">
                              <textarea
                                ref={textareaRef}
                                disabled={isLoadingActions || !isLoggedIn}
                                value={comment}
                                id="textarea-disc"
                                onChange={handleAddComment}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    handleSubmit(e);
                                  }
                                }}
                                rows="1"
                                placeholder={`${
                                  isLoggedIn
                                    ? "Scrie un comentariu si apasa enter"
                                    : "Autentifica-te pentru a lasa un comentariu"
                                }`}
                                className="bg-slate-100 border-none focus:border-none focus:ring-0 border-none text-gray-900 text-sm w-full placeholder:text-xs dark:bg-bgMain2 dark:placeholder-gray-400 dark:text-white mb-0 hover:ring-white"
                              ></textarea>
                            </div>
                          </form>
                        </div>
                        <div className="comments relative">
                          {prediction?.comments === 0 ? (
                            <div
                              className="comments__empty text-slate-500 select-none"
                              style={{ fontSize: "12px" }}
                            >
                              Fi primul care comenteaza la predictie!
                            </div>
                          ) : (
                            <>
                              <div className="comments-item-wrapper p-4">
                                {reversedComments
                                  ?.slice(0, repliesShown)
                                  .map((c, index) => (
                                    <Comment
                                      key={index}
                                      user={user}
                                      c={c}
                                      handleDeleteComment={handleDeleteComment}
                                      eveniment={prediction}
                                      editCommentPrediction={
                                        editCommentPrediction
                                      }
                                      isLoggedIn={isLoggedIn}
                                      addReplyToComment={addReplyToComment}
                                      isLoadingActions={isLoadingActions}
                                      deleteReplyCommentPrediction={
                                        deleteReplyCommentPrediction
                                      }
                                      editReplyCommentPrediction={
                                        editReplyCommentPrediction
                                      }
                                      setRepliesShown={setRepliesShown}
                                      repliesShown={repliesShown}
                                      likeCommentPrediction={
                                        likeCommentPrediction
                                      }
                                    />
                                  ))}
                              </div>
                              <div className="flex items-center justify-center p-4">
                                {prediction?.comments > 3 ? (
                                  <button
                                    onClick={handleShowMoreComments}
                                    className="button button_small"
                                  >
                                    {repliesShown < prediction?.comments
                                      ? "Arata toate comentariile"
                                      : "Ascunde toate comentariile"}
                                  </button>
                                ) : null}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;

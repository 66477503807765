import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import predictionsService from "./predictionsService";
import { toast } from "react-toastify";

const initialState = {
  prediction: null,
  predictionStored: null,
  predictions: [],
  predictionsNotStarted: [],
  predictionsByEvent: [],
  predictionsByUser: [],
  predictionProfit10: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  isLoadingPrediction: false,
  isLoadingAllPredictions: false,
  isLoadingPredictionProfit10: false,
  totalPredictions: null,
  inDesfasurare: null,
  isLoadingActions: false,
  isSuccessActions: false,
  isErrorActions: false,
  message: "",
};

// Add match
export const addPrediction = createAsyncThunk(
  "predictions/prediction",
  async (predictionData, thunkAPI) => {
    try {
      return await predictionsService.addPrediction(predictionData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get all predictions
export const getAllPredictions = createAsyncThunk(
  "predictions/allpredictions",
  async (params, thunkAPI) => {
    const {
      totalResults,
      predictionStatus,
      searchPrediction,
      onlyFollowedUsers,
      userId,
    } = params;
    try {
      return await predictionsService.getAllPredictions({
        totalResults,
        predictionStatus,
        searchPrediction,
        onlyFollowedUsers,
        userId,
      });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get all predictions by event
export const getAllPredictionsSortByEveniment = createAsyncThunk(
  "predictions/allpredictionssortbyeveniment",
  async (params, thunkAPI) => {
    const { date } = params;
    try {
      return await predictionsService.getAllPredictionsSortByEveniment({
        date,
      });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get prediction by id
export const getPredictionById = createAsyncThunk(
  "predictions/predictionbyid",
  async (predictionData, thunkAPI) => {
    try {
      return await predictionsService.getPredictionById(predictionData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get prediction profit > 10
export const getPredictionProfit10 = createAsyncThunk(
  "predictions/predictionprofit10",
  async (_, thunkAPI) => {
    try {
      return await predictionsService.getPredictionProfit10();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get and store prediction by id
export const getAndStorePredictionById = createAsyncThunk(
  "predictions/getAndStorePredictionById",
  async (predictionData, thunkAPI) => {
    try {
      return await predictionsService.getAndStorePredictionById(predictionData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get all predictions from eveniments
export const getAllPredictionsFromEveniment = createAsyncThunk(
  "predictions/getallpredictionsforfixture",
  async (evenimentData, thunkAPI) => {
    try {
      return await predictionsService.getAllPredictionsFromEveniment(
        evenimentData
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update prediction
export const updatePrediction = createAsyncThunk(
  "predictions/updateprediction",
  async (matchData, thunkAPI) => {
    try {
      return await predictionsService.updatePrediction(matchData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get eveniment from prediction
export const getEvenimentFromPrediction = createAsyncThunk(
  "predictions/evenimentbyindex",
  async (evenimentData, thunkAPI) => {
    try {
      return await predictionsService.getEvenimentFromPrediction(evenimentData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// like prediction
export const likePrediction = createAsyncThunk(
  "predictions/like",
  async (likeData, thunkAPI) => {
    try {
      return await predictionsService.likePrediction(likeData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// dislike prediction
export const dislikePrediction = createAsyncThunk(
  "predictions/dislike",
  async (dislikeData, thunkAPI) => {
    try {
      return await predictionsService.dislikePrediction(dislikeData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// post comment prediction
export const postCommentPrediction = createAsyncThunk(
  "predictions/comment",
  async (commentData, thunkAPI) => {
    try {
      return await predictionsService.postCommentPrediction(commentData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// delete comment prediction
export const deleteCommentPrediction = createAsyncThunk(
  "predictions/deleteComment",
  async (commentData, thunkAPI) => {
    try {
      return await predictionsService.deleteCommentPrediction(commentData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// edit comment prediction
export const editCommentPrediction = createAsyncThunk(
  "predictions/editComment",
  async (commentData, thunkAPI) => {
    try {
      return await predictionsService.editCommentPrediction(commentData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// add reply to comment
export const addReplyToComment = createAsyncThunk(
  "predictions/comment/reply",
  async (replyData, thunkAPI) => {
    try {
      return await predictionsService.addReplyToComment(replyData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// delete reply from comment
export const deleteReplyCommentPrediction = createAsyncThunk(
  "predictions/deleteReply",
  async (replyData, thunkAPI) => {
    try {
      return await predictionsService.deleteReplyCommentPrediction(replyData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// edit comment prediction
export const editReplyCommentPrediction = createAsyncThunk(
  "predictions/editReply",
  async (editData, thunkAPI) => {
    try {
      return await predictionsService.editReplyCommentPrediction(editData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// like comment prediction
export const likeCommentPrediction = createAsyncThunk(
  "predictions/comment/like",
  async (editData, thunkAPI) => {
    try {
      return await predictionsService.likeCommentPrediction(editData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get all predictions for user
export const getAllPredictionsForUser = createAsyncThunk(
  "predictions/allpredictionsbyuser",
  async (predictionsData, thunkAPI) => {
    try {
      return await predictionsService.getAllPredictionsForUser(predictionsData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

const predictionsSlice = createSlice({
  name: "predictions",
  initialState,
  reducers: {
    RESET(state) {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
      state.resultsStatus0 = null;
      state.resultsStatus1 = null;
      state.resultsStatus2 = null;
      state.resultsStatus3 = null;
      state.isLoadingActions = false;
      state.isSuccessActions = false;
      state.isErrorActions = false;
    },
    RESETPREDICTIONS(state) {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
      state.predictions = [];
      state.predictionsNotStarted = [];
      state.totalPredictionsAnyStatus = null;
      state.totalPredictions = null;
      state.resultsStatus0 = null;
      state.resultsStatus1 = null;
      state.resultsStatus2 = null;
      state.resultsStatus3 = null;
      state.isLoadingActions = false;
      state.isSuccessActions = false;
      state.isErrorActions = false;
    },
    RESETPREDICTIONSBYUSER(state) {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
      state.predictionsByUser = [];
      state.predictionsNotStarted = [];
      state.totalPredictionsAnyStatus = null;
      state.totalPredictions = null;
      state.resultsStatus0 = null;
      state.resultsStatus1 = null;
      state.resultsStatus2 = null;
      state.resultsStatus3 = null;
      state.isLoadingActions = false;
      state.isSuccessActions = false;
      state.isErrorActions = false;
    },
    RESETPREDICTION(state) {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
      state.prediction = null;
      state.isLoadingActions = false;
      state.isSuccessActions = false;
      state.isErrorActions = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // Add match
      .addCase(addPrediction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addPrediction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.predictions.unshift(action.payload);
        state.message = action.payload;
        toast.success(action.payload);
      })
      .addCase(addPrediction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      // Get all predictions
      .addCase(getAllPredictions.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllPredictions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.predictions = action.payload.predictionsFinal;
        state.totalPredictions = action.payload.noTotalPredictions;
        state.inDesfasurare = action.payload.inDesfasurareFinal;
      })
      .addCase(getAllPredictions.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      // Get all predictions by event
      .addCase(getAllPredictionsSortByEveniment.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllPredictionsSortByEveniment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.predictionsByEvent = action.payload.predictionsByEvent;
      })
      .addCase(getAllPredictionsSortByEveniment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      // Get all matches
      .addCase(getEvenimentFromPrediction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getEvenimentFromPrediction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.prediction = action.payload.evenimentFinal;
      })
      .addCase(getEvenimentFromPrediction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      // Get prediction by id
      .addCase(getPredictionById.pending, (state, action) => {
        state.isLoadingPrediction = true;
      })
      .addCase(getPredictionById.fulfilled, (state, action) => {
        state.isLoadingPrediction = false;
        state.isSuccess = true;
        state.prediction = action.payload;
      })
      .addCase(getPredictionById.rejected, (state, action) => {
        state.isLoadingPrediction = false;
        state.isError = true;
        toast.error(action.payload);
      })
      // Get prediction profit > 10
      .addCase(getPredictionProfit10.pending, (state, action) => {
        state.isLoadingPredictionProfit10 = true;
      })
      .addCase(getPredictionProfit10.fulfilled, (state, action) => {
        state.isLoadingPredictionProfit10 = false;
        state.isSuccess = true;
        state.predictionProfit10 = action.payload;
      })
      .addCase(getPredictionProfit10.rejected, (state, action) => {
        state.isLoadingPredictionProfit10 = false;
        state.isError = true;
        toast.error(action.payload);
      })
      // Get and store prediction by id
      .addCase(getAndStorePredictionById.pending, (state, action) => {
        state.isLoadingPrediction = true;
      })
      .addCase(getAndStorePredictionById.fulfilled, (state, action) => {
        state.isLoadingPrediction = false;
        state.isSuccess = true;
        state.predictionStored = action.payload;
      })
      .addCase(getAndStorePredictionById.rejected, (state, action) => {
        state.isLoadingPrediction = false;
        state.isError = true;
        toast.error(action.payload);
      })
      // Update prediction
      .addCase(updatePrediction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updatePrediction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(updatePrediction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      // Update prediction
      .addCase(getAllPredictionsFromEveniment.pending, (state, action) => {
        state.isLoadingAllPredictions = true;
      })
      .addCase(getAllPredictionsFromEveniment.fulfilled, (state, action) => {
        state.isLoadingAllPredictions = false;
        state.isSuccess = true;
        state.predictions = action.payload.predictions;
      })
      .addCase(getAllPredictionsFromEveniment.rejected, (state, action) => {
        state.isLoadingAllPredictions = false;
        state.isError = true;
        toast.error(action.payload);
      })
      // like prediction
      .addCase(likePrediction.pending, (state, action) => {
        state.isLoadingActions = true;
      })
      .addCase(likePrediction.fulfilled, (state, action) => {
        state.isLoadingActions = false;
        state.isSuccessActions = true;
        const updatedPredictions = state.predictions.map((prediction) =>
          prediction._id === action.payload.data._id
            ? action.payload.data
            : prediction
        );
        state.predictions = updatedPredictions;
        if (state.prediction) {
          const updatedPrediction =
            state.prediction._id === action.payload.data._id
              ? action.payload.data
              : null;
          state.prediction = updatedPrediction;
        }
        if (state.predictionsByUser) {
          const updatedPredictions = state.predictionsByUser.map((prediction) =>
            prediction._id === action.payload.data._id
              ? action.payload.data
              : prediction
          );
          state.predictionsByUser = updatedPredictions;
        }
        if (state.predictionStored) {
          const updatedPredictionStored =
            state.predictionStored._id === action.payload.data._id
              ? action.payload.data
              : state.predictionStored;
          state.predictionStored = updatedPredictionStored;
        }
      })
      .addCase(likePrediction.rejected, (state, action) => {
        state.isLoadingActions = false;
        state.isErrorActions = true;
        toast.error(action.payload);
      })
      // dislike prediction
      .addCase(dislikePrediction.pending, (state, action) => {
        state.isLoadingActions = true;
      })
      .addCase(dislikePrediction.fulfilled, (state, action) => {
        state.isLoadingActions = false;
        state.isSuccessActions = true;
        const updatedPredictions = state.predictions.map((prediction) =>
          prediction._id === action.payload.data._id
            ? action.payload.data
            : prediction
        );
        state.predictions = updatedPredictions;
        if (state.prediction) {
          const updatedPrediction =
            state.prediction._id === action.payload.data._id
              ? action.payload.data
              : null;
          state.prediction = updatedPrediction;
        }
        if (state.predictionsByUser) {
          const updatedPredictions = state.predictionsByUser.map((prediction) =>
            prediction._id === action.payload.data._id
              ? action.payload.data
              : prediction
          );
          state.predictionsByUser = updatedPredictions;
        }
        if (state.predictionStored) {
          const updatedPredictionStored =
            state.predictionStored._id === action.payload.data._id
              ? action.payload.data
              : state.predictionStored;
          state.predictionStored = updatedPredictionStored;
        }
      })
      .addCase(dislikePrediction.rejected, (state, action) => {
        state.isLoadingActions = false;
        state.isErrorActions = true;
        toast.error(action.payload);
      })
      // post comment prediction
      .addCase(postCommentPrediction.pending, (state, action) => {
        state.isLoadingActions = true;
      })
      .addCase(postCommentPrediction.fulfilled, (state, action) => {
        state.isLoadingActions = false;
        state.isSuccessActions = true;
        const updatedPredictions = state.predictions.map((prediction) =>
          prediction._id === action.payload.data._id
            ? action.payload.data
            : prediction
        );
        state.predictions = updatedPredictions;
        if (state.prediction) {
          const updatedPrediction =
            state.prediction._id === action.payload.data._id
              ? action.payload.data
              : null;
          state.prediction = updatedPrediction;
        }
        if (state.predictionsByUser) {
          const updatedPredictions = state.predictionsByUser.map((prediction) =>
            prediction._id === action.payload.data._id
              ? action.payload.data
              : prediction
          );
          state.predictionsByUser = updatedPredictions;
        }
        if (state.predictionStored) {
          const updatedPredictionStored =
            state.predictionStored._id === action.payload.data._id
              ? action.payload.data
              : state.predictionStored;
          state.predictionStored = updatedPredictionStored;
        }
      })
      .addCase(postCommentPrediction.rejected, (state, action) => {
        state.isLoadingActions = false;
        state.isErrorActions = true;
        toast.error(action.payload);
      })
      // delete comment prediction
      .addCase(deleteCommentPrediction.pending, (state, action) => {
        state.isLoadingActions = true;
      })
      .addCase(deleteCommentPrediction.fulfilled, (state, action) => {
        state.isLoadingActions = false;
        state.isSuccessActions = true;
        const updatedPredictions = state.predictions.map((prediction) =>
          prediction._id === action.payload.data._id
            ? action.payload.data
            : prediction
        );
        state.predictions = updatedPredictions;
        if (state.prediction) {
          const updatedPrediction =
            state.prediction._id === action.payload.data._id
              ? action.payload.data
              : null;
          state.prediction = updatedPrediction;
        }
        if (state.predictionsByUser) {
          const updatedPredictions = state.predictionsByUser.map((prediction) =>
            prediction._id === action.payload.data._id
              ? action.payload.data
              : prediction
          );
          state.predictionsByUser = updatedPredictions;
        }
        if (state.predictionStored) {
          const updatedPredictionStored =
            state.predictionStored._id === action.payload.data._id
              ? action.payload.data
              : state.predictionStored;
          state.predictionStored = updatedPredictionStored;
        }
      })
      .addCase(deleteCommentPrediction.rejected, (state, action) => {
        state.isLoadingActions = false;
        state.isErrorActions = true;
        toast.error(action.payload);
      })
      // edit comment prediction
      .addCase(editCommentPrediction.pending, (state, action) => {
        state.isLoadingActions = true;
      })
      .addCase(editCommentPrediction.fulfilled, (state, action) => {
        state.isLoadingActions = false;
        state.isSuccessActions = true;
        const updatedPredictions = state.predictions.map((prediction) =>
          prediction._id === action.payload.data._id
            ? action.payload.data
            : prediction
        );
        state.predictions = updatedPredictions;
        if (state.prediction) {
          const updatedPrediction =
            state.prediction._id === action.payload.data._id
              ? action.payload.data
              : null;
          state.prediction = updatedPrediction;
        }
        if (state.predictionsByUser) {
          const updatedPredictions = state.predictionsByUser.map((prediction) =>
            prediction._id === action.payload.data._id
              ? action.payload.data
              : prediction
          );
          state.predictionsByUser = updatedPredictions;
        }
        if (state.predictionStored) {
          const updatedPredictionStored =
            state.predictionStored._id === action.payload.data._id
              ? action.payload.data
              : state.predictionStored;
          state.predictionStored = updatedPredictionStored;
        }
      })
      .addCase(editCommentPrediction.rejected, (state, action) => {
        state.isLoadingActions = false;
        state.isErrorActions = true;
        toast.error(action.payload);
      })
      // post comment prediction
      .addCase(addReplyToComment.pending, (state, action) => {
        state.isLoadingActions = true;
      })
      .addCase(addReplyToComment.fulfilled, (state, action) => {
        state.isLoadingActions = false;
        state.isSuccessActions = true;
        const updatedPredictions = state.predictions.map((prediction) =>
          prediction._id === action.payload.data._id
            ? action.payload.data
            : prediction
        );
        state.predictions = updatedPredictions;
        if (state.prediction) {
          const updatedPrediction =
            state.prediction._id === action.payload.data._id
              ? action.payload.data
              : null;
          state.prediction = updatedPrediction;
        }
        if (state.predictionsByUser) {
          const updatedPredictions = state.predictionsByUser.map((prediction) =>
            prediction._id === action.payload.data._id
              ? action.payload.data
              : prediction
          );
          state.predictionsByUser = updatedPredictions;
        }
        if (state.predictionStored) {
          const updatedPredictionStored =
            state.predictionStored._id === action.payload.data._id
              ? action.payload.data
              : state.predictionStored;
          state.predictionStored = updatedPredictionStored;
        }
      })
      .addCase(addReplyToComment.rejected, (state, action) => {
        state.isLoadingActions = false;
        state.isErrorActions = true;
        toast.error(action.payload);
      })
      // delete comment prediction
      .addCase(deleteReplyCommentPrediction.pending, (state, action) => {
        state.isLoadingActions = true;
      })
      .addCase(deleteReplyCommentPrediction.fulfilled, (state, action) => {
        state.isLoadingActions = false;
        state.isSuccessActions = true;
        const updatedPredictions = state.predictions.map((prediction) =>
          prediction._id === action.payload.data._id
            ? action.payload.data
            : prediction
        );
        state.predictions = updatedPredictions;
        if (state.prediction) {
          const updatedPrediction =
            state.prediction._id === action.payload.data._id
              ? action.payload.data
              : null;
          state.prediction = updatedPrediction;
        }
        if (state.predictionsByUser) {
          const updatedPredictions = state.predictionsByUser.map((prediction) =>
            prediction._id === action.payload.data._id
              ? action.payload.data
              : prediction
          );
          state.predictionsByUser = updatedPredictions;
        }
        if (state.predictionStored) {
          const updatedPredictionStored =
            state.predictionStored._id === action.payload.data._id
              ? action.payload.data
              : state.predictionStored;
          state.predictionStored = updatedPredictionStored;
        }
      })
      .addCase(deleteReplyCommentPrediction.rejected, (state, action) => {
        state.isLoadingActions = false;
        state.isErrorActions = true;
        toast.error(action.payload);
      })
      // edit reply to comment prediction
      .addCase(editReplyCommentPrediction.pending, (state, action) => {
        state.isLoadingActions = true;
      })
      .addCase(editReplyCommentPrediction.fulfilled, (state, action) => {
        state.isLoadingActions = false;
        state.isSuccessActions = true;
        const updatedPredictions = state.predictions.map((prediction) =>
          prediction._id === action.payload.data._id
            ? action.payload.data
            : prediction
        );
        state.predictions = updatedPredictions;
        if (state.prediction) {
          const updatedPrediction =
            state.prediction._id === action.payload.data._id
              ? action.payload.data
              : null;
          state.prediction = updatedPrediction;
        }
        if (state.predictionsByUser) {
          const updatedPredictions = state.predictionsByUser.map((prediction) =>
            prediction._id === action.payload.data._id
              ? action.payload.data
              : prediction
          );
          state.predictionsByUser = updatedPredictions;
        }
        if (state.predictionStored) {
          const updatedPredictionStored =
            state.predictionStored._id === action.payload.data._id
              ? action.payload.data
              : state.predictionStored;
          state.predictionStored = updatedPredictionStored;
        }
      })
      .addCase(editReplyCommentPrediction.rejected, (state, action) => {
        state.isLoadingActions = false;
        state.isErrorActions = true;
        toast.error(action.payload);
      })
      // like comment prediction
      .addCase(likeCommentPrediction.pending, (state, action) => {
        state.isLoadingActions = true;
      })
      .addCase(likeCommentPrediction.fulfilled, (state, action) => {
        state.isLoadingActions = false;
        state.isSuccessActions = true;
        const updatedPredictions = state.predictions.map((prediction) =>
          prediction._id === action.payload.data._id
            ? action.payload.data
            : prediction
        );
        state.predictions = updatedPredictions;
        if (state.prediction) {
          const updatedPrediction =
            state.prediction._id === action.payload.data._id
              ? action.payload.data
              : null;
          state.prediction = updatedPrediction;
        }
        if (state.predictionsByUser) {
          const updatedPredictions = state.predictionsByUser.map((prediction) =>
            prediction._id === action.payload.data._id
              ? action.payload.data
              : prediction
          );
          state.predictionsByUser = updatedPredictions;
        }
        if (state.predictionStored) {
          const updatedPredictionStored =
            state.predictionStored._id === action.payload.data._id
              ? action.payload.data
              : state.predictionStored;
          state.predictionStored = updatedPredictionStored;
        }
      })
      .addCase(likeCommentPrediction.rejected, (state, action) => {
        state.isLoadingActions = false;
        state.isErrorActions = true;
        toast.error(action.payload);
      })
      // Get all predictions for user
      .addCase(getAllPredictionsForUser.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllPredictionsForUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.predictionsByUser = action.payload.predictions;
        state.predictionsNotStarted = action.payload.notStartedPredictions;
        state.totalPredictions = action.payload.noTotalPredictions;
        state.totalPredictionsAnyStatus =
          action.payload.noTotalPredictionsAnyStatus;
        state.resultsStatus0 = action.payload.resultsStatus0;
        state.resultsStatus1 = action.payload.resultsStatus1;
        state.resultsStatus2 = action.payload.resultsStatus2;
        state.resultsStatus3 = action.payload.resultsStatus3;
      })
      .addCase(getAllPredictionsForUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      });
  },
});

export const {
  RESET,
  RESETPREDICTIONS,
  RESETPREDICTION,
  RESETPREDICTIONSBYUSER,
} = predictionsSlice.actions;

export const selectPrediction = (state) => state.prediction.match;

export default predictionsSlice.reducer;

import React, { useEffect, useState } from "react";
import socket from "../SocketDotIo/Socket";

const UserAvatar = ({ name, level, levelXp, photo }) => {
  return (
    <div
      className={`user-avatar-wrap user-avatar-wrap_small ${
        level === 0 || level === undefined ? "role-user" : ``
      } level-${level} `}
    >
      <div className="user-avatar user-avatar_small">
        <img src={photo || ""} alt={`${name} photo`} />
      </div>
      <span className="user-level-badge">{levelXp}</span>
    </div>
  );
};
// role-user role-support role-admin
export default UserAvatar;

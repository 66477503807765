import React from "react";
import UserAvatar from "../../utils/UserAvatar/UserAvatar";
import { Link } from "react-router-dom";
import { MdVerified } from "react-icons/md";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const Tipster = ({ t, i, currencyFormat }) => {
  return (
    <li key={i}>
      <span className="position">{i + 1}</span>
      <div className="user">
        <div className="profile-block-avatar flex-column">
          <UserAvatar
            name={t?.name}
            level={t?.level}
            levelXp={t?.levelXp}
            photo={t?.photo}
          />
        </div>
        <div
          className="profile-block-name flex-column flex-column_center"
          style={{ gap: 4 }}
        >
          <div className="profile-name profile-name_default">
            <Link
              to={`/p/${t?.handle}`}
              className="flex tipster-user-prediction gap-2"
            >
              <span className="link-name">{t?.name}</span>
              <span>
                {t?.role === "tipster" || t?.role === "admin" ? (
                  <MdVerified
                    data-tooltip-id="tooltip-verified-tipster-c"
                    data-tooltip-content="Tipster verificat"
                    className="text-green-400"
                  />
                ) : null}
              </span>
              <Tooltip
                id="tooltip-verified-tipster-c"
                data-tooltip-delay-show={200}
                place="top"
                style={{
                  fontSize: 12,
                  paddingTop: 0,
                  paddingBottom: 0,
                  textTransform: "none",
                  borderRadius: "12px",
                  color: "#fff",
                  lineHeight: "32px",
                  backgroundColor: "#3a434b",
                }}
              />
            </Link>
          </div>
          <span className="amount-icon">
            <span
              className={`text-sm ${
                t?.profitByDays > 0
                  ? "green-color"
                  : t?.profitByDays === 0
                  ? "gray-color"
                  : "red-color"
              }`}
              style={{ fontWeight: "600" }}
            >
              {t?.profitByDays > 0
                ? "+" + currencyFormat(t?.profitByDays)
                : currencyFormat(t?.profitByDays)}
            </span>
            <span className="text-sm text-gray-500 tipster-info__tips-count flex items-center">
              {t?.predictions} Predictii
            </span>
          </span>
        </div>
      </div>
      <div className="prize">
        <div className="badge won-predictions">{t?.wonPredictions}</div>
        <div className="badge lost-predictions">{t?.lostPredictions}</div>
        <div className="badge cur-predictions">
          {t?.predictions -
            t?.wonPredictions -
            t?.lostPredictions -
            t?.voidPredictions}
        </div>
      </div>
    </li>
  );
};

export default Tipster;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RESET, verifyUser } from "../redux/features/auth/authSlice";
import { useLocation } from "react-router-dom";
import { CgSpinner } from "react-icons/cg";
import { FaTimes } from "react-icons/fa";

const ModalVerify = ({ handleVerifyModal }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const verificationToken = searchParams.get("tk");

  const { isLoading } = useSelector((state) => state.auth);
  const verifyAccount = async () => {
    await dispatch(verifyUser(verificationToken)).then((res) => {
      if (res.payload.message === "Account Verification Successful") {
        localStorage.setItem("isEmailConfirmationDismissed", true);
        handleVerifyModal();
      }
    });
    await dispatch(RESET());
  };

  return (
    <div className="modal-screen modal-screen_login showModalBg">
      <div className="modal-screen-wrap" id="modal-target">
        <div className="modal-window showModalWindow">
          <div className="modal-header flex-row flex-row_center flex-row_space-between">
            <div className="modal-title">
              <div className="flex-row flex-row_space-between align-center full-width">
                <span>Verificare cont</span>
              </div>
            </div>
            <div className="flex-row flex-row_center">
              <div className="btn-close p-4" onClick={handleVerifyModal}>
                <FaTimes className="text-lg text-gray-500" />
              </div>
            </div>
          </div>
          <div className="modal-content">
            <div className="modal-window__content">
              <div className="welcome-logo">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/socialape-bea5b.appspot.com/o/logo.png?alt=media"
                  alt="AnalizeSportive"
                  title="AnalizeSportive"
                />
              </div>
              <div>
                <div className="welcome-text flex-row flex-row_center">
                  <span>Verificare cont</span>
                </div>

                <div className="block-row">
                  <div className="info-block-component default-color">
                    <div className="info-block-component__icon">
                      <span className="icon-info_outline"></span>
                    </div>
                    <div className="info-block-component__text">
                      Mai ai inca putin, pentru a-ti verifica contul, apasa pe
                      butonul "Verifica-ti contul". Acest lucru te va
                      redirectiona catre pagina principala.
                    </div>
                  </div>
                </div>
                <div className="button-flex-holder button-flex-holder_center">
                  <button onClick={verifyAccount}>
                    {isLoading ? (
                      <>
                        <span className="flex items-center justify-center">
                          <CgSpinner className="animate-spin h-5 w-5 mr-3" />
                          Verificare cont...
                        </span>
                      </>
                    ) : (
                      "Verifica-ti contul"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalVerify;

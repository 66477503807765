import React, { useEffect, useState } from "react";
import {
  FaCheck,
  FaCheckCircle,
  FaEdit,
  FaPen,
  FaStar,
  FaTimes,
  FaTimesCircle,
  FaTrash,
  FaTrashAlt,
} from "react-icons/fa";
import { BsAlarm, BsDash } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import useRedirectLoggedOutUser from "../../../utils/useRedirectLoggedOutUser";
import {
  deleteMatch,
  getMatches,
} from "../../../redux/features/matches/matchesSlice";
import {
  getAllBiletulzilei,
  approveBiletulzilei,
  approveBiletulzileiTicket,
} from "../../../redux/features/biletulzilei/biletulzileiSlice";
import { shortenText } from "../../../utils/ShortText/shortenText";

import Search from "../../../comp/Timeline/Search";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import Moment from "react-moment";
import SearchInPredictions from "../../../comp/Timeline/Search";
import Filters from "../../../comp/Timeline/Filter";
import FooterPrediction from "../../../comp/Timeline/FooterPrediction";
import Ticket from "../../../comp/Timeline/Ticket";
import Prediction from "../../../comp/Timeline/Prediction";
import HeaderPrediction from "../../../comp/Timeline/HeaderPrediction";
import CircleLoad from "../../../utils/Loaders/CircleLoad";
import NoResultsFilter from "../../../utils/Messages/NoResultsFilter";
import ShowMoreText from "react-show-more-text";

const AllBiletulZilei = () => {
  const {
    prediction,
    predictions,
    isLoading,
    isLoadingActions,
    totalPredictions,
    totalPredictionsAnyStatus,
  } = useSelector((state) => state.predictions);

  const { allbiletulzilei } = useSelector((state) => state.biletulzilei);
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  useRedirectLoggedOutUser("/login");
  const dispatch = useDispatch();

  // handle search prediction
  const [search, setSearch] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [biletulzileiStatus, setBiletulZileistatus] = useState("all");
  const [totalResults, setTotalResults] = useState(15);
  const [onlyFollowedUsers, setOnlyFollowedUsers] = useState(false);
  useEffect(() => {
    dispatch(
      getAllBiletulzilei({
        totalResults,
        biletulzileiStatus,
      })
    );
  }, [user, onlyFollowedUsers]);

  // Approve eveniment
  const approvePrediction = async (e, _id) => {
    e.preventDefault();

    const matchData = {
      matchStatus: 2,
      id: _id,
    };

    console.log(matchData);
    // const emailData = {
    //   subject: "Account role changed - AnalizeSportive",
    //   send_to: email,
    //   reply_to: "noreply@google.com",
    //   template: "changeRole",
    //   url: "/login",
    // };

    await dispatch(approveBiletulzileiTicket(matchData));
    // await dispatch(sendAutomatedEmail(emailData));
    await dispatch(
      getAllBiletulzilei({
        totalResults,
        biletulzileiStatus,
      })
    );
    // await dispatch(EMAIL_RESET());
  };

  // NS eveniment
  const nsPrediction = async (e, _id) => {
    e.preventDefault();

    const matchData = {
      matchStatus: 0,
      id: _id,
    };

    console.log(matchData);
    // const emailData = {
    //   subject: "Account role changed - AnalizeSportive",
    //   send_to: email,
    //   reply_to: "noreply@google.com",
    //   template: "changeRole",
    //   url: "/login",
    // };

    await dispatch(approveBiletulzileiTicket(matchData));
    // await dispatch(sendAutomatedEmail(emailData));
    await dispatch(
      getAllBiletulzilei({
        totalResults,
        biletulzileiStatus,
      })
    );
    // await dispatch(EMAIL_RESET());
  };

  // Void eveniment
  const voidPrediction = async (e, _id) => {
    e.preventDefault();

    const matchData = {
      matchStatus: 3,
      id: _id,
    };

    console.log(matchData);
    // const emailData = {
    //   subject: "Account role changed - AnalizeSportive",
    //   send_to: email,
    //   reply_to: "noreply@google.com",
    //   template: "changeRole",
    //   url: "/login",
    // };

    await dispatch(approveBiletulzileiTicket(matchData));
    // await dispatch(sendAutomatedEmail(emailData));
    await dispatch(
      getAllBiletulzilei({
        totalResults,
        biletulzileiStatus,
      })
    );
    // await dispatch(EMAIL_RESET());
  };
  // Cancel eveniment
  const cancelPrediction = async (e, _id) => {
    e.preventDefault();

    const matchData = {
      matchStatus: 1,
      id: _id,
    };

    // const emailData = {
    //   subject: "Account role changed - AnalizeSportive",
    //   send_to: email,
    //   reply_to: "noreply@google.com",
    //   template: "changeRole",
    //   url: "/login",
    // };

    await dispatch(approveBiletulzileiTicket(matchData));
    // await dispatch(sendAutomatedEmail(emailData));
    await dispatch(
      getAllBiletulzilei({
        totalResults,
        biletulzileiStatus,
      })
    );
    // await dispatch(EMAIL_RESET());
  };
  // Sterge eveniment
  const removeMatch = async (id) => {
    await dispatch(deleteMatch(id));
    dispatch(getMatches());
  };

  const confirmDelete = (id, homeTeam, awayTeam) => {
    confirmAlert({
      title: `Stergere eveniment ${homeTeam} - ${awayTeam}`,
      message: "Are you sure you want to delete this user?",
      buttons: [
        {
          label: "Delete",
          onClick: () => removeMatch(id),
        },
        {
          label: "Cancel",
          // onClick: () => alert('Click No')
        },
      ],
    });
  };

  // Approve eveniment
  const approveTheEveniment = async (e, _id, evenimentIndex) => {
    e.preventDefault();

    const matchData = {
      matchStatus: 2,
      id: _id,
      evenimentIndex: evenimentIndex,
    };

    console.log(matchData);
    // const emailData = {
    //   subject: "Account role changed - AnalizeSportive",
    //   send_to: email,
    //   reply_to: "noreply@google.com",
    //   template: "changeRole",
    //   url: "/login",
    // };

    await dispatch(approveBiletulzilei(matchData));
    // await dispatch(sendAutomatedEmail(emailData));
    await dispatch(
      getAllBiletulzilei({
        totalResults,
        biletulzileiStatus,
      })
    );
    // await dispatch(EMAIL_RESET());
  };

  // NS eveniment
  const nsTheEveniment = async (e, _id, evenimentIndex) => {
    e.preventDefault();

    const matchData = {
      matchStatus: 0,
      id: _id,
      evenimentIndex: evenimentIndex,
    };

    console.log(matchData);
    // const emailData = {
    //   subject: "Account role changed - AnalizeSportive",
    //   send_to: email,
    //   reply_to: "noreply@google.com",
    //   template: "changeRole",
    //   url: "/login",
    // };

    await dispatch(approveBiletulzilei(matchData));
    // await dispatch(sendAutomatedEmail(emailData));
    await dispatch(
      getAllBiletulzilei({
        totalResults,
        biletulzileiStatus,
      })
    );
    // await dispatch(EMAIL_RESET());
  };

  // Approve eveniment
  const voidTheEveniment = async (e, _id, evenimentIndex) => {
    e.preventDefault();

    const matchData = {
      matchStatus: 3,
      id: _id,
      evenimentIndex: evenimentIndex,
    };

    console.log(matchData);
    // const emailData = {
    //   subject: "Account role changed - AnalizeSportive",
    //   send_to: email,
    //   reply_to: "noreply@google.com",
    //   template: "changeRole",
    //   url: "/login",
    // };

    await dispatch(approveBiletulzilei(matchData));
    // await dispatch(sendAutomatedEmail(emailData));
    await dispatch(
      getAllBiletulzilei({
        totalResults,
        biletulzileiStatus,
      })
    );
    // await dispatch(EMAIL_RESET());
  };

  // Approve eveniment
  const cancelTheEveniment = async (e, _id, evenimentIndex) => {
    e.preventDefault();

    const matchData = {
      matchStatus: 1,
      id: _id,
      evenimentIndex: evenimentIndex,
    };

    console.log(matchData);
    // const emailData = {
    //   subject: "Account role changed - AnalizeSportive",
    //   send_to: email,
    //   reply_to: "noreply@google.com",
    //   template: "changeRole",
    //   url: "/login",
    // };

    await dispatch(approveBiletulzilei(matchData));
    // await dispatch(sendAutomatedEmail(emailData));
    await dispatch(
      getAllBiletulzilei({
        totalResults,
        biletulzileiStatus,
      })
    );
    // await dispatch(EMAIL_RESET());
  };

  // Sterge eveniment

  // handle button show more predictions
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const handleShowMorePredictions = (totalResults) => {
    setIsLoadingButton(true);
    setTotalResults(totalResults);
    dispatch(
      getAllBiletulzilei({
        totalResults,
        biletulzileiStatus,
      })
    );
  };

  useEffect(() => {
    if (!isLoading) {
      setIsLoadingButton(false);
    }
  }, [allbiletulzilei]);
  // handle button show more predictions
  return (
    <div className="flex flex-col w-full">
      <div className="px-4 py-5 sm:px-6  bg-gray-200 dark:bg-gray-700 rounded-t">
        <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
          Cota de doi
        </h3>
      </div>
      <div className="">
        <div className="flex justify-between items-center px-4 pb-2">
          <SearchInPredictions
            setSearchLoading={setSearchLoading}
            search={search}
            user={user}
            setSearch={setSearch}
            totalResults={totalResults}
            getAllBiletulzilei={getAllBiletulzilei}
            setBiletulZileistatus={setBiletulZileistatus}
            biletulzileiStatus={biletulzileiStatus}
          />
          <Filters
            searchLoading={searchLoading}
            search={search}
            user={user}
            totalResults={totalResults}
            getAllBiletulzilei={getAllBiletulzilei}
            biletulzileiStatus={biletulzileiStatus}
            setBiletulZileistatus={setBiletulZileistatus}
          />
        </div>
      </div>
      <div className="p-4 pt-2">
        <div className="flex flex-col">
          {allbiletulzilei?.length === 0 && !isLoading && !searchLoading ? (
            <NoResultsFilter
              setSearch={setSearch}
              setBiletulZileistatus={setBiletulZileistatus}
            />
          ) : null}
          {searchLoading ? (
            <CircleLoad />
          ) : (
            allbiletulzilei?.map((eveniment, index) => (
              <div
                className={`${
                  eveniment.status === 0
                    ? ""
                    : eveniment.status === 1
                    ? "border-red-400"
                    : eveniment.status === 2
                    ? "border-green-400"
                    : "border-yellow-400"
                } flex flex-col w-full bg-slate-100 dark:bg-bgMain2 rounded-lg p-2 mt-6 first:mt-0 border-2`}
              >
                <HeaderPrediction eveniment={eveniment} />
                {eveniment?.description !== "" ? (
                  <ShowMoreText
                    lines={2}
                    more="vezi mai mult"
                    less="vezi mai putin"
                    className="w-full mt-4 content-css text-xs text-gray support-textarea"
                    anchorclassName="read-more-button text-blue-500 font-semibold"
                    expanded={false}
                    width={440}
                    truncatedEndingComponent={"... "}
                  >
                    {eveniment?.description}
                  </ShowMoreText>
                ) : null}
                {eveniment?.evenimente?.map((e, index) => (
                  <div
                    className={`flex w-full bg-white dark:bg-bgMain border-2 ${
                      eveniment.status === 0
                        ? "border-gray-200 dark:border-gray-900"
                        : eveniment.status === 2
                        ? "border-green-200 dark:border-gray-900"
                        : eveniment.status === 1
                        ? "border-red-200 dark:border-gray-900"
                        : eveniment.status === 3
                        ? "border-yellow-200 dark:border-gray-900"
                        : null
                    } rounded-lg my-2 cursor-pointer`}
                  >
                    <div className="flex w-full p-2 items-center justify-between">
                      <div className="flex grow flex-1 w-full">
                        <div className="flex items-center justify-center mr-2 w-14">
                          <div className="border border-gray-300 dark:border-gray-900 mr-1 w-8 h-8 flex items-center justify-center relative bg-gray-200 dark:bg-gray-800 rounded-full">
                            <img
                              src={e?.eveniment.teams.home.logo}
                              className="h-5"
                            />
                          </div>
                          <div
                            style={{ marginLeft: "-10px" }}
                            className="border border-gray-300 dark:border-gray-900 w-8 h-8 flex items-center justify-center relative bg-gray-200 dark:bg-gray-800 rounded-full"
                          >
                            <img
                              src={e?.eveniment.teams.away.logo}
                              className="h-5"
                            />
                          </div>
                        </div>
                        <div className="flex flex-col justify-center">
                          <div className="flex text-xs text-gray-500">
                            <span>{e?.eveniment.teams.home.name}</span>
                            <span
                              className={`mx-1 font-bold ${
                                e?.eveniment?.fixture?.status?.long ===
                                "Match Finished"
                                  ? "text-black"
                                  : "text-red-500"
                              }`}
                            >
                              {e?.eveniment.goals.home}
                            </span>
                            <span>-</span>
                            <span
                              className={`mx-1 font-bold ${
                                e?.eveniment?.fixture?.status?.long ===
                                "Match Finished"
                                  ? "text-black"
                                  : "text-red-500"
                              }`}
                            >
                              {e?.eveniment.goals.away}
                            </span>
                            <span>{e?.eveniment.teams.away.name}</span>
                          </div>
                          <div
                            className="flex text-gray-500 font-semibold"
                            style={{ fontSize: "13px" }}
                          >
                            {e?.prediction.name} - {e?.prediction.value}
                          </div>
                        </div>
                      </div>
                      <div
                        className={`flex relative items-center flex-col justify-center w-14 h-11 m-0 px-0.5 rounded-lg font-semibold text-center ${
                          e?.prediction.status === 0
                            ? "bg-blue-400"
                            : e?.prediction.status === 2
                            ? "bg-green-400"
                            : e?.prediction.status === 1
                            ? "bg-red-400"
                            : e?.prediction.status === 3
                            ? "bg-yellow-400"
                            : null
                        } text-white text-roboto`}
                        style={{ fontSize: "13px" }}
                      >
                        {e?.prediction.odd}
                        {e?.prediction.status === 0 ? null : e?.prediction
                            .status === 2 ? (
                          <span
                            style={{
                              fontSize: "10px",
                              opacity: ".8",
                              fontWeight: "400",
                              textTransform: "uppercase",
                            }}
                          >
                            CASTIGAT
                          </span>
                        ) : e?.prediction.status === 1 ? (
                          <span
                            style={{
                              fontSize: "10px",
                              opacity: ".8",
                              fontWeight: "400",
                              textTransform: "uppercase",
                            }}
                          >
                            PIERDUT
                          </span>
                        ) : e?.prediction.status === 3 ? (
                          <span
                            style={{
                              fontSize: "10px",
                              opacity: ".8",
                              fontWeight: "400",
                              textTransform: "uppercase",
                            }}
                          >
                            ANULAT
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className={`${
                        eveniment?.evenimente?.length === 1 ? "hidden" : "flex"
                      } justify-end`}
                    >
                      <button
                        onClick={(e) =>
                          approveTheEveniment(e, eveniment?._id, index)
                        }
                        className="p-1.5 text-sm font-medium text-white rounded-lg  hover:bg-green-100 focus:ring-4 focus:outline-none focus:ring-gray-200  dark:hover:bg-green-100 dark:focus:ring-gray-900"
                      >
                        <FaCheck className="text-sm	text-green-400" />
                      </button>
                      <button
                        onClick={(e) =>
                          cancelTheEveniment(e, eveniment?._id, index)
                        }
                        className="mx-2 p-1.5 text-sm font-medium text-white rounded-lg  hover:bg-red-100 focus:ring-4 focus:outline-none focus:ring-gray-200  dark:hover:bg-red-100 dark:focus:ring-gray-900"
                      >
                        <FaTimes className="text-sm	text-red-800" />
                      </button>
                      <button
                        onClick={(e) =>
                          voidTheEveniment(e, eveniment?._id, index)
                        }
                        className="p-1.5 text-sm font-medium text-white rounded-lg  hover:bg-yellow-100 focus:ring-4 focus:outline-none focus:ring-gray-200  dark:hover:bg-yellow-100 dark:focus:ring-gray-900"
                      >
                        <BsDash className="text-sm	text-yellow-800" />
                      </button>
                      <button
                        onClick={(e) =>
                          nsTheEveniment(e, eveniment?._id, index)
                        }
                        className="p-1.5 text-sm font-medium text-white rounded-lg  hover:bg-yellow-100 focus:ring-4 focus:outline-none focus:ring-gray-200  dark:hover:bg-yellow-100 dark:focus:ring-gray-900"
                      >
                        <BsDash className="text-sm	text-yellow-800" />
                      </button>
                    </div>
                  </div>
                ))}
                <div className="flex justify-end">
                  <button
                    onClick={(e) => approvePrediction(e, eveniment?._id)}
                    className="p-1.5 text-sm font-medium text-white rounded-lg  bg-green-100 focus:ring-4 focus:outline-none focus:ring-gray-200  dark:hover:bg-green-100 dark:focus:ring-gray-900"
                  >
                    <FaCheck className="text-sm	text-green-400" />
                  </button>
                  <button
                    onClick={(e) => cancelPrediction(e, eveniment?._id)}
                    className="mx-2 p-1.5 text-sm font-medium text-white rounded-lg  bg-red-100 focus:ring-4 focus:outline-none focus:ring-gray-200  dark:hover:bg-red-100 dark:focus:ring-gray-900"
                  >
                    <FaTimes className="text-sm	text-red-800" />
                  </button>
                  <button
                    onClick={(e) => voidPrediction(e, eveniment?._id)}
                    className="p-1.5 text-sm font-medium text-white rounded-lg  bg-yellow-100 focus:ring-4 focus:outline-none focus:ring-gray-200  dark:hover:bg-yellow-100 dark:focus:ring-gray-900"
                  >
                    <BsDash className="text-sm	text-yellow-500" />
                  </button>
                  <button
                    onClick={(e) => nsPrediction(e, eveniment?._id)}
                    className="p-1.5 text-sm font-medium text-white rounded-lg  bg-yellow-100 focus:ring-4 focus:outline-none focus:ring-gray-200  dark:hover:bg-yellow-100 dark:focus:ring-gray-900"
                  >
                    <BsAlarm className="text-sm text-yellow-500" />
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
        <button
          disabled={
            totalResults >= totalPredictionsAnyStatus || isLoadingButton
          }
          onClick={() => handleShowMorePredictions(totalResults + 15)}
          className={`disabled:cursor-not-allowed disabled:bg-gray-200 disabled:hover:bg-gray-300 mt-2 uppercase italic w-full  text-white hover:text-white bg-gray-400 hover:bg-buttonPrimary2 font-medium rounded-lg text-sm px-4 py-2 text-center dark:border-buttonPrimary dark:text-gray-900 dark:hover:text-gray-900 dark:hover:bg-buttonPrimary2`}
        >
          vezi mai mult
        </button>
      </div>
    </div>
  );
};

export default AllBiletulZilei;

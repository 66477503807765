import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../redux/features/auth/authSlice";
import emailReducer from "../redux/features/email/emailSlice";
import filterReducer from "../redux/features/auth/filterSlice";
import filteredMatchesSlice from "../redux/features/matches/filterSlice";
import matchesReducer from "../redux/features/matches/matchesSlice";
import rapidApiReducer from "../redux/features/rapidapi/rapidApiSlice";
import predictionsReducer from "../redux/features/predictions/predictionsSlice";
import cotadedoiReducer from "../redux/features/cotadedoi/cotadedoiSlice";
import biletulzileiReducer from "../redux/features/biletulzilei/biletulzileiSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    email: emailReducer,
    filter: filterReducer,
    filteredMatches: filteredMatchesSlice,
    matches: matchesReducer,
    apiMatches: rapidApiReducer,
    predictions: predictionsReducer,
    cotadedoi: cotadedoiReducer,
    biletulzilei: biletulzileiReducer,
  },
});

import React, { useEffect, useState, useRef } from "react";
import { RiSendPlane2Fill } from "react-icons/ri";
import { FaAngleDown, FaHashtag, FaUserAlt, FaUsers } from "react-icons/fa";
import { FiInfo } from "react-icons/fi";
import axios from "axios";
import { toast } from "react-toastify";
import ChatMessage from "./ChatMessage";
import socket from "../../utils/SocketDotIo/Socket";
import { Link } from "react-router-dom";
import ScrollableFeed from "react-scrollable-feed";
import Moment from "react-moment";
import Countdown from "react-countdown";
import { useDispatch } from "react-redux";

const Chat = ({
  user,
  users,
  predictions,
  isLoggedIn,
  onlineUsers,
  setMessages,
  messages,
  newMessage,
  setNewMessage,
  isSharedPrediction,
  setIsSharedPrediction,
  showChat,
  getUser,
  handleAuthModal,
}) => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("authToken");
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(25);
  const [countMessages, setCountMessages] = useState(25);
  const [showMoreMessagesButton, setShowMoreMessagesButton] = useState(false);
  const feedRef = useRef(null);
  const [isAtTop, setIsAtTop] = useState(false);
  const timeNow = new Date().getTime();

  // join chat socket

  useEffect(() => {
    socket.emit("view chat", user || { _id: null, name: "guest" });
  }, [user]);
  //
  useEffect(() => {
    if (
      showChat &&
      isLoggedIn &&
      !user?.banned &&
      timeNow >= new Date(user?.timedout).getTime()
    ) {
      const messageInput = document.getElementById("message-input");
      messageInput.focus();
      messageInput.selectionStart = messageInput.selectionEnd =
        messageInput.value.length;
    }
  }, [showChat, isSharedPrediction]);

  // handle scroll and new messages alert
  const handleScroll = (isAtBottom) => {
    setIsAtTop(!isAtBottom);
    if (isAtBottom) {
      setShowMoreMessagesButton(false);
      setCountMessages(messages.length);
    }
  };

  const handleScrollToBottom = () => {
    feedRef.current.scrollToBottom();
    setShowMoreMessagesButton(false);
  };
  // handle scroll and new messages alert
  const fetchMessages = async (limit) => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `https://api.analizesportive.ro/api/message/64073bd437a3759c13679be8?limit=${limit}`
      );

      setMessages(data);

      setLoading(false);
    } catch (error) {
      toast.error(error);
    }
  };

  const pingRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
        }
      },
      { rootMargin: "0px", threshold: 1.0 }
    );

    if (pingRef.current) {
      observer.observe(pingRef.current);
    }

    return () => {
      if (pingRef.current) {
        observer.unobserve(pingRef.current);
      }
    };
  }, []);

  useEffect(() => {
    fetchMessages(limit);
  }, []);

  useEffect(() => {
    socket.on("message received", (newMessageReceived) => {
      setMessages([...messages, newMessageReceived]);
    });

    socket.on("message liked", (message) => {
      const updatedMessage = messages.map((m) =>
        m._id === message._id ? message : m
      );
      setMessages(updatedMessage);
    });

    socket.on("message deleted", (message) => {
      const updatedMessage = messages.map((m) =>
        m._id === message._id ? message : m
      );
      setMessages(updatedMessage);
    });

    socket.on("message received", (newMessageReceived) => {
      setMessages([...messages, newMessageReceived]);
    });

    if (!isAtTop) {
      setCountMessages(messages.length);
    } else if (isAtTop && countMessages !== messages.length) {
      setShowMoreMessagesButton(true);
    }
  });

  useEffect(() => {
    socket.on("timedout user", ({ timeout, user }) => {
      dispatch(getUser());
    });
    return () => {
      socket.off("timedout user");
    };
  }, [socket]);

  useEffect(() => {
    socket.on("banned user", ({ timeout, user }) => {
      dispatch(getUser());
    });
    return () => {
      socket.off("banned user");
    };
  }, [socket]);

  const highlightText = (m, users) => {
    const userRegex = /@[a-zA-Z0-9]+/g;
    const hashtagRegex = /#[a-fA-F0-9]{24}\b/g;

    const highlightedText = m.content
      .split(/(@[a-zA-Z0-9]+|#[a-fA-F0-9]{24}\b)/g)
      .map((part, i) => {
        if (part.match(userRegex)) {
          const username = part.replace(/[@\s]+/g, "");
          const user = users.find((u) => u.name === username);
          if (user) {
            return (
              <Link
                className="link-name"
                to={`/p/${username.toLowerCase()}`}
                style={{
                  color: "orange",
                  display: "inline-flex",
                }}
                key={i}
              >
                {username}
              </Link>
            );
          } else {
            return <span key={i}>{part}</span>;
          }
        } else if (part.match(hashtagRegex)) {
          const hashtag = part.replace(/[#\s]/g, "");
          const eveniment = m.predictions.find((p) => p._id === hashtag);
          if (eveniment) {
            return (
              <Link
                className="link-hashtag mark"
                to={`?modal=prediction&id=${hashtag.toLowerCase()}`}
                style={{
                  color:
                    eveniment.status === 1
                      ? "#f84447"
                      : eveniment.status === 2
                      ? "#8bbb4c"
                      : eveniment.status === 3
                      ? "#f9c915"
                      : eveniment.status === 0
                      ? "#8a99a5"
                      : "",
                  display: "inline-flex",
                }}
                key={i}
              >
                #{hashtag.slice(0, 7)}
              </Link>
            );
          } else {
            return <span key={i}>{hashtag}</span>;
          }
        } else {
          return part;
        }
      });

    return highlightedText;
  };

  useEffect(() => {
    const predictionRegex = /#(\w+)\s/; // regular expression to match the prediction id

    const predictionMatch = newMessage?.match(predictionRegex); // match the prediction id
    const predictionId = predictionMatch ? predictionMatch[1] : null; // extract the prediction id if it exists
    const eveniment = predictions.find((u) => u._id === predictionId);
    if (eveniment) {
      setIsSharedPrediction(true);
    } else {
      setIsSharedPrediction(false);
    }
  }, [newMessage]);

  const checkHighlightUsername = (substring, user) => {
    const regex = /@[a-zA-Z0-9]+/g;
    const matches = substring.match(regex);

    if (!matches) {
      return { hasMentions: false, usernames: [] };
    }

    let hasMentions = false;
    const usernames = [];

    matches.forEach((match) => {
      const username = match.replace("@", "");
      const taggedUser = users.find((u) => u.name === username);

      if (taggedUser && taggedUser.name !== user.name) {
        console.log(taggedUser, user.name);
        socket.emit("taggedUser", {
          taggedUserId: taggedUser._id,
          taggedUserSocketId: socket.id,
          username: user.name,
          taggedUsername: taggedUser.name,
        });
        hasMentions = true;
        usernames.push(username);
      }
    });

    return { hasMentions, usernames };
  };

  const sendMessage = async (e) => {
    e.preventDefault();

    if (!newMessage) {
      return;
    }

    try {
      // check if user mention
      // check if user mention
      const userMention = checkHighlightUsername(newMessage, user);

      if (userMention.hasMentions) {
        // emit a 'taggedUser' event to the server along with the userMention object
        console.log(userMention);
      }
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const predictionRegex = /#(\w+)\s/g; // add 'g' flag to match all occurrences

      const predictionMatches = [...newMessage.matchAll(predictionRegex)]; // use matchAll to match all occurrences
      const predictionIds = predictionMatches.map((match) => match[1]); // extract the prediction IDs
      const dataToBeSent = isSharedPrediction
        ? {
            type: "chat_prediction",
            content: newMessage,
            predictions: JSON.stringify(predictionIds),
          }
        : {
            type: "chat_message",
            content: newMessage,
          };
      const { data } = await axios.post(
        "https://api.analizesportive.ro/api/message",
        dataToBeSent,
        config
      );

      setNewMessage("");
      socket.emit("new message", data);
      setMessages([...messages, data]);
    } catch (error) {
      toast.error("A aparut o eroare la trimiterea mesajului");
    }
  };

  const textareaRef = useRef(null);
  const replyToUser = (m) => {
    const username = `@${m.sender.name} `;
    let message;
    if (newMessage) {
      message = newMessage.endsWith(" ")
        ? newMessage + username
        : newMessage + ` ${username}`;
    } else {
      message = username;
    }
    setNewMessage(message);
    textareaRef.current.focus();
  };

  useEffect(() => {
    if (
      !isLoggedIn ||
      user?.banned ||
      timeNow < new Date(user?.timedout).getTime()
    ) {
      return;
    }

    // Make sure textareaRef has a valid reference before accessing its style
    if (textareaRef.current) {
      const { length } = newMessage || "";

      if (length > 34 && length < 68) {
        textareaRef.current.style.height = "60px";
      } else if (length > 68 && length < 102) {
        textareaRef.current.style.height = "78px";
      } else if (length > 102) {
        textareaRef.current.style.height = "96px";
      } else {
        textareaRef.current.style.height = "42px";
      }
    }
  }, [newMessage, isLoggedIn]);

  const handleLikeMessage = async (user, message) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const dataToBeSent = {
      userId: user,
      messageId: message,
    };
    const { data } = await axios.put(
      "https://api.analizesportive.ro/api/message/like",
      dataToBeSent,
      config
    );

    const updatedMessage = messages.map((message) =>
      message._id === data.data._id ? data.data : message
    );
    setMessages(updatedMessage);
    socket.emit("like message", data.data);
  };

  const handleDeleteMessage = async (user, message) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const dataToBeSent = {
      userId: user,
      messageId: message,
    };
    const { data } = await axios.put(
      "https://api.analizesportive.ro/api/message/delete",
      dataToBeSent,
      config
    );

    const updatedMessage = messages.map((message) =>
      message._id === data.data._id ? data.data : message
    );
    setMessages(updatedMessage);
    socket.emit("delete message", data.data);
  };

  const handleBanMessage = async (user, message) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const dataToBeSent = {
      userId: user,
      messageId: message,
    };
    const { data } = await axios.put(
      "https://api.analizesportive.ro/api/message/ban",
      dataToBeSent,
      config
    );

    const updatedMessage = messages.map((message) =>
      message._id === data.data._id ? data.data : message
    );
    setMessages(updatedMessage);
    socket.emit("ban user", data.data);
  };

  const handleTimeoutMessage = async (user, message) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const dataToBeSent = {
      userId: user,
      messageId: message,
    };
    const { data } = await axios.put(
      "https://api.analizesportive.ro/api/message/timeout",
      dataToBeSent,
      config
    );

    const updatedMessage = messages.map((message) =>
      message._id === data.data._id ? data.data : message
    );
    setMessages(updatedMessage);
    socket.emit("timeout user", data.data);
  };

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      dispatch(getUser()).then(() => setNewMessage(""));
    } else {
      // Render a countdown
      return (
        <span className="font-bold red-color">
          {minutes}:{seconds}
        </span>
      );
    }
  };
  return (
    <>
      <div className="chat__header">
        <div className="chat__header-top flex-row flex-row_space-between">
          <div className="chat__header-top-holder">
            <div className="header-hold button_link ">
              <span className="icon-people-custom">
                <FaUsers className="text-3xl" />
              </span>
              <span className="chat-online-counter">{onlineUsers?.length}</span>
            </div>
          </div>
          <div className="header-hold">
            <FiInfo className="text-2xl" />
          </div>
        </div>
      </div>
      <div className="chat__body">
        <ScrollableFeed ref={feedRef}>
          <div id="ping" ref={pingRef}></div>
          <ChatMessage
            messages={messages}
            replyToUser={replyToUser}
            users={users}
            onlineUsers={onlineUsers}
            highlightText={highlightText}
            predictions={predictions}
            user={user}
            handleLikeMessage={handleLikeMessage}
            handleDeleteMessage={handleDeleteMessage}
            handleBanMessage={handleBanMessage}
            handleTimeoutMessage={handleTimeoutMessage}
          />
        </ScrollableFeed>
        {isAtTop && showMoreMessagesButton && (
          <div className="new-messages-button">
            <button
              className="button_icon button_icon-custom"
              onClick={handleScrollToBottom}
            >
              <span className="icon-wallet">
                <FaAngleDown className="text-white w-5 h-5" />
              </span>
              <span className="ml-1 button-text">Mesaje noi mai jos</span>
            </button>
          </div>
        )}
      </div>
      <div
        className={`${
          isLoggedIn
            ? !user?.banned
              ? new Date().getTime() < new Date(user?.timedout).getTime()
                ? "chat__dialog-timeout"
                : "chat__dialog"
              : "chat__dialog-banned"
            : "chat__dialog-loggedout"
        }`}
        style={{ display: "block" }}
      >
        {isLoggedIn &&
        !user?.banned &&
        timeNow >= new Date(user?.timedout).getTime() ? (
          <div className="flex-row flex-row_flex-end flex-row_space-between ">
            <div className="multiline-text multiline-text_absolute">
              <textarea
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    sendMessage(e);
                  }
                }}
                value={newMessage}
                className="font-roboto-mono bg-slate-100 border-none focus:border-none focus:ring-0 border-none text-gray-900 text-sm w-full placeholder:text-xs dark:bg-bgMain2 dark:placeholder-gray-400 dark:text-white mb-0 hover:ring-white"
                id="message-input"
                placeholder="Scrie un mesaj..."
                rows="1"
                maxlength="1000"
                ref={textareaRef}
                style={{ height: "42px", display: "block" }}
              ></textarea>
              <div className="multiline-text_button-holder"></div>
            </div>
            <button
              onClick={(event) => sendMessage(event)}
              title="Send message"
              className="button-send-message button_ico"
              style={{ height: 42 }}
            >
              <span className="icon-chat">
                <RiSendPlane2Fill />
              </span>
            </button>
          </div>
        ) : isLoggedIn && user?.banned ? (
          <button
            disabled={user?.banned}
            className="button button_secondary full-width"
            type="button"
          >
            Ai fost banat in chat
          </button>
        ) : isLoggedIn && timeNow < new Date(user?.timedout).getTime() ? (
          <button
            disabled={user?.banned}
            className="button button_secondary full-width"
            type="button"
          >
            Ai primit timeout{" "}
            <Countdown
              renderer={renderer}
              zeroPadTime={2}
              date={user?.timedout}
            />
          </button>
        ) : (
          <button
            onClick={handleAuthModal}
            className="button button_secondary full-width"
            type="button"
            style={{ width: "100%", height: "100%" }}
          >
            Autentifica-te pentru a participa in chat
          </button>
        )}
      </div>
    </>
  );
};

export default Chat;

import React, { useState, useEffect, useRef } from "react";
import { FaDotCircle, FaEquals, FaTimes } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import { getAllCotadedoi } from "../../redux/features/cotadedoi/cotadedoiSlice";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns"; // Import date-fns library for formatting dates
import { ro } from "date-fns/locale";
import DuckLoader from "../../utils/Loaders/DuckLoader";
import { Link } from "react-router-dom";

const CotaDeDoi = () => {
  const dispatch = useDispatch();
  const { isLoading: isLoadingCotaDeDoi, last5Cotadedoi } = useSelector(
    (state) => state.cotadedoi
  );
  // change date
  //const isMountedCotaDeDoi = useRef(true); // Using a ref to track the initial mount state

  useEffect(() => {
    // This block runs when the component is initially mounted
    //if (isMountedCotaDeDoi.current) {
    //isMountedCotaDeDoi.current = false; // Update the ref to indicate that the initial mount is done
    //return; // Exit the effect to prevent dispatch on initial mount
    //}

    // Dispatch getAllCotadedoi with your specified parameters
    dispatch(
      getAllCotadedoi({
        cotadedoiStatus: "all",
        totalResults: 5,
      })
    );
  }, [dispatch]);

  const [bilet, setBilet] = useState([]);

  // Initialize bilet state after fetching last5Cotadedoi
  useEffect(() => {
    if (last5Cotadedoi.length > 0) {
      setBilet(last5Cotadedoi[last5Cotadedoi.length - 1]);
    }
  }, [last5Cotadedoi]);

  const [formattedStartDate, setFormattedStartDate] = useState("");
  const [formattedEndDate, setFormattedEndDate] = useState("");

  useEffect(() => {
    if (last5Cotadedoi && last5Cotadedoi.length > 0) {
      const startDateCotadedoi = new Date(last5Cotadedoi[0].data);
      const endDateCotadedoi = new Date(
        last5Cotadedoi[last5Cotadedoi.length - 1].data
      );

      const formattedStartDate = format(startDateCotadedoi, "dd MMMM", {
        locale: ro,
      }); // Assuming 'ro' is the locale for Romanian
      const formattedEndDate = format(endDateCotadedoi, "dd MMMM", {
        locale: ro,
      });

      setFormattedStartDate(formattedStartDate);
      setFormattedEndDate(formattedEndDate);
    }
  }, [last5Cotadedoi]);

  // cota de doi

  const monthNames = [
    null, // Index 0 (there's no month 0)
    "Ian", // Index 1
    "Feb", // Index 2
    "Mar", // Index 3
    "Apr", // Index 4
    "Mai", // Index 5
    "Iun", // Index 6
    "Iul", // Index 7
    "Aug", // Index 8
    "Sep", // Index 9
    "Oct", // Index 10
    "Noi", // Index 11
    "Dec", // Index 12
  ];
  const gmtPlus3Offset = 3 * 60 * 60 * 1000; // 3 hours in milliseconds
  const currentDateInGMTPlus3 = new Date(new Date().getTime() + gmtPlus3Offset);
  const [currentDate, setCurrentDate] = useState(currentDateInGMTPlus3);
  const formattedDate = currentDate.toISOString().split("T")[0];
  return (
    <div className="token-info__tile flex-col">
      <section className="accordion-section__block section-info flex justify-between items-center">
        <h2>Cota de doi</h2>
      </section>
      {isLoadingCotaDeDoi ? (
        <DuckLoader rowCount={2} />
      ) : (
        <>
          <div className="token-info__tile-wrapper w-full flex-col cota-de-doi-box">
            <div className="w-full items-center flex justify-between pb-4">
              <span className="text-sm text-gray-500">
                {formattedStartDate}
              </span>
              <BsThreeDots className="text-gray-500" />
              <span className="text-sm text-gray-500">{formattedEndDate}</span>
            </div>
            <div className="flex w-full justify-between pb-4">
              {last5Cotadedoi?.map((b, index) => (
                <div
                  key={b.id}
                  onClick={() =>
                    setBilet({
                      cota: b.cota,
                      miza: b.miza,
                      id: b.id,
                      status: b.status,
                      data: b.data,
                    })
                  }
                  id={b.id}
                  className="flex flex-col items-center justify-center p-2 rounded-lg cursor-pointer"
                  style={{ background: "#24262b" }}
                >
                  <span
                    className={`${
                      bilet?.id === b.id
                        ? "orange-bg-color text-white"
                        : "bg-white text-black"
                    }  hover:bg-buttonPrimary hover:text-gray-200 flex items-center justify-center p-4 rounded-full w-8 h-8`}
                  >
                    {b.data.split("-")[2]}
                  </span>
                  <span className="mt-1 uppercase text-sm">
                    {monthNames[parseInt(b.data.split("-")[1])] || null}
                  </span>
                  <FaDotCircle
                    className={`mt-1 ${
                      b.status === 2
                        ? "text-green-500"
                        : b.status === 0
                        ? "text-gray"
                        : "text-red-500"
                    }`}
                    style={{ fontSize: "8px" }}
                  />
                </div>
              ))}
            </div>
            <Link
              to={`/cotadedoi/cota-de-doi-fotbal-${bilet?.data}`}
              className="flex items-center justify-center w-full"
            >
              <div
                className="flex flex-col items-center"
                style={{ flex: "1 0 82px", width: "82px" }}
              >
                <div className="text-center mb-1 uppercase leading-none text-xs">
                  Total cota
                </div>
                <div
                  className="flex flex-col shadow-sm flex items-center justify-center w-full h-11 text-sm leading-tight rounded-lg"
                  style={{ background: "#2e3139" }}
                >
                  <span
                    className="text-roboto font-bold"
                    style={{ fontSize: "13px" }}
                  >
                    {bilet?.cota?.toFixed(2)}
                  </span>
                </div>
              </div>
              <div
                className="mx-1 relative top-2.5 flex items-center justify-center"
                style={{ flex: "0 0 auto" }}
              >
                <FaTimes className="text-gray-500 text-xs" />
              </div>
              <div
                className="flex flex-col items-center"
                style={{ flex: "1 0 82px", width: "82px" }}
              >
                <div className="text-center mb-1 uppercase leading-none text-xs">
                  Miza
                </div>
                <div
                  className="bg-white flex flex-col dark:bg-slate-800 shadow-sm flex items-center justify-center w-full h-11 text-sm leading-tight rounded-lg"
                  style={{ background: "#2e3139" }}
                >
                  <span
                    className="text-roboto font-bold"
                    style={{ fontSize: "13px" }}
                  >
                    {bilet?.miza}
                  </span>
                </div>
              </div>
              <div
                className="mx-1 relative top-2.5 flex items-center justify-center"
                style={{ flex: "0 0 auto" }}
              >
                <FaEquals className="text-gray-500 text-xs" />
              </div>
              {bilet?.status === 2 ? (
                <div
                  className="flex flex-col items-center"
                  style={{ flex: "1 0 82px", width: "82px" }}
                >
                  <div className="text-center mb-1 uppercase leading-none text-xs">
                    Profit
                  </div>
                  <div className="flex flex-col green-bg-color text-white shadow-sm flex items-center justify-center w-full h-11 text-sm leading-tight rounded-lg">
                    <span className="text-roboto" style={{ fontSize: "13px" }}>
                      {(bilet?.cota * bilet?.miza - bilet?.miza).toFixed(2)}
                    </span>
                    <span
                      style={{
                        fontSize: "8px",
                        opacity: ".8",
                        fontWeight: "400",
                        textTransform: "uppercase",
                      }}
                    >
                      CASTIGAT
                    </span>
                  </div>
                </div>
              ) : bilet?.status === 1 ? (
                <div
                  className="flex flex-col items-center"
                  style={{ flex: "1 0 82px", width: "82px" }}
                >
                  <div className="text-center mb-1 uppercase leading-none text-xs">
                    Profit
                  </div>
                  <div className="flex flex-col red-bg-color text-white shadow-sm flex items-center justify-center w-full h-11 text-sm leading-tight rounded-lg">
                    <span className="text-roboto" style={{ fontSize: "13px" }}>
                      -{bilet?.miza}
                    </span>
                    <span
                      style={{
                        fontSize: "10px",
                        opacity: ".8",
                        fontWeight: "400",
                        textTransform: "uppercase",
                      }}
                    >
                      PIERDUT
                    </span>
                  </div>
                </div>
              ) : bilet?.status === 3 ? (
                <div
                  className="flex flex-col items-center"
                  style={{ flex: "1 0 82px", width: "82px" }}
                >
                  <div className="text-center mb-1 uppercase leading-none text-xs">
                    Profit
                  </div>
                  <div className="flex flex-col bg-slate-400 dark:bg-slate-600 text-white shadow-sm flex items-center justify-center w-full h-11 text-sm leading-tight rounded-lg">
                    <span
                      className="text-roboto font-bold"
                      style={{ fontSize: "13px" }}
                    >
                      {bilet?.miza}
                    </span>
                    <span
                      style={{
                        fontSize: "10px",
                        opacity: ".8",
                        fontWeight: "400",
                        textTransform: "uppercase",
                      }}
                    >
                      ANULAT
                    </span>
                  </div>
                </div>
              ) : (
                <div
                  className="flex flex-col items-center"
                  style={{ flex: "1 0 82px", width: "82px" }}
                >
                  <div className="text-center mb-1 uppercase leading-none text-xs">
                    Potential
                  </div>
                  <div className="flex flex-col gray-bg-color text-white dark:bg-slate-800 shadow-sm flex items-center justify-center w-full h-11 text-sm leading-tight rounded-lg">
                    <span className="text-roboto" style={{ fontSize: "13px" }}>
                      {(bilet?.cota * bilet?.miza).toFixed(2)}
                    </span>
                  </div>
                </div>
              )}
            </Link>
          </div>
          <div className="mt-3">
            <p style={{ fontSize: "12px", lineHeight: "18px", margin: 0 }}>
              <strong>Cota de Doi</strong> - Transforma-ti pasiunea pentru sport
              în castiguri palpabile! Pariem pe victoria ta cu alegeri ce au
              cote minime de 2.
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default CotaDeDoi;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import rapidApiService from "./rapidApiService";
import { toast } from "react-toastify";

const initialState = {
  match: null,
  matches: [],
  liveMatches: null,
  odds: [],
  bookmaker: "",
  isError: false,
  isErrorOdds: false,
  isSuccess: false,
  isSuccessOdds: false,
  isLoading: false,
  isLoadingOdds: false,
  message: "",
};

// Get all matches from RapidApi
export const getAllMatches = createAsyncThunk(
  "rapidapi/getmatches",
  async (matchDate, thunkAPI) => {
    try {
      return await rapidApiService.getAllMatches(matchDate);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get all matches from RapidApi
export const getMatchesEvenimente = createAsyncThunk(
  "rapidapi/getmatchesevenimente",
  async (matchDate, thunkAPI) => {
    try {
      return await rapidApiService.getMatchesEvenimente(matchDate);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get all matches from RapidApi
export const getAllMatchesV2 = createAsyncThunk(
  "rapidapi/getmatchesandstore",
  async (matchDate, thunkAPI) => {
    try {
      return await rapidApiService.getAllMatchesV2(matchDate);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get match from RapidApi
export const getMatch = createAsyncThunk(
  "rapidapi/getmatch",
  async (matchData, thunkAPI) => {
    try {
      return await rapidApiService.getMatch(matchData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get match odds from RapidApi
export const getMatchOdds = createAsyncThunk(
  "rapidapi/getmatchodds",
  async (matchData, thunkAPI) => {
    try {
      return await rapidApiService.getMatchOdds(matchData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

const rapidApiMatchesSlice = createSlice({
  name: "apiMatches",
  initialState,
  reducers: {
    RESETODDS(state) {
      state.isErrorOdds = false;
      state.isSuccessOdds = false;
      state.isLoadingOdds = false;
      state.message = "";
      state.bookmaker = "";
      state.odds = [];
    },
    RESET(state) {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
      state.bookmaker = "";
      state.odds = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // Get all matches from RapidApi
      .addCase(getAllMatches.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllMatches.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.matches = action.payload;
      })
      .addCase(getAllMatches.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      // Get all matches from RapidApi evenimente
      .addCase(getMatchesEvenimente.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getMatchesEvenimente.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.matches = action.payload.filteredFixtures;
        state.liveMatches = action.payload.liveEvenimenteCount;
      })
      .addCase(getMatchesEvenimente.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      // Get all matches from RapidApi V2
      .addCase(getAllMatchesV2.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllMatchesV2.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.matches = action.payload;
      })
      .addCase(getAllMatchesV2.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      // Get match odds from RapidApi
      .addCase(getMatchOdds.pending, (state, action) => {
        state.isLoadingOdds = true;
      })
      .addCase(getMatchOdds.fulfilled, (state, action) => {
        state.isLoadingOdds = false;
        state.isSuccessOdds = true;
        state.odds = action.payload.bets;
        state.bookmaker = action.payload.name;
      })
      .addCase(getMatchOdds.rejected, (state, action) => {
        state.isSuccessOdds = false;
        state.isErrorOdds = true;
        state.message = action.payload;
      })
      // Get match from RapidApi
      .addCase(getMatch.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getMatch.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.match = action.payload;
      })
      .addCase(getMatch.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      });
  },
});

export const { RESETODDS, RESET } = rapidApiMatchesSlice.actions;

export const selecMatch = (state) => state.match.match;

export default rapidApiMatchesSlice.reducer;

import React, { useState, useRef, useEffect } from "react";
import { BsThreeDots } from "react-icons/bs";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import "moment/locale/ro";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import ShowMoreText from "react-show-more-text";
import UserAvatar from "../../utils/UserAvatar/UserAvatar";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { MdVerified } from "react-icons/md";

const Reply = ({
  r,
  c,
  user,
  deleteReplyCommentPrediction,
  eveniment,
  editReplyCommentPrediction,
}) => {
  const dispatch = useDispatch();
  const tenMinutesAgo = Date.now() - 10 * 60 * 1000;
  const createdAtTimestamp = new Date(r?.createdAt).getTime();
  const updatedAtTimestamp = new Date(r?.updatedAt).getTime();

  const [actionsButton, setActionsButton] = useState(false);

  const btnWithMenuRef = useRef(null);

  const handleDeleteComment = (id) => {
    const commentData = {
      predictionId: eveniment._id,
      user: user,
      commentId: c._id,
      replyId: id,
    };
    console.log(commentData);
    dispatch(deleteReplyCommentPrediction(commentData)).then(() => {
      setActionsButton(false);
    });
  };

  useEffect(() => {
    const divEl = btnWithMenuRef.current;
    if (divEl) {
      divEl.style.display = actionsButton ? "block" : "none";
    }
  }, [actionsButton]);

  const handleActionsButton = () => {
    setActionsButton(!actionsButton);
  };

  const handleClickOutside = (event) => {
    if (
      btnWithMenuRef.current &&
      !btnWithMenuRef.current.contains(event.target)
    ) {
      setActionsButton(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [showModal, setShowModal] = useState(false);

  const handleModal = (eveniment) => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    setActionsButton(false);
  }, [showModal]);

  useEffect(() => {
    const body = document.querySelector("body");

    if (showModal) {
      body.classList.add("modal-opened");
      return () => {
        body.classList.remove("modal-opened");
      };
    } else {
    }
  }, [showModal]);

  return (
    <>
      <div className="comments-item reply-item mt-2">
        <Link
          title={r.user.name}
          className="user-avatar hover:z-10 cursor-pointer mr-2 "
          to={`/p/${r.user.handle}`}
          style={{ height: "43px" }}
        >
          <UserAvatar
            name={r.user.name}
            level={r.user.level}
            levelXp={r.user.levelXp}
            photo={r.user.photo}
          />
        </Link>

        <div className="comments-item__main">
          <div className="comments-item__main__meta flex items-center">
            <div className="comments-item__info items-center flex">
              <Link
                className="comments-item__user text-sm text-gray-500 font-semibold hover:text-sky-400"
                to={`/p/${r.user.handle}`}
              >
                <div className="flex gap-2 items-center">
                  <span className="text-sm ">{r.user.name}</span>
                  <span className="pointer">
                    {r.user.role === "tipster" || r.user.role === "admin" ? (
                      <MdVerified
                        data-tooltip-id="tooltip-verified-tipster-f"
                        data-tooltip-content="Tipster verificat"
                        className="text-green-400"
                      />
                    ) : null}
                  </span>
                </div>
              </Link>

              <div className="flex gap-2 items-center justify-center">
                <span
                  className="comments-item__info"
                  style={{ fontSize: "10px" }}
                >
                  <Moment locale="ro" className="" fromNow>
                    {createdAtTimestamp}
                  </Moment>
                </span>
                {r?.updated ? (
                  <span
                    className="comments-item__info text-slate-400"
                    style={{ fontSize: "10px" }}
                  >
                    (editat)
                  </span>
                ) : (
                  <span className="h-4"></span>
                )}
              </div>
            </div>
            <div className="btn-with-menu">
              <span
                className={`btn-with-menu__trigger ${
                  actionsButton ? "active" : ""
                }`}
              >
                <div
                  className={`comments-item__settings u-circle ${
                    user?._id === r?.user._id || user?.role === "admin"
                      ? ""
                      : "hidden"
                  }`}
                  onClick={handleActionsButton}
                >
                  <BsThreeDots className="text-lg pointer" />
                </div>
              </span>
              {actionsButton && (
                <div
                  className="btn-with-menu__options gray-bg-color"
                  ref={btnWithMenuRef}
                  id="buttons-here"
                >
                  {createdAtTimestamp < tenMinutesAgo &&
                  user?.role !== "admin" ? (
                    <span className="text-xs">
                      Un raspuns poate fi editat sau sters in decurs de 10
                      minute de la postare.
                    </span>
                  ) : (
                    <div className={`btn-with-menu__options__meta`}>
                      <div
                        onClick={handleModal}
                        className="text-white dropdown-button hidden"
                      >
                        <i className="flex items-center justify-center">
                          <FaEdit className="text-lg" />
                        </i>
                        Editeaza raspunsul
                      </div>
                      <div
                        className="text-red-500 dropdown-button"
                        onClick={() => handleDeleteComment(r?._id)}
                      >
                        <i className="flex items-center justify-center">
                          <FaTrash className="text-lg text-red-500 " />
                        </i>
                        Sterge raspunsul
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="comments-item__body bg-white dark:bg-slate-900">
            <ShowMoreText
              lines={1}
              more="vezi mai mult"
              less="vezi mai putin"
              className="text-xs text-slate-500 dark:text-white select-none"
              anchorclassName="read-more-button text-blue-500"
              expanded={false}
              width={440}
              truncatedEndingComponent={"... "}
            >
              {r.reply}
            </ShowMoreText>
          </div>
        </div>
        {/* <EditReplyModal
          showModal={showModal}
          handleModal={handleModal}
          r={r}
          user={user}
          eveniment={eveniment}
          editReplyCommentPrediction={editReplyCommentPrediction}
          c={c}
        /> */}
      </div>
    </>
  );
};

export default Reply;

import React, { useState } from "react";
import { FaLock, FaUser } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import Moment from "react-moment";
import { useDispatch } from "react-redux";
import { followUser } from "../../redux/features/auth/authSlice";
import DuckLoader from "../../utils/Loaders/DuckLoader";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { MdVerified } from "react-icons/md";

const AvatarBox = ({
  isLoggedIn,
  user,
  userProfile,
  handleAvatarModal,
  imagePreview,
  handleChangePasswordModal,
  loadingProfile,
}) => {
  const dispatch = useDispatch();

  const followHandle = (id) => {
    const userData = {
      userId: user?._id,
      followerId: id,
    };
    dispatch(followUser(userData));
  };

  const isFollowing = user?.following?.find(
    (following) => following?.id === userProfile?._id
  );

  return (
    <div className="token-info__item _animate _fade-in _active">
      <div className="flex-col user-avatar-parent">
        {loadingProfile ? (
          <div className="p-6 flex items-center justify-center">
            <DuckLoader rowCount={4} />
          </div>
        ) : (
          <>
            <div className="token-info__tile profile-avatar-page flex-col user-profile-photo-box">
              <div
                className={`relative user-avatar-wrap user-avatar-wrap_small ${
                  userProfile?.level === 0 || userProfile?.level === undefined
                    ? "role-user"
                    : ``
                } level-${userProfile?.level}`}
              >
                <div className="user-avatar user-avatar_default">
                  <img
                    src={
                      imagePreview === null ? userProfile?.photo : imagePreview
                    }
                    alt="User avatar"
                    style={{ display: "block" }}
                  />
                </div>
                <span className="user-level-badge">{userProfile?.levelXp}</span>
                <div
                  onClick={handleAvatarModal}
                  className={`user-button-avatar ${
                    user?._id !== userProfile?._id || user === null
                      ? "hidden"
                      : ""
                  }`}
                >
                  <MdEdit className="text-lg text-gray-100" />
                </div>
              </div>
              <div className="user-name-profile">
                <div className="flex gap-2">
                  <span>{userProfile?.name}</span>
                  <span className="pointer">
                    {userProfile?.role === "tipster" ||
                    userProfile?.role === "admin" ? (
                      <MdVerified
                        data-tooltip-id="tooltip-verified-tipster-f"
                        data-tooltip-content="Tipster verificat"
                        className="text-green-400"
                      />
                    ) : null}
                  </span>
                  <Tooltip
                    id="tooltip-verified-tipster-f"
                    data-tooltip-delay-show={200}
                    place="bottom"
                    style={{
                      fontSize: 12,
                      paddingTop: 0,
                      paddingBottom: 0,
                      textTransform: "none",
                      borderRadius: "12px",
                      color: "#fff",
                      lineHeight: "32px",
                      backgroundColor: "#3a434b",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="user-stats" style={{ padding: 16 }}>
              <div className="user-stats-block _animate _fade-in _active">
                <div className="user-stats-block__info">
                  <span className="label">Urmareste</span>
                  <strong className="amount">
                    <span className="icon-person">
                      <FaUser style={{ fontSize: 12 }} />
                    </span>
                    <span style={{ fontSize: 16 }}>
                      {userProfile?.following?.length}
                    </span>
                  </strong>
                </div>
              </div>
              <div className="user-stats-block _animate _fade-in _delay-02 _active">
                <div className="user-stats-block__info">
                  <span className="label">Urmaritori</span>
                  <strong className="amount">
                    <span className="icon-person">
                      <FaUser style={{ fontSize: 12 }} />
                    </span>

                    <span style={{ fontSize: 16 }}>
                      {userProfile?.followers?.length}
                    </span>
                  </strong>
                </div>
              </div>
            </div>
            <div
              style={{ paddingLeft: 16, paddingRight: 16, paddingBottom: 16 }}
              className={`user-profile-head__follow ${
                user?._id === userProfile?._id || !isLoggedIn ? "hidden" : ""
              }`}
            >
              <button
                onClick={() => followHandle(userProfile?._id)}
                type="button"
                className={`btn btn-follow ${
                  isFollowing ? "btn--primary-dark" : "btn--primary"
                }`}
              >
                {isFollowing ? "Urmaresti" : "Urmareste"}
              </button>
            </div>
            <div
              className={`flex items-center justify-center ${
                user?._id !== userProfile?._id || user === null ? "hidden" : ""
              }`}
              style={{ paddingLeft: 16, paddingRight: 16, paddingBottom: 16 }}
            >
              <button
                className="button_icon button_icon-custom"
                onClick={handleChangePasswordModal}
              >
                <span className="icon-wallet">
                  <FaLock />
                </span>
                <span className="ml-1 button-text">Schimba parola</span>
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AvatarBox;

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllMatchesV2,
  getMatch,
} from "../../redux/features/rapidapi/rapidApiSlice";
import {
  getAllPredictions,
  likePrediction,
  dislikePrediction,
  getPredictionById,
  getAllPredictionsSortByEveniment,
  RESETPREDICTIONS,
} from "../../redux/features/predictions/predictionsSlice";
import { FaPlusCircle } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import Filter from "./Filter";
import Search from "./Search";
import OnlyFollowers from "./OnlyFollowers";
import CircleLoad from "../../utils/Loaders/CircleLoad";
import HeaderPrediction from "./HeaderPrediction";
import Prediction from "./Prediction";
import Ticket from "./Ticket";
import FooterPrediction from "./FooterPrediction";
import NoResultsFilter from "../../utils/Messages/NoResultsFilter";
import DuckLoader from "../../utils/Loaders/DuckLoader";
import PredictionBox from "./PredictionBox";
import usePredictionDispatch from "../../utils/LikeAndDislike/LikeAndDislike";
import BannerSuperbet from "../../utils/Banners/BannerSuperbet";
import BannerBetano from "../../utils/Banners/BannerBetano";
import BannerMozzart from "../../utils/Banners/BannerMozzart";

const Timeline = ({
  setEveniment,
  setDisplay,
  handleModal,
  showModal,
  handleShowMatches,
  showMatches,
  onlineUsers,
  setMessages,
  messages,
  showChat,
  setShowChat,
  newMessage,
  setNewMessage,
  isSharedPrediction,
  setIsSharedPrediction,
}) => {
  const { matches } = useSelector((state) => state.apiMatches);
  const {
    prediction,
    predictions,
    predictionsByEvent,
    isLoading,
    isLoadingActions,
    totalPredictions,
    inDesfasurare,
  } = useSelector((state) => state.predictions);
  const { isLoggedIn, user, users } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  // only from tipsters i follow
  const [onlyFollowedUsers, setOnlyFollowedUsers] = useState(
    localStorage.getItem("onlyFollowedUsers") === "true"
  );

  useEffect(() => {
    if (isLoggedIn) {
      localStorage.setItem("onlyFollowedUsers", onlyFollowedUsers);
    } else {
      localStorage.removeItem("onlyFollowedUsers");
    }
  }, [onlyFollowedUsers, isLoggedIn]);

  const handleCheckbox = () => {
    if (isLoggedIn) {
      setOnlyFollowedUsers(!onlyFollowedUsers);
    }
  };
  // end

  const [predictionStatus, setPredictionStatus] = useState("all");

  // handle search prediction
  const [search, setSearch] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);

  // const [showModal, setShowModal] = useState(false);
  // const [currentEveniment, setCurrentEveniment] = useState(null);
  // const handleModal = (eveniment) => {
  //   setCurrentEveniment(eveniment);
  //   if (!showModal) {
  //     const predictionData = {
  //       _id: eveniment._id,
  //     };
  //     dispatch(getPredictionById(predictionData));
  //   } else {
  //     dispatch(RESETPREDICTION());
  //   }
  //   setShowModal(!showModal);
  // };

  // const handleClickOutside = (e) => {
  //   if (e.target.id === "defaultModal") {
  //     setShowModal(false);
  //     dispatch(RESETPREDICTION());
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("click", handleClickOutside, true);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside, true);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (showModal && currentEveniment) {
  //     const intervalId = setInterval(() => {
  //       const predictionData = {
  //         _id: currentEveniment._id,
  //       };
  //       dispatch(getPredictionById(predictionData));
  //     }, 40000);
  //     return () => clearInterval(intervalId);
  //   }
  // }, [showModal]);

  // useEffect(() => {
  //   const body = document.querySelector("body");

  //   if (showModal) {
  //     body.classList.add("modal-opened");
  //     return () => {
  //       body.classList.remove("modal-opened");
  //     };
  //   } else {
  //   }
  // }, [showModal]);

  const [content, setContent] = useState([]);

  const [totalResults, setTotalResults] = useState(15);

  const memoizedParams = useMemo(() => {
    let params = {
      totalResults,
      predictionStatus,
      searchPrediction: search,
    };

    if (isLoggedIn) {
      params.onlyFollowedUsers = onlyFollowedUsers;
      params.userId = user ? user._id : "";
    } else {
      params.onlyFollowedUsers = false;
      params.userId = "";
    }

    return params;
  }, [
    isLoggedIn,
    onlyFollowedUsers,
    predictionStatus,
    search,
    totalResults,
    user,
  ]);

  const [alreadyLoaded, setAlreadyLoaded] = useState(false);
  const [isLoadingAllPredictions, setIsLoadingAllPredictions] = useState(false);

  useEffect(() => {
    if (!alreadyLoaded) {
      setIsLoadingAllPredictions(true);
    }

    dispatch(getAllPredictions(memoizedParams));
    const interval = setInterval(() => {
      dispatch(getAllPredictions(memoizedParams));
    }, 30000);

    setTimeout(() => {
      setIsLoadingAllPredictions(false); // Remove loading indicator after dispatch
      setAlreadyLoaded(true);
    }, 2000); // 2000 milliseconds (2 seconds)

    return () => {
      clearInterval(interval);
    };
  }, [dispatch, memoizedParams, alreadyLoaded]);

  // update user every minute

  //
  // handle button show more predictions
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const handleShowMorePredictions = (totalResults) => {
    setIsLoadingButton(true);
    setTotalResults(totalResults);
    dispatch(
      getAllPredictions({
        totalResults,
        predictionStatus,
        searchPrediction: search,
        onlyFollowedUsers,
        userId: user ? user?._id : "",
      })
    );
  };

  useEffect(() => {
    if (!isLoading) {
      setIsLoadingButton(false);
    }
  }, [predictions]);
  // handle button show more predictions

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     dispatch(
  //       getAllPredictions({
  //         totalResults,
  //         predictionStatus,
  //         searchPrediction: search,
  //         onlyFollowedUsers,
  //         userId: user ? user?._id : "",
  //       })
  //     );
  //   }, 30000);

  //   return () => clearInterval(interval);
  // }, [
  //   totalResults,
  //   predictionStatus,
  //   search,
  //   showModal,
  //   onlyFollowedUsers,
  //   user,
  // ]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setContent((prevRenderedArray) => [...prevRenderedArray, ...predictions]);
    }, 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  const handleEveniment = async (eveniment) => {
    setEveniment(eveniment);
    await dispatch(
      getMatch({ matchId: eveniment.id, userId: eveniment.userId })
    );
    setDisplay(true);
  };

  // like dislike prediction

  const { handleLikePrediction, handleDislikePrediction } =
    usePredictionDispatch();

  // like dislike prediction

  return (
    <>
      <div className="token-info__tile flex-col" style={{ marginBottom: 0 }}>
        <section className="accordion-section__block section-info flex justify-between items-center">
          <h2>
            Predictii{" "}
            {isLoading ? (
              <b
                className="gray-color uppercase italic"
                style={{ fontSize: 10 }}
              >
                loading
              </b>
            ) : predictionStatus === 0 ? (
              <b
                className="gray-color uppercase italic"
                style={{ fontSize: 10 }}
              >
                {totalPredictions} active
              </b>
            ) : predictionStatus === 1 ? (
              <b
                className="red-color uppercase italic"
                style={{ fontSize: 10 }}
              >
                {totalPredictions} pierdute
              </b>
            ) : predictionStatus === 2 ? (
              <b
                className="green-color uppercase italic"
                style={{ fontSize: 10 }}
              >
                {totalPredictions} castigate
              </b>
            ) : predictionStatus === 3 ? (
              <b
                className="text-yellow-500 uppercase italic"
                style={{ fontSize: 10 }}
              >
                {totalPredictions} anulate
              </b>
            ) : (
              <b
                className="gray-color uppercase italic"
                style={{ fontSize: 10 }}
              >
                {inDesfasurare} active
              </b>
            )}
          </h2>
          <button
            disabled={!isLoggedIn || showMatches}
            onClick={handleShowMatches}
            className="button_add_prediction tutorial-step-deposit"
          >
            <span className="icon-wallet">
              <FaPlusCircle className="text-white w-5 h-5" />
            </span>
            <span className="text">Adauga predictie</span>
          </button>
        </section>
        <div className="token-info__tile-wrapper w-full flex-col">
          <div className="flex flex-col w-full">
            <div className="flex justify-between items-center mb-6">
              <Search
                setSearchLoading={setSearchLoading}
                search={search}
                user={user}
                setSearch={setSearch}
                totalResults={totalResults}
                onlyFollowedUsers={onlyFollowedUsers}
                getAllPredictions={getAllPredictions}
                setPredictionStatus={setPredictionStatus}
                predictionStatus={predictionStatus}
              />
              <Filter
                searchLoading={searchLoading}
                search={search}
                user={user}
                totalResults={totalResults}
                onlyFollowedUsers={onlyFollowedUsers}
                getAllPredictions={getAllPredictions}
                predictionStatus={predictionStatus}
                setPredictionStatus={setPredictionStatus}
              />
              <OnlyFollowers
                onlyFollowedUsers={onlyFollowedUsers}
                isLoggedIn={isLoggedIn}
                handleCheckbox={handleCheckbox}
              />
            </div>
          </div>
          <div className="flex flex-col predictions-data">
            {predictions?.length === 0 &&
            !isLoading &&
            !searchLoading &&
            user &&
            !isLoadingAllPredictions &&
            typeof onlyFollowedUsers === "boolean" ? (
              <NoResultsFilter
                setSearch={setSearch}
                setPredictionStatus={setPredictionStatus}
              />
            ) : null}
            {searchLoading ? (
              <DuckLoader rowCount={6} />
            ) : (
              <>
                {predictions?.map((eveniment, index) => (
                  <React.Fragment key={eveniment._id}>
                    <PredictionBox
                      eveniment={eveniment}
                      user={user}
                      isLoggedIn={isLoggedIn}
                      handleModal={handleModal}
                      handleLikePrediction={handleLikePrediction}
                      isLoadingActions={isLoadingActions}
                      predictions={predictions}
                      onlineUsers={onlineUsers}
                      setMessages={setMessages}
                      messages={messages}
                      showChat={showChat}
                      setShowChat={setShowChat}
                      newMessage={newMessage}
                      setNewMessage={setNewMessage}
                      isSharedPrediction={isSharedPrediction}
                      setIsSharedPrediction={setIsSharedPrediction}
                      isLoadingAllPredictions={isLoadingAllPredictions}
                    />
                    {/* {index === 2 && <BannerSuperbet />}
                    {index === 4 && <BannerBetano />}
                    {index === 6 && <BannerMozzart />} */}
                  </React.Fragment>
                ))}
              </>
            )}

            <button
              disabled={
                totalResults >= totalPredictions || isLoadingButton || isLoading
              }
              onClick={() => handleShowMorePredictions(totalResults + 15)}
              className={`disabled:cursor-not-allowed button mt-4 ${
                totalPredictions > 15 ? "" : "hidden"
              }`}
            >
              vezi mai mult
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Timeline;

import React, { useState, useEffect, useRef } from "react";
import Moment from "react-moment";
import { useDispatch } from "react-redux";
import { MdDelete } from "react-icons/md";

const Notification = ({ n, setNotificationRead, userId, handleModal }) => {
  const dispatch = useDispatch();
  const [isInteracting, setIsInteracting] = useState(false);
  const isDispatched = useRef(false);

  useEffect(() => {
    if (isInteracting && n?.read === false && !isDispatched.current) {
      const userData = {
        userId,
        notificationId: n?._id,
      };
      console.log(userData);
      dispatch(setNotificationRead(userData));
      isDispatched.current = true;
    }
  }, [isInteracting, n, dispatch, setNotificationRead, userId]);
  return n?.type === "like_prediction" ? (
    <div
      className={`notification-block notification-block_success ${
        n?.read ? "" : "active"
      }`}
      key={n._id}
      onMouseEnter={() => setIsInteracting(true)}
      onMouseLeave={() => setIsInteracting(false)}
      onClick={() => handleModal({ _id: n?.predictionId })}
    >
      <span className="notification-block__badge notification-block__badge_success"></span>
      <div>
        <span className="notification-block__text">
          <div className="message-rich-text">
            {n?.fromName} a apreciat predictia ta
          </div>
          <div className="message-rich-text text-gray-400 text-sm">
            {n?.description}
          </div>
        </span>
        <div className="flex-row align-center">
          <span className="notification-block__time">
            <Moment locale="ro" className="text-xs text-gray-500" fromNow>
              {n?.date}
            </Moment>
          </span>
        </div>
      </div>
      <div className="delete notification-block__delete">
        <MdDelete />
      </div>
    </div>
  ) : n?.type === "dislike_prediction" ? (
    <div
      className={`notification-block notification-block_security_issue ${
        n?.read ? "" : "active"
      }`}
      key={n._id}
      onMouseEnter={() => setIsInteracting(true)}
      onMouseLeave={() => setIsInteracting(false)}
      onClick={() => handleModal({ _id: n?.predictionId })}
    >
      <span className="notification-block__badge notification-block__badge_security_issue"></span>
      <div>
        <span className="notification-block__text">
          <div className="message-rich-text">
            {n?.fromName} nu a apreciat predictia ta
          </div>
          <div className="message-rich-text text-gray-400 text-sm">
            {n?.description}
          </div>
        </span>
        <div className="flex-row align-center">
          <span className="notification-block__time">
            <Moment locale="ro" className="text-xs text-gray-500" fromNow>
              {n?.date}
            </Moment>
          </span>
        </div>
      </div>
      <div className="delete notification-block__delete">
        <MdDelete />
      </div>
    </div>
  ) : n?.type === "comment_prediction" ? (
    <div
      className={`notification-block notification-block_mention ${
        n?.read ? "" : "active"
      }`}
      key={n._id}
      onMouseEnter={() => setIsInteracting(true)}
      onMouseLeave={() => setIsInteracting(false)}
      onClick={() => handleModal({ _id: n?.predictionId })}
    >
      <span className="notification-block__badge notification-block__badge_mention"></span>
      <div>
        <span className="notification-block__text">
          <div className="message-rich-text">
            {n?.fromName} a comentat la predictia ta
          </div>
          <div className="message-rich-text text-gray-400 text-sm">
            {n?.description}
          </div>
        </span>
        <div className="flex-row align-center">
          <span className="notification-block__time">
            <Moment locale="ro" className="text-xs text-gray-500" fromNow>
              {n?.date}
            </Moment>
          </span>
        </div>
      </div>
      <div className="delete notification-block__delete">
        <MdDelete />
      </div>
    </div>
  ) : n?.type === "reply_comment_prediction" ? (
    <div
      className={`notification-block notification-block_reply ${
        n?.read ? "" : "active"
      }`}
      key={n._id}
      onMouseEnter={() => setIsInteracting(true)}
      onMouseLeave={() => setIsInteracting(false)}
      onClick={() => handleModal({ _id: n?.predictionId })}
    >
      <span className="notification-block__badge notification-block__badge_reply"></span>
      <div>
        <span className="notification-block__text">
          <div className="message-rich-text">
            {n?.fromName} a raspuns la comentariul tau
          </div>
          <div className="message-rich-text text-gray-400 text-sm">
            {n?.description}
          </div>
        </span>
        <div className="flex-row align-center">
          <span className="notification-block__time">
            <Moment locale="ro" className="text-xs text-gray-500" fromNow>
              {n?.date}
            </Moment>
          </span>
        </div>
      </div>
      <div className="delete notification-block__delete">
        <MdDelete />
      </div>
    </div>
  ) : n?.type === "like_comment_prediction" ? (
    <div
      className={`notification-block notification-block_success ${
        n?.read ? "" : "active"
      }`}
      key={n._id}
      onMouseEnter={() => setIsInteracting(true)}
      onMouseLeave={() => setIsInteracting(false)}
      onClick={() => handleModal({ _id: n?.predictionId })}
    >
      <span className="notification-block__badge notification-block__badge_success"></span>
      <div>
        <span className="notification-block__text">
          <div className="message-rich-text">
            {n?.fromName} a apreciat comentariul tau
          </div>
          <div className="message-rich-text text-gray-400 text-sm">
            {n?.description}
          </div>
        </span>
        <div className="flex-row align-center">
          <span className="notification-block__time">
            <Moment locale="ro" className="text-xs text-gray-500" fromNow>
              {n?.date}
            </Moment>
          </span>
        </div>
      </div>
      <div className="delete notification-block__delete">
        <MdDelete />
      </div>
    </div>
  ) : n?.type === "new_prediction" ? (
    <div
      className={`notification-block notification-block_warning ${
        n?.read ? "" : "active"
      }`}
      key={n._id}
      onMouseEnter={() => setIsInteracting(true)}
      onMouseLeave={() => setIsInteracting(false)}
      onClick={() => handleModal({ _id: n?.predictionId })}
    >
      <span className="notification-block__badge notification-block__badge_warning"></span>
      <div>
        <span className="notification-block__text">
          <div className="message-rich-text">
            {n?.fromName} a postat o predictie noua
          </div>
          <div className="message-rich-text text-gray-400 text-sm">
            {n?.description}
          </div>
        </span>
        <div className="flex-row align-center">
          <span className="notification-block__time">
            <Moment locale="ro" className="text-xs text-gray-500" fromNow>
              {n?.date}
            </Moment>
          </span>
        </div>
      </div>
      <div className="delete notification-block__delete">
        <MdDelete />
      </div>
    </div>
  ) : n?.type === "follow" ? (
    <div
      className={`notification-block notification-block_information ${
        n?.read ? "" : "active"
      }`}
      key={n._id}
      onMouseEnter={() => setIsInteracting(true)}
      onMouseLeave={() => setIsInteracting(false)}
    >
      <span className="notification-block__badge notification-block__badge_information"></span>
      <div>
        <span className="notification-block__text">
          <div className="message-rich-text">Un nou urmaritor!</div>
          <div className="message-rich-text text-gray-400 text-sm">
            {n?.description}
          </div>
        </span>
        <div className="flex-row align-center">
          <span className="notification-block__time">
            <Moment locale="ro" className="text-xs text-gray-500" fromNow>
              {n?.date}
            </Moment>
          </span>
        </div>
      </div>
      <div className="delete notification-block__delete">
        <MdDelete />
      </div>
    </div>
  ) : (
    <div>da</div>
  );
};

export default Notification;

import React, { useRef, useState, useEffect } from "react";
import UserAvatar from "../../utils/UserAvatar/UserAvatar";
import Moment from "react-moment";
import "moment/locale/ro";
import {
  FaHeart,
  FaLock,
  FaRegClock,
  FaReply,
  FaTrash,
  FaUser,
} from "react-icons/fa";
import moment from "moment";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { MdVerified } from "react-icons/md";

const ChatMessage = ({
  users,
  predictions,
  messages,
  replyToUser,
  onlineUsers,
  highlightText,
  user,
  handleLikeMessage,
  handleDeleteMessage,
  handleBanMessage,
  handleTimeoutMessage,
}) => {
  const markerRefs = useRef([]);

  const handleMouseOver = (index, m) => {
    markerRefs.current[index].querySelector(".reply-in-chat").style.display =
      "block";
    if (user._id === m.sender._id) {
      markerRefs.current[index].querySelector(".delete-message").style.display =
        "block";
    }

    if (user.role === "admin") {
      markerRefs.current[index].querySelector(".delete-message").style.display =
        "block";
      markerRefs.current[index].querySelector(".delete-message").style.color =
        "#8bbb4c";
      markerRefs.current[index].querySelector(".ban-user").style.display =
        "block";
      markerRefs.current[index].querySelector(".ban-user").style.color =
        "#8bbb4c";
      markerRefs.current[index].querySelector(".timeout-user").style.display =
        "block";
      markerRefs.current[index].querySelector(".timeout-user").style.color =
        "#8bbb4c";
    }
  };

  const handleMouseOut = (index, m) => {
    markerRefs.current[index].querySelector(".reply-in-chat").style.display =
      "none";
    if (m.sender._id === user._id || user.role === "admin") {
      markerRefs.current[index].querySelector(".delete-message").style.display =
        "none";
    }
    if (user.role === "admin") {
      markerRefs.current[index].querySelector(".ban-user").style.display =
        "none";
      markerRefs.current[index].querySelector(".timeout-user").style.display =
        "none";
    }
  };

  return (
    <div className="chat-messages-wrapper" id="chat-messages-wrapper">
      <div className="chat-messages" id="chat-messages">
        {messages.map((m, index) => {
          return m.type === "chat_message" ? (
            <div
              className="chat__message m-4"
              style={{ display: "flex" }}
              key={m._id}
              ref={(el) => (markerRefs.current[index] = el)}
              onMouseOver={() => handleMouseOver(index, m)}
              onMouseOut={() => handleMouseOut(index, m)}
            >
              <div className="profile-block-avatar flex-column">
                <UserAvatar
                  name={m.sender.name}
                  level={m.sender.level}
                  levelXp={m.sender.levelXp}
                  photo={m.sender.photo}
                  onlineUsers={onlineUsers}
                />
              </div>
              <div className="profile-block-name">
                <div className="chat-message flex-column">
                  <div
                    className="chat-message__header"
                    style={{ height: "17px" }}
                  >
                    <div
                      className="flex flex justify-center"
                      style={{ gap: "4px" }}
                    >
                      <span className="link-name">{m.sender.name}</span>
                      <span className="pointer">
                        {m.sender?.role === "tipster" ||
                        m.sender?.role === "admin" ? (
                          <MdVerified
                            data-tooltip-id="tooltip-verified-tipster-f"
                            data-tooltip-content="Tipster verificat"
                            className="text-green-400"
                          />
                        ) : null}
                      </span>
                      <Tooltip
                        id="tooltip-verified-tipster-f"
                        data-tooltip-delay-show={200}
                        place="right"
                        style={{
                          fontSize: 12,
                          paddingTop: 0,
                          paddingBottom: 0,
                          textTransform: "none",
                          borderRadius: "12px",
                          color: "#fff",
                          lineHeight: "32px",
                          backgroundColor: "#3a434b",
                        }}
                      />
                      {m.sender.role === "admin" ? (
                        <span
                          className="role-block"
                          style={{ background: "#8bbb4c" }}
                        >
                          admin
                        </span>
                      ) : m.sender.role === "mod" ? (
                        <span
                          className="role-block"
                          style={{ background: "#8c5679" }}
                        >
                          mod
                        </span>
                      ) : null}
                      <div className="flex items-start justify-start">
                        <span
                          style={{ fontWeight: "600", marginLeft: "5px" }}
                          className={`text-sm uppercase ${
                            m.sender.profit === 0
                              ? "gray-color"
                              : m.sender.profit > 0
                              ? "green-color"
                              : "red-color"
                          } mr-1`}
                        >
                          {m.sender.profit > 0
                            ? "+" + m.sender.profit.toFixed(2)
                            : m.sender.profit === 0
                            ? null
                            : m.sender.profit.toFixed(2)}
                        </span>
                      </div>
                    </div>
                    <div className="chat-time">
                      <Moment
                        locale="ro"
                        className=""
                        format={
                          moment(m.createdAt).isSame(new Date(), "day")
                            ? "HH:mm"
                            : "DD/MM/YY"
                        }
                      >
                        {m.createdAt}
                      </Moment>
                    </div>
                    <div
                      className={`${
                        m.deleted && "hidden"
                      } chat-message-options`}
                    >
                      <div className="message-buttons">
                        <span
                          onClick={() => replyToUser(m)}
                          className="button_link reply-in-chat"
                          style={{ display: "none" }}
                        >
                          <span className="icon-ico_reply">
                            <FaReply />
                          </span>
                        </span>
                        <span
                          onClick={() => handleDeleteMessage(user._id, m._id)}
                          className="button_link delete-message"
                          style={{ display: "none" }}
                        >
                          <span className="icon-ico_delete">
                            <FaTrash />
                          </span>
                        </span>
                        <span
                          onClick={() => handleBanMessage(user._id, m._id)}
                          className="button_link ban-user"
                          style={{ display: "none" }}
                        >
                          <span className="icon-ico_lock">
                            <FaLock />
                          </span>
                        </span>
                        <span
                          onClick={() => handleTimeoutMessage(user._id, m._id)}
                          className="button_link timeout-user"
                          style={{ display: "none" }}
                        >
                          <span className="icon-ico_lock">
                            <FaRegClock />
                          </span>
                        </span>
                        <span
                          className={`like-button ${
                            m?.likes > 0 ? "liked" : ""
                          } ${
                            m?.likedBy?.findIndex(
                              (x) =>
                                x?._id?.toString() === user?._id?.toString()
                            ) !== -1
                              ? "liked-me"
                              : ""
                          } button_link`}
                          onClick={() => handleLikeMessage(user._id, m._id)}
                        >
                          <span className="icon-ico_heart_empty">
                            <FaHeart />
                          </span>
                        </span>

                        <div
                          className={`${
                            m.likes === 0 ? "hidden" : ""
                          } message-likes-amount`}
                        >
                          {m.likes}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="chat-message__text">
                    <div className="message-rich-text">
                      {m.deleted ? (
                        <span className="italic">Mesaj sters</span>
                      ) : (
                        highlightText(m, users)
                      )}
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              className="chat__message m-4"
              style={{ display: "flex" }}
              key={m._id}
              ref={(el) => (markerRefs.current[index] = el)}
              onMouseOver={() => handleMouseOver(index, m)}
              onMouseOut={() => handleMouseOut(index, m)}
            >
              <div className="profile-block-avatar flex-column">
                <UserAvatar
                  name={m.sender.name}
                  level={m.sender.level}
                  levelXp={m.sender.levelXp}
                  photo={m.sender.photo}
                  onlineUsers={onlineUsers}
                />
              </div>
              <div className="profile-block-name">
                <div className="chat-message flex-column">
                  <div
                    className="chat-message__header"
                    style={{ height: "17px" }}
                  >
                    <div
                      className="flex flex justify-center"
                      style={{ gap: "4px" }}
                    >
                      <span className="link-name">{m.sender.name}</span>
                      {m.sender.role === "admin" && (
                        <span
                          className="role-block"
                          style={{ background: "#465059" }}
                        >
                          admin
                        </span>
                      )}
                      <div className="flex items-start justify-start">
                        <span
                          style={{ fontWeight: "600", marginLeft: "5px" }}
                          className={`text-sm uppercase ${
                            m.sender.profit === 0
                              ? "gray-color"
                              : m.sender.profit > 0
                              ? "green-color"
                              : "red-color"
                          } mr-1`}
                        >
                          {m.sender.profit > 0
                            ? "+" + m.sender.profit.toFixed(2)
                            : m.sender.profit === 0
                            ? null
                            : m.sender.profit.toFixed(2)}
                        </span>
                      </div>
                    </div>
                    <div className="chat-time">
                      <Moment
                        locale="ro"
                        className=""
                        format={
                          moment(m.createdAt).isSame(new Date(), "day")
                            ? "HH:mm"
                            : "DD/MM/YY"
                        }
                      >
                        {m.createdAt}
                      </Moment>
                    </div>
                    <div
                      className={`${
                        m.deleted && "hidden"
                      } chat-message-options`}
                    >
                      <div className="message-buttons">
                        <span
                          onClick={() => replyToUser(m)}
                          className="button_link reply-in-chat"
                          style={{ display: "none" }}
                        >
                          <span className="icon-ico_reply">
                            <FaReply />
                          </span>
                        </span>
                        <span
                          onClick={() => handleDeleteMessage(user._id, m._id)}
                          className="button_link delete-message"
                          style={{ display: "none" }}
                        >
                          <span className="icon-ico_delete">
                            <FaTrash />
                          </span>
                        </span>
                        <span
                          onClick={() => handleBanMessage(user._id, m._id)}
                          className="button_link ban-user"
                          style={{ display: "none" }}
                        >
                          <span className="icon-ico_lock">
                            <FaLock />
                          </span>
                        </span>
                        <span
                          onClick={() => handleTimeoutMessage(user._id, m._id)}
                          className="button_link timeout-user"
                          style={{ display: "none" }}
                        >
                          <span className="icon-ico_lock">
                            <FaRegClock />
                          </span>
                        </span>
                        <span
                          className={`like-button ${
                            m?.likes > 0 ? "liked" : ""
                          } ${
                            m?.likedBy?.findIndex(
                              (x) =>
                                x?._id?.toString() === user?._id?.toString()
                            ) !== -1
                              ? "liked-me"
                              : ""
                          } button_link`}
                          onClick={() => handleLikeMessage(user._id, m._id)}
                        >
                          <span className="icon-ico_heart_empty">
                            <FaHeart />
                          </span>
                        </span>

                        <div
                          className={`${
                            m.likes === 0 ? "hidden" : ""
                          } message-likes-amount`}
                        >
                          {m.likes}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="chat-message__text">
                    {!m.deleted && (
                      <div className="message-rich-text">
                        {m.predictions.length === 1 && (
                          <div
                            className={`chat-transaction chat-transaction_widget ${
                              m.predictions[0].status === 2
                                ? "high-roll-win"
                                : m.predictions[0].status === 1
                                ? "high-roll-lost"
                                : m.predictions[0].status === 3
                                ? "high-roll-void"
                                : m.predictions[0].status === 0
                                ? "high-roll-ns"
                                : null
                            } `}
                          >
                            <span className="widget-label">
                              {m.predictions[0].status === 2
                                ? "predictie castigata"
                                : m.predictions[0].status === 1
                                ? "predictie pierduta"
                                : m.predictions[0].status === 3
                                ? "predictie anulata"
                                : "predictie in desfasurare"}
                            </span>
                            {m.predictions[0].status === 0 ? null : (
                              <span className="widget-label">
                                {m.predictions[0].status === 2
                                  ? "predictie castigata"
                                  : m.predictions[0].status === 1
                                  ? "predictie pierduta"
                                  : m.predictions[0].status === 3
                                  ? "predictie anulata"
                                  : "predictie in desfasurare"}
                              </span>
                            )}
                            <div className="flex-row align-center">
                              <div className="flex-column flex-column_align-start mt-2">
                                <Link
                                  to={`?modal=prediction&id=${m.predictions[0]._id}`}
                                  className="widget-title break-words"
                                >
                                  {m.predictions[0].type !== "simplu" ? (
                                    `BILET COMPUS ${m.predictions[0].evenimente.length} EVENIMENTE`
                                  ) : (
                                    <div>
                                      <span>
                                        {
                                          m.predictions[0].evenimente[0]
                                            .eveniment.teams.home.name
                                        }
                                      </span>
                                      {m?.predictions[0].status !== 0 &&
                                      m?.predictions[0].status !== 3 ? (
                                        <>
                                          <span className="mx-1">
                                            {
                                              m.predictions[0].evenimente[0]
                                                .eveniment.goals.home
                                            }
                                          </span>
                                          <span>-</span>
                                          <span className="mx-1">
                                            {
                                              m.predictions[0].evenimente[0]
                                                .eveniment.goals.away
                                            }
                                          </span>
                                        </>
                                      ) : (
                                        <span className="mx-1">-</span>
                                      )}
                                      <span>
                                        {
                                          m.predictions[0].evenimente[0]
                                            .eveniment.teams.away.name
                                        }
                                      </span>
                                    </div>
                                  )}
                                </Link>
                                <div className="widget-hint-flex">
                                  Adaugat de&nbsp;
                                  <span
                                    className="link-name link-name_custom"
                                    data-hash="7a3977765a"
                                  >
                                    {m?.author?.name}
                                  </span>
                                  <span className="ml-2 ">
                                    cota {m.predictions[0].totalOdd}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    <div style={{ lineHeight: "18px" }}>
                      {m.deleted ? (
                        <span className="italic">Mesaj sters</span>
                      ) : (
                        highlightText(m, users)
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ChatMessage;

import React from "react";
import { FaUserAlt } from "react-icons/fa";
import { MdChat, MdSportsSoccer } from "react-icons/md";
import { RiMenuUnfoldFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const BottomToolbar = ({
  handleRegisterModal,
  handlePanel,
  handleShowChat,
  notificationsCount,
  mobileRef,
  showChat,
  panel,
  setDropdown,
  dropdown,
  isLoggedIn,
  toggleMobileMenu,
  mobileMenu,
  setMobileMenu,
}) => {
  const urllocation = window.location;
  const navigate = useNavigate();
  const handleDashboard = () => {
    if (mobileMenu) {
      setMobileMenu();
    }
    if (showChat) {
      handleShowChat();
    }
    navigate("/");
  };
  const handleProfile = () => {
    if (isLoggedIn) {
      setDropdown(!dropdown);
      setMobileMenu(false);
    } else {
      handleRegisterModal();
      setMobileMenu(false);
    }

    if (showChat && isLoggedIn) {
      handleShowChat();
      setDropdown(!dropdown);
    } else if (showChat && !isLoggedIn) {
      handleShowChat();
    }
  };

  const handleChat = () => {
    if (mobileMenu) {
      setMobileMenu();
      handleShowChat();
    } else {
      handleShowChat();
    }
  };

  const handleMenu = () => {
    if (showChat) {
      handleShowChat();
      toggleMobileMenu();
    } else {
      toggleMobileMenu();
    }
  };
  return (
    <div id="react-bottom-toolbar">
      <div className="toolbar-mobile-bottom">
        <div className="toolbar-mobile-bottom-wrapper">
          <div
            className={`toolbar-mobile-bottom-item ${
              mobileMenu ? "active" : ""
            }`}
            onClick={handleMenu}
          >
            <RiMenuUnfoldFill className="text-xl" />
            MENU
          </div>
          <div
            onClick={handleDashboard}
            className={`toolbar-mobile-bottom-item ${
              urllocation.pathname === "/" &&
              !showChat &&
              !mobileMenu &&
              !dropdown
                ? "active"
                : ""
            }`}
          >
            <MdSportsSoccer className="text-xl" />
            DASHBOARD
          </div>
          <div
            className={`toolbar-mobile-bottom-item  tablet-hidden ${
              showChat ? "active" : ""
            }`}
            onClick={handleChat}
          >
            <MdChat className="text-xl" />
            CHAT
            {notificationsCount > 0 ? (
              <div className="notification-badge"></div>
            ) : (
              ""
            )}
          </div>
          <div
            className={`toolbar-mobile-bottom-item tablet-hidden ${
              dropdown && isLoggedIn ? "active" : ""
            }`}
            ref={mobileRef}
            onClick={handleProfile}
          >
            <FaUserAlt className="text-xl" />
            PROFIL
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomToolbar;

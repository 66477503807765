import React, { useState, useEffect } from "react";
import { RiDiscussFill, RiDiscussLine, RiSendPlane2Fill } from "react-icons/ri";
import {
  postCommentPrediction,
  deleteCommentPrediction,
  editCommentPrediction,
  addReplyToComment,
  deleteReplyCommentPrediction,
  editReplyCommentPrediction,
  likeCommentPrediction,
} from "../../redux/features/predictions/predictionsSlice";
import { useDispatch } from "react-redux";
import Comment from "../../comp/Timeline/Comment";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import socket from "../../utils/SocketDotIo/Socket";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const FooterPrediction = ({
  isLoggedIn,
  eveniment,
  user,
  handleLikePrediction,
  isLoadingActions,
  setMessages,
  messages,
  setShowChat,
  showChat,
  newMessage,
  setNewMessage,
  isSharedPrediction,
  setIsSharedPrediction,
}) => {
  const token = localStorage.getItem("authToken");
  const dispatch = useDispatch();
  const reversedComments = [...eveniment.commentedBy].reverse();
  const [isComments, setIsComments] = useState(false);
  const [comment, setComment] = useState("");
  const [repliesShown, setRepliesShown] = useState(3);

  const toggleComments = () => {
    setIsComments(!isComments);
  };

  const handleAddComment = (e) => {
    setComment(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const commentData = {
      predictionId: eveniment._id,
      comment: comment,
      userId: user._id,
    };
    console.log(commentData);
    dispatch(postCommentPrediction(commentData));
    setComment("");
  };

  const handleDeleteComment = (id) => {
    const commentData = {
      predictionId: eveniment._id,
      user: user,
      commentId: id,
    };
    console.log(commentData);
    dispatch(deleteCommentPrediction(commentData));
  };

  const handleShowMoreComments = () => {
    if (repliesShown < eveniment?.comments) {
      setRepliesShown(eveniment?.comments);
    } else {
      setIsComments(false);
      setRepliesShown(3);
    }
  };

  const sendToChat = async (e) => {
    e.preventDefault();

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      setNewMessage((prevMessage) => {
        const newHashtag = `#${eveniment._id} `;
        // Check if the new hashtag is already in the message
        if (prevMessage && prevMessage.includes(newHashtag)) {
          // If it is, don't add it again
          return prevMessage;
        } else {
          // If it isn't, add it to the end of the message
          return (prevMessage || "") + newHashtag;
        }
      });

      if (!showChat) {
        setShowChat(true);
        setIsSharedPrediction(true);
      }
    } catch (error) {
      toast.error("A aparut o eroare la send message");
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex w-full items-center justify-between">
        <div
          className="flex flex-wrap reactions h-7"
          style={{ marginLeft: "-10px" }}
        >
          <button
            disabled={!isLoggedIn || isLoadingActions}
            onClick={() => handleLikePrediction(user?._id, eveniment._id)}
            className={`button_like-prediction flex items-center justify-center text-gray-500 uppercase ml-4 pointer`}
          >
            <div className="heart-btn">
              <div
                className={`content-hearth ${
                  eveniment.likedBy.findIndex(
                    (x) => x?._id?.toString() === user?._id?.toString()
                  ) !== -1
                    ? "heart-active"
                    : ""
                }`}
              >
                <span
                  className={`heart ${
                    eveniment?.likedBy.findIndex(
                      (x) => x?._id?.toString() === user?._id?.toString()
                    ) !== -1
                      ? "heart-active"
                      : ""
                  }`}
                />
              </div>
            </div>
          </button>
          <div className="flex ml-10 user-avatars-stack">
            {eveniment?.likedBy.slice(0, 3).map((user, i) => (
              <Link
                data-tooltip-id="tooltip-user-like"
                data-tooltip-content={user?.name}
                key={i}
                to={`/p/${user?.handle}`}
                className="user-avatar w-4 h-4 hover:z-10 cursor-pointer bg-gray-200 dark:bg-gray-900 dark:border-gray-900"
              >
                <img
                  key={user._id}
                  src={user.photo}
                  alt={`${user.name}'s photo`}
                  className="w-4 h-4 rounded-full avatar-abbreviation"
                />
              </Link>
            ))}
            {eveniment?.likes === 0 ? null : (
              <span className="select-none text-xs text-gray-500 flex items-center justify-center ml-1">
                {eveniment?.likes}
              </span>
            )}
            <Tooltip
              id="tooltip-user-like"
              place="top"
              style={{
                fontSize: 12,
                paddingTop: 0,
                paddingBottom: 0,
                textTransform: "none",
                borderRadius: "12px",
                color: "#000",
                lineHeight: "32px",
                backgroundColor: "#fff",
              }}
            />
          </div>
        </div>
        <div className="flex" style={{ gap: 8 }}>
          <button
            data-tooltip-id="tooltip-footer-predictions"
            data-tooltip-content={
              !isLoggedIn
                ? "Necesita autentificare"
                : "Distribuie predictia in chat"
            }
            onClick={sendToChat}
            className={`${
              !isLoggedIn ? "cursor-not-allowed" : "cursor-pointer"
            } button-prediction-footer button_ico`}
          >
            <RiSendPlane2Fill className="text-lg" />
            <Tooltip
              id="tooltip-footer-predictions"
              data-tooltip-delay-show={200}
              place="left"
              style={{
                fontSize: 12,
                paddingTop: 0,
                paddingBottom: 0,
                textTransform: "none",
                borderRadius: "12px",
                color: "#fff",
                lineHeight: "32px",
                backgroundColor: "#3a434b",
              }}
            />
          </button>
          <button
            className="button-prediction-footer button_ico"
            onClick={toggleComments}
          >
            <div className="flex">
              {eveniment.comments === 0 ? (
                <>
                  <RiDiscussLine className="text-lg mr-2" />
                </>
              ) : (
                <>
                  <RiDiscussFill className="text-lg mr-2 orange-color" />
                  <span className="orange-color" style={{ fontSize: 12 }}>
                    {eveniment.comments}
                  </span>
                </>
              )}
            </div>
          </button>
        </div>
      </div>
      <div
        className={`tip__comments mt-4 w-full ${
          isComments ? "flex" : "hidden"
        }`}
      >
        <div className="comments-wrapper w-full">
          <div className="comments-form bg-slate-100">
            <form className="comments-form__form" onSubmit={handleSubmit}>
              <div className="form-textarea form-textarea--resizable">
                <textarea
                  disabled={isLoadingActions || !isLoggedIn}
                  value={comment}
                  id="textarea-disc"
                  onChange={handleAddComment}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit(e);
                    }
                  }}
                  rows="1"
                  placeholder={`${
                    isLoggedIn
                      ? "Scrie un comentariu si apasa enter"
                      : "Autentifica-te pentru a lasa un comentariu"
                  }`}
                  className={`bg-slate-100 border-none focus:border-none focus:ring-0 border-none text-gray-900 text-sm w-full placeholder:text-xs dark:bg-bgMain2 dark:placeholder-gray-400 dark:text-white mb-0 hover:ring-white `}
                ></textarea>
              </div>
            </form>
          </div>
          {eveniment.comments === 0 ? null : (
            <div className="comments relative">
              <div className="comments-item-wrapper">
                {reversedComments.slice(0, repliesShown).map((c, index) => (
                  <Comment
                    key={index}
                    user={user}
                    c={c}
                    handleDeleteComment={handleDeleteComment}
                    eveniment={eveniment}
                    editCommentPrediction={editCommentPrediction}
                    isLoggedIn={isLoggedIn}
                    addReplyToComment={addReplyToComment}
                    isLoadingActions={isLoadingActions}
                    deleteReplyCommentPrediction={deleteReplyCommentPrediction}
                    editReplyCommentPrediction={editReplyCommentPrediction}
                    setRepliesShown={setRepliesShown}
                    repliesShown={repliesShown}
                    likeCommentPrediction={likeCommentPrediction}
                  />
                ))}
              </div>
              <div className="flex items-center justify-center mt-4">
                {eveniment?.comments > 3 ? (
                  <button
                    onClick={handleShowMoreComments}
                    className="button button_small"
                  >
                    {repliesShown < eveniment?.comments
                      ? "Arata toate comentariile"
                      : "Ascunde toate comentariile"}
                  </button>
                ) : null}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FooterPrediction;

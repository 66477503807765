import api from "../../../utils/api";

// Add prediction
const addPrediction = async (predictionData) => {
  const response = await api.post("predictions/prediction", predictionData);
  return response.data;
};
// Get all predictions
const getAllPredictions = async ({
  totalResults,
  predictionStatus,
  searchPrediction,
  onlyFollowedUsers,
  userId,
}) => {
  const response = await api.get(
    `predictions/allpredictions?totalResults=${totalResults}&predictionStatus=${predictionStatus}&searchPrediction=${searchPrediction}&onlyFollowedUsers=${onlyFollowedUsers}&userId=${userId}`
  );
  return response.data;
};

// Get eveniment from prediction
const getEvenimentFromPrediction = async (evenimentData) => {
  const response = await api.post(
    "predictions/evenimentbyindex",
    evenimentData
  );
  return response.data;
};

// Get prediction by id
const getPredictionById = async (predictionData) => {
  const response = await api.post("predictions/predictionbyid", predictionData);
  return response.data;
};

// Get prediction profit > 10
const getPredictionProfit10 = async () => {
  const response = await api.get("predictions/predictionprofit10");
  return response.data;
};

// Get and store prediction by id
const getAndStorePredictionById = async (predictionData) => {
  const response = await api.post(
    "predictions/getAndStorePredictionById",
    predictionData
  );
  return response.data;
};

// Get all predictions from eveniment
const getAllPredictionsFromEveniment = async (evenimentData) => {
  const response = await api.post(
    "predictions/getallpredictionsforfixture",
    evenimentData
  );
  return response.data;
};

// Update prediction
const updatePrediction = async (matchData) => {
  const response = await api.post("predictions/updateprediction", matchData);
  return response.data;
};

// like prediction
const likePrediction = async (likeData) => {
  const response = await api.put("predictions/like", likeData);
  return response.data;
};

// dislike prediction
const dislikePrediction = async (dislikeData) => {
  const response = await api.put("predictions/dislike", dislikeData);
  return response.data;
};

// post comment prediction
const postCommentPrediction = async (commentData) => {
  const response = await api.put("predictions/comment", commentData);
  return response.data;
};

// delete comment prediction
const deleteCommentPrediction = async (commentData) => {
  const response = await api.post("predictions/deleteComment", commentData);
  return response.data;
};

// edit comment prediction
const editCommentPrediction = async (commentData) => {
  const response = await api.put("predictions/editComment", commentData);
  return response.data;
};

// add reply to comment
const addReplyToComment = async (replyData) => {
  const response = await api.put("predictions/comment/reply", replyData);
  return response.data;
};

// delete reply to comment
const deleteReplyCommentPrediction = async (replyData) => {
  const response = await api.post("predictions/deleteReply", replyData);
  return response.data;
};

// edit reply to comment prediction
const editReplyCommentPrediction = async (replyData) => {
  const response = await api.put("predictions/editReply", replyData);
  return response.data;
};

// like comment
const likeCommentPrediction = async (commentData) => {
  const response = await api.put("predictions/comment/like", commentData);
  return response.data;
};

// Get all predictions for user
const getAllPredictionsForUser = async (predictionsData) => {
  const response = await api.post(
    "predictions/allpredictionsbyuser",
    predictionsData
  );
  return response.data;
};

const predictionsService = {
  addPrediction,
  getAllPredictions,
  getEvenimentFromPrediction,
  updatePrediction,
  getAllPredictionsFromEveniment,
  likePrediction,
  dislikePrediction,
  getPredictionById,
  getPredictionProfit10,
  getAndStorePredictionById,
  postCommentPrediction,
  deleteCommentPrediction,
  editCommentPrediction,
  addReplyToComment,
  deleteReplyCommentPrediction,
  editReplyCommentPrediction,
  likeCommentPrediction,
  getAllPredictionsForUser,
};

export default predictionsService;

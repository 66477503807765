import axios from "axios";

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const getAuthToken = () => {
  if (typeof localStorage === "undefined") return null;
  const token = localStorage.getItem("authToken");
  if (!token) return null;
  return token;
};

const api = axios.create({
  baseURL: `${BACKEND_URL}/api`,
});

api.interceptors.request.use((config) => {
  try {
    const token = getAuthToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  } catch {}
  return config;
});

export default api;

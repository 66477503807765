import React from "react";

const DuckLoader = ({ rowCount }) => {
  const rows = Array.from({ length: rowCount }, (_, index) => index + 1);

  return (
    <div className="preloader-section mt-4">
      {rows.map((row) => (
        <div key={row} className="preloader-section__row">
          <div className="preloader"></div>
          <div className="preloader preloader_medium"></div>
          <div className="preloader preloader_small"></div>
        </div>
      ))}
    </div>
  );
};

export default DuckLoader;

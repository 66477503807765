import React from "react";
import { Link } from "react-router-dom";

const HomePage = () => {
  return (
    <div className="wrapper" id="landing-root">
      <header className="header-lp fixed" id="header-lp">
        <div className="container-lp">
          <div className="header-lp__holder">
            <div className="header-lp__left-block">
              <div className="header-lp__logo">LOGO</div>

              <nav className="navigation-menu">
                {/* <ul className="navigation-menu__list">
                  <li className="navigation-menu__item">
                    <Link to="/dashboard" className="navigation-menu__link">
                      Evenimente
                    </Link>
                  </li>
                  <li className="navigation-menu__item">
                    <Link to="/dashboard" className="navigation-menu__link">
                      Biletul zilei
                    </Link>
                  </li>
                </ul> */}
              </nav>
            </div>
            <div className="header-lp__right-block">
              <div className="header-lp__actions " data-header-lp-login="">
                <Link
                  to="/?modal=inregistrare"
                  className="button registration button_medium mr-2"
                  data-ael="signup"
                >
                  Inregistrare
                </Link>
                <Link
                  to="/?modal=autentificare"
                  className="button button_secondary button_medium "
                  data-ael="login"
                >
                  Autentificare
                </Link>
              </div>
              <div className="user-info hide" data-header-lp-user="">
                <a href="" className="profile" data-ael="my-info">
                  <div className="profile-wrapper">
                    <div className="profile-name">
                      <div className="profile-name__wrapper">
                        <div className="profile-block-avatar">
                          <div className="user-avatar-wrap level-5">
                            <div
                              className="user-avatar user-avatar_default"
                              style={{
                                backgroundImage:
                                  "url('../assets/images/landingHome/ico_avatar_dark.svg')",
                                backgroundSize: "cover",
                              }}
                            >
                              <img
                                src=""
                                alt="User avatar"
                                style={{ display: "block" }}
                                width="30"
                                height="30"
                                className="hide"
                              />
                            </div>
                            <span className="user-level-badge">53</span>
                          </div>
                        </div>
                        <div className="profile-name__holder">
                          <span className="name" data-username="">
                            ralucaioanavv
                          </span>
                          <div className="user-level user-level_default">
                            <span>
                              <span className="icon-ico_foot colorized"></span>
                              <span className="icon-ico_foot colorized"></span>
                              <span className="icon-ico_foot colorized"></span>
                              <span className="icon-ico_foot"></span>
                              <span className="icon-ico_foot"></span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <main>
        <section className="welcome">
          <div className="welcome__wrapper _fade-in _animate _active">
            <div className="container-lp">
              <div className="row">
                <div className="col">
                  <div className="welcome__content">
                    <h1>
                      Best Bitcoin <span>Dice Game</span> Since 2016<sup>*</sup>
                    </h1>
                    <p>
                      Creative approach to dice and sports betting. The most
                      advanced and popular game among bitcoin gamblers.
                    </p>
                    <div className="btn-holder-flex">
                      <a
                        href="https://duckdice.io/dice"
                        className="btn btn_xl"
                        data-ael="start-winning"
                      >
                        start winning
                      </a>
                    </div>
                    <div>
                      <sup>*</sup> according to our most loyal players :)
                    </div>
                    <ul className="welcome__list">
                      <li className="welcome__list-item">
                        <div className="welcome__list-award">
                          <strong>6</strong>
                          <i className="welcome__list-award-icon icon-venok"></i>
                        </div>
                        <span>
                          Years
                          <br />
                          Active
                        </span>
                      </li>
                      <li className="welcome__list-item">
                        <div className="welcome__list-award">
                          <strong>5</strong>
                          <i className="welcome__list-award-icon icon-venok"></i>
                        </div>
                        <span>
                          Licenses &amp;
                          <br />
                          certificates
                        </span>
                      </li>
                      <li className="welcome__list-item">
                        <div className="welcome__list-award">
                          <strong>
                            30<i>+</i>
                          </strong>
                          <i className="welcome__list-award-icon icon-venok"></i>
                        </div>
                        <span>
                          supported
                          <br />
                          currencies
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col">
                  <div className="welcome__media">
                    <img
                      src="https://duckdice.io/assets/images/landingHome/welcome-layers/img-layer-1.svg?id=678430e9c0e3508b1cd6ad7d672ab004"
                      data-speed="0"
                      width="351"
                      height="295"
                      className="layer layer-1"
                      alt="Your perfect bitcoin dice experience"
                    />
                    <img
                      src="https://duckdice.io/assets/images/landingHome/welcome-layers/img-layer-2.svg?id=11a6c6196866cdadcd44acdd0b8c2172"
                      width="496"
                      height="267"
                      data-speed="8"
                      className="layer layer-2"
                      alt="Your perfect bitcoin dice experience"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="banner _fade-in _animate _active">
          <div className="container-lp">
            <div className="bonus-banner bonus-banner_generic">
              <div className="bonus-banner__media">
                <img
                  src="https://duckdice.io/assets/images/landingHome/banner-generic-img.svg?id=0bb9ce568ec45b79fc9dcf2bc7f9585e"
                  width="140"
                  height="108"
                  alt="Bonuses"
                />
              </div>
              <div className="bonus-banner__text">
                <h3>100% Deposit Bonus</h3>
                <p>
                  Every minute in the game counts! Find your favorite Bonus on
                  DuckDice.
                </p>
              </div>
              <div className="bonus-banner__action">
                <a
                  href="https://duckdice.io/bonuses"
                  className="btn"
                  data-ael="bonus-banner"
                >
                  Get bonus
                </a>
              </div>
            </div>
          </div>
        </div>
        <section className="tiles">
          <div className="container-lp">
            <div className="tiles__holder">
              <div className="tiles__item _fade-in _animate _active">
                <img
                  src="https://duckdice.io/assets/images/landingHome/tiles/icon-playing.svg?id=b7ea07e3f99953c208a64f3bc794aff0"
                  width="60"
                  height="60"
                  alt="Total users playing dice now"
                />
                <div className="tiles__item-content">
                  <span className="tiles__item-sub-title">Playing Now</span>
                  <span className="tiles__item-count">480</span>
                </div>
              </div>
              <div className="tiles__item _fade-in _animate _active">
                <img
                  src="https://duckdice.io/assets/images/landingHome/tiles/icon-jackpot.svg?id=c1b2b7f4160600ce8115a117b86e3735"
                  width="60"
                  height="60"
                  alt="Sweet bitcoin casino jackpot"
                />
                <div className="tiles__item-content">
                  <span className="tiles__item-sub-title">Jackpot</span>
                  <span className="tiles__item-count">
                    <i>$</i>
                    33,240
                  </span>
                </div>
              </div>
              <div className="tiles__item _fade-in _animate _active">
                <img
                  src="https://duckdice.io/assets/images/landingHome/tiles/icon-payouts.svg?id=6c6c525184cb87c1f3851f34c02a8ce4"
                  width="60"
                  height="60"
                  alt="Summary of all casino withdrawals"
                />
                <div className="tiles__item-content">
                  <span className="tiles__item-sub-title">Payouts</span>
                  <span className="tiles__item-count">
                    <i>$</i>
                    130,280,859
                  </span>
                </div>
              </div>
              <div className="tiles__item tiles__item_label _fade-in _animate _active">
                <div className="tiles__item-label">
                  <div className="tiles__item-label-wrapper">
                    <strong>
                      <i className="icon-bch_s"></i>3
                    </strong>
                    <span>max win</span>
                  </div>
                </div>
                <img
                  src="https://duckdice.io/assets/images/landingHome/tiles/icon-bets.svg?id=f36a12c0976927a689b6527cfdefa1df"
                  width="60"
                  height="60"
                  alt="Total # of crypto dice bets"
                />
                <div className="tiles__item-content">
                  <span className="tiles__item-sub-title">Bets</span>
                  <span className="tiles__item-count">51,145,197,058</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="cards">
          <div className="container-lp">
            <div className="cards__holder">
              <div className="cards__item cards__item_dice _fade-in _animate _active">
                <div className="cards__item-media">
                  <img
                    src="https://duckdice.io/assets/images/landingHome/cards/img-duck-dice.svg?id=2832becdf12ca5da69fec54e94f55c91"
                    width="555"
                    height="295"
                    alt="Original Pro Dice"
                  />
                </div>
                <div className="cards__item-content">
                  <h3 className="cards__item-title">Original Pro Dice</h3>
                  <div className="cards__item-description">
                    Auto and Flash betting, advanced strategies builder, native
                    multi-currency, 1 satoshi min bet, demo mode, bank feature
                    and so much more!
                  </div>
                  <a href="https://duckdice.io/dice" className="btn">
                    play dice
                  </a>
                </div>
              </div>
              <div className="cards__item cards__item_sport _fade-in _animate _active">
                <div className="cards__item-media">
                  <img
                    src="https://duckdice.io/assets/images/landingHome/cards/img-duck-sport.svg?id=229bb523fd0acebaa99ceac25c9fc618"
                    width="555"
                    height="295"
                    alt="Sport Betting"
                  />
                </div>
                <div className="cards__item-content">
                  <h3 className="cards__item-title">Sport Betting</h3>
                  <div className="cards__item-description">
                    <strong>100,000+</strong> real live monthly events,{" "}
                    <strong>50,000+</strong> pre match monthly events,{" "}
                    <strong>15,000+</strong> international leagues,{" "}
                    <strong>100+</strong> sports and much more!
                  </div>
                  <a href="https://duckdice.io/sport" className="btn">
                    score bet
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="tiles tiles_bg">
          <div className="container-lp">
            <div className="tiles__header-lp">
              <h2>
                <i className="tiles__header-lp-icon icon-crown"></i>DD Club
                Bonuses
              </h2>
              <p>
                We offer up to 15% Rakeback and free faucets in BTC or in
                another supported cryptocurrency. Rakeback aka cashback can be
                claimed as often as once every 15 minutes and there are special
                weekly and monthly rakebacks.
              </p>
            </div>
            <div className="tiles__holder">
              <div className="tiles__item _fade-in _animate _active">
                <img
                  src="https://duckdice.io/assets/images/landingHome/tiles/icon-rakeback.svg?id=5b5aaaccca8335a17e8263ced58e2bb8"
                  width="60"
                  height="60"
                  alt="Rakeback"
                />
                <div className="tiles__item-content">
                  <h4 className="tiles__item-title">Rakeback</h4>
                  <p className="tiles__item-description">
                    Daily bonus that is available every 15 minutes
                  </p>
                </div>
              </div>
              <div className="tiles__item _fade-in _animate _active">
                <img
                  src="https://duckdice.io/assets/images/landingHome/tiles/icon-hot-friday.svg?id=3a97f3327175f939bc423329f5fadb00"
                  width="60"
                  height="60"
                  alt="Hot Friday"
                />
                <div className="tiles__item-content">
                  <h4 className="tiles__item-title">Hot Friday</h4>
                  <p className="tiles__item-description">
                    Weekly bonus that is available every Friday
                  </p>
                </div>
              </div>
              <div className="tiles__item _fade-in _animate _active">
                <img
                  src="https://duckdice.io/assets/images/landingHome/tiles/icon-ducks7.svg?id=be9e53b790ad6b0ff3f5196fcc8eeb7e"
                  width="60"
                  height="60"
                  alt="Duck’s 7th"
                />
                <div className="tiles__item-content">
                  <h4 className="tiles__item-title">Duck’s 7th</h4>
                  <p className="tiles__item-description">
                    Monthly bonus that is available every 7th day
                  </p>
                </div>
              </div>
              <div className="tiles__item _fade-in _animate _active">
                <img
                  src="https://duckdice.io/assets/images/landingHome/tiles/icon-faucet.svg?id=365176a3075ce841ff8a81ff1a754b8c"
                  width="60"
                  height="60"
                  alt="Free Faucet"
                />
                <div className="tiles__item-content">
                  <h4 className="tiles__item-title">Free Faucet</h4>
                  <p className="tiles__item-description">
                    Free bonus that is available up to 55 times a day
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="info-section info-section_reverse">
          <div className="container-lp">
            <div className="info-section__holder">
              <div className="info-section__media">
                <img
                  src="https://duckdice.io/assets/images/landingHome/info-section/img-duck-affiliate.svg?id=733e9b16ffec01667615b6c495767a79"
                  width="539"
                  height="539"
                  alt="Affiliate program"
                  className="_animate _scale-in _active"
                />
              </div>
              <div className="info-section__content _fade-in _animate _active">
                <h2>Affiliate Program</h2>
                <p>
                  Get passive income with DuckDice’s Affiliate program. Refer a
                  friend and earn up to <strong>30%</strong> commission!
                </p>
                <div className="info-section__feature">
                  <div className="info-section__feature-item">
                    <img
                      src="https://duckdice.io/assets/images/landingHome/info-section/icon-percent.svg?id=160bd3a5299df4459ac7e75baa05619e"
                      width="30"
                      height="30"
                      alt="percent"
                    />
                    <div>
                      <span className="info-section__feature-count">30%</span>
                      <span className="info-section__feature-description">
                        max commission
                      </span>
                    </div>
                  </div>
                  <div className="info-section__feature-item">
                    <img
                      src="https://duckdice.io/assets/images/landingHome/info-section/icon-dollar.svg?id=8d929b1442c38ba403bd8fb273176692"
                      width="30"
                      height="30"
                      alt="dollar"
                    />
                    <div>
                      <span className="info-section__feature-count">
                        $983,839.09
                      </span>
                      <span className="info-section__feature-description">
                        Commission paid
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="info-section info-section_bg">
          <div className="container-lp">
            <div className="info-section__holder">
              <div className="info-section__media">
                <img
                  src="https://duckdice.io/assets/images/landingHome/info-section/img-duck-deposit.svg?id=57c5f8e7863484afddea06f58fa34514"
                  width="539"
                  height="539"
                  alt="Instant Deposit and Withdrawal"
                  className="_animate _scale-in _active"
                />
              </div>
              <div className="info-section__content _fade-in _animate _active">
                <h2>Instant Deposit and Withdrawal</h2>
                <p>
                  Over <strong>90%</strong> of deposits are credited instantly
                  making your crypto gambling experience as smooth as
                  practically possible. It means we add a deposit to your
                  balance even before the transaction is processed so you won’t
                  need to wait. Most withdrawals are processed instantly as
                  well!
                </p>
                <div className="info-section__feature">
                  <div className="info-section__feature-item">
                    <img
                      src="https://duckdice.io/assets/images/landingHome/info-section/icon-check.svg?id=e86c6090604b01c9d71496db4a6a5b5c"
                      width="30"
                      height="30"
                      alt="percent"
                    />
                    <div>
                      <span className="info-section__feature-count">99.8%</span>
                      <span className="info-section__feature-description">
                        withdrawals approved instantly
                      </span>
                    </div>
                  </div>
                </div>
                <div className="btn-holder-flex">
                  <a
                    href="https://duckdice.io/dice?modal=registration"
                    className="btn hide"
                    data-bonuses-guest=""
                    data-ael="instant-deposit"
                  >
                    Try now
                  </a>
                  <a
                    href="https://duckdice.io/dice?modal=wallet&amp;tab=deposit"
                    className="btn"
                    data-bonuses-user=""
                    data-ael="instant-deposit"
                  >
                    Try now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="info-section info-section_bg info-section_reverse">
          <div className="container-lp">
            <div className="info-section__holder">
              <div className="info-section__media">
                <img
                  src="https://duckdice.io/assets/images/landingHome/info-section/img-duck-bets.svg?id=67ef6d7bda16cceee74de08b287ac7d0"
                  width="539"
                  height="539"
                  alt="Bets Bets Bets - Manual, Auto, Flash"
                  className="_animate _scale-in _active"
                />
              </div>
              <div className="info-section__content _fade-in _animate _active">
                <h2>Bets Bets Bets - Manual, Auto, Flash</h2>
                <p>
                  We offer 3 betting modes, manual - where every bet is placed
                  by hand, automated betting - where you configure the game
                  settings and just look [or going to make yourself a nice cup
                  of coffee] how the system automatically places them, and flash
                  betting – a new definition of speed.
                  <span className="mark">
                    <i className="icon-path"></i>
                    <strong>100 000</strong> of bets per minute.
                  </span>
                  <br />
                  Zero hunters, are you here?
                </p>
                <a
                  className="info-section__link"
                  href="https://duckdice.io/faq#game"
                  data-ael="betting-modes-rules"
                >
                  More about Betting Modes
                </a>
                <h3 className="h-icon h-icon_gift">High Rollers Bonuses</h3>
                <p>
                  Yes, we know how it hurts to be down and our support will
                  always consider special bonuses for big players!
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="info-section info-section_faucet">
          <div className="container-lp">
            <div className="info-section__holder">
              <div className="info-section__media">
                <img
                  src="https://duckdice.io/assets/images/landingHome/info-section/img-duck-faucet.svg?id=cc54a0e78cdbde910be9007ac43e4382"
                  width="539"
                  height="539"
                  alt="Industry Highest Faucets"
                  className="_animate _scale-in _active"
                />
              </div>
              <div className="info-section__content _fade-in _animate _active">
                <h2>Industry Highest Faucets</h2>
                <p>
                  We kid you not! Whilst most sites are dropping free bonuses
                  and shifting towards rakeback-only models DuckDice still pays
                  hundreds of thousands of USD monthly in faucet earnings only.
                </p>
                <p>
                  The record-high withdrawal from Faucet was{" "}
                  <strong>~200,000 USD</strong> and this is not a joke, it was a
                  verified and paid-out win by one of our players not so long
                  ago. Some people think we are too generous but we believe
                  other market players are too greedy :)
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="currencies _fade-in _animate _active">
          <div className="container-lp">
            <div className="currencies__content">
              <a href="https://duckdice.io/dice" aria-label="Bitcoin">
                <i className="icon-btc_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/ltc-litecoin"
                aria-label="Litecoin"
              >
                <i className="icon-ltc_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/doge-dogecoin"
                aria-label="Dogecoin"
              >
                <i className="icon-doge_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/eth-ethereum"
                aria-label="Ethereum"
              >
                <i className="icon-eth_s"></i>
              </a>
              <a href="https://duckdice.io/dice/dash-dash" aria-label="Dash">
                <i className="icon-dash_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/bch-bitcoin-cash"
                aria-label="Bitcoin Cash"
              >
                <i className="icon-bch_s"></i>
              </a>
              <a href="https://duckdice.io/dice/xmr-monero" aria-label="Monero">
                <i className="icon-xmr_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/bnb-binance-coin"
                aria-label="Binance Coin"
              >
                <i className="icon-bnb_s"></i>
              </a>
              <a href="https://duckdice.io/dice/xrp-ripple" aria-label="Ripple">
                <i className="icon-xrp_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/etc-ethereum-classic"
                aria-label="Ethereum Classic"
              >
                <i className="icon-etc_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/btg-bitcoin-gold"
                aria-label="Bitcoin Gold"
              >
                <i className="icon-btg_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/xlm-stellar"
                aria-label="Stellar"
              >
                <i className="icon-xlm_s"></i>
              </a>
              <a href="https://duckdice.io/dice/zec-zcash" aria-label="Zcash">
                <i className="icon-zec_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/usdt-tether"
                aria-label="Tether"
              >
                <i className="icon-usdt_s"></i>
              </a>
              <a href="https://duckdice.io/dice/trx-tron" aria-label="TRON">
                <i className="icon-trx_s"></i>
              </a>
              <a href="https://duckdice.io/dice/eos-eos" aria-label="EOS">
                <i className="icon-eos_s"></i>
              </a>
              <a href="https://duckdice.io/dice/dai-dai" aria-label="Dai">
                <i className="icon-dai_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/usdc-usd-coin"
                aria-label="USD Coin"
              >
                <i className="icon-usdc_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/ada-cardano"
                aria-label="Cardano"
              >
                <i className="icon-ada_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/busd-binance-usd"
                aria-label="Binance USD"
              >
                <i className="icon-busd_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/dot-polkadot"
                aria-label="Polkadot"
              >
                <i className="icon-dot_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/rvn-ravencoin"
                aria-label="Ravencoin"
              >
                <i className="icon-rvn_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/zen-horizen"
                aria-label="Horizen"
              >
                <i className="icon-zen_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/shib-shiba-inu"
                aria-label="Shiba Inu"
              >
                <i className="icon-shib_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/bttc-bittorrent"
                aria-label="BitTorrent"
              >
                <i className="icon-bttc_s"></i>
              </a>
              <a href="https://duckdice.io/dice/sol-solana" aria-label="Solana">
                <i className="icon-sol_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/matic-polygon"
                aria-label="Polygon"
              >
                <i className="icon-matic_s"></i>
              </a>
              <a href="https://duckdice.io/dice/ftm-fantom" aria-label="Fantom">
                <i className="icon-ftm_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/avax-avalanche"
                aria-label="Avalanche"
              >
                <i className="icon-avax_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/near-near-protocol"
                aria-label="NEAR Protocol"
              >
                <i className="icon-near_s"></i>
              </a>
              <a href="https://duckdice.io/dice/aave-aave" aria-label="Aave">
                <i className="icon-aave_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/atom-cosmos"
                aria-label="Cosmos"
              >
                <i className="icon-atom_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/uni-uniswap"
                aria-label="Uniswap"
              >
                <i className="icon-uni_s"></i>
              </a>
              <a href="https://duckdice.io/dice/decoy-decoy" aria-label="Decoy">
                <i className="icon-decoy_s"></i>
              </a>
            </div>
          </div>
        </section>
        <section className="info-section info-section_reverse info-section_bg">
          <div className="container-lp">
            <div className="info-section__holder">
              <div className="info-section__media">
                <img
                  src="https://duckdice.io/assets/images/landingHome/info-section/img-support.svg?id=55259dad692e001e48cc777f5536048f"
                  width="539"
                  height="539"
                  alt="Support, Live Chat and Community"
                  className="_animate _scale-in _active"
                />
              </div>
              <div className="info-section__content _fade-in _animate _active">
                <h2>Support, Live Chat and Community</h2>
                <p>
                  Our support is around the clock ready to solve any issue. You
                  will always be talking to a person who knows what he is doing
                  and how to help you, no bot answers. You'll love our support
                  angels.
                </p>
                <ul>
                  <li>
                    <span>
                      Support can be contacted via email{" "}
                      <a
                        href="mailto:support@duckdice.io"
                        data-ael="support@duckdice.io"
                      >
                        support@duckdice.io
                      </a>
                    </span>
                  </li>
                  <li>
                    <span>Live Chat is available on site</span>
                  </li>
                  <li>
                    <span>
                      And friendly community with Forum and Help Center is
                      always there for you
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="currencies _fade-in _animate _active">
          <div className="container-lp">
            <div className="currencies__content">
              <a href="https://duckdice.io/dice" aria-label="Bitcoin">
                <i className="icon-btc_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/ltc-litecoin"
                aria-label="Litecoin"
              >
                <i className="icon-ltc_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/doge-dogecoin"
                aria-label="Dogecoin"
              >
                <i className="icon-doge_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/eth-ethereum"
                aria-label="Ethereum"
              >
                <i className="icon-eth_s"></i>
              </a>
              <a href="https://duckdice.io/dice/dash-dash" aria-label="Dash">
                <i className="icon-dash_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/bch-bitcoin-cash"
                aria-label="Bitcoin Cash"
              >
                <i className="icon-bch_s"></i>
              </a>
              <a href="https://duckdice.io/dice/xmr-monero" aria-label="Monero">
                <i className="icon-xmr_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/bnb-binance-coin"
                aria-label="Binance Coin"
              >
                <i className="icon-bnb_s"></i>
              </a>
              <a href="https://duckdice.io/dice/xrp-ripple" aria-label="Ripple">
                <i className="icon-xrp_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/etc-ethereum-classic"
                aria-label="Ethereum Classic"
              >
                <i className="icon-etc_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/btg-bitcoin-gold"
                aria-label="Bitcoin Gold"
              >
                <i className="icon-btg_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/xlm-stellar"
                aria-label="Stellar"
              >
                <i className="icon-xlm_s"></i>
              </a>
              <a href="https://duckdice.io/dice/zec-zcash" aria-label="Zcash">
                <i className="icon-zec_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/usdt-tether"
                aria-label="Tether"
              >
                <i className="icon-usdt_s"></i>
              </a>
              <a href="https://duckdice.io/dice/trx-tron" aria-label="TRON">
                <i className="icon-trx_s"></i>
              </a>
              <a href="https://duckdice.io/dice/eos-eos" aria-label="EOS">
                <i className="icon-eos_s"></i>
              </a>
              <a href="https://duckdice.io/dice/dai-dai" aria-label="Dai">
                <i className="icon-dai_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/usdc-usd-coin"
                aria-label="USD Coin"
              >
                <i className="icon-usdc_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/ada-cardano"
                aria-label="Cardano"
              >
                <i className="icon-ada_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/busd-binance-usd"
                aria-label="Binance USD"
              >
                <i className="icon-busd_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/dot-polkadot"
                aria-label="Polkadot"
              >
                <i className="icon-dot_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/rvn-ravencoin"
                aria-label="Ravencoin"
              >
                <i className="icon-rvn_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/zen-horizen"
                aria-label="Horizen"
              >
                <i className="icon-zen_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/shib-shiba-inu"
                aria-label="Shiba Inu"
              >
                <i className="icon-shib_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/bttc-bittorrent"
                aria-label="BitTorrent"
              >
                <i className="icon-bttc_s"></i>
              </a>
              <a href="https://duckdice.io/dice/sol-solana" aria-label="Solana">
                <i className="icon-sol_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/matic-polygon"
                aria-label="Polygon"
              >
                <i className="icon-matic_s"></i>
              </a>
              <a href="https://duckdice.io/dice/ftm-fantom" aria-label="Fantom">
                <i className="icon-ftm_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/avax-avalanche"
                aria-label="Avalanche"
              >
                <i className="icon-avax_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/near-near-protocol"
                aria-label="NEAR Protocol"
              >
                <i className="icon-near_s"></i>
              </a>
              <a href="https://duckdice.io/dice/aave-aave" aria-label="Aave">
                <i className="icon-aave_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/atom-cosmos"
                aria-label="Cosmos"
              >
                <i className="icon-atom_s"></i>
              </a>
              <a
                href="https://duckdice.io/dice/uni-uniswap"
                aria-label="Uniswap"
              >
                <i className="icon-uni_s"></i>
              </a>
              <a href="https://duckdice.io/dice/decoy-decoy" aria-label="Decoy">
                <i className="icon-decoy_s"></i>
              </a>
            </div>
          </div>
        </section>
      </main>
      <footer className="footer">
        <div className="container-lp">
          <div className="footer__top">
            <div className="footer__top-categories">
              <div className="our-links-block">
                <ul className="our-links">
                  <li>
                    <a
                      href="https://duckdice.io/terms"
                      className="link-name"
                      data-ael="terms-of-use"
                    >
                      Terms Of Use
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://duckdice.io/privacy-policy"
                      className="link-name"
                      data-ael="privacy-policy"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://duckdice.io/about-us"
                      className="link-name"
                      data-ael="about-us"
                    >
                      About Us
                    </a>
                  </li>
                </ul>
                <ul className="our-links">
                  <li>
                    <a
                      href="https://duckdice.io/faq#gamble-aware"
                      className="link-name"
                      data-ael="gamble-aware"
                    >
                      Gamble Aware
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://help.duckdice.io/hc"
                      rel="noopener noreferrer"
                      className="link-name"
                      data-ael="help-center"
                    >
                      Help Center
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://duckdice.io/bitcoin-gambling"
                      className="link-name"
                      data-ael="bitcoin-gambling"
                    >
                      Bitcoin Gambling
                    </a>
                  </li>
                </ul>
                <ul className="our-links">
                  <li>
                    <a
                      href="https://duckdice.io/forum"
                      className="link-name"
                      data-ael="forum"
                    >
                      Forum
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://duckdice.io/fairness"
                      className="link-name"
                      data-ael="fairness"
                    >
                      Fair
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://duckdice.io/faq"
                      className="link-name"
                      data-ael="faq"
                    >
                      FAQ
                    </a>
                  </li>
                </ul>
              </div>
              <div className="review-rating-block">
                <span className="review-header-lp">
                  AVERAGE SATISFACTION LEVEL
                </span>
                <div className="review-stars">
                  <i className="icon-star"></i>
                  <i className="icon-star"></i>
                  <i className="icon-star"></i>
                  <i className="icon-star"></i>
                  <i className="icon-star"></i>
                </div>
                <div
                  itemscope=""
                  itemtype="http://schema.org/WebSite"
                  className="review-rating"
                >
                  <span itemprop="name" className="review-rating-name">
                    DuckDice
                  </span>
                  <img
                    itemprop="image"
                    src="https://duckdice.io/assets/images/logo.svg?id=3cb7163efb976dd7f629b11c5ae5053a"
                    alt="DuckDice"
                    width="37"
                    height="37"
                    className="review-rating-image"
                  />
                  <div>
                    Rated <span className="review-rating-value">5</span>
                    <span className="review-rating-value">/5</span>
                    based on <span>98</span> user reviews
                  </div>
                </div>
              </div>
            </div>
            <address className="address-block">
              <p>
                Sabant B.V. reg. number 145622. Heelsumstraat 51, E-Commercepark
                Unit 102, Curacao. Licence #8048/JAZ2018-018
                <br />
                Payment agent - Defraya Ltd. HE 408759. Loutrakiou 5, Chara
                Venezia Bld, 1st floor, Office 101, 2027 Nicosia, Cyprus.
              </p>
            </address>
          </div>
          <div className="footer__bottom">
            <span className="footer-time">
              <strong>
                Server Time: <span id="footer-time">2023-03-01 01:06:59</span>
              </strong>
            </span>
            <div id="react-demo-button">
              <a
                href="https://duckdice.io/dice?gameMode=demo"
                className="btn btn_demo-mode"
                data-ael="demo-mode"
              >
                Demo Mode
              </a>
            </div>
            <div className="footer-social">
              <ul className="social-links">
                <li className="bitcointalk">
                  <a
                    className="social-links__item"
                    data-for="main"
                    data-tip="Bitcoin forum"
                    href="https://bitcointalk.org/index.php?topic=1676313"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="BitcoinTalk"
                    currentitem="false"
                    data-ael="social-bitcointalk"
                  >
                    <span className="icon-bitcointalk"></span>
                  </a>
                </li>
                <li className="twitter">
                  <a
                    className="social-links__item"
                    data-for="main"
                    data-tip="Twitter page"
                    href="https://twitter.com/duckdiceio"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Twitter"
                    currentitem="false"
                    data-ael="social-twitter"
                  >
                    <span className="icon-twitter"></span>
                  </a>
                </li>
                <li className="facebook">
                  <a
                    className="social-links__item"
                    data-for="main"
                    data-tip="Facebook page"
                    href="https://www.facebook.com/duckdiceio/"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Facebook"
                    currentitem="false"
                    data-ael="social-facebook"
                  >
                    <span className="icon-facebook"></span>
                  </a>
                </li>
                <li className="youtube">
                  <a
                    className="social-links__item"
                    data-for="main"
                    data-tip="Youtube channel"
                    href="https://www.youtube.com/channel/UCnzvjGe-8DYzZO_AWdHOo3A"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Youtube"
                    currentitem="false"
                    data-ael="social-youtube"
                  >
                    <span className="icon-youtube"></span>
                  </a>
                </li>
                <li className="telegram">
                  <a
                    className="social-links__item js-modal-link"
                    href="https://duckdice.io/dice?modal=telegram"
                    data-modal="telegram"
                    data-for="main"
                    data-tip="Telegram Resources"
                    title="Telegram"
                    currentitem="false"
                    data-ael="social-telegram"
                  >
                    <span className="icon-telegram"></span>
                  </a>
                </li>
                <li className="discord">
                  <a
                    className="social-links__item"
                    data-for="main"
                    data-tip="Discord channel"
                    href="https://discord.gg/3RjXcAj"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Discord"
                    currentitem="false"
                    data-ael="social-discord"
                  >
                    <span className="icon-discord"></span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer> */}
    </div>
  );
};

export default HomePage;

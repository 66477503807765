import React from "react";
import { FaEquals, FaTimes } from "react-icons/fa";

const PredictionResult = ({ predictionStored }) => {
  return (
    <div
      className="w-full flex mt-4 pb-4 px-4 items-center justify-center"
      id="scroll-target"
    >
      <div
        className="flex flex-col items-center"
        style={{
          flex: "1 0 82px",
          width: "82px",
          gap: "4px",
        }}
      >
        <div className="text-center mb-1 uppercase leading-none text-sm select-none text-slate-900">
          Total cota
        </div>
        <div className="flex flex-col bg-white shadow-lg flex items-center justify-center w-full h-9 px-2.5 text-sm leading-tight rounded-lg">
          <span
            className="text-roboto gray2nd-color font-bold"
            style={{ fontSize: "13px" }}
          >
            {predictionStored?.totalOdd?.toFixed(2)}
          </span>
        </div>
      </div>
      <div
        className="mx-4 relative top-2.5 flex items-center justify-center"
        style={{ flex: "0 0 auto" }}
      >
        <FaTimes className="text-gray-500" />
      </div>
      <div
        className="flex flex-col items-center"
        style={{
          flex: "1 0 82px",
          width: "82px",
          gap: "4px",
        }}
      >
        <div className="text-center mb-1 uppercase leading-none text-sm select-none text-slate-900">
          Miza (1-10)
        </div>
        <div className="flex flex-col bg-white shadow-lg flex items-center justify-center w-full h-9 px-2.5 text-sm leading-tight rounded-lg">
          <span
            className="text-roboto gray2nd-color font-bold"
            style={{ fontSize: "13px" }}
          >
            {predictionStored?.stake}
          </span>
        </div>
      </div>
      <div
        className="mx-4 relative top-2.5 flex items-center justify-center"
        style={{ flex: "0 0 auto" }}
      >
        <FaEquals className="text-gray-500" />
      </div>
      {predictionStored?.status === 2 ? (
        <div
          className="flex flex-col items-center"
          style={{
            flex: "1 0 82px",
            width: "82px",
            gap: "4px",
          }}
        >
          <div className="text-center mb-1 uppercase leading-none text-sm select-none text-slate-900">
            Profit
          </div>
          <div className="flex flex-col green-bg-color text-white shadow-lg flex items-center justify-center w-full h-9 px-2.5 text-sm leading-tight rounded-lg">
            <span
              className="text-roboto font-bold"
              style={{ fontSize: "13px" }}
            >
              {(
                predictionStored?.totalOdd * predictionStored?.stake -
                predictionStored?.stake
              ).toFixed(2)}
            </span>
            <span
              style={{
                fontSize: "8px",
                opacity: ".8",
                fontWeight: "400",
                textTransform: "uppercase",
              }}
            >
              CASTIGAT
            </span>
          </div>
        </div>
      ) : predictionStored?.status === 1 ? (
        <div
          className="flex flex-col items-center"
          style={{
            flex: "1 0 82px",
            width: "82px",
            gap: "4px",
          }}
        >
          <div className="text-center mb-1 uppercase leading-none text-sm">
            Profit
          </div>
          <div className="flex flex-col red-bg-color text-white shadow-lg flex items-center justify-center w-full h-9 px-2.5 text-sm leading-tight rounded-lg">
            <span
              className="text-roboto font-bold"
              style={{ fontSize: "13px" }}
            >
              -{predictionStored?.stake}
            </span>
            <span
              style={{
                fontSize: "8px",
                opacity: ".8",
                fontWeight: "400",
                textTransform: "uppercase",
              }}
            >
              PIERDUT
            </span>
          </div>
        </div>
      ) : predictionStored?.status === 3 ? (
        <div
          className="flex flex-col items-center"
          style={{
            flex: "1 0 82px",
            width: "82px",
            gap: "4px",
          }}
        >
          <div className="text-center mb-1 uppercase leading-none text-sm">
            Profit
          </div>
          <div className="flex flex-col bg-yellow-300 text-white shadow-lg flex items-center justify-center w-full h-9 px-2.5 text-sm leading-tight rounded-lg">
            <span
              className="text-roboto font-bold"
              style={{ fontSize: "13px" }}
            >
              -
            </span>
            <span
              style={{
                fontSize: "8px",
                opacity: ".8",
                fontWeight: "400",
                textTransform: "uppercase",
              }}
            >
              ANULAT
            </span>
          </div>
        </div>
      ) : (
        <div
          className="flex flex-col items-center"
          style={{
            flex: "1 0 82px",
            width: "82px",
            gap: "4px",
          }}
        >
          <div className="text-center mb-1 uppercase leading-none text-sm">
            Potential
          </div>
          <div className="flex flex-col bg-white gray2nd-color  shadow-lg flex items-center justify-center w-full h-9 px-2.5 text-sm leading-tight rounded-lg">
            <span
              className="text-roboto font-bold"
              style={{ fontSize: "13px" }}
            >
              {(predictionStored?.totalOdd * predictionStored?.stake).toFixed(
                2
              )}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default PredictionResult;

import React from "react";
import { FaAngleUp } from "react-icons/fa";

const TicketInBottom = ({
  evenimentePeBilet,
  handleShowMatches,
  showMatches,
  showNotification,
}) => {
  return (
    <div
      className={`${
        evenimentePeBilet.length > 0 && !showMatches && !showNotification
          ? "flex"
          : "hidden"
      } betslip betslip--sidebar `}
      onClick={handleShowMatches}
    >
      <div className="betslip__head create-tip-accumulator__header--dark">
        <div className="betslip-mini pointer">
          <div className="tip-header ">
            <div className="create-tip-accumulator__header__count">
              <img
                width="18"
                height="24"
                className="create-tip-accumulator__header__count__img lazyLoad isLoaded"
                src="https://cdn.oddspedia.com/images/static/icons/bet-summary-white.svg"
              />
              <div className="create-tip-accumulator__header__count__val">
                {evenimentePeBilet.length}
              </div>
            </div>
            <div
              className="create-tip-accumulator__header__text uppercase font-semibold flex flex-col items-start"
              style={{ fontSize: "12px", lineHeight: "1.38" }}
            >
              <span>
                {evenimentePeBilet.length === 1
                  ? "eveniment adaugat"
                  : "evenimente adaugate"}
              </span>
              <span className="uppercase" style={{ fontSize: "10px" }}>
                TIP: {evenimentePeBilet.length === 1 ? "SIMPLU" : "MULTIPLU"}
              </span>
            </div>
          </div>
          <div className="button_square button_square-xs">
            <span className="icon-keyboard_arrow_left">
              <FaAngleUp className="text-xl icon-up" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketInBottom;

import React from "react";
import Moment from "react-moment";
import { shortenText } from "../../utils/ShortText/shortenText";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Leagues = ({ evenimentePeBilet, leagues, handleSelectedMatch }) => {
  const specialLeagues = [
    61, 78, 135, 15, 283, 140, 39, 2, 3, 848, 48, 285, 34, 960,
  ];

  // check if match is added //
  const checkMatchAdded = (evenimentId) => {
    return (
      evenimentePeBilet?.filter((e) => evenimentId === e.eveniment.fixture.id)
        .length > 0 || false
    );
  };
  // end
  return (
    <div
      className={`${
        evenimentePeBilet?.length > 0 ? "mb-14" : ""
      } flex flex-col w-full`}
    >
      {leagues.map((league) => (
        <div className="flex flex-col w-full mt-1" key={league.leagueId}>
          <div
            className={`flex relative items-center overflow-hidden uppercase text-sm ${
              specialLeagues?.includes(league.leagueId)
                ? "special-league"
                : "normal-league"
            }`}
            style={{
              padding: "8px 10px",
              minHeight: "30px",
              lineHeight: "1.273",
            }}
            id={league.leagueId}
          >
            {shortenText(league.leagueCountry + " - " + league.leagueName, 45)}
          </div>
          {league.fixtures?.map((fixture) => (
            <div
              onClick={() => handleSelectedMatch(fixture)}
              key={fixture.fixture.id}
              className={`cursor-pointer flex items-center leagues-teams m-0 w-full ${
                checkMatchAdded(fixture.fixture.id) ? "second-bg" : ""
              }`}
            >
              <div
                className="flex text-center w-9 items-center justify-center"
                style={{ flexBasis: "36px", paddingRight: "5px" }}
              >
                <Moment
                  className="gray-color text-sm overflow-hidden text-ellipsis whitespace-nowrap"
                  format="HH:mm"
                >
                  {fixture.fixture.timestamp * 1000}
                </Moment>
              </div>
              <div className="flex flex-col overflow-hidden whitespace-nowrap">
                <div
                  className="flex items-center"
                  style={{ marginBottom: "2px" }}
                >
                  <LazyLoadImage
                    alt={fixture.teams.home.name}
                    src={fixture.teams.home.logo}
                    style={{
                      width: "20px",
                      margin: "0 4px",
                      verticalAlign: "top",
                      fontSize: 0,
                    }}
                  />
                  <div className="flex">
                    <span
                      className="gray-color overflow-hidden text-ellipsis whitespace-nowrap"
                      style={{
                        fontSize: "13px",
                        lineHeight: "22px",
                        display: "inline-block",
                      }}
                    >
                      {fixture.teams.home.name}
                    </span>
                  </div>
                </div>
                <div
                  className="flex items-center"
                  style={{ marginBottom: "2px" }}
                >
                  <LazyLoadImage
                    alt={fixture.teams.away.name}
                    src={fixture.teams.away.logo}
                    style={{
                      width: "20px",
                      margin: "0 4px",
                      verticalAlign: "top",
                      fontSize: 0,
                    }}
                  />
                  <div className="flex">
                    <span
                      className="gray-color overflow-hidden text-ellipsis whitespace-nowrap"
                      style={{
                        fontSize: "13px",
                        lineHeight: "22px",
                        display: "inline-block",
                      }}
                    >
                      {fixture.teams.away.name}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
      {leagues?.length === 0 ? (
        <div className="list-empty-message flex">
          <div className="list-empty-message__img-wrap">
            <img
              alt="list-empty-message"
              className="list-empty-message__img lazyLoad isLoaded"
              src="https://cdn.oddspedia.com/images/static/user-profile/empty-followers.svg"
            />
          </div>
          <div className="list-empty-message__text text-sm text-gray-500">
            Niciun rezultat gasit
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Leagues;

import { BrowserRouter, Routes, Route } from "react-router-dom";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoginStatus,
  getUser,
  selectIsLoading,
  selectIsLoggedIn,
  selectUser,
} from "./redux/features/auth/authSlice";
import { getAllMatchesV2 } from "./redux/features/rapidapi/rapidApiSlice";
import { GoogleOAuthProvider } from "@react-oauth/google";
import usePersistedState from "./utils/usePersistedState";
import { ThemeProvider } from "styled-components";
import light from "./utils/themes/light";
import dark from "./utils/themes/dark";
import { convertDate } from "./utils/ConvertDate/convertDate";
import ScrollToTop from "./utils/ScrollToTop";
import DashboardNou from "./page/Dashboard/Dashboard";
import socket from "./utils/SocketDotIo/Socket";

import Admin from "./page/admin/Admin";
import HomePage from "./page/HomePage/HomePage";
import Profile from "./page/Profile/Profile";
import TipsteriPage from "./page/Tipsteri/Page";
import Prediction from "./page/Prediction/Prediction";
import CotaDeDoiPage from "./page/CotaDeDoi/Page";
import BiletulZileiPage from "./page/BiletulZilei/Page";

import Evenimente from "./page/Evenimente/Evenimente";

import { getPredictionProfit10 } from "./redux/features/predictions/predictionsSlice";
import NotFoundPage from "./page/NotFoundPage/NotFoundPage";
import Eveniment from "./page/Eveniment/Eveniment";

axios.defaults.withCredentials = true;

function App() {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState();
  const [isSharedPrediction, setIsSharedPrediction] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [toggleAccumulator, setToggleAccumulator] = useState(false);
  const [theme, setTheme] = usePersistedState("theme", dark);
  const toggleTheme = () => {
    setTheme(theme.title === "light" ? dark : light);
  };

  useEffect(() => {
    if (theme.title === "dark") {
      document.body.classList.add("dark");
      document.body.classList.remove("light"); // Remove "light" class if present
    } else {
      document.body.classList.remove("light"); // Remove "dark" class if present
      document.body.classList.add("dark");
    }
  }, [theme]);

  const dispatch = useDispatch();

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const user = useSelector(selectUser);

  const [socketConnected, setSocketConnected] = useState(false);
  const [onlineUsers, setOnlineUsers] = useState(null);
  useEffect(() => {
    // emit "setup" event with user's data when component mounts
    socket.emit("setup", user || { _id: null, name: "guest" });

    socket.on("connection", () => {
      setSocketConnected(true);
    });

    socket.emit("get status");

    // listen for "online users" event and update state
    socket.on("online users", (onlineUsers) => {
      setOnlineUsers(onlineUsers);
    });

    // cleanup function to remove listener when component unmounts
    return () => {
      socket.off("online users");
    };
    // socket.emit("join chat", user?.name || "guest");
  }, [user]);

  useEffect(() => {
    dispatch(getLoginStatus());
    if (isLoggedIn && user === null) {
      dispatch(getUser());
    }
  }, [dispatch, isLoggedIn, theme]);

  // header

  const [showNotification, setShowNotification] = useState(false);
  const [showMatches, setShowMatches] = useState(false);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [unreadChatCount, setUnreadChatCount] = useState(0);

  const handleShowNotification = () => {
    setShowNotification(!showNotification);
    setShowMatches(false);
    setShowChat(false);
  };
  const handleShowChat = () => {
    setShowChat(!showChat);
    setShowNotification(false);
    setShowMatches(false);
    handleViewChatNotifications(0);
  };

  const [dropdown, setDropdown] = useState(false);
  const handleClick = () => {
    setDropdown(!dropdown);
  };

  const handleViewChatNotifications = () => {
    setNotificationsCount(0);
    setUnreadChatCount(0);
  };
  // header
  // handle dropdown
  const desktopRef = useRef(null);
  const mobileRef = useRef(null);

  useEffect(() => {
    // Conditionally apply styles based on showChat state
    if (showChat && mobileRef) {
      document.body.classList.add("block-scroll");
    } else {
      // If showChat is false, remove the styles
      document.body.classList.remove("block-scroll");
    }
  }, [showChat, mobileRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        desktopRef.current &&
        !desktopRef.current.contains(event.target) &&
        mobileRef.current &&
        !mobileRef.current.contains(event.target)
      ) {
        setDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [desktopRef, mobileRef]);

  // handle dropdown

  // adauga predictie

  const handleShowMatches = () => {
    const matchDate = {
      date: convertDate(new Date()),
    };
    dispatch(getAllMatchesV2(matchDate)).then(() => {
      setShowMatches(!showMatches);
      setShowNotification(false);
      setShowChat(false);
      setToggleAccumulator(true);
    });
  };

  // adauga predictie

  // get prediction with profit > 10
  useEffect(() => {
    dispatch(getPredictionProfit10());
  }, []);

  // mobile menu
  const [mobileMenu, setMobileMenu] = useState(false);
  const toggleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <ScrollToTop />

          <div id="notifications">
            <ToastContainer theme={theme.title} />
          </div>
          <GoogleOAuthProvider
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          >
            <Routes>
              <Route
                path="/"
                element={
                  <DashboardNou
                    getUser={getUser}
                    onlineUsers={onlineUsers}
                    setMessages={setMessages}
                    messages={messages}
                    showChat={showChat}
                    setShowChat={setShowChat}
                    newMessage={newMessage}
                    setNewMessage={setNewMessage}
                    setToggleAccumulator={setToggleAccumulator}
                    toggleAccumulator={toggleAccumulator}
                    handleShowMatches={handleShowMatches}
                    isSharedPrediction={isSharedPrediction}
                    setIsSharedPrediction={setIsSharedPrediction}
                    handleShowNotification={handleShowNotification}
                    handleShowChat={handleShowChat}
                    showNotification={showNotification}
                    handleClick={handleClick}
                    dropdown={dropdown}
                    handleViewChatNotifications={handleViewChatNotifications}
                    setDropdown={setDropdown}
                    showMatches={showMatches}
                    setShowMatches={setShowMatches}
                    setNotificationsCount={setNotificationsCount}
                    unreadChatCount={unreadChatCount}
                    setUnreadChatCount={setUnreadChatCount}
                    notificationsCount={notificationsCount}
                    desktopRef={desktopRef}
                    mobileRef={mobileRef}
                    toggleTheme={toggleTheme}
                    toggleMobileMenu={toggleMobileMenu}
                    setMobileMenu={setMobileMenu}
                    mobileMenu={mobileMenu}
                  />
                }
              />
              <Route
                path="/p/:handle"
                element={
                  <Profile
                    getUser={getUser}
                    onlineUsers={onlineUsers}
                    setMessages={setMessages}
                    messages={messages}
                    showChat={showChat}
                    setShowChat={setShowChat}
                    newMessage={newMessage}
                    setNewMessage={setNewMessage}
                    isSharedPrediction={isSharedPrediction}
                    setIsSharedPrediction={setIsSharedPrediction}
                    toggleMobileMenu={toggleMobileMenu}
                    setMobileMenu={setMobileMenu}
                    mobileMenu={mobileMenu}
                  />
                }
              />
              <Route path="/comingsoon" element={<HomePage />} />
              <Route path="/admin" element={<Admin />} />
              <Route
                path="/predictie/:id"
                element={
                  <Prediction
                    handleShowNotification={handleShowNotification}
                    handleShowChat={handleShowChat}
                    showChat={showChat}
                    showNotification={showNotification}
                    handleClick={handleClick}
                    dropdown={dropdown}
                    onlineUsers={onlineUsers}
                    notificationsCount={notificationsCount}
                    unreadChatCount={unreadChatCount}
                    showMatches={showMatches}
                    setShowMatches={setShowMatches}
                    setMessages={setMessages}
                    messages={messages}
                    setNewMessage={setNewMessage}
                    newMessage={newMessage}
                    getUser={getUser}
                    isSharedPrediction={isSharedPrediction}
                    setIsSharedPrediction={setIsSharedPrediction}
                    setDropdown={setDropdown}
                    desktopRef={desktopRef}
                    mobileRef={mobileRef}
                    toggleMobileMenu={toggleMobileMenu}
                    setMobileMenu={setMobileMenu}
                    mobileMenu={mobileMenu}
                  />
                }
              />
              <Route
                path="/:id/:event"
                element={
                  <Eveniment
                    handleShowNotification={handleShowNotification}
                    handleShowChat={handleShowChat}
                    showChat={showChat}
                    showNotification={showNotification}
                    handleClick={handleClick}
                    dropdown={dropdown}
                    onlineUsers={onlineUsers}
                    notificationsCount={notificationsCount}
                    unreadChatCount={unreadChatCount}
                    showMatches={showMatches}
                    setShowMatches={setShowMatches}
                    setMessages={setMessages}
                    messages={messages}
                    setNewMessage={setNewMessage}
                    newMessage={newMessage}
                    getUser={getUser}
                    isSharedPrediction={isSharedPrediction}
                    setIsSharedPrediction={setIsSharedPrediction}
                    setDropdown={setDropdown}
                    desktopRef={desktopRef}
                    mobileRef={mobileRef}
                    toggleMobileMenu={toggleMobileMenu}
                    setMobileMenu={setMobileMenu}
                    mobileMenu={mobileMenu}
                    setShowChat={setShowChat}
                  />
                }
              />
              <Route
                path="/evenimente"
                element={
                  <Evenimente
                    handleShowNotification={handleShowNotification}
                    handleShowChat={handleShowChat}
                    showChat={showChat}
                    showNotification={showNotification}
                    handleClick={handleClick}
                    dropdown={dropdown}
                    onlineUsers={onlineUsers}
                    notificationsCount={notificationsCount}
                    unreadChatCount={unreadChatCount}
                    showMatches={showMatches}
                    setShowMatches={setShowMatches}
                    setMessages={setMessages}
                    messages={messages}
                    setNewMessage={setNewMessage}
                    newMessage={newMessage}
                    getUser={getUser}
                    isSharedPrediction={isSharedPrediction}
                    setIsSharedPrediction={setIsSharedPrediction}
                    setDropdown={setDropdown}
                    desktopRef={desktopRef}
                    mobileRef={mobileRef}
                    toggleMobileMenu={toggleMobileMenu}
                    setMobileMenu={setMobileMenu}
                    mobileMenu={mobileMenu}
                  />
                }
              />
              <Route
                path="/tipsteri/"
                element={
                  <TipsteriPage
                    handleShowNotification={handleShowNotification}
                    handleShowChat={handleShowChat}
                    showChat={showChat}
                    showNotification={showNotification}
                    handleClick={handleClick}
                    dropdown={dropdown}
                    onlineUsers={onlineUsers}
                    notificationsCount={notificationsCount}
                    unreadChatCount={unreadChatCount}
                    setDropdown={setDropdown}
                    desktopRef={desktopRef}
                    mobileRef={mobileRef}
                    setMessages={setMessages}
                    messages={messages}
                    setNewMessage={setNewMessage}
                    newMessage={newMessage}
                    isSharedPrediction={isSharedPrediction}
                    setIsSharedPrediction={setIsSharedPrediction}
                    showMatches={showMatches}
                    setShowMatches={setShowMatches}
                    getUser={getUser}
                    toggleAccumulator={toggleAccumulator}
                    setToggleAccumulator={setToggleAccumulator}
                    handleShowMatches={handleShowMatches}
                    toggleMobileMenu={toggleMobileMenu}
                    setMobileMenu={setMobileMenu}
                    mobileMenu={mobileMenu}
                  />
                }
              />
              <Route
                path="/cotadedoi/:date"
                element={
                  <CotaDeDoiPage
                    handleShowNotification={handleShowNotification}
                    handleShowChat={handleShowChat}
                    showChat={showChat}
                    showNotification={showNotification}
                    handleClick={handleClick}
                    dropdown={dropdown}
                    onlineUsers={onlineUsers}
                    notificationsCount={notificationsCount}
                    unreadChatCount={unreadChatCount}
                    setDropdown={setDropdown}
                    desktopRef={desktopRef}
                    mobileRef={mobileRef}
                    setMessages={setMessages}
                    messages={messages}
                    setNewMessage={setNewMessage}
                    newMessage={newMessage}
                    isSharedPrediction={isSharedPrediction}
                    setIsSharedPrediction={setIsSharedPrediction}
                    showMatches={showMatches}
                    setShowMatches={setShowMatches}
                    getUser={getUser}
                    toggleAccumulator={toggleAccumulator}
                    setToggleAccumulator={setToggleAccumulator}
                    handleShowMatches={handleShowMatches}
                    toggleMobileMenu={toggleMobileMenu}
                    setMobileMenu={setMobileMenu}
                    mobileMenu={mobileMenu}
                  />
                }
              />
              <Route
                path="/biletul-zilei/:date"
                element={
                  <BiletulZileiPage
                    handleShowNotification={handleShowNotification}
                    handleShowChat={handleShowChat}
                    showChat={showChat}
                    showNotification={showNotification}
                    handleClick={handleClick}
                    dropdown={dropdown}
                    onlineUsers={onlineUsers}
                    notificationsCount={notificationsCount}
                    unreadChatCount={unreadChatCount}
                    setDropdown={setDropdown}
                    desktopRef={desktopRef}
                    mobileRef={mobileRef}
                    setMessages={setMessages}
                    messages={messages}
                    setNewMessage={setNewMessage}
                    newMessage={newMessage}
                    isSharedPrediction={isSharedPrediction}
                    setIsSharedPrediction={setIsSharedPrediction}
                    showMatches={showMatches}
                    setShowMatches={setShowMatches}
                    getUser={getUser}
                    toggleAccumulator={toggleAccumulator}
                    setToggleAccumulator={setToggleAccumulator}
                    handleShowMatches={handleShowMatches}
                    toggleMobileMenu={toggleMobileMenu}
                    setMobileMenu={setMobileMenu}
                    mobileMenu={mobileMenu}
                  />
                }
              />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </GoogleOAuthProvider>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  postCommentPrediction,
  deleteCommentPrediction,
  editCommentPrediction,
  addReplyToComment,
  deleteReplyCommentPrediction,
  editReplyCommentPrediction,
  likeCommentPrediction,
} from "../../redux/features/predictions/predictionsSlice";
import Comment from "../../comp/Timeline/Comment";

const PredictionComment = ({ predictionStored, showModal }) => {
  const dispatch = useDispatch();
  const { user, isLoggedIn } = useSelector((state) => state.auth);
  const { isLoadingPrediction, isLoadingActions } = useSelector(
    (state) => state.predictions
  );
  // comments

  const reversedComments = predictionStored?.commentedBy;
  const [isComments, setIsComments] = useState(false);
  const [comment, setComment] = useState("");
  const [repliesShown, setRepliesShown] = useState(3);

  const handleAddComment = (e) => {
    setComment(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const commentData = {
      predictionId: predictionStored._id,
      comment: comment,
      userId: user._id,
      name: user.name,
      photo: user.photo,
    };

    dispatch(postCommentPrediction(commentData));
    setComment("");
  };

  const handleDeleteComment = (id) => {
    const commentData = {
      predictionId: predictionStored._id,
      user: user,
      commentId: id,
    };
    console.log(commentData);
    dispatch(deleteCommentPrediction(commentData));
  };

  const handleShowMoreComments = () => {
    if (repliesShown < predictionStored?.comments) {
      setRepliesShown(predictionStored?.comments);
    } else {
      setIsComments(false);
      setRepliesShown(3);
    }
  };

  // focus textarea
  const modalRef = useRef(null);
  const textareaRef = useRef(null);
  useEffect(() => {
    if (showModal && !isLoadingPrediction) {
      const target = document.querySelector("#scroll-target");
      textareaRef.current.focus();
      if (predictionStored?.totalEvenimente > 1) {
        target.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [isLoadingPrediction]);
  //
  // comments
  return (
    <div
      className="tip-expanded__comments rounded-b bg-slate-100 darker-bg"
      style={{ padding: "10px 30px 10px 30px" }}
    >
      <div className="comments-wrapper w-full">
        <div className="comments-form bg-slate-100">
          <form className="comments-form__form" onSubmit={handleSubmit}>
            <div className="form-textarea form-textarea--resizable">
              <textarea
                ref={textareaRef}
                disabled={isLoadingActions || !isLoggedIn}
                value={comment}
                id="textarea-disc"
                onChange={handleAddComment}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit(e);
                  }
                }}
                rows="1"
                placeholder={`${
                  isLoggedIn
                    ? "Scrie un comentariu si apasa enter"
                    : "Autentifica-te pentru a lasa un comentariu"
                }`}
                className="bg-slate-100 border-none focus:border-none focus:ring-0 border-none text-gray-900 text-sm w-full placeholder:text-xs dark:bg-bgMain2 dark:placeholder-gray-400 dark:text-white mb-0 hover:ring-white"
              ></textarea>
            </div>
          </form>
        </div>
        <div className="comments relative">
          {predictionStored?.comments === 0 ? (
            <div
              className="comments__empty text-slate-500 select-none"
              style={{ fontSize: "12px" }}
            >
              Fi primul care comenteaza la predictie!
            </div>
          ) : (
            <>
              <div className="comments-item-wrapper p-4">
                {reversedComments?.slice(0, repliesShown).map((c, index) => (
                  <Comment
                    key={index}
                    user={user}
                    c={c}
                    handleDeleteComment={handleDeleteComment}
                    eveniment={predictionStored}
                    editCommentPrediction={editCommentPrediction}
                    isLoggedIn={isLoggedIn}
                    addReplyToComment={addReplyToComment}
                    isLoadingActions={isLoadingActions}
                    deleteReplyCommentPrediction={deleteReplyCommentPrediction}
                    editReplyCommentPrediction={editReplyCommentPrediction}
                    setRepliesShown={setRepliesShown}
                    repliesShown={repliesShown}
                    likeCommentPrediction={likeCommentPrediction}
                  />
                ))}
              </div>
              <div className="flex items-center justify-center">
                {predictionStored?.comments > 3 ? (
                  <button
                    onClick={handleShowMoreComments}
                    className="button button_link button_small"
                  >
                    {repliesShown < predictionStored?.comments
                      ? "Arata toate comentariile"
                      : "Ascunde toate comentariile"}
                  </button>
                ) : null}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PredictionComment;

import { useDispatch } from "react-redux";
import {
  likePrediction,
  dislikePrediction,
} from "../../redux/features/predictions/predictionsSlice";

const usePredictionDispatch = () => {
  const dispatch = useDispatch();

  const handleLikePrediction = (userId, predictionId) => {
    const likeData = {
      userId,
      predictionId,
    };
    dispatch(likePrediction(likeData));
  };

  const handleDislikePrediction = (userId, predictionId) => {
    const dislikeData = {
      userId,
      predictionId,
    };
    dispatch(dislikePrediction(dislikeData));
  };

  return { handleLikePrediction, handleDislikePrediction };
};

export default usePredictionDispatch;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Chat from "../../comp/Chat/Chat";
import Notifications from "../../comp/Notifications/Notifications";
import BottomToolbar from "../../comp/BottomToolbar/BottomToolbar";
import Footer from "../../comp/Footer/Footer";
import Header from "../../comp/Header/Header";
import VerifyAccount from "../../comp/VerifyAccount/VerifyAccount";
import Modal from "../../modals/Modal";
import ModalReset from "../../modals/ModalReset";
import ModalVerify from "../../modals/ModalVerify";
import ModalRegister from "../../modals/ModalRegister";
import ModalLoginWithCode from "../../modals/ModalLoginWithCode";
import ModalForgot from "../../modals/ModalForgot";
import ModalLogin from "../../modals/ModalLogin";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  RESETPREDICTION,
  getPredictionById,
  getAndStorePredictionById,
} from "../../redux/features/predictions/predictionsSlice";
import usePredictionDispatch from "../../utils/LikeAndDislike/LikeAndDislike";
import { getUsers } from "../../redux/features/auth/authSlice";
import {
  FaSmileBeam,
  FaSadTear,
  FaAngleDown,
  FaMinusCircle,
} from "react-icons/fa";
import { BsFillPersonFill } from "react-icons/bs";
import DuckLoader from "../../utils/Loaders/DuckLoader";
import CreatePrediction from "../../comp/CreatePrediction/CreatePrediction";
import { Helmet } from "react-helmet-async";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { MdVerified } from "react-icons/md";

const Page = ({
  handleShowNotification,
  handleShowChat,
  showChat,
  showNotification,
  handleClick,
  dropdown,
  onlineUsers,
  notificationsCount,
  unreadChatCount,
  setDropdown,
  desktopRef,
  mobileRef,
  setMessages,
  messages,
  isSharedPrediction,
  setNewMessage,
  newMessage,
  showMatches,
  setShowMatches,
  getUser,
  setIsSharedPrediction,
  toggleAccumulator,
  setToggleAccumulator,
  handleShowMatches,
  toggleMobileMenu,
  setMobileMenu,
  mobileMenu,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { isLoading, isLoadingActions, prediction, predictions } = useSelector(
    (state) => state.predictions
  );
  const { user, users, isLoggedIn, isLoadingUsers } = useSelector(
    (state) => state.auth
  );

  const currentDate = new Date(); // Get the current date

  // Get the start date of the current month (September)
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Months are zero-indexed, so add 1
  const formattedCurrentMonth =
    currentMonth < 10 ? `0${currentMonth}` : `${currentMonth}`;
  const formattedStartDateCurrentMonth = `${currentYear}-${formattedCurrentMonth}-01`;
  const lastDayCurrentMonth = new Date(currentYear, currentMonth, 0).getDate();
  const formattedEndDateCurrentMonth = `${currentYear}-${formattedCurrentMonth}-${lastDayCurrentMonth}`;

  // Get the start date of the previous month (August)
  const previousMonth = currentMonth - 1;
  const formattedPreviousMonth =
    previousMonth < 10 ? `0${previousMonth}` : `${previousMonth}`;
  const formattedStartDatePreviousMonth = `${currentYear}-${formattedPreviousMonth}-01`;
  const lastDayPreviousMonth = new Date(
    currentYear,
    previousMonth,
    0
  ).getDate();
  const formattedEndDatePreviousMonth = `${currentYear}-${formattedPreviousMonth}-${lastDayPreviousMonth}`;

  const [startDateProfit, setStartDateProfit] = useState(
    formattedStartDateCurrentMonth
  );
  const [endDateProfit, setEndDateProfit] = useState(
    formattedEndDateCurrentMonth
  );

  const [tipstersDataDays, setTipstersDataDays] = useState(3);

  const [isLoadingTipsters, setIsLoadingTipsters] = useState(false); // Add a loading state

  // Use useEffect to trigger the API call when dates change
  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingTipsters(true); // Set loading to true when starting the API call
      try {
        const usersData = {
          startDate: startDateProfit,
          endDate: endDateProfit,
        };
        await dispatch(getUsers(usersData));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setIsLoadingTipsters(false); // Set loading to false when API call is complete
    };

    fetchData(); // Call the fetchData function

    // Use setTimeout to hide the loading indicator after 5 seconds
    const loadingTimeout = setTimeout(() => {
      setIsLoadingTipsters(false);
    }, 5000); // 5000 milliseconds (5 seconds)

    // Clear the timeout if the component unmounts or if the date range changes
    return () => {
      clearTimeout(loadingTimeout);
    };
  }, [startDateProfit, endDateProfit, dispatch]);

  const handleStatsPeriod = (v) => {
    if (v === 1) {
      setStartDateProfit("2022-01-01");
      setEndDateProfit("2030-01-01");
      setTipstersDataDays(1);
    } else if (v === 2) {
      setStartDateProfit(formattedStartDatePreviousMonth);
      setEndDateProfit(formattedEndDatePreviousMonth);
      setTipstersDataDays(2);
    } else if (v == 3) {
      setStartDateProfit(formattedStartDateCurrentMonth);
      setEndDateProfit(formattedEndDateCurrentMonth);
      setTipstersDataDays(3);
    }
    setTipstersDataDays(v);
  };

  const sortedUsers = [...users].sort(
    (a, b) => b.profitByDays - a.profitByDays
  );
  const firstThreeUsers = sortedUsers.slice(0, 3);
  const restOfUsers = sortedUsers.slice(3);

  const [tipsters, setTipsters] = useState(restOfUsers);

  useEffect(() => {
    setTipsters(restOfUsers);
  }, [users]);

  const currencyFormat = (num) => {
    return num?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  // modal
  const searchParams = new URLSearchParams(location.search);
  const [showModal, setShowModal] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showForgotModal, setShowForgotModal] = useState(false);
  const [showLoginWithCodeModal, setShowLoginWithCodeModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);

  const handleClickOutside = (e) => {
    if (e.target.id === "modal-target") {
      setShowModal(false);
      setShowAuthModal(false);
      setShowForgotModal(false);
      setShowLoginWithCodeModal(false);
      setShowRegisterModal(false);
      setShowVerifyModal(false);
      setShowResetModal(false);
      dispatch(RESETPREDICTION());

      // Delete modal id parameter from URL
      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.delete("modal");
      newSearchParams.delete("id");
      newSearchParams.delete("tk");
      navigate(`${location.pathname}?${newSearchParams.toString()}`);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const [isLoadingPrediction, setIsLoadingThePrediction] = useState(false);
  const handleModal = (eveniment) => {
    if (!showModal) {
      if (!isLoadingPrediction && location.search === "") {
        // Display a loading indicator for 5 seconds
        setIsLoadingThePrediction(true);
        const predictionData = {
          _id: eveniment._id,
        };
        dispatch(getPredictionById(predictionData));
        setTimeout(() => {
          setIsLoadingThePrediction(false); // Remove loading indicator after dispatch
        }, 2000); // 5000 milliseconds (5 seconds)
      }

      // Add modal id parameter to URL
      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.set("modal", "prediction");
      newSearchParams.set("id", eveniment._id);
      navigate(`${location.pathname}?${newSearchParams.toString()}`);
      setShowModal(true);
    } else {
      if (prediction._id === eveniment._id) {
        // If the modal is already open for the clicked event, do nothing
        return;
      }
      dispatch(RESETPREDICTION());
      setShowModal(false);

      // Delete modal id parameter from URL
      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.delete("modal");
      newSearchParams.delete("id");
      navigate(`${location.pathname}?${newSearchParams.toString()}`);
    }
  };

  useEffect(() => {
    const modalPredictionId = new URLSearchParams(location.search).get("id");

    if (modalPredictionId && prediction === null) {
      setIsLoadingThePrediction(true);
      const predictionData = {
        _id: modalPredictionId,
      };
      setShowModal(true);
      dispatch(getPredictionById(predictionData));
      setTimeout(() => {
        setIsLoadingThePrediction(false); // Remove loading indicator after dispatch
      }, 2000); // 5000 milliseconds (5 seconds)
    }
  }, [location.search, predictions, showModal]);

  useEffect(() => {
    const modalPredictionId = new URLSearchParams(location.search).get("modal");

    if (modalPredictionId === "autentificare") {
      setShowAuthModal(true);
      setShowForgotModal(false);
      setShowRegisterModal(false);
    } else if (modalPredictionId === "recuperare-parola") {
      setShowForgotModal(true);
      setShowAuthModal(false);
      setShowRegisterModal(false);
    } else if (modalPredictionId === "autentificare-cod") {
      setShowLoginWithCodeModal(true);
      setShowAuthModal(false);
      setShowForgotModal(false);
      setShowRegisterModal(false);
    } else if (modalPredictionId === "inregistrare") {
      setShowRegisterModal(true);
      setShowAuthModal(false);
      setShowForgotModal(false);
    } else if (modalPredictionId === "verificare-cont") {
      setShowVerifyModal(true);
    } else if (modalPredictionId === "resetare-parola") {
      setShowResetModal(true);
    }
  }, [
    location.search,
    showAuthModal,
    showForgotModal,
    showLoginWithCodeModal,
    showRegisterModal,
    showVerifyModal,
    showResetModal,
  ]);

  // Update search params and navigate to the updated URL
  useEffect(() => {
    if (prediction) {
      if (showModal) {
        searchParams.set("modal", "prediction");
        searchParams.set("id", prediction._id);
      } else {
        searchParams.delete("modal");
        searchParams.delete("id");
      }
      navigate(`${location.pathname}?${searchParams.toString()}`);
    }
  }, [showModal, isLoadingPrediction, prediction]);

  useEffect(() => {
    if (showModal && prediction) {
      const intervalId = setInterval(() => {
        const predictionData = {
          _id: prediction._id,
        };
        dispatch(getPredictionById(predictionData));
      }, 40000);
      return () => clearInterval(intervalId);
    }
  }, [showModal, prediction]);

  // auth modal
  const handleAuthenticationModal = (modalId, show, additionalParams = {}) => {
    if (show) {
      // Add modal id parameter to URL
      const newSearchParams = searchParams;
      newSearchParams.set("modal", modalId);
      for (const key in additionalParams) {
        newSearchParams.set(key, additionalParams[key]);
      }
      navigate(`${location.pathname}?${newSearchParams.toString()}`);
    } else {
      // Delete modal id parameter from URL
      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.delete("modal");
      for (const key in additionalParams) {
        newSearchParams.delete(key);
      }
      navigate(`${location.pathname}?${newSearchParams.toString()}`);
    }
  };

  // Example usage
  const handleAuthModal = () => {
    if (!showAuthModal) {
      handleAuthenticationModal("autentificare", true);
      setShowAuthModal(true);
    } else {
      setShowAuthModal(false);
      handleAuthenticationModal("autentificare", false);
    }
  };

  const handleForgotModal = () => {
    handleAuthModal();
    if (!showForgotModal) {
      handleAuthenticationModal("recuperare-parola", true);
      setShowForgotModal(true);
    } else {
      setShowForgotModal(false);
      handleAuthenticationModal("recuperare-parola", false);
    }
  };

  const handleLoginWithCodeModal = (email) => {
    if (!showLoginWithCodeModal) {
      handleAuthenticationModal("autentificare-cod", true, { email });
      setShowLoginWithCodeModal(true);
    } else {
      setShowLoginWithCodeModal(false);
      handleAuthenticationModal("autentificare-cod", false, { email });
    }
  };

  const handleRegisterModal = () => {
    if (!showRegisterModal) {
      handleAuthenticationModal("inregistrare", true);
      setShowRegisterModal(true);
    } else {
      setShowRegisterModal(false);
      handleAuthenticationModal("inregistrare", false);
    }
  };

  const handleVerifyModal = (tk) => {
    if (!showVerifyModal) {
      handleAuthenticationModal("verificare-cont", true, { tk });
      setShowVerifyModal(true);
    } else {
      setShowVerifyModal(false);
      handleAuthenticationModal("verificare-cont", false, { tk });
    }
  };

  const handleResetModal = (tk) => {
    if (!showResetModal) {
      handleAuthenticationModal("resetare-parola", true, { tk });
      setShowResetModal(true);
    } else {
      setShowResetModal(false);
      handleAuthenticationModal("resetare-parola", false, { tk });
    }
  };

  //

  useEffect(() => {
    const body = document.querySelector("body");

    if (
      showModal ||
      showAuthModal ||
      showForgotModal ||
      showLoginWithCodeModal ||
      showRegisterModal ||
      showVerifyModal ||
      showResetModal
    ) {
      body.classList.add("body-scroll-hidden");
      return () => {
        body.classList.remove("body-scroll-hidden");
      };
    } else {
    }
  }, [
    showModal,
    showAuthModal,
    showForgotModal,
    showLoginWithCodeModal,
    showRegisterModal,
    showVerifyModal,
    showResetModal,
  ]);
  // modal

  // like dislike prediction

  const { handleLikePrediction, handleDislikePrediction } =
    usePredictionDispatch();

  // like dislike prediction

  // handle dropdown
  const [dropdownMenu, setDropdownMenu] = useState(false);
  const dropdownRef = useRef(null);

  const handleClickDropDown = () => {
    setDropdownMenu(!dropdownMenu);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownMenu(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef]);

  // handle dropdown

  // adauga predictie

  // get currentselected matches de pe ticket  din localstorage //

  const [evenimentePeBilet, setEvenimentePeBilet] = useState([]);
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("evenimentePeBilet"));
    if (items) {
      setEvenimentePeBilet(items);
    }
  }, []);
  const [isOpen, setIsOpen] = useState(false);

  // end
  // adauga predictie
  return (
    <>
      <Helmet>
        <title>Tipsteri - AnalizeSportive</title>
        <meta
          name="description"
          content="Vezi clasamentul tipsterilor AnalizeSportive în funcție de profit. Descoperă cei mai buni specialiști la ponturi pariuri sportive online în 2023"
        />
        <meta name="og:image" content="https://i.ibb.co/FnPJ7dF/cover.jpg" />
      </Helmet>
      <div className="page">
        <div className="page__main">
          <div id="react-modal-screen">
            <div id="modals">
              {showModal && (
                <Modal
                  handleModal={handleModal}
                  showModal={showModal}
                  prediction={prediction}
                  user={user}
                  isLoggedIn={isLoggedIn}
                  isLoading={isLoading}
                  isLoadingActions={isLoadingActions}
                  handleDislikePrediction={handleDislikePrediction}
                  handleLikePrediction={handleLikePrediction}
                  isLoadingPrediction={isLoadingPrediction}
                />
              )}
              {showAuthModal && (
                <ModalLogin
                  showAuthModal={showAuthModal}
                  setShowAuthModal={setShowAuthModal}
                  handleAuthModal={handleAuthModal}
                  handleForgotModal={handleForgotModal}
                  handleRegisterModal={handleRegisterModal}
                />
              )}
              {showForgotModal && (
                <ModalForgot
                  showForgotModal={showForgotModal}
                  setShowForgotModal={setShowForgotModal}
                  handleAuthModal={handleAuthModal}
                  handleForgotModal={handleForgotModal}
                  handleRegisterModal={handleRegisterModal}
                />
              )}
              {showLoginWithCodeModal && (
                <ModalLoginWithCode
                  handleLoginWithCodeModal={handleLoginWithCodeModal}
                  setShowLoginWithCodeModal={setShowLoginWithCodeModal}
                  showLoginWithCodeModal={showLoginWithCodeModal}
                />
              )}
              {showRegisterModal && (
                <ModalRegister
                  handleRegisterModal={handleRegisterModal}
                  showRegisterModal={showRegisterModal}
                  setShowRegisterModal={setShowRegisterModal}
                  setShowAuthModal={setShowAuthModal}
                  handleAuthModal={handleAuthModal}
                />
              )}
              {showVerifyModal && (
                <ModalVerify
                  setShowVerifyModal={setShowVerifyModal}
                  handleVerifyModal={handleVerifyModal}
                />
              )}
              {showResetModal && (
                <ModalReset handleResetModal={handleResetModal} />
              )}
            </div>
          </div>
          <VerifyAccount
            user={user}
            isLoading={isLoading}
            isLoggedIn={isLoggedIn}
          />
          <Header
            handleShowNotification={handleShowNotification}
            handleShowChat={handleShowChat}
            showChat={showChat}
            showNotification={showNotification}
            handleClick={handleClick}
            dropdown={dropdown}
            desktopRef={desktopRef}
            onlineUsers={onlineUsers}
            notificationsCount={notificationsCount}
            unreadChatCount={unreadChatCount}
            handleAuthModal={handleAuthModal}
            handleRegisterModal={handleRegisterModal}
            setMobileMenu={setMobileMenu}
            mobileMenu={mobileMenu}
          />
          <div className="main container">
            <div className="content">
              <div className="play-block">
                <div className="page-block page-block_cms page-block_contests">
                  <div className="content-block">
                    <div
                      className="leaderboard"
                      id="react-wagering-contest-leaderboard"
                    >
                      {isLoadingUsers ? (
                        <DuckLoader rowCount={6} />
                      ) : (
                        <div className="leaderboard-stats">
                          <div className="flex align-center w-100 justify-between">
                            <h1>Clasament tipsteri</h1>
                            <div
                              className={`tipsters-stats-tab__dropdowns `}
                              ref={dropdownRef}
                              onClick={handleClickDropDown}
                            >
                              <div
                                className={`dropdown dropdown--uppercase dropdown--light ${
                                  dropdownMenu ? "active" : ""
                                }`}
                              >
                                <button className="dropdown__toggle dropdown__tipsteri bg-white">
                                  <span
                                    title="Last week"
                                    className="t-ellipsis text-slate-900 font-normal"
                                  >
                                    {tipstersDataDays === 1
                                      ? "De la inregistrare"
                                      : tipstersDataDays === 2
                                      ? "Luna precedenta"
                                      : tipstersDataDays === 3
                                      ? "Luna curenta"
                                      : ""}
                                  </span>
                                  <span className="dropdown__toggle__profile__icon">
                                    <FaAngleDown className="text-sm text-gray-500" />
                                  </span>
                                </button>
                                <div className="dropdown__content bg-white">
                                  <div
                                    onClick={() => handleStatsPeriod(1)}
                                    title="De la inregistrare"
                                    className={`dropdown__list-item hover:text-sky-400 t-ellipsis ${
                                      tipstersDataDays === 1 ? "active" : ""
                                    }`}
                                  >
                                    <span
                                      className={`t-ellipsis ${
                                        tipstersDataDays === 1
                                          ? "text-sky-400"
                                          : ""
                                      }`}
                                    >
                                      De la inregistrare
                                    </span>
                                  </div>
                                  <div
                                    onClick={() => handleStatsPeriod(2)}
                                    title="Ultima luna"
                                    className={`dropdown__list-item hover:text-sky-400 t-ellipsis ${
                                      tipstersDataDays === 2 ? "active" : ""
                                    } `}
                                  >
                                    <span
                                      className={`t-ellipsis ${
                                        tipstersDataDays === 2
                                          ? "text-sky-400"
                                          : ""
                                      }`}
                                    >
                                      Luna precedenta
                                    </span>
                                  </div>
                                  <div
                                    onClick={() => handleStatsPeriod(3)}
                                    title="Ultima saptamana"
                                    className={`dropdown__list-item hover:text-sky-400 t-ellipsis ${
                                      tipstersDataDays === 3 ? "active" : ""
                                    }`}
                                  >
                                    <span
                                      className={`t-ellipsis ${
                                        tipstersDataDays === 3
                                          ? "text-sky-400"
                                          : ""
                                      }`}
                                    >
                                      Luna curenta
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="wagering-contest-banner">
                            <h2>Top Tipsteri anul 2023</h2>
                            <p>
                              Verifica ultimele clasamente ale tipsterilor, cine
                              se afla in varful clasamentului
                            </p>
                            <div>
                              <span className="wagering-contest-banner__time">
                                {users?.length} Tipsteri activi
                              </span>
                            </div>
                          </div>
                          <div className="flex"></div>
                          <div className="wagering-contest-leaderboard-section">
                            <div className="wagering-contest-leaderboard-section__holder">
                              {firstThreeUsers.map((user, index) => (
                                <div
                                  key={index}
                                  className="wagering-contest-leaderboard-block"
                                >
                                  <div className="wagering-contest-leaderboard-block__holder">
                                    <div className="wagering-contest-leaderboard-block__content">
                                      <Link
                                        to={`/p/${user?.handle}`}
                                        className="wagering-contest-leaderboard-block__avatar"
                                      >
                                        <div
                                          className={`user-avatar-wrap user-avatar-wrap_large level-${user.level}`}
                                        >
                                          <div className="user-avatar user-avatar_large">
                                            {user.photo ? (
                                              <img
                                                src={user.photo}
                                                alt="User avatar"
                                              />
                                            ) : null}
                                          </div>
                                          <span className="user-level-badge">
                                            {user.levelXp}
                                          </span>
                                        </div>
                                        <div className="wagering-contest-leaderboard-block__position">
                                          <span>{index + 1}</span>
                                        </div>
                                      </Link>
                                      <h4 className="flex gap-2 items-center">
                                        <span>{user.name}</span>
                                        <span className="pointer">
                                          {user?.role === "tipster" ||
                                          user?.role === "admin" ? (
                                            <MdVerified
                                              data-tooltip-id="tooltip-verified-tipster-d"
                                              data-tooltip-content="Tipster verificat"
                                              className="text-green-400"
                                            />
                                          ) : null}
                                        </span>
                                        <Tooltip
                                          id="tooltip-verified-tipster-d"
                                          data-tooltip-delay-show={200}
                                          place="right"
                                          style={{
                                            fontSize: 12,
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                            textTransform: "none",
                                            borderRadius: "12px",
                                            color: "#fff",
                                            lineHeight: "32px",
                                            backgroundColor: "#3a434b",
                                          }}
                                        />
                                      </h4>
                                      <div className="wagering-contest-info">
                                        <span className="wagering-contest-info__wagered">
                                          Profit
                                        </span>
                                        <span className="wagering-contest-reward amount-icon">
                                          <span
                                            className={`text-lg ${
                                              user?.profitByDays > 0
                                                ? "green-color"
                                                : user?.profitByDays === 0
                                                ? "gray-color"
                                                : "red-color"
                                            }`}
                                            style={{ fontWeight: "600" }}
                                          >
                                            {user?.profitByDays > 0
                                              ? "+" +
                                                currencyFormat(
                                                  user?.profitByDays
                                                )
                                              : currencyFormat(
                                                  user?.profitByDays
                                                )}
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                    <div className="wagering-contest-leaderboard-block__footer">
                                      <span className="wagering-contest-leaderboard-block__amount">
                                        <FaSmileBeam className="mr-2" />
                                        <span>{user?.wonPredictions}</span>
                                      </span>
                                      <span className="wagering-contest-leaderboard-block__amount">
                                        <FaSadTear className="mr-2 " />
                                        <span>{user?.lostPredictions}</span>
                                      </span>
                                      <span className="wagering-contest-leaderboard-block__amount">
                                        <FaMinusCircle className="mr-2" />
                                        <span>{user?.voidPredictions}</span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="trophy-table-holder">
                            <div className="table-section">
                              <table className="table wagering-contest-leaderboard-table">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Tipster</th>
                                    <th>Profit</th>
                                    <th>Castigate</th>
                                    <th className="mobile-hidden">Pierdute</th>
                                    <th className="mobile-hidden">Anulate</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {restOfUsers.map((user, index) => (
                                    <tr
                                      key={index}
                                      className="wagering-contest-position-4"
                                    >
                                      <td>
                                        <span className="wagering-contest-position">
                                          {index + 4}
                                        </span>
                                      </td>
                                      <td>
                                        <Link
                                          to={`/p/${user?.handle}`}
                                          className="link-name link-name_custom flex gap-2"
                                        >
                                          <BsFillPersonFill className="mr-1" />
                                          {user?.name}
                                          <span className="pointer">
                                            {user?.role === "tipster" ||
                                            user?.role === "admin" ? (
                                              <MdVerified
                                                data-tooltip-id="tooltip-verified-tipster-e"
                                                data-tooltip-content="Tipster verificat"
                                                className="text-green-400"
                                              />
                                            ) : null}
                                          </span>
                                          <Tooltip
                                            id="tooltip-verified-tipster-e"
                                            data-tooltip-delay-show={200}
                                            place="right"
                                            style={{
                                              fontSize: 12,
                                              paddingTop: 0,
                                              paddingBottom: 0,
                                              textTransform: "none",
                                              borderRadius: "12px",
                                              color: "#fff",
                                              lineHeight: "32px",
                                              backgroundColor: "#3a434b",
                                            }}
                                          />
                                        </Link>
                                      </td>
                                      <td>
                                        <span className="wagering-contest-bets">
                                          <span
                                            className={`text-lg ${
                                              user?.profitByDays > 0
                                                ? "green-color"
                                                : user?.profitByDays === 0
                                                ? "gray-color"
                                                : "red-color"
                                            }`}
                                          >
                                            {user?.profitByDays > 0
                                              ? "+" +
                                                currencyFormat(
                                                  user?.profitByDays
                                                )
                                              : currencyFormat(
                                                  user?.profitByDays
                                                )}
                                          </span>
                                        </span>
                                      </td>
                                      <td>
                                        <span className="wagering-contest-wagered amount-icon">
                                          <span className="icon-btc_s"></span>
                                          <span className="wagering-contest-wagered__volume">
                                            {user?.wonPredictions}
                                          </span>
                                        </span>
                                      </td>
                                      <td className="mobile-hidden">
                                        <div className="wagering-contest-reward-wrapper">
                                          <span className="wagering-contest-reward amount-icon">
                                            <span className="icon-usd_s"></span>
                                            <span className="wagering-contest-reward__amount">
                                              {user?.lostPredictions}
                                            </span>
                                          </span>
                                        </div>
                                      </td>
                                      <td className="mobile-hidden">
                                        <span className="wagering-contest-reward amount-icon">
                                          <span className="icon-decoy_s"></span>
                                          <span className="wagering-contest-reward__amount">
                                            {user?.voidPredictions}
                                          </span>
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* <div className="block block_default">
                    <h2>Contest Rules</h2>
                    <ol className="rules-list">
                      <li>
                        You can wager in any game - dice and/or sport and in any
                        currency and wagering progress will be counted with a 1%
                        house edge rate.
                      </li>
                      <li>
                        We reserve the right to exclude players from the contest
                        if a violation occurs.
                      </li>
                      <li>
                        We reserve the right to change and terminate this
                        contest.
                      </li>
                    </ol>
                  </div> */}
                  </div>
                </div>
              </div>
              <Footer />
            </div>
            <div
              className={`page__panel ${
                showNotification || showMatches || showChat
                  ? ""
                  : "page__panel_hidden"
              }`}
            >
              <div id="react-toolbar-right">
                <div
                  className={`side-panel ${
                    showNotification || showMatches || showChat
                      ? ""
                      : "side-panel_hidden"
                  }`}
                >
                  {showChat && (
                    <div className="chat" id="chat">
                      <Chat
                        user={user}
                        isLoggedIn={isLoggedIn}
                        onlineUsers={onlineUsers}
                        users={users}
                        setMessages={setMessages}
                        messages={messages}
                        newMessage={newMessage}
                        setNewMessage={setNewMessage}
                        predictions={predictions}
                        isSharedPrediction={isSharedPrediction}
                        setIsSharedPrediction={setIsSharedPrediction}
                        showChat={showChat}
                        getUser={getUser}
                        handleAuthModal={handleAuthModal}
                      />
                    </div>
                  )}

                  {showNotification && (
                    <div className="notification">
                      <Notifications user={user} handleModal={handleModal} />
                    </div>
                  )}
                  {showMatches && (
                    <div className="notification notification_game">
                      <CreatePrediction
                        evenimentePeBilet={evenimentePeBilet}
                        setEvenimentePeBilet={setEvenimentePeBilet}
                        toggleAccumulator={toggleAccumulator}
                        setToggleAccumulator={setToggleAccumulator}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        showMatches={showMatches}
                        handleShowMatches={handleShowMatches}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        setShowMatches={setShowMatches}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <BottomToolbar
            handleRegisterModal={handleRegisterModal}
            handleShowChat={handleShowChat}
            notificationsCount={notificationsCount}
            mobileRef={mobileRef}
            showChat={showChat}
            setDropdown={setDropdown}
            dropdown={dropdown}
            isLoggedIn={isLoggedIn}
            toggleMobileMenu={toggleMobileMenu}
            mobileMenu={mobileMenu}
            setMobileMenu={setMobileMenu}
          />
        </div>
      </div>
    </>
  );
};

export default Page;

import React from "react";
import { FaList } from "react-icons/fa";
import Moment from "react-moment";
import DuckLoader from "../../utils/Loaders/DuckLoader";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const EvenimenteBox = ({ leagues, isLoadingMatches, loadingLeagues }) => {
  const specialLeagues = [
    61, 78, 135, 15, 283, 140, 39, 2, 3, 848, 48, 285, 34, 960,
  ];

  const formatTeamNames = (team1, team2) => {
    const replaceSpacesWithHyphens = (str) => str.replace(/\s+/g, "-");

    const formattedTeam1 = replaceSpacesWithHyphens(team1 ?? "").toLowerCase();
    const formattedTeam2 = replaceSpacesWithHyphens(team2 ?? "").toLowerCase();

    return `${formattedTeam1}-vs-${formattedTeam2}`;
  };

  return (
    <div className="fix-margin">
      {loadingLeagues ? (
        <div className="p-4">
          <DuckLoader rowCount={4} />
        </div>
      ) : (
        leagues?.map((league) => (
          <div className="flex flex-col w-full" key={league.leagueId}>
            <div
              className={`match-list-headline flex ${
                specialLeagues?.includes(league.leagueId)
                  ? "special-league"
                  : ""
              }`}
            >
              <figure class="match-list-headline__icon">
                {league.leagueFlag === null ? (
                  "N/A"
                ) : (
                  <LazyLoadImage
                    alt={league.leagueCountry}
                    height="16"
                    src={league.leagueFlag} // use normal <img> attributes as props
                    width="16"
                  />
                )}
              </figure>
              <ul class="m-0">
                <li>
                  <a class="" title="Albania">
                    {league.leagueCountry}
                  </a>
                </li>
                <li>
                  <a class="" title="Abissnet Superiore">
                    {league.leagueName}
                  </a>
                </li>
              </ul>
            </div>
            {league.fixtures?.map((fixture) => (
              <div
                class={`match-list-item ${
                  fixture.fixture.status.long === "Halftime" ||
                  fixture.fixture.status.long === "First Half" ||
                  fixture.fixture.status.long === "Second Half"
                    ? "match-list-item--inplay"
                    : "match-list-item--no-score"
                }`}
              >
                <div class="match">
                  <Link
                    to={`/${fixture?.fixture?.id}/${formatTeamNames(
                      fixture?.teams?.home?.name,
                      fixture?.teams?.away?.name
                    )}`}
                    title={`${fixture?.teams.home.name} - ${fixture?.teams.away.name}`}
                    class="match-url match-url--flex"
                  >
                    <div class="match-state">
                      <div class="match-state-inner">
                        {fixture.fixture.status.long === "Not Started" ? (
                          <div class="match-date">
                            <span class="match-date__time">
                              <Moment
                                className="gray-color text-sm overflow-hidden text-ellipsis whitespace-nowrap"
                                format="HH:mm"
                              >
                                {fixture.fixture.timestamp * 1000}
                              </Moment>{" "}
                              <br />
                            </span>
                            <span class="match-date__status"></span>
                          </div>
                        ) : fixture.fixture.status.long === "Match Finished" ? (
                          <div class="match-status">Final</div>
                        ) : fixture.fixture.status.long ===
                          "Match Postponed" ? (
                          <div class="match-status match-status--special">
                            Amanat
                          </div>
                        ) : fixture.fixture.status.long ===
                          "Match Suspended" ? (
                          <div class="match-status match-status--special">
                            Suspendat
                          </div>
                        ) : fixture.fixture.status.long ===
                          "Match Cancelled" ? (
                          <div class="match-status match-status--special">
                            Anulat
                          </div>
                        ) : fixture.fixture.status.long === "Technical loss" ? (
                          <div class="match-status match-status--special">
                            E. Tehnica
                          </div>
                        ) : fixture.fixture.status.long === "Walkover" ? (
                          <div class="match-status match-status--special">
                            Neprezentare
                          </div>
                        ) : fixture.fixture.status.long ===
                          "Match Abandoned" ? (
                          <div class="match-status match-status--special">
                            Abandonat
                          </div>
                        ) : fixture.fixture.status.long ===
                          "Time to be defined" ? (
                          <div class="match-status match-status--special">
                            TBA
                          </div>
                        ) : fixture.fixture.status.long === "Halftime" ? (
                          <div class="match-status match-status--inplay">
                            Pauza
                          </div>
                        ) : fixture.fixture.status.long === "First Half" ? (
                          <div class="match-status match-status--inplay">
                            <div class="match-timer">
                              {fixture.fixture.status.elapsed}
                              <span class="blinking-apostrophe">'</span>
                            </div>
                          </div>
                        ) : fixture.fixture.status.long === "Second Half" ? (
                          <div class="match-status match-status--inplay">
                            <div class="match-timer">
                              {fixture.fixture.status.elapsed}
                              <span class="blinking-apostrophe">'</span>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div class="match-state-outer"></div>
                    </div>
                    <div
                      class={`match-teams ${
                        fixture.goals.home === fixture.goals.away
                          ? ""
                          : "match-teams--highlight-winner"
                      } `}
                    >
                      <div
                        class={`match-team ${
                          fixture.goals.home > fixture.goals.away
                            ? "match-team--winner"
                            : ""
                        }`}
                      >
                        <LazyLoadImage
                          alt={fixture.teams.home.name}
                          height="20"
                          src={fixture.teams.home.logo} // use normal <img> attributes as props
                          width="20"
                          class="logo-team match-team__logo image-size-sm"
                        />

                        <div class="match-team__name">
                          {fixture.teams.home.name}
                        </div>
                        <div class="match-team__widgets"> </div>
                      </div>
                      <div
                        class={`match-team ${
                          fixture.goals.away > fixture.goals.home
                            ? "match-team--winner"
                            : ""
                        }`}
                      >
                        <LazyLoadImage
                          alt={fixture.teams.away.name}
                          height="20"
                          src={fixture.teams.away.logo} // use normal <img> attributes as props
                          width="20"
                          class="logo-team match-team__logo image-size-sm"
                        />
                        <div class="match-team__name">
                          {fixture.teams.away.name}
                        </div>
                        <div class="match-team__widgets"> </div>
                      </div>
                    </div>
                    <div
                      class={`match-score ${
                        fixture.fixture.status.long === "Halftime" ||
                        fixture.fixture.status.long === "First Half" ||
                        fixture.fixture.status.long === "Second Half"
                          ? "match-score--inplay"
                          : ""
                      }`}
                    >
                      <div class="match-score__team match-score__team--home">
                        <div class="match-score-result">
                          <span
                            class={`match-score-result__score ${
                              fixture.goals.home >= fixture.goals.away
                                ? ""
                                : "muted"
                            }`}
                          >
                            {fixture.goals.home}
                          </span>
                        </div>
                      </div>
                      <div class="match-score__team match-score__team--away">
                        <div class="match-score-result">
                          <span
                            class={`match-score-result__score ${
                              fixture.goals.away >= fixture.goals.home
                                ? ""
                                : "muted"
                            }`}
                          >
                            {fixture.goals.away}
                          </span>
                        </div>
                      </div>
                    </div>
                    {fixture.fixture.status.long !== "Not Started" &&
                    fixture.fixture.status.long !== "First Half" ? (
                      <div class={`match-score`}>
                        <div class="match-score__team match-score__team--home">
                          <div class="match-score-result">
                            <span
                              class={`match-score-result__score hard-muted`}
                            >
                              {fixture.score.halftime.home}
                            </span>
                          </div>
                        </div>
                        <div class="match-score__team match-score__team--away">
                          <div class="match-score-result">
                            <span
                              class={`match-score-result__score hard-muted`}
                            >
                              {fixture.score.halftime.away}
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </Link>
                  {fixture.predictionCount > 0 ? (
                    <div class="match-favourite-btn-wrapper">
                      <button
                        class="button_small"
                        data-tooltip-id="tooltip-predictions"
                        data-tooltip-content={`${
                          fixture.predictionCount === 1
                            ? `o predictie`
                            : `${fixture.predictionCount} predictii`
                        }`}
                      >
                        {fixture.predictionCount}
                      </button>
                      <Tooltip
                        id="tooltip-predictions"
                        place="left"
                        style={{
                          fontSize: 12,
                          paddingTop: 0,
                          paddingBottom: 0,
                          textTransform: "none",
                          borderRadius: "12px",
                          color: "#fff",
                          lineHeight: "32px",
                          backgroundColor: "#3a434b",
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        ))
      )}
    </div>
  );
};

export default EvenimenteBox;

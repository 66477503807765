import { Avatar, Tooltip } from "flowbite-react";
import React, { useState, useEffect } from "react";
import Switch from "react-switch";
import { FaAngleDown, FaClipboardCheck } from "react-icons/fa";
import { getUsers } from "../../redux/features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import { Link } from "react-router-dom";
import Tipster from "./Tipster";
import DuckLoader from "../../utils/Loaders/DuckLoader";

const Tipsteri = ({ display, predictions }) => {
  const dispatch = useDispatch();
  const { users, isLoadingUsers } = useSelector((state) => state.auth);

  const currentDate = new Date(); // Get the current date

  const monthNames = {
    en: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    ro: [
      "Ianuarie",
      "Februarie",
      "Martie",
      "Aprilie",
      "Mai",
      "Iunie",
      "Iulie",
      "August",
      "Septembrie",
      "Octombrie",
      "Noiembrie",
      "Decembrie",
    ],
  };

  // Get the current month index (0-based)
  const currentMonthIndex = currentDate.getMonth();

  // Get the start date of the current month (September)
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Months are zero-indexed, so add 1
  const formattedCurrentMonth =
    currentMonth < 10 ? `0${currentMonth}` : `${currentMonth}`;
  const formattedStartDateCurrentMonth = `${currentYear}-${formattedCurrentMonth}-01`;
  const lastDayCurrentMonth = new Date(currentYear, currentMonth, 0).getDate();
  const formattedEndDateCurrentMonth = `${currentYear}-${formattedCurrentMonth}-${lastDayCurrentMonth}`;

  // Get the start date of the previous month
  const previousMonthIndex =
    currentMonthIndex - 1 >= 0 ? currentMonthIndex - 1 : 11; // Handle January (0) as previous month

  // Get the start date of the previous month (August)
  const previousMonth = currentMonth - 1;
  const formattedPreviousMonth =
    previousMonth < 10 ? `0${previousMonth}` : `${previousMonth}`;
  const formattedStartDatePreviousMonth = `${currentYear}-${formattedPreviousMonth}-01`;
  const lastDayPreviousMonth = new Date(
    currentYear,
    previousMonth,
    0
  ).getDate();
  const formattedEndDatePreviousMonth = `${currentYear}-${formattedPreviousMonth}-${lastDayPreviousMonth}`;

  const [startDateProfit, setStartDateProfit] = useState(
    formattedStartDateCurrentMonth
  );
  const [endDateProfit, setEndDateProfit] = useState(
    formattedEndDateCurrentMonth
  );
  const [monthName, setMonthName] = useState(monthNames.ro[currentMonthIndex]);
  const [tipstersDataDays, setTipstersDataDays] = useState(3);

  const [isLoading, setIsLoading] = useState(false); // Add a loading state

  // Use useEffect to trigger the API call when dates change
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Set loading to true when starting the API call
      try {
        const usersData = {
          startDate: startDateProfit,
          endDate: endDateProfit,
        };
        await dispatch(getUsers(usersData));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setIsLoading(false); // Set loading to false when API call is complete
    };

    fetchData(); // Call the fetchData function

    // Use setTimeout to hide the loading indicator after 5 seconds
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 5000); // 5000 milliseconds (5 seconds)

    // Clear the timeout if the component unmounts or if the date range changes
    return () => {
      clearTimeout(loadingTimeout);
    };
  }, [startDateProfit, endDateProfit, dispatch]);

  const handleStatsPeriod = (v) => {
    if (v === 1) {
      setStartDateProfit("2022-01-01");
      setEndDateProfit("2030-01-01");
      setTipstersDataDays(1);
    } else if (v === 2) {
      setStartDateProfit(formattedStartDatePreviousMonth);
      setEndDateProfit(formattedEndDatePreviousMonth);
      setMonthName(monthNames.ro[previousMonthIndex]);
      setTipstersDataDays(2);
    } else if (v === 3) {
      setStartDateProfit(formattedStartDateCurrentMonth);
      setEndDateProfit(formattedEndDateCurrentMonth);
      setMonthName(monthNames.ro[currentMonthIndex]);
      setTipstersDataDays(3);
    }
    setTipstersDataDays(v);
  };
  const sortedUsers = [...users]
    .sort((a, b) => b.profitByDays - a.profitByDays)
    .slice(0, 5); // This will give you the top 5 users instead of top 3

  const top3Users = sortedUsers;

  const [tipsters, setTipsters] = useState(top3Users);

  useEffect(() => {
    setTipsters(top3Users);
  }, [users]);

  const currencyFormat = (num) => {
    return num?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  // handle dropdown
  const [dropdown, setDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const handleClick = () => {
    setDropdown(!dropdown);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef]);

  // handle dropdown
  return (
    <div
      className={`token-info__tile flex-col ${!display ? "flex" : "hidden"}`}
      style={{
        flexGrow: "initial",
      }}
    >
      <section className="accordion-section__block section-info flex justify-between items-center">
        <h2>Tipsteri</h2>
        <div
          className={`tipsters-stats-tab__dropdowns `}
          ref={dropdownRef}
          onClick={handleClick}
        >
          <div
            className={`dropdown dropdown--uppercase dropdown--light ${
              dropdown ? "active" : ""
            }`}
          >
            <button className="dropdown__toggle bg-white">
              <span
                title="Last week"
                className="t-ellipsis text-slate-900 font-normal"
              >
                {tipstersDataDays === 1
                  ? "De la inregistrare"
                  : tipstersDataDays === 2
                  ? "Luna precedenta"
                  : tipstersDataDays === 3
                  ? "Luna curenta"
                  : ""}
              </span>
              <span className="dropdown__toggle__profile__icon">
                <FaAngleDown className="text-sm text-gray-500" />
              </span>
            </button>
            <div className="dropdown__content bg-white">
              <div
                onClick={() => handleStatsPeriod(1)}
                title="De la inregistrare"
                className={`dropdown__list-item hover:text-sky-400 t-ellipsis ${
                  tipstersDataDays === 1 ? "active" : ""
                }`}
              >
                <span
                  className={`t-ellipsis ${
                    tipstersDataDays === 1 ? "text-sky-400" : ""
                  }`}
                >
                  De la inregistrare
                </span>
              </div>
              <div
                onClick={() => handleStatsPeriod(2)}
                title="Ultima luna"
                className={`dropdown__list-item hover:text-sky-400 t-ellipsis ${
                  tipstersDataDays === 2 ? "active" : ""
                } `}
              >
                <span
                  className={`t-ellipsis ${
                    tipstersDataDays === 2 ? "text-sky-400" : ""
                  }`}
                >
                  Luna precedenta
                </span>
              </div>
              <div
                onClick={() => handleStatsPeriod(3)}
                title="Ultima saptamana"
                className={`dropdown__list-item hover:text-sky-400 t-ellipsis ${
                  tipstersDataDays === 3 ? "active" : ""
                }`}
              >
                <span
                  className={`t-ellipsis ${
                    tipstersDataDays === 3 ? "text-sky-400" : ""
                  }`}
                >
                  Luna curenta
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      {isLoading ? (
        <DuckLoader rowCount={5} />
      ) : (
        <>
          <div className="token-info__tile-wrapper">
            <div className="sniper-rolls__previous-game">
              <span className="text-sm text-gray-500">
                Top 5 Tipsteri{" "}
                {tipstersDataDays === 1 ? "anul 2023" : `luna ${monthName}`}
              </span>
              <ul className="previous-game__leaders mt-4">
                {tipsters?.map((t, i) => (
                  <Tipster
                    key={i}
                    t={t}
                    i={i}
                    currencyFormat={currencyFormat}
                  />
                ))}
              </ul>
            </div>
          </div>

          <div className="mt-3">
            <p style={{ fontSize: "12px", lineHeight: "18px", margin: 0 }}>
              Descopera ierarhia tipsterilor de la{" "}
              <strong>AnalizeSportive</strong> in functie de profit. Afla cine a
              avut cele mai bune rezultate in luna curenta si cine a oferit cele
              mai precise ponturi pentru pariuri sportive pe tot parcursul
              anului 2023.
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default Tipsteri;

import React from "react";
import { FaUsers } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const OnlyFollowers = ({ onlyFollowedUsers, isLoggedIn, handleCheckbox }) => {
  return (
    <div
      className="ml-2 bg-gray-100 flex items-center justify-center rounded-full"
      style={{
        width: 36,
        height: 36,
        background:
          "linear-gradient(90deg, rgb(70, 80, 89) 100%, rgb(62, 72, 81) 0px)",
      }}
    >
      <div
        data-tooltip-id="tooltip-onlyfollowers"
        data-tooltip-delay-show={200}
        data-tooltip-content={
          !isLoggedIn
            ? "Necesita autentificare"
            : "Afiseaza doar de la tipsterii pe care ii urmaresc"
        }
        disabled={!isLoggedIn}
        onClick={handleCheckbox}
        style={{ width: 28, height: 28 }}
        className={`flex flex-col flex items-center justify-center rounded-full ${
          !isLoggedIn ? "cursor-not-allowed" : "cursor-pointer"
        } ${onlyFollowedUsers ? "bg-blue-400" : "bg-white"}`}
      >
        <FaUsers
          className={`${onlyFollowedUsers ? "text-white" : "text-blue-400"}`}
        />
      </div>
      <Tooltip
        id="tooltip-onlyfollowers"
        place="left"
        className=""
        style={{
          fontSize: 12,
          paddingTop: 0,
          paddingBottom: 0,
          textTransform: "none",
          borderRadius: "12px",
          color: "#000",
          lineHeight: "32px",
          backgroundColor: "#fff",
          zIndex: 100,
          opacity: 1,
        }}
      />
    </div>
  );
};

export default OnlyFollowers;

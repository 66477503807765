import React, { useState, useRef, useEffect } from "react";
import { BsThreeDots } from "react-icons/bs";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import "moment/locale/ro";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import Reply from "./Reply";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import ShowMoreText from "react-show-more-text";
import UserAvatar from "../../utils/UserAvatar/UserAvatar";
import { MdVerified } from "react-icons/md";

const Comment = ({
  c,
  user,
  handleDeleteComment,
  eveniment,
  editCommentPrediction,
  isLoggedIn,
  addReplyToComment,
  isLoadingActions,
  deleteReplyCommentPrediction,
  editReplyCommentPrediction,
  likeCommentPrediction,
}) => {
  const dispatch = useDispatch();
  const reversedReplies = c.replies;
  const tenMinutesAgo = Date.now() - 10 * 60 * 1000;
  const createdAtTimestamp = new Date(c?.createdAt).getTime();
  const updatedAtTimestamp = new Date(c?.updatedAt).getTime();

  const [actionsButton, setActionsButton] = useState(false);

  const actionsRef = useRef();

  const handleActionsButton = () => {
    setActionsButton(!actionsButton);
  };

  const handleClickOutside = (event) => {
    if (actionsRef.current && !actionsRef.current.contains(event.target)) {
      setActionsButton(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setActionsButton(false);
  }, [handleDeleteComment]);

  const [showModal, setShowModal] = useState(false);

  const handleModal = (eveniment) => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    setActionsButton(false);
  }, [showModal]);

  useEffect(() => {
    const body = document.querySelector("body");

    if (showModal) {
      body.classList.add("modal-opened");
      return () => {
        body.classList.remove("modal-opened");
      };
    } else {
    }
  }, [showModal]);

  const [isReplies, setIsReplies] = useState(false);
  const [replyTextarea, setReplyTextarea] = useState(false);
  const [reply, setReply] = useState("");

  const toggleReplyTextarea = () => {
    setReplyTextarea(!replyTextarea);
  };

  const toggleReplies = () => {
    setIsReplies(!isReplies);
  };

  const handleAddReply = (e) => {
    setReply(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const replyData = {
      predictionId: eveniment._id,
      reply: reply,
      userId: user._id,
      commentId: c._id,
    };

    dispatch(addReplyToComment(replyData));
    setReply("");
    setReplyTextarea(!replyTextarea);
    setIsReplies(true);
  };

  const handleLikeComment = () => {
    const likeData = {
      userId: user._id,
      predictionId: eveniment._id,
      commentId: c._id,
    };
    dispatch(likeCommentPrediction(likeData));
  };
  return (
    <>
      <div
        className={`comments-item ${
          c.replies.length > 0 ? "has-border has-border-small" : ""
        }`}
      >
        <Link
          title={c.user.name}
          className="user-avatar hover:z-10 cursor-pointer mr-2"
          to={`/p/${c.user.handle}`}
          style={{ height: "43px" }}
        >
          <UserAvatar
            name={c.user.name}
            level={c.user.level}
            levelXp={c.user.levelXp}
            photo={c.user.photo}
          />
        </Link>
        <div className="comments-item__main">
          <div className="comments-item__main__meta flex items-center">
            <div className="comments-item__info items-center flex">
              <Link
                className="comments-item__user text-sm text-gray-500 font-semibold hover:text-sky-400"
                to={`/p/${c.user.handle}`}
              >
                <div className="flex gap-2 items-center">
                  <span className="text-sm ">{c.user.name}</span>
                  <span className="pointer">
                    {c.user.role === "tipster" || c.user.role === "admin" ? (
                      <MdVerified
                        data-tooltip-id="tooltip-verified-tipster-f"
                        data-tooltip-content="Tipster verificat"
                        className="text-green-400"
                      />
                    ) : null}
                  </span>
                </div>
              </Link>

              <div className="flex gap-2 items-center justify-center">
                <span
                  className="comments-item__info"
                  style={{ fontSize: "10px" }}
                >
                  <Moment locale="ro" className="" fromNow>
                    {createdAtTimestamp}
                  </Moment>
                </span>
                {c?.updated ? (
                  <span
                    className="comments-item__info text-slate-400"
                    style={{ fontSize: "10px" }}
                  >
                    (editat)
                  </span>
                ) : (
                  <span className="h-4"></span>
                )}
              </div>
            </div>
            <div className="btn-with-menu">
              <span
                className={`btn-with-menu__trigger ${
                  actionsButton ? "active" : ""
                }`}
              >
                <div
                  className={`comments-item__settings u-circle ${
                    user?._id === c?.user._id || user?.role === "admin"
                      ? ""
                      : "hidden"
                  }`}
                  onClick={handleActionsButton}
                >
                  <BsThreeDots className="text-lg pointer" />
                </div>
              </span>
              {actionsButton && (
                <div
                  className="btn-with-menu__options gray-bg-color"
                  ref={actionsRef}
                  id="buttons-here"
                >
                  {createdAtTimestamp < tenMinutesAgo &&
                  user?.role !== "admin" ? (
                    <span className="text-xs">
                      Un comentariu poate fi editat sau sters in decurs de 10
                      minute de la postare.
                    </span>
                  ) : (
                    <div className={`btn-with-menu__options__meta`}>
                      <div
                        onClick={handleModal}
                        className="text-white dropdown-button hidden"
                      >
                        <i className="flex items-center justify-center">
                          <FaEdit className="text-lg" />
                        </i>
                        Editeaza comentariu
                      </div>
                      <div
                        className="text-red-500 dropdown-button"
                        onClick={() => handleDeleteComment(c?._id)}
                      >
                        <i className="flex items-center justify-center">
                          <FaTrash className="text-lg text-red-500 " />
                        </i>
                        Sterge comentariu
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="comments-item__body ">
            <ShowMoreText
              lines={2}
              more="vezi mai mult"
              less="vezi mai putin"
              className="text-xs text-slate-500 dark:text-white select-none"
              anchorclassName="read-more-button text-blue-500"
              expanded={false}
              width={440}
              truncatedEndingComponent={"... "}
            >
              {c.comment}
            </ShowMoreText>
          </div>
          <div className="reactions-meta justify-between">
            <div
              className="flex flex-wrap reactions h-7"
              style={{ marginLeft: "-10px" }}
            >
              <button
                disabled={!isLoggedIn || isLoadingActions}
                onClick={handleLikeComment}
                className={`button_like-prediction flex items-center justify-center text-gray-500 uppercase ml-4 pointer`}
              >
                <div className="heart-btn">
                  <div
                    className={`content-hearth ${
                      c.likedBy.findIndex(
                        (x) => x?._id?.toString() === user?._id?.toString()
                      ) !== -1
                        ? "heart-active"
                        : ""
                    }`}
                  >
                    <span
                      className={`heart ${
                        c?.likedBy.findIndex(
                          (x) => x?._id?.toString() === user?._id?.toString()
                        ) !== -1
                          ? "heart-active"
                          : ""
                      }`}
                    />
                  </div>
                </div>
              </button>
              <div className="flex ml-10 user-avatars-stack">
                {c?.likedBy.slice(0, 3).map((user) => (
                  <Link
                    data-tooltip-id="tooltip-user-like"
                    data-tooltip-content={user?.name}
                    key={user._id}
                    to={`/p/${user?.handle}`}
                    className="user-avatar hover:z-10 cursor-pointer bg-gray-200  dark:bg-gray-900 dark:border-gray-900"
                  >
                    <img
                      key={user._id}
                      src={user.photo}
                      alt={`${user.name}'s photo`}
                      className="w-6 h-6 rounded-full avatar-abbreviation"
                    />
                  </Link>
                ))}
                {c?.likes === 0 ? null : (
                  <span className="select-none text-xs text-gray-500 flex items-center justify-center ml-1">
                    {c?.likes}
                  </span>
                )}
                <Tooltip
                  id="tooltip-user-like"
                  place="top"
                  style={{
                    fontSize: 12,
                    paddingTop: 0,
                    paddingBottom: 0,
                    textTransform: "none",
                    borderRadius: "12px",
                    color: "#000",
                    lineHeight: "32px",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            </div>
            <div
              className="select-none link-name text-gray-500 flex items-center justify-center pointer"
              onClick={toggleReplyTextarea}
            >
              Raspunde
            </div>
          </div>
          <div className={`tip__comments w-full `}>
            <div className="comments-wrapper w-full">
              <div
                className={`comments-item-wrapper mt-2 ${
                  replyTextarea ? "flex" : "hidden"
                }`}
              >
                <div
                  className={`comments-item w-full items-center`}
                  style={{ padding: 0 }}
                >
                  <form
                    className="comments-form__form w-full"
                    onSubmit={handleSubmit}
                  >
                    <div className="form-textarea form-textarea--resizable">
                      <textarea
                        disabled={isLoadingActions || !isLoggedIn}
                        value={reply}
                        id="textarea-disc"
                        onChange={handleAddReply}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleSubmit(e);
                          }
                        }}
                        rows="1"
                        name="comments-form-field-66871-"
                        placeholder={`${
                          isLoggedIn
                            ? "Raspunde la comentariu si apasa enter"
                            : "Autentifica-te pentru a raspunde la comentariu"
                        }`}
                        className="bg-slate-100 border-none focus:border-none focus:ring-0 border-none text-gray-900 text-sm w-full placeholder:text-xs dark:bg-bgMain2 dark:placeholder-gray-400 dark:text-white mb-0 hover:ring-white"
                      ></textarea>
                    </div>
                  </form>
                </div>
              </div>

              <div className="comments">
                <div className="comments-item-wrapper">
                  {!isReplies && c.replies.length !== 0 ? null : (
                    <div className="comments">
                      <div className="comments-item-wrapper">
                        {reversedReplies.map((r, index) => (
                          <Reply
                            r={r}
                            c={c}
                            user={user}
                            deleteReplyCommentPrediction={
                              deleteReplyCommentPrediction
                            }
                            eveniment={eveniment}
                            editReplyCommentPrediction={
                              editReplyCommentPrediction
                            }
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* {eveniment.comments === 0 ? null : ( */}

              {/* )} */}
            </div>
          </div>
        </div>
        {/* <EditCommentModal
          showModal={showModal}
          handleModal={handleModal}
          c={c}
          user={user}
          eveniment={eveniment}
          editCommentPrediction={editCommentPrediction}
        /> */}
      </div>
      {c?.replies.length === 0 ? null : (
        <div
          onClick={toggleReplies}
          className="comments-item-wrapper__action pointer"
        >
          {isReplies
            ? `Ascunde ${c?.replies.length} ${
                c?.replies.length === 1 ? "raspuns" : "raspunsuri"
              }`
            : `Arata ${c?.replies.length} ${
                c?.replies.length === 1 ? "raspuns" : "raspunsuri"
              }`}
        </div>
      )}
    </>
  );
};

export default Comment;

import React from "react";
import { FaTimes } from "react-icons/fa";

const InfoPopup = ({ isError, message, handleErrorClose }) => {
  return (
    <div
      className={`info-pop-up info-pop-up--theme-secondary ${
        !isError ? "hidden" : "flex"
      }`}
    >
      <div className="info-pop-up__icon info-pop-up__icon--circle">
        <img
          alt="Flag"
          width="28"
          height="28"
          src="https://cdn.oddspedia.com/images/static/icons/wrong.svg"
          className="lazyLoad isLoaded"
        />
      </div>
      <div
        type="button"
        onClick={handleErrorClose}
        className="info-pop-up__close"
      >
        <FaTimes className="text-xl text-gray-500" />
      </div>
      <div className="info-pop-up__heading">Ooops! Ceva nu a mers bine.</div>
      <div className="info-pop-up__body">{message}</div>
    </div>
  );
};

export default InfoPopup;

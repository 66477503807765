import React, { useRef } from "react";
import { FaAngleDown } from "react-icons/fa";

const MatchOdds = ({
  odd,
  selectedBet,
  selectedMatch,
  evenimentePeBilet,
  setSelectedBet,
  setSelectedValue,
  setSelectedOdd,
  handleValueChange,
}) => {
  const bodyRef = useRef(null);
  // check active odd list //

  const checkActiveOddList = (evenimentId, oddId) => {
    const constructor = evenimentId + "-" + oddId;
    return (
      evenimentePeBilet?.filter(
        (e) => constructor === e.eveniment.fixture.id + "-" + e.prediction.id
      ).length > 0 || false
    );
  };

  const handleOpenOdds = (event) => {
    const bodyRef = event.currentTarget.nextSibling;
    if (bodyRef.style.display === "none") {
      bodyRef.style.display = "block";
    } else {
      bodyRef.style.display = "none";
    }
    setSelectedBet(event.target.id);
    setSelectedValue("");
    setSelectedOdd("");
  };

  // check active button //
  const checkIfButtonIsSelected = (
    evenimentId,
    oddId,
    valueOdd,
    valueValue
  ) => {
    const constructor =
      evenimentId + "-" + valueOdd + "-" + valueValue + "-" + oddId;
    return (
      evenimentePeBilet?.filter(
        (e) =>
          constructor ===
          e.eveniment.fixture.id +
            "-" +
            e.prediction.odd +
            "-" +
            e.prediction.value +
            "-" +
            e.prediction.id
      ).length > 0 || false
    );
  };
  // end
  return (
    <div className="create-tip-match-info-odds__markets">
      <div className="create-tip-market">
        <div
          className="create-tip-market__head pointer"
          onClick={handleOpenOdds}
        >
          <span className="create-tip-market__head__label" id={odd.id}>
            {odd.name}
          </span>
          <FaAngleDown className="text-lg gray-color" />
        </div>
        {odd.id === 5 ||
        odd.id === 6 ||
        odd.id === 45 ||
        odd.id === 26 ||
        odd.id === 105 ||
        odd.id === 106 ||
        odd.id === 107 ||
        odd.id === 108 ||
        odd.id === 153 ||
        odd.id === 132 ||
        odd.id === 133 ||
        odd.id === 134 ||
        odd.id === 135 ||
        odd.id === 127 ||
        odd.id === 128 ||
        odd.id === 155 ||
        odd.id === 156 ||
        odd.id === 57 ||
        odd.id === 58 ||
        odd.id === 77 ||
        odd.id === 16 ||
        odd.id === 17 ||
        odd.id === 50 ||
        odd.id === 72 ? (
          <div
            className="create-tip-market__body"
            ref={bodyRef}
            style={{
              display: `${
                checkActiveOddList(selectedMatch?.fixture?.id, odd.id)
                  ? "block"
                  : "none"
              }`,
            }}
          >
            <div className="create-tip-market-odds-multiple">
              <div className="create-tip-market-odds-multiple__lines">
                <div className="create-tip-market-odds-multiple__lines__item flex">
                  <div className="create-tip-market-odds-multiple__lines__item__odds mt-2">
                    {selectedBet !== undefined
                      ? odd?.values
                          .filter((item) => item.value.includes("Peste"))
                          .map((value, i) => (
                            <div
                              key={i}
                              className="flex items-center create-tip-market-odds-multiple__lines__item__label text-sm font-normal t-ellipsis"
                              style={{
                                height: "40x",
                                minHeight: "40px",
                                margin: "6.5px",
                              }}
                            >
                              {value.value.split(" ")[1]}
                            </div>
                          ))
                      : ""}
                  </div>
                  <div className="flex flex-col w-full">
                    <div
                      className="create-tip-market-outcomes"
                      style={{
                        gridTemplateColumns: "repeat(2, 1fr)",
                      }}
                    >
                      <div className="create-tip-market-outcomes__item mr-2">
                        Peste
                      </div>
                      <div className="create-tip-market-outcomes__item">
                        Sub
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="flex w-full flex-col mr-2">
                        {selectedBet !== undefined
                          ? odd.values
                              .filter((item) => item.value.includes("Peste"))
                              .map((value, i) => (
                                <div
                                  key={i}
                                  className="create-tip-market-odds mb-2 w-full"
                                  style={{
                                    gridTemplateColumns: "repeat(1, 1fr)",
                                  }}
                                >
                                  <div
                                    onClick={() =>
                                      handleValueChange(odd, value)
                                    }
                                    className={`create-tip-market-odd pointer ${
                                      checkIfButtonIsSelected(
                                        selectedMatch?.fixture?.id,
                                        odd.id,
                                        value.odd,
                                        value.value
                                      )
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <span className="create-tip-market-odd__label"></span>
                                    <span className="create-tip-market-odd__value">
                                      {value.odd}
                                    </span>
                                  </div>
                                </div>
                              ))
                          : ""}
                      </div>

                      <div className="flex w-full flex-col">
                        {selectedBet !== undefined
                          ? odd.values
                              .filter((item) => item.value.includes("Sub"))
                              .map((value, i) => (
                                <div
                                  key={i}
                                  className="create-tip-market-odds mb-2 w-full"
                                  style={{
                                    gridTemplateColumns: "repeat(1, 1fr)",
                                  }}
                                >
                                  <div
                                    onClick={() =>
                                      handleValueChange(odd, value)
                                    }
                                    className={`create-tip-market-odd pointer ${
                                      checkIfButtonIsSelected(
                                        selectedMatch?.fixture?.id,
                                        odd.id,
                                        value.odd,
                                        value.value
                                      )
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <span className="create-tip-market-odd__label"></span>
                                    <span className="create-tip-market-odd__value">
                                      {value.odd}
                                    </span>
                                  </div>
                                </div>
                              ))
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : odd.id === 4 ||
          odd.id === 9 ||
          odd.id === 18 ||
          odd.id === 104 ||
          odd.id === 56 ||
          odd.id === 125 ||
          odd.id === 126 ||
          odd.id === 152 ||
          odd.id === 19 ||
          odd.id === 79 ? (
          <div
            className="create-tip-market__body"
            ref={bodyRef}
            style={{
              display: `${
                checkActiveOddList(selectedMatch?.fixture?.id, odd.id)
                  ? "block"
                  : "none"
              }`,
            }}
          >
            <div className="create-tip-market-odds-multiple">
              <div className="create-tip-market-odds-multiple__lines">
                <div className="create-tip-market-odds-multiple__lines__item flex">
                  <div className="create-tip-market-odds-multiple__lines__item__odds mt-2">
                    {selectedBet !== undefined
                      ? odd.values
                          .filter((item) => item.value.includes("Gazde"))
                          .map((value, i) => (
                            <div
                              key={i}
                              className="flex items-center create-tip-market-odds-multiple__lines__item__label text-sm font-normal t-ellipsis"
                              style={{
                                height: "40px",
                                minHeight: "40px",
                                margin: "6.5px",
                              }}
                            >
                              {value.value.split(" ")[1]}
                            </div>
                          ))
                      : ""}
                  </div>
                  <div className="flex flex-col w-full">
                    <div
                      className="create-tip-market-outcomes"
                      style={{
                        gridTemplateColumns: "repeat(2, 1fr)",
                      }}
                    >
                      <div className="create-tip-market-outcomes__item mr-2">
                        Acasa
                      </div>
                      <div className="create-tip-market-outcomes__item">
                        Deplasare
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="flex w-full flex-col mr-2">
                        {selectedBet !== undefined
                          ? odd.values
                              .filter((item) => item.value.includes("Gazde"))
                              .map((value, i) => (
                                <div
                                  key={i}
                                  className="create-tip-market-odds mb-2 w-full"
                                  style={{
                                    gridTemplateColumns: "repeat(1, 1fr)",
                                  }}
                                >
                                  <div
                                    onClick={() =>
                                      handleValueChange(odd, value)
                                    }
                                    className={`create-tip-market-odd pointer ${
                                      checkIfButtonIsSelected(
                                        selectedMatch?.fixture?.id,
                                        odd.id,
                                        value.odd,
                                        value.value
                                      )
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <span className="create-tip-market-odd__label"></span>
                                    <span className="create-tip-market-odd__value">
                                      {value.odd}
                                    </span>
                                  </div>
                                </div>
                              ))
                          : ""}
                      </div>

                      <div className="flex w-full flex-col">
                        {selectedBet !== undefined
                          ? odd.values
                              .filter((item) => item.value.includes("Oaspeti"))
                              .map((value, i) => (
                                <div
                                  key={i}
                                  className="create-tip-market-odds mb-2 w-full"
                                  style={{
                                    gridTemplateColumns: "repeat(1, 1fr)",
                                  }}
                                >
                                  <div
                                    onClick={() =>
                                      handleValueChange(odd, value)
                                    }
                                    className={`create-tip-market-odd pointer ${
                                      checkIfButtonIsSelected(
                                        selectedMatch?.fixture?.id,
                                        odd.id,
                                        value.odd,
                                        value.value
                                      )
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <span className="create-tip-market-odd__label"></span>
                                    <span className="create-tip-market-odd__value">
                                      {value.odd}
                                    </span>
                                  </div>
                                </div>
                              ))
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : odd.id === 7 ||
          odd.id === 10 ||
          odd.id === 31 ||
          odd.id === 38 ||
          odd.id === 24 ||
          odd.id === 25 ||
          odd.id === 47 ||
          odd.id === 97 ||
          odd.id === 52 ||
          odd.id === 92 ||
          odd.id === 93 ||
          odd.id === 94 ||
          odd.id === 95 ||
          odd.id === 96 ||
          odd.id === 78 ||
          odd.id === 102 ||
          odd.id === 103 ? (
          <div
            className="create-tip-market__body"
            ref={bodyRef}
            style={{
              display: `${
                checkActiveOddList(selectedMatch?.fixture?.id, odd.id)
                  ? "block"
                  : "none"
              }`,
            }}
          >
            <div className="create-tip-market-odds-multiple">
              <div className="create-tip-market-odds-multiple__lines">
                <div className="create-tip-market-odds-multiple__lines__item flex">
                  <div className="create-tip-market-odds-multiple__lines__item__odds mt-2">
                    {selectedBet !== undefined
                      ? odd.values.map((value, i) => (
                          <div
                            key={i}
                            className="flex items-center create-tip-market-odds-multiple__lines__item__label text-sm font-normal t-ellipsis"
                            style={{
                              height: "40px",
                              minHeight: "40px",
                              margin: "6.5px",
                            }}
                          >
                            {value.value}
                          </div>
                        ))
                      : ""}
                  </div>
                  <div className="flex flex-col w-full">
                    <div
                      className="create-tip-market-outcomes mb-2"
                      style={{
                        gridTemplateColumns: "repeat(1, 1fr)",
                      }}
                    >
                      <div className="create-tip-market-outcomes__item"></div>
                    </div>
                    <div className="flex w-full">
                      <div className="flex w-full flex-col mr-2">
                        {selectedBet !== undefined
                          ? odd.values.map((value, i) => (
                              <div
                                key={i}
                                className="create-tip-market-odds mb-2 w-full"
                                style={{
                                  gridTemplateColumns: "repeat(1, 1fr)",
                                }}
                              >
                                <div
                                  onClick={() => handleValueChange(odd, value)}
                                  className={`create-tip-market-odd pointer ${
                                    checkIfButtonIsSelected(
                                      selectedMatch?.fixture?.id,
                                      odd.id,
                                      value.odd,
                                      value.value
                                    )
                                      ? "active"
                                      : ""
                                  }`}
                                >
                                  <span className="create-tip-market-odd__label"></span>
                                  <span className="create-tip-market-odd__value">
                                    {value.odd}
                                  </span>
                                </div>
                              </div>
                            ))
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="create-tip-market__body"
            ref={bodyRef}
            style={{
              display: `${
                checkActiveOddList(selectedMatch?.fixture?.id, odd.id)
                  ? "block"
                  : "none"
              }`,
            }}
          >
            <div className="create-tip-market-odds-single">
              <div className="create-tip-market-odds-single__outcomes">
                <div
                  className="create-tip-market-outcomes"
                  style={{
                    gridTemplateColumns: `repeat(${odd.values.length}, 1fr)`,
                  }}
                >
                  {selectedBet !== undefined
                    ? odd.values.map((value, i) => (
                        <div
                          key={value?.value}
                          className="create-tip-market-outcomes__item"
                        >
                          {value?.value}
                        </div>
                      ))
                    : ""}
                </div>
                <div className="create-tip-market-odds-single__odds">
                  <div
                    className="create-tip-market-odds"
                    style={{
                      gridTemplateColumns: `repeat(${odd.values.length}, 1fr)`,
                    }}
                  >
                    {selectedBet !== undefined
                      ? odd.values.map((value, i) => (
                          <div
                            key={i}
                            onClick={() => handleValueChange(odd, value)}
                            className={`create-tip-market-odd pointer ${
                              checkIfButtonIsSelected(
                                selectedMatch?.fixture?.id,
                                odd.id,
                                value.odd,
                                value.value
                              )
                                ? "active"
                                : ""
                            }`}
                          >
                            <span className="create-tip-market-odd__label"></span>
                            <span className="create-tip-market-odd__value">
                              {value?.odd}
                            </span>
                          </div>
                        ))
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MatchOdds;

import React, { useState, useEffect, useRef } from "react";
import Chart from "react-apexcharts";
import { FaAngleDown } from "react-icons/fa";
import DuckLoader from "../../utils/Loaders/DuckLoader";

const GraphProfile = ({
  userProfile,
  chartData,
  period,
  setPeriod,
  loadingProfile,
}) => {
  // handle dropdown
  const [dropdown, setDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const handleClick = () => {
    setDropdown(!dropdown);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef]);

  // handle dropdown
  return (
    <div
      className="token-info__item _animate _fade-in _active"
      style={{ width: "100%" }}
    >
      <div className="token-info__tile flex-col relative">
        <div
          className={`user-profile-stats-tab__dropdowns `}
          ref={dropdownRef}
          onClick={handleClick}
        >
          <div
            className={`dropdown dropdown--uppercase dropdown--light ${
              dropdown ? "active" : ""
            }`}
          >
            <button className="dropdown__toggle bg-white">
              <span
                title="Last week"
                className="t-ellipsis text-slate-900 font-normal"
              >
                {period === "allTime"
                  ? "De la inregistare"
                  : period === "last30Days"
                  ? "Ultima luna"
                  : period === "lastWeek"
                  ? "Ultima saptamana"
                  : ""}
              </span>
              <span className="dropdown__toggle__profile__icon">
                <FaAngleDown className="text-sm text-gray-500" />
              </span>
            </button>
            <div className="dropdown__content bg-white">
              <div
                onClick={() => setPeriod("allTime")}
                title="De la inregistrare"
                className={`dropdown__list-item hover:text-sky-400 t-ellipsis ${
                  period === "allTime" ? "active" : ""
                }`}
              >
                <span
                  className={`t-ellipsis ${
                    period === "allTime" ? "text-sky-400" : ""
                  }`}
                >
                  De la inregistrare
                </span>
              </div>
              <div
                onClick={() => setPeriod("last30Days")}
                title="Ultima luna"
                className={`dropdown__list-item hover:text-sky-400 t-ellipsis ${
                  period === "last30Days" ? "active" : ""
                }`}
              >
                <span
                  className={`t-ellipsis ${
                    period === "last30Days" ? "text-sky-400" : ""
                  }`}
                >
                  Ultima luna
                </span>
              </div>
              <div
                onClick={() => setPeriod("lastWeek")}
                title="Ultima saptamana"
                className={`dropdown__list-item hover:text-sky-400 t-ellipsis ${
                  period === "lastWeek" ? "active" : ""
                }`}
              >
                <span
                  className={`t-ellipsis ${
                    period === "lastWeek" ? "text-sky-400" : ""
                  }`}
                >
                  Ultima saptamana
                </span>
              </div>
            </div>
          </div>
        </div>
        <section className="accordion-section__block section-info flex justify-between">
          <h2>Evolutie</h2>
        </section>

        <div className="token-info__tile-wrapper w-full flex-col">
          {loadingProfile ? (
            <div className="p-6 flex items-center justify-center">
              <DuckLoader rowCount={3} />
            </div>
          ) : (
            <Chart
              options={chartData.options}
              series={chartData.series}
              type="area"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default GraphProfile;

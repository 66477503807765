import React from "react";
import { useNavigate } from "react-router-dom";

const SuccessPost = ({
  tipComplete,
  setTipComplete,
  setDescription,
  setStartDate,
  todayDate,
}) => {
  const navigate = useNavigate();

  // handle create new tip button //
  const handleCreateNewTicket = () => {
    setTipComplete(false);
    setDescription("");
    setStartDate(todayDate);
  };

  // handle create new tip button //

  // handle view my tip button //
  const handleViewMyTip = () => {
    navigate("/profile");
    setDescription("");
  };
  // handle view my tip button //
  return (
    <div className={`create-tip-complete ${tipComplete ? "" : "hidden"}`}>
      <div className="betslip-status-message">
        <div className="bets-info__image"></div>
      </div>
      <div className="flex items-center justify-center my-4">
        <h1 className="font-bold">Adaugat cu succes!</h1>
      </div>
      <div
        className="flex flex-col items-center justify-center"
        style={{ gap: 16 }}
      >
        <button
          className="button registration button w-fit"
          onClick={handleCreateNewTicket}
        >
          Adauga predictie noua
        </button>
      </div>
      <div className="betslip-gambling-regulators text-xs text-gray-400">
        Accesul persoanelor sub 18 ani este strict interzis.
      </div>
    </div>
  );
};

export default SuccessPost;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PasswordInput from "../utils/PasswordInput/PasswordInput";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RESET, resetPassword } from "../redux/features/auth/authSlice";
import { FaTimes } from "react-icons/fa";
import { CgSpinner } from "react-icons/cg";
import { BsCheck2All } from "react-icons/bs";

const initialState = {
  password: "",
  password2: "",
};

const ModalReset = ({ handleResetModal, handleRegisterModal }) => {
  const [formData, setFormData] = useState(initialState);
  const { password, password2 } = formData;

  const [uCase, setUCase] = useState(false);
  const [num, setNum] = useState(false);
  const [passLength, setPassLength] = useState(false);

  const timesIcon = <FaTimes color="red" size={15} />;
  const checkIcon = <BsCheck2All color="green" size={15} />;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, isSuccess, message, isLoggedIn } = useSelector(
    (state) => state.auth
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const resetToken = searchParams.get("tk");
  console.log(resetToken);
  const switchIcon = (condition) => {
    if (condition) {
      return checkIcon;
    }
    return timesIcon;
  };

  useEffect(() => {
    if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
      setUCase(true);
    } else {
      setUCase(false);
    }
    if (password.match(/([0-9])/)) {
      setNum(true);
    } else {
      setNum(false);
    }
    if (password.length > 5) {
      setPassLength(true);
    } else {
      setPassLength(false);
    }
  }, [password]);

  const reset = async (e) => {
    e.preventDefault();

    if (password != password2) {
      return toast.error("Parolele nu se potrivesc");
    }

    if (password.length < 6) {
      return toast.error("Minim 6 caractere trebuie sa contina parola");
    }

    const userData = {
      password,
    };

    await dispatch(resetPassword({ userData, resetToken }));
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
      handleResetModal();
    }
    dispatch(RESET());
  }, [message, isSuccess, isLoggedIn, dispatch, navigate]);

  return (
    <div className="modal-screen modal-screen_login showModalBg">
      <div className="modal-screen-wrap" id="modal-target">
        <div className="modal-window showModalWindow">
          <div className="modal-header flex-row flex-row_center flex-row_space-between">
            <div className="modal-title">
              <div className="flex-row flex-row_space-between align-center full-width">
                <span>Resetare parola</span>
              </div>
            </div>
            <div className="flex-row flex-row_center">
              <div className="btn-close p-4" onClick={handleResetModal}>
                <FaTimes className="text-lg text-gray-500" />
              </div>
            </div>
          </div>
          <div className="modal-content">
            <div className="modal-window__content">
              <div className="welcome-logo">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/socialape-bea5b.appspot.com/o/logo.png?alt=media"
                  alt="AnalizeSportive"
                  title="AnalizeSportive"
                />
              </div>
              <div>
                <div className="welcome-text flex-row flex-row_center">
                  <span>Resetare parola</span>
                </div>
                <form className="space-y-4 md:space-y-6" onSubmit={reset}>
                  <div>
                    <label htmlFor="password">Parola</label>
                    <div className="element-input__group">
                      <PasswordInput
                        type="password"
                        name="password"
                        id="password"
                        placeholder="••••••••"
                        required=""
                        value={password}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="password">Confirmare parola</label>
                    <div className="element-input__group">
                      <PasswordInput
                        type="password"
                        name="password2"
                        id="password2"
                        placeholder="••••••••"
                        required=""
                        value={password2}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="block-row">
                    <div className="info-block-component default-color">
                      <div className="info-block-component__icon">
                        <span className="icon-info_outline"></span>
                      </div>
                      <div
                        className="flex p-4 w-full flex-col"
                        style={{ gap: 8 }}
                      >
                        <div className="flex items-center">
                          {switchIcon(uCase)}
                          <span className="ml-2">Litere mici si majuscule</span>
                        </div>
                        <div className="flex items-center">
                          {switchIcon(num)}
                          <span className="ml-2">Numar (0-9)</span>
                        </div>
                        <div className="flex items-center">
                          {switchIcon(passLength)}
                          <span className="ml-2">Cel putin 6 caractere</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="block-row_custom-middle button-flex-holder">
                    <button disabled={isLoading} type="submit">
                      {isLoading ? (
                        <>
                          <span className="flex items-center justify-center">
                            <CgSpinner className="animate-spin h-5 w-5 mr-3" />
                            Schimbare parola...
                          </span>
                        </>
                      ) : (
                        "Schimba parola"
                      )}
                    </button>
                  </div>

                  <div className="help-text text-center">
                    <span>
                      Nu ai cont inca?
                      <span onClick={handleRegisterModal} className="ml-1 link">
                        Inregistrare
                      </span>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalReset;

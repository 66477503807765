import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword, RESET } from "../redux/features/auth/authSlice";
import { validateEmail } from "../redux/features/auth/authService";
import { FaTimes } from "react-icons/fa";

const ModalForgot = ({
  handleAuthModal,
  handleForgotModal,
  handleRegisterModal,
}) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");

  const { isLoading } = useSelector((state) => state.auth);

  const forgot = async (e) => {
    e.preventDefault();

    if (!email) {
      return toast.error("Please enter an email");
    }

    if (!validateEmail(email)) {
      return toast.error("Please enter a valid email");
    }

    const userData = {
      email,
    };

    await dispatch(forgotPassword(userData));
    await dispatch(RESET(userData));
    setEmail("");
    handleForgotModal();
  };

  return (
    <div className="modal-screen modal-screen_login showModalBg">
      <div className="modal-screen-wrap" id="modal-target">
        <div className="modal-window showModalWindow">
          <div className="modal-header flex-row flex-row_center flex-row_space-between">
            <div className="modal-title">
              <div className="flex-row flex-row_space-between align-center full-width">
                <span>Recuperare parola</span>
              </div>
            </div>
            <div className="flex-row flex-row_center">
              <div className="btn-close p-4" onClick={handleForgotModal}>
                <FaTimes className="text-lg text-gray-500" />
              </div>
            </div>
          </div>
          <div className="modal-content">
            <div className="modal-window__content">
              <div className="welcome-logo">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/socialape-bea5b.appspot.com/o/logo.png?alt=media"
                  alt="AnalizeSportive"
                  title="AnalizeSportive"
                />
              </div>
              <div>
                <div className="welcome-text flex-row flex-row_center">
                  <span>Recuperare parola</span>
                </div>

                <form className="space-y-4 md:space-y-6" onSubmit={forgot}>
                  <div>
                    <label htmlFor="email">Adresa de Email</label>
                    <div className="element-input__group">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="nume@email.com"
                        required=""
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="button-flex-holder button-flex-holder_center">
                    <Link
                      to="?modal=autentificare"
                      className="button button_secondary"
                    >
                      Inapoi
                    </Link>
                    <button disabled={isLoading} type="submit">
                      Recuperare parola
                    </button>
                  </div>

                  <div className="help-text text-center">
                    <span>
                      Nu ai cont inca?
                      <span onClick={handleRegisterModal} className="ml-1 link">
                        Inregistrare
                      </span>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalForgot;

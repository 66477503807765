import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PasswordInput from "../utils/PasswordInput/PasswordInput";
import { useDispatch, useSelector } from "react-redux";
import { validateEmail } from "../redux/features/auth/authService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  login,
  loginWithGoogle,
  RESET,
  sendLoginCode,
} from "../redux/features/auth/authSlice";
import { FaTimes } from "react-icons/fa";
import { CgSpinner } from "react-icons/cg";

const initialState = {
  email: "",
  password: "",
};

const ModalLogin = ({
  setShowAuthModal,
  handleAuthModal,
  handleForgotModal,
  handleRegisterModal,
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [formData, setFormData] = useState(initialState);
  const { email, password } = formData;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, isLoggedIn, isSuccess, message, isError, twoFactor } =
    useSelector((state) => state.auth);

  const loginUser = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      return toast.error("Toate campurile sunt obligatorii");
    }

    if (password.length < 6) {
      return toast.error("Minim 6 caractere trebuie sa contina parola");
    }

    if (!validateEmail(email)) {
      return toast.error("Te rugam sa introduci un email valid");
    }

    const userData = {
      email,
      password,
    };

    await dispatch(login(userData));
  };

  useEffect(() => {
    if (isSuccess && isLoggedIn) {
      // Delete modal id parameter from URL
      setShowAuthModal(false);
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete("modal");
      navigate("/");
    }

    if (isError && twoFactor) {
      dispatch(sendLoginCode(email));
      navigate(`?modal=autentificare-cod&email=${email}`);
    }

    dispatch(RESET());
  }, [
    isLoggedIn,
    isSuccess,
    dispatch,
    navigate,
    isError,
    email,
    twoFactor,
    searchParams,
  ]);

  const googleLogin = async (credentialResponse) => {
    console.log(credentialResponse);
    await dispatch(
      loginWithGoogle({ userToken: credentialResponse.credential })
    );
  };
  return (
    <div className="modal-screen modal-screen_login showModalBg">
      <div className="modal-screen-wrap" id="modal-target">
        <div className="modal-window showModalWindow">
          <div className="modal-header flex-row flex-row_center flex-row_space-between">
            <div className="modal-title">
              <div className="flex-row flex-row_space-between align-center full-width">
                <span>Autentificare</span>
              </div>
            </div>
            <div className="flex-row flex-row_center">
              <div className="btn-close p-4" onClick={handleAuthModal}>
                <FaTimes className="text-lg text-gray-500" />
              </div>
            </div>
          </div>
          <div className="modal-content">
            <div className="modal-window__content">
              <div className="welcome-logo">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/socialape-bea5b.appspot.com/o/logo.png?alt=media"
                  alt="AnalizeSportive"
                  title="AnalizeSportive"
                />
              </div>
              <div>
                <div className="welcome-text flex-row flex-row_center">
                  <span>Autentificare</span>
                </div>

                <form className="space-y-4 md:space-y-6 " onSubmit={loginUser}>
                  <div>
                    <label htmlFor="email">Adresa de Email</label>
                    <div className="element-input__group">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="nume@email.com"
                        required=""
                        value={email}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="password">Parola</label>

                    <div className="element-input__group">
                      <PasswordInput
                        type="password"
                        name="password"
                        id="password"
                        placeholder="••••••••"
                        required=""
                        value={password}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <span onClick={handleForgotModal} className="link">
                      Ai uitat parola?
                    </span>
                  </div>
                  <div className="block-row_custom-middle button-flex-holder">
                    <button disabled={isLoading} type="submit">
                      {isLoading ? (
                        <>
                          <span className="flex items-center justify-center">
                            <CgSpinner className="animate-spin h-5 w-5 mr-3" />
                            Autentificare...
                          </span>
                        </>
                      ) : (
                        "Autentificare"
                      )}
                    </button>
                  </div>
                  <div className="help-text text-center">
                    <span>
                      Nu ai cont inca?
                      <span onClick={handleRegisterModal} className="ml-1 link">
                        Inregistrare
                      </span>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalLogin;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RESET, verifyUser } from "../redux/features/auth/authSlice";
import { useLocation } from "react-router-dom";
import { CgSpinner } from "react-icons/cg";
import { FaInfoCircle, FaTimes } from "react-icons/fa";
import Avatar from "react-avatar-edit";

const ModalChangeAvatar = ({
  showAvatarModal,
  handleAvatarModal,
  setProfileImage,
  setImagePreview,
  imagePreview,
  saveProfile,
  setShowAvatarModal,
  user,
  isLoading,
  isSuccess,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const verificationToken = searchParams.get("tk");

  const [src, setSrc] = useState(null);

  const onClose = () => {
    setImagePreview(null);
  };

  const onCrop = (view) => {
    setImagePreview(view);
    fetch(view)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], "File name", { type: "image/png" });
        setProfileImage(file);
      });
  };

  return (
    <div className="modal-screen modal-screen_login showModalBg">
      <div className="modal-screen-wrap" id="modal-target">
        <div className="modal-window showModalWindow">
          <div className="modal-header flex-row flex-row_center flex-row_space-between">
            <div className="modal-title">
              <div className="flex-row flex-row_space-between align-center full-width">
                <span>Schimba poza de profil</span>
              </div>
            </div>
            <div className="flex-row flex-row_center">
              <div className="btn-close p-4" onClick={handleAvatarModal}>
                <FaTimes className="text-lg text-gray-500" />
              </div>
            </div>
          </div>
          <div className="modal-content">
            <div className="modal-window__content">
              <div className="welcome-logo">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/socialape-bea5b.appspot.com/o/logo.png?alt=media"
                  alt="AnalizeSportive"
                  title="AnalizeSportive"
                />
              </div>
              <div className="flex p-4">
                <div className="flex justify-between items-center w-full flex-col">
                  <div
                    className="o-scrollbar overflow-auto overscroll-contain scrollbar-thin scrollbar-thumb-slate-400 scrollbar-track-gray-100"
                    style={{ width: "400px", height: "200px" }}
                  >
                    <Avatar
                      borderStyle={{
                        border: "1px solid #eee",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      label="Alege imagine"
                      labelStyle={{ fontSize: "14px", cursor: "pointer" }}
                      width={400}
                      height={200}
                      onCrop={onCrop}
                      onClose={onClose}
                      src={src}
                      cropColor="#00b1ff"
                    />
                  </div>
                  <div className="block-row">
                    <div className="info-block-component default-color">
                      <div className="info-block-component__text">
                        Va rugam sa incarcati o imagine cu o dimensiune minimă
                        de 512px
                      </div>
                    </div>
                  </div>
                  <button
                    disabled={isLoading}
                    onClick={saveProfile}
                    className="disabled:cursor-not-allowed button_medium"
                    type="button"
                  >
                    {isLoading ? (
                      <>
                        <span className="flex items-center justify-center">
                          <CgSpinner className="animate-spin h-5 w-5 mr-3" />
                          Actualizare...
                        </span>
                      </>
                    ) : (
                      "Actualizeaza"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalChangeAvatar;

import React, { useState } from "react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";

const PasswordInput = ({
  placeholder,
  value,
  onChange,
  name,
  onPaste,
  className,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="relative">
      <input
        type={showPassword ? "text" : "password"}
        placeholder={placeholder}
        required
        className={className}
        name={name}
        value={value}
        onChange={onChange}
        onPaste={onPaste}
      />
      <div
        className="absolute top-5 right-5 cursor-pointer"
        onClick={togglePassword}
      >
        {showPassword ? (
          <EyeIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        ) : (
          <EyeSlashIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        )}
      </div>
    </div>
  );
};

export default PasswordInput;

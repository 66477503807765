import api from "../../../utils/api";

// Get all matches
const getMatches = async () => {
  const response = await api.get("matches/allmatches");
  return response.data;
};

// Get all matches by date
const getMatchesByDate = async (matchDate) => {
  const response = await api.post("matches/matchbydate", matchDate);
  return response.data;
};

// Approve match
const approveMatch = async (matchData) => {
  const response = await api.post("matches/approvematch", matchData);
  return response.data;
};

// Approve eveniment
const approveEveniment = async (matchData) => {
  const response = await api.post("matches/approveeveniment", matchData);
  return response.data;
};

// Add match
const addMatch = async (matchRequest) => {
  const response = await api.post("matches/addmatch", matchRequest);
  return response.data;
};

// Delete match
const deleteMatch = async (id) => {
  const response = await api.delete(`matches/${id}`);
  return response.data.message;
};

// Get all predictions by event
const getAllPredictionsSortByEveniment = async ({ date }) => {
  const response = await api.get(
    `matches/allpredictionssortbyeveniment?date=${date}`
  );
  return response.data;
};

const matchesService = {
  getMatches,
  approveMatch,
  deleteMatch,
  addMatch,
  getMatchesByDate,
  approveEveniment,
  getAllPredictionsSortByEveniment,
};

export default matchesService;

import React, { useEffect, useState } from "react";
import { BsCheck2All } from "react-icons/bs";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { FaTimes } from "react-icons/fa";
import { changePassword, RESET } from "../redux/features/auth/authSlice";
import PasswordInput from "../utils/PasswordInput/PasswordInput";
import { CgSpinner } from "react-icons/cg";

const ModalChangePassword = ({
  handleChangePasswordModal,
  showChangePasswordModal,
  setShowChangePasswordModal,
}) => {
  const { isLoading } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const initialStatePassword = {
    oldPassword: "",
    password: "",
    password2: "",
  };

  const [formData, setFormData] = useState(initialStatePassword);
  const { oldPassword, password, password2 } = formData;

  const [uCase, setUCase] = useState(false);
  const [num, setNum] = useState(false);
  const [passLength, setPassLength] = useState(false);

  const timesIcon = <FaTimes color="red" size={15} />;
  const checkIcon = <BsCheck2All color="green" size={15} />;

  const switchIcon = (condition) => {
    if (condition) {
      return checkIcon;
    }
    return timesIcon;
  };

  useEffect(() => {
    if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
      setUCase(true);
    } else {
      setUCase(false);
    }
    if (password.match(/([0-9])/)) {
      setNum(true);
    } else {
      setNum(false);
    }
    if (password.length > 5) {
      setPassLength(true);
    } else {
      setPassLength(false);
    }
  }, [password]);

  const handleInputPassword = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const updatePassword = async (e) => {
    e.preventDefault();

    if (!oldPassword || !password || !password2) {
      return toast.error("All field are required");
    }

    if (password != password2) {
      return toast.error("Passwords do not match");
    }

    const userData = {
      oldPassword,
      password,
    };

    await Promise.all([
      dispatch(changePassword(userData)),
      dispatch(RESET(userData)),
    ]).then(() => {
      handleChangePasswordModal();
      setFormData(initialStatePassword);
    });
  };

  return (
    <div className="modal-screen modal-screen_login showModalBg">
      <div className="modal-screen-wrap" id="modal-target">
        <div className="modal-window showModalWindow">
          <div className="modal-header flex-row flex-row_center flex-row_space-between">
            <div className="modal-title">
              <div className="flex-row flex-row_space-between align-center full-width">
                <span>Schimbare parola</span>
              </div>
            </div>
            <div className="flex-row flex-row_center">
              <div
                className="btn-close p-4"
                onClick={handleChangePasswordModal}
              >
                <FaTimes className="text-lg text-gray-500" />
              </div>
            </div>
          </div>
          <div className="modal-content">
            <div className="modal-window__content">
              <div className="welcome-logo">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/socialape-bea5b.appspot.com/o/logo.png?alt=media"
                  alt="AnalizeSportive"
                  title="AnalizeSportive"
                />
              </div>
              <div>
                <div className="welcome-text flex-row flex-row_center">
                  <span>Schimbare parola</span>
                </div>

                <form onSubmit={updatePassword} className="text-center">
                  <div className="flex w-full flex-col justify-between">
                    <div className="flex flex-col w-full">
                      <PasswordInput
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-bgMain dark:border-gray-800  dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-900 dark:focus:border-gray-900"
                        placeholder="Parola curenta"
                        name="oldPassword"
                        value={oldPassword}
                        onChange={handleInputPassword}
                      />
                    </div>
                    <div className="flex flex-col w-full my-4">
                      <PasswordInput
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-bgMain dark:border-gray-800  dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-900 dark:focus:border-gray-900"
                        placeholder="Parola noua"
                        name="password"
                        value={password}
                        onChange={handleInputPassword}
                      />
                    </div>
                    <div className="flex flex-col w-full">
                      <PasswordInput
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-bgMain dark:border-gray-800  dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-900 dark:focus:border-gray-900"
                        placeholder="Confirma parola noua"
                        name="password2"
                        value={password2}
                        onChange={handleInputPassword}
                      />
                    </div>
                  </div>

                  <div className="block-row">
                    <div className="info-block-component default-color">
                      <div className="info-block-component__icon">
                        <span className="icon-info_outline"></span>
                      </div>
                      <div
                        className="flex p-4 w-full flex-col"
                        style={{ gap: 8 }}
                      >
                        <div className="flex items-center">
                          {switchIcon(uCase)}
                          <span className="ml-2">Litere mici si majuscule</span>
                        </div>
                        <div className="flex items-center">
                          {switchIcon(num)}
                          <span className="ml-2">Numar (0-9)</span>
                        </div>
                        <div className="flex items-center">
                          {switchIcon(passLength)}
                          <span className="ml-2">Cel putin 6 caractere</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    disabled={isLoading}
                    type="submit"
                    className="mt-4 text-white hover:text-white bg-buttonPrimary hover:bg-buttonPrimary2 font-medium rounded-lg text-sm px-4 py-2 text-center dark:border-buttonPrimary dark:text-gray-900 dark:hover:text-gray-900 dark:hover:bg-buttonPrimary2 w-40"
                  >
                    {isLoading ? (
                      <>
                        <span className="flex items-center justify-center">
                          <CgSpinner className="animate-spin h-5 w-5 mr-3" />
                          Schimbare parola...
                        </span>
                      </>
                    ) : (
                      "Schimba parola"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalChangePassword;
